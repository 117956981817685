import { useWizardDataStore, useWizardStepStore } from "@components";
import { type AbstractWizardStep, type AbstractWizardStepInitialData, type AbstractWizardStepValueTypes, AbstractWizardSteps } from "../common";

export const AbstractsBulkActionStoreKey = "abstract-wizard-bulk-action";

export const useAbstractsBulkActionDataStore = () => {
	return {
		stepStore: useWizardStepStore<AbstractWizardStep>(AbstractsBulkActionStoreKey, AbstractWizardSteps),
		dataStore: useWizardDataStore<AbstractWizardStep, AbstractWizardStepValueTypes, AbstractWizardStepInitialData>(AbstractsBulkActionStoreKey),
	};
};
