import Period from "@infrastructure/components/interface/period/Period";
import DateService from "@infrastructure/services/dates/date.service";
import { Cultures, DefaultCulture, useUser } from "@mykey4/core";

type Props = {
	dateStartIso: string;
	dateEndIso: string;
};
const PeriodContainer = (props: Props) => {
	const { dateStartIso, dateEndIso } = props;
	// #region 'Definitions'
	const authentication = useUser();

	const culture = Cultures[authentication.user.locale].locale;
	// #endregion 'Definitions

	const startDateLocaleStringFormatDependingOnEndDate = (
		dateFromIso: string,
		dateToIso: string,
		culture: string = DefaultCulture.locale,
	): { dateStart: string; dateEnd: string } => {
		const datetimeFrom = DateService.Convert.GetDateFromIsoString2(dateFromIso);
		const datetimeTo = DateService.Convert.GetDateFromIsoString2(dateToIso);
		const dateObjectFrom = datetimeFrom.toObject();
		const dateObjectTo = datetimeTo.toObject();

		const dateEnd = DateService.Format.FullDate({
			date: datetimeTo,
			culture,
		});

		if (dateObjectFrom.year !== dateObjectTo.year) {
			// years are different: display start date day, month and year
			return {
				dateStart: DateService.Format.FullDate({
					date: datetimeFrom,
					culture,
				}),
				dateEnd,
			};
		}
		if (dateObjectFrom.month !== dateObjectTo.month) {
			// months are different: display start date day and month
			return {
				dateStart: datetimeFrom.setLocale(culture).toLocaleString({
					month: "long",
					day: "numeric",
				}),
				dateEnd,
			};
		}
		// years and months are the same: display start date day only
		return {
			dateStart: datetimeFrom.setLocale(culture).toLocaleString({
				day: "numeric",
			}),
			dateEnd,
		};
	};
	const period = startDateLocaleStringFormatDependingOnEndDate(dateStartIso, dateEndIso, culture);

	// #region 'Rendering'
	return <Period dateFrom={period.dateStart} dateTo={period.dateEnd} />;
	// #endregion 'Rendering'
};

export default PeriodContainer;
