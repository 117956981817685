import { MenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import ClashesFilters from "@application/components/_common/Clashes/ClashesFilters";
import ClashesRender from "@application/components/_common/Clashes/ClashesRender";
import ClashesSkeleton from "@application/components/_common/Clashes/ClashesSkeleton";
import { EnumSessionFacultyClashesEntityType } from "@application/enum/Clashes.enum";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import ClashesFactory from "@application/factory/clashes/ClashesFactory";
import GetTranslationsForClashes from "@application/helpers/translations/clashes/GetTranslationsForClashes";
import { useSessionStatusConfigurations } from "@configurations";
import type { ISessionFacultyClashesModel } from "@domain/model/clashes.model";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import { EMenuPath, type IK4Card, NoData, Services, queryFilters, queryStringPagination, useSnackBarHook } from "@key4-front-library/core";
import { useContextModule, useNotificationContext } from "@key4-front-library/core/Contexts";
import { Stack } from "@mui/material";
import { FilteringOperator, QUERY_KEYS_PROGRAMME } from "@mykey4/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const entityType = [EnumSessionFacultyClashesEntityType.ALL, EnumSessionFacultyClashesEntityType.SESSION, EnumSessionFacultyClashesEntityType.INVITATION];

const SessionClashes = () => {
	const { sendSuccess, sendError } = useSnackBarHook();
	const { client, event } = useContextModule();
	const { fetchCounterActiveClashes } = useNotificationContext();
	const { sessionId } = useParams();
	const translations = GetTranslationsForClashes();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const [clashes, setClashes] = useState<Array<ISessionFacultyClashesModel>>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [tabIndex, setTabIndex] = useState<number>(0);
	const [isOnlyActive, setIsOnlyActive] = useState<boolean>(true);

	const sessionStatusConfigurations = useSessionStatusConfigurations();
	const detailsConfig = MenuActionsConfigurations[MenuActionsReference.DETAILS];

	const menuItems = [
		{
			label: translations.buttons.details,
			icon: detailsConfig.icon,
			handleClick: (data: IK4Card) => handleSessionClick(data),
		},
	];

	const fetchClashes = useCallback(
		(clientId: string, eventId: string, sessionId: string, entityType: Array<string>, isOnlyActive: boolean) => {
			setIsLoading(true);
			Services.Events.Programme.SessionsService.getListPaginedClashes(clientId, eventId, sessionId, [
				...queryFilters(
					`ignoredDate${isOnlyActive ? FilteringOperator.Equal : FilteringOperator.NotEqual}null${FilteringOperator.And}entityType${
						FilteringOperator.Equal + entityType[tabIndex]
					}`,
				),
				...queryStringPagination({
					page: 0,
					pageSize: 100,
				}),
			])
				.then((_response: any) => {
					const { data } = _response;
					setClashes(data);
					void queryClient.invalidateQueries({
						queryKey: [...QUERY_KEYS_PROGRAMME.clashes, "getList", client.id, eventId, sessionId],
					});
				})
				.catch(sendError)
				.finally(() => setIsLoading(false));
		},
		[fetchCounterActiveClashes, tabIndex],
	);

	useEffect(() => {
		if (sessionId) {
			fetchClashes(client.id, event.id, sessionId, entityType, isOnlyActive);
		}
	}, [event, sessionId, tabIndex, isOnlyActive, fetchClashes]);

	const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	const handleIsOnlyActiveChange = () => {
		setIsOnlyActive((prev) => !prev);
	};

	const handleSessionClick = (session: IK4Card) => {
		navigate(["", client.key, event.key, EMenuPath.SESSIONS, session.id].join("/"));
	};

	const handleClashesUpdate = (clientId: string, eventId: string, clashId: string, comments: string, isIgnored: boolean, snackbarMessage?: string) => {
		Services.Events.Programme.ClashesService.patch(clientId, eventId, clashId, {
			comments,
			isIgnored,
		})
			.then(() => {
				if (sessionId) {
					fetchClashes(client.id, event.id, sessionId, entityType, isOnlyActive);
					if (snackbarMessage) sendSuccess(snackbarMessage);
				}
			})
			.catch(sendError);
	};

	return (
		<AppBox>
			<Stack spacing={2}>
				<ClashesFilters tabIndex={tabIndex} isOnlyActive={isOnlyActive} onTabChange={handleTabChange} OnIsOnlyActiveChange={handleIsOnlyActiveChange} />
				{!event || isLoading ? (
					<ClashesSkeleton quantity={4} />
				) : (
					<Stack spacing={2}>
						{clashes && clashes.length > 0 ? (
							clashes.map((clash) => {
								return (
									<ClashesRender
										key={clash.id}
										clashes={ClashesFactory.TransformSessionClashesDataForReading({
											clash,
											client: client.key,
											event,
											translations,
											sessionStatusConfigurations,
										})}
										translations={translations}
										handleClashesUpdate={handleClashesUpdate}
										menuItems={menuItems}
										handleCardClick={handleSessionClick}
									/>
								);
							})
						) : (
							<NoData />
						)}
					</Stack>
				)}
			</Stack>
		</AppBox>
	);
};

export default SessionClashes;
