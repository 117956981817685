import { type SpeakerRead, type SpeakerWrite, getPresentationSpeaker, postPresentationsSpeakers, postSpeaker, putSpeaker } from "@api";
import { API_VERSION_QUERY_PARAM, type BodyPostGet, QUERY_KEYS_PROGRAMME, type UseMutationArgs, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export type UsePresentationsSpeakersArgs<TData = Array<SpeakerRead>> = UseQueryArgs<Array<SpeakerRead>, TData> & {
	body: BodyPostGet;
};

export const usePresentationsSpeakers = <TData = Array<SpeakerRead>>(
	clientId: string,
	eventId: string,
	args: UsePresentationsSpeakersArgs<TData>,
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, body, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.speakers, ...queryKeyPart, "GetPost", clientId, eventId, body, queryStrings],
		queryFn: ({ signal }) =>
			postPresentationsSpeakers({
				clientId,
				eventId,
				body,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export const usePresentationSpeaker = (
	clientId: string,
	eventId: string,
	sessionId: string,
	presentationId: string,
	participantId: string,
	args: UseQueryArgs<SpeakerRead> = {},
): UseQueryResult<SpeakerRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.speakers, ...queryKeyPart, "Get", clientId, eventId, sessionId, presentationId, participantId, queryStrings],
		queryFn: ({ signal }) =>
			getPresentationSpeaker({
				clientId,
				eventId,
				sessionId,
				presentationId,
				participantId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export interface SpeakerCreateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	participantId: string;
	body: SpeakerWrite;
	signal?: AbortSignal;
}

export const useSpeakerCreate = (args: UseMutationArgs<SpeakerCreateRequest, string>): UseMutationResult<string, Error, SpeakerCreateRequest> => {
	return useMutation({
		mutationFn: (request: SpeakerCreateRequest) => postSpeaker(request),
		...args,
	});
};

export interface SpeakerUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	participantId: string;
	body: SpeakerWrite;
	signal?: AbortSignal;
}

export const useSpeakerUpdate = (args: UseMutationArgs<SpeakerUpdateRequest>): UseMutationResult<boolean, Error, SpeakerUpdateRequest> => {
	return useMutation({
		mutationFn: (request: SpeakerUpdateRequest) => putSpeaker(request),
		...args,
	});
};
