import { useSessionPublicationStatusConfiguration } from "@configurations";
import { BulletText } from "@mykey4/core";

export interface SessionPublicationStatusBulletTextProps {
	value: boolean;
}

export const SessionPublicationStatusBulletText = (props: SessionPublicationStatusBulletTextProps) => {
	const { value } = props;
	const configuration = useSessionPublicationStatusConfiguration({ status: value });
	return (
		<BulletText
			text={configuration.name}
			slotProps={{
				icon: { color: configuration.color },
				typography: { variant: "body2" },
			}}
		/>
	);
};
