import { useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Stack, type SxProps, useTheme } from "@mui/material";
import type { Theme } from "@mui/system";
import { deepmerge } from "@mui/utils";
import { Icon } from "@mykey4/core";
import { AnimatePresence, motion } from "framer-motion";
import { memo } from "react";
import { dragOverColors } from "./DndRow.configuration";
import type { DndRowType } from "./DndRow.type";

interface MotionContainerProps {
	layoutId?: string;
	isDragging?: boolean;
	isOver?: boolean;
	sx?: SxProps<Theme>;
	children?: React.ReactElement;
}

const MotionStack = motion.create(Stack);
const AnimatePresenceMemo = memo(AnimatePresence);

const MotionContainer = (props: MotionContainerProps) => {
	const { layoutId, sx, isDragging, isOver, children } = props;
	const theme = useTheme();

	return (
		<AnimatePresenceMemo>
			<MotionStack
				layoutId={layoutId}
				animate={{ opacity: isDragging ? 0.7 : 1 }}
				transition={{ type: "spring", stiffness: 120, damping: 20, mass: 0.5 }}
				sx={deepmerge(
					{
						borderRadius: 2,
						background: isOver ? dragOverColors[theme.palette.mode] : theme.palette.background.paper,
						transition: "background 0.3s",
					},
					sx,
				)}
			>
				{children}
			</MotionStack>
		</AnimatePresenceMemo>
	);
};

const DndRowIcon = memo(() => <Icon name={"grip-dots-vertical"} />);

export interface DndRowProps<TItem extends DndRowType> {
	item: TItem;
	layoutId?: string;
	draggableDisabled?: boolean;
	droppableDisabled?: boolean;
	sx?: SxProps<Theme>;
	children?: React.ReactElement;
}

export const DndRow = <TItem extends DndRowType>(props: DndRowProps<TItem>) => {
	const { item, layoutId, draggableDisabled = false, droppableDisabled = false, sx, children } = props;
	const theme = useTheme();

	const draggable = useDraggable({
		id: `DndRow-draggable-${item.id}`,
		data: item,
		disabled: draggableDisabled,
	});

	const droppable = useDroppable({
		id: `DndRow-droppable-${item.id}`,
		data: item,
		disabled: droppableDisabled,
	});

	const { isDragging, transform, setNodeRef: setDraggableNodeRef, listeners, attributes } = draggable;
	const { isOver, setNodeRef: setDroppableNodeRef } = droppable;

	return (
		<MotionContainer layoutId={layoutId} sx={sx} isOver={isOver} isDragging={isDragging}>
			<Stack
				ref={setDroppableNodeRef}
				direction={"row"}
				alignItems={"center"}
				borderRadius={2}
				height={"48px"}
				width={"100%"}
				px={1}
				sx={{
					backgroundColor: isDragging ? theme.palette.background.paper : undefined,
					transform: CSS.Translate.toString(transform),
				}}
			>
				{!draggableDisabled && (
					<Stack
						ref={setDraggableNodeRef}
						{...listeners}
						{...attributes}
						alignItems={"center"}
						justifyContent={"center"}
						sx={{ flexShrink: 0, width: "1rem", height: "1rem" }}
					>
						<DndRowIcon />
					</Stack>
				)}
				{children}
			</Stack>
		</MotionContainer>
	);
};
