import { SessionFormComponent, SessionStatusSelect } from "@components";
import { SessionPublicationStatusSelect } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { DateTimePicker, FormControl, GridLabel } from "@mykey4/core";
import { t } from "i18next";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import { type SessionStatusFormType, sessionStatusDefaultValues, sessionStatusSchema } from "./SessionStatusForm.configuration";

export interface SessionStatusFormProps {
	existingValues?: SessionStatusFormType;
	onSubmit: SubmitHandler<SessionStatusFormType>;
}

export const SessionStatusForm = (props: SessionStatusFormProps) => {
	const { existingValues, onSubmit } = props;

	const form = useForm<SessionStatusFormType>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: sessionStatusDefaultValues(),
		resolver: zodResolver(sessionStatusSchema),
	});

	useEffect(() => {
		form.reset(sessionStatusDefaultValues(existingValues));
	}, [existingValues]);

	useEffect(() => {
		const subscription = form.watch(() => form.handleSubmit(onSubmit)());
		return () => subscription.unsubscribe();
	}, [form.handleSubmit, form.watch, onSubmit]);

	return (
		<FormProvider {...form}>
			<Grid container spacing={2}>
				<GridLabel gridSize={{ xs: 12 }} label={t(`${SessionFormComponent}.status`)}>
					<FormControl name={"status"} render={(args) => <SessionStatusSelect {...args} />} />
				</GridLabel>
				<GridLabel gridSize={{ xs: 12 }} label={t(`${SessionFormComponent}.isPublished`)}>
					<FormControl name={"isPublished"} render={(args) => <SessionPublicationStatusSelect {...args} />} />
				</GridLabel>
				<GridLabel gridSize={{ xs: 12 }} label={t(`${SessionFormComponent}.publicationDate`)}>
					<FormControl name={"publicationDate"} render={(args) => <DateTimePicker {...args} />} />
				</GridLabel>
			</Grid>
		</FormProvider>
	);
};
