import { t } from "i18next";
import type { MouseEventHandler } from "react";
import K4ButtonSave from "../../../infrastructure/components/interface/button/K4ButtonSave";

interface Props {
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonSaveDecorated = (props: Props) => {
	const { isDisabled = false, onClick } = props;
	return <K4ButtonSave label={t("old.common.button.save")} isDisabled={isDisabled} onClick={onClick} />;
};

export default K4ButtonSaveDecorated;
