import { ActivityInvitationStatusBulletText } from "@components";
import { MenuItem } from "@mui/material";
import { type ActivityInvitationStatus, ActivityInvitationStatusTypes, Select } from "@mykey4/core";
import type { SelectProps } from "@mykey4/core/Components/Atoms/Select/Select";

export type ActivityInvitationStatusSelectProps = Omit<SelectProps<ActivityInvitationStatus>, "children" | "renderValue" | "isRequired">;

export const ActivityInvitationStatusSelect = (props: ActivityInvitationStatusSelectProps) => {
	return (
		<Select {...props} isRequired={true} renderValue={(value) => <ActivityInvitationStatusBulletText value={value} />}>
			{ActivityInvitationStatusTypes.map((status) => (
				<MenuItem key={status} value={status}>
					<ActivityInvitationStatusBulletText value={status} />
				</MenuItem>
			))}
		</Select>
	);
};
