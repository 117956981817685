import { type ActivityReplacementDto, type UserInformationProps, getMatchingType } from "@key4-front-library/core";
import type { ChipProps } from "@mui/material";

export interface MatchingOverviewCardInfo {
	chip: ChipProps;
	comment?: string;
	proposing: UserInformationProps;
	proposal?: UserInformationProps;
}

export const activityReplacementDtoToMatchingOverviewCardInfo = (
	activity: ActivityReplacementDto,
	proposal: UserInformationProps | undefined,
	facultyDetailsLink: string,
): MatchingOverviewCardInfo => {
	return {
		chip: getMatchingType(activity.type),
		comment: activity.invitationReplacement?.comment,
		proposing: {
			email: activity.email,
			firstname: activity.firstname,
			lastname: activity.lastname,
			link: {
				children: activity.key,
				to: `${facultyDetailsLink}/${activity.participantId}`,
			},
		},
		proposal,
	};
};
