import type { TagRead } from "@api";
import type { ActionItem } from "@key4-front-library/core";
import type { TypographyProps } from "@mykey4/core";

export const tagsReadToSelectItems = (tags: Array<TagRead>): Array<ActionItem<TypographyProps>> =>
	tags.map((tag) => ({
		value: tag.id,
		name: tag.label,
		component: {
			children: tag.label,
		},
	}));
