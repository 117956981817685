import type { TRoomDetails } from "@domain/interfaces/room.interface";
import type { RoomForm } from "@domain/model/room.model";
import { type DtoRoom, Services, queryStringSorts } from "@key4-front-library/core";
import type { TypeAutoCompleteSubLabelItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteSubLabel";
import { emptyToUndefined } from "@mykey4/core";
import type { TFunction } from "i18next";

const getRoomDetails = async (clientId: string, eventId: string, roomId: string): Promise<TRoomDetails> => {
	const room = await Services.Events.Programme.RoomsService.getDetails(clientId, eventId, roomId);
	return {
		id: room.id,
		name: room.name ?? "N/A",
		capacity: room.capacity ?? 0,
	};
};

const getRoomListBulkAction = async (clientId: string, eventId: string, t: TFunction): Promise<Array<TypeAutoCompleteSubLabelItem>> => {
	const roomsData = Services.Events.Programme.RoomsService.getList(clientId, eventId, [...queryStringSorts(["id"])]);
	return (await roomsData).map((room: DtoRoom) => {
		return {
			key: room.id,
			label: room.name,
			subLabel: room.capacity
				? t("room.capacity", {
						capacity: room.capacity,
					})
				: t("room.capacityNotDefined"),
		};
	});
};

const deleteRoom = async (clientId: string, eventId: string, roomId: string) => {
	return Services.Events.Programme.RoomsService.deleteEntity(clientId, eventId, roomId);
};

const updateRoom = async (clientId: string, eventId: string, room: RoomForm) => {
	return Services.Events.Programme.RoomsService.put(clientId, eventId, room.id, {
		name: room.name,
		capacity: room.capacity ? Number.parseInt(room.capacity) : null,
		order: emptyToUndefined(room.order ?? undefined),
	});
};

const RoomController = {
	updateRoom,
	getRoomListBulkAction,
	deleteRoom,
	getRoomDetails,
};

export default RoomController;
