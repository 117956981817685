import { ActivityInvitationStatusSelect, DurationAutoComplete, SessionStatusSelect } from "@components";
import { FormControlLabel, Radio } from "@mui/material";
import { Checkbox, DateTimePicker, FormControl, Grid, Stack, TextField, TimePicker, TitleDivider, getRhfCheckbox, getTextField } from "@mykey4/core";
import { t } from "i18next";
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { SessionPublicationStatusSelect } from "../../Molecules";
import { useSessionTemplateFormContext } from "./SessionTemplateFormProvider";

export const SessionTemplateForm = () => {
	const component = "sessionTemplateForm";

	const {
		form: { setValue },
		existing,
	} = useSessionTemplateFormContext();

	const [selectedHoursOrDuration, setSelectedHoursOrDuration] = useState<"hours" | "duration" | null>(null);

	useEffect(() => {
		switch (true) {
			case !!existing?.startHour && !!existing.endHour:
				setSelectedHoursOrDuration("hours");
				break;
			case !!existing?.duration:
				setSelectedHoursOrDuration("duration");
				break;
			default:
				setSelectedHoursOrDuration(null);
				break;
		}
	}, [existing]);

	return (
		<Stack spacing={2} pr={2}>
			<TitleDivider title={{ variant: "h5", children: t(`${component}.sections.sessionInformations`) }} />
			<Grid container spacing={2} pr={2} mt={0}>
				<FormControl
					name={"isPrivate"}
					gridSize={{ xs: 12 }}
					render={(args) => (
						<Checkbox {...args} {...getRhfCheckbox(args, { type: "default" })}>
							{t(`${component}.fields.isPrivate`)}
						</Checkbox>
					)}
				/>
				<FormControl
					name={"status"}
					gridSize={{ xs: 12, md: 4 }}
					render={(args) => <SessionStatusSelect {...args} label={t(`${component}.fields.status`)} />}
				/>
				<FormControl
					name={"isPublished"}
					gridSize={{ xs: 12, md: 4 }}
					render={(args) => <SessionPublicationStatusSelect {...args} label={t(`${component}.fields.isPublished`)} />}
				/>
				<FormControl
					name={"publicationDate"}
					gridSize={{ xs: 12, md: 4 }}
					render={(args) => <DateTimePicker {...args} label={t(`${component}.fields.publicationDate`)} />}
				/>
				<Grid item xs={12}>
					<Grid container direction="row" spacing={2}>
						<Grid item md={6} xs={12}>
							<Stack direction={"column"} width={"100%"}>
								<FormControlLabel
									control={<Radio />}
									label={t(`${component}.fields.timeOrDuration.time`)}
									checked={selectedHoursOrDuration === "hours"}
									value="hours"
									name="hoursOrDuration"
									onChange={() => {
										setSelectedHoursOrDuration("hours");
										setValue("duration", null);
									}}
								/>
								<Stack direction={"column"}>
									<FormControl
										key={uniqueId()}
										name={"startHour"}
										render={(args) => (
											<TimePicker
												{...args}
												label={t(`${component}.fields.startTime`)}
												disabled={selectedHoursOrDuration !== null && selectedHoursOrDuration !== "hours"}
											/>
										)}
									/>
									<FormControl
										key={uniqueId()}
										name={"endHour"}
										render={(args) => (
											<TimePicker
												{...args}
												label={t(`${component}.fields.endTime`)}
												disabled={selectedHoursOrDuration !== null && selectedHoursOrDuration !== "hours"}
											/>
										)}
									/>
								</Stack>
							</Stack>
						</Grid>
						<Grid item md={6} xs={12}>
							<Stack direction={"column"} width={"100%"}>
								<FormControlLabel
									control={<Radio />}
									label={t(`${component}.fields.timeOrDuration.duration`)}
									checked={selectedHoursOrDuration === "duration"}
									value="duration"
									name="hoursOrDuration"
									onChange={() => {
										setSelectedHoursOrDuration("duration");
										setValue("startHour", null);
										setValue("endHour", null);
									}}
								/>
								<Stack width={"100%"}>
									<FormControl
										name={"duration"}
										render={(args) => (
											<DurationAutoComplete
												{...args}
												maxHours={8}
												stepMinutes={15}
												label={t(`${component}.fields.duration`)}
												disabled={selectedHoursOrDuration !== null && selectedHoursOrDuration !== "duration"}
											/>
										)}
									/>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<TitleDivider title={{ variant: "h5", children: t(`${component}.sections.presentation`) }} />
			<Grid container spacing={2} pr={2} mt={0}>
				<FormControl
					name={"presentationCount"}
					gridSize={{ xs: 12, md: 6 }}
					render={(args) => (
						<TextField {...args} {...getTextField({ type: "number" })} label={t(`${component}.fields.numberOfPresentations`)} inputProps={{ min: 0 }} />
					)}
				/>
				<FormControl
					name={"presentationDuration"}
					gridSize={{ xs: 12, md: 6 }}
					render={(args) => <DurationAutoComplete {...args} maxHours={2} stepMinutes={5} label={t(`${component}.fields.presentationDuration`)} />}
				/>
			</Grid>
			<TitleDivider title={{ variant: "h5", children: t(`${component}.sections.invitationsStatus`) }} />
			<Grid container spacing={2} pr={2} mt={0}>
				<FormControl
					name={"chairInvitationStatus"}
					gridSize={{ xs: 12, md: 6 }}
					render={(args) => <ActivityInvitationStatusSelect {...args} label={t(`${component}.fields.chairInvitationStatus`)} />}
				/>
				<FormControl
					name={"speakerInvitationStatus"}
					gridSize={{ xs: 12, md: 6 }}
					render={(args) => <ActivityInvitationStatusSelect {...args} label={t(`${component}.fields.speakerInvitationStatus`)} />}
				/>
			</Grid>
		</Stack>
	);
};
