import { FormControlLabel, Radio, RadioGroup, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import type { AbstractWizardActionType, AbstractWizardProps } from "../common";
import { AbstractStepLayout, AbstractsSelectedWizardHeader } from "../components";

export interface ActionOptionProps {
	label: string;
	labelInfo?: string;
	isAuthorized: boolean;
	action: AbstractWizardActionType;
	selectedAction: AbstractWizardActionType | undefined;
	onChange: () => void;
}

const ActionOption = (props: ActionOptionProps) => {
	const { label, labelInfo, isAuthorized, action, selectedAction, onChange } = props;
	const { t } = useTranslation();
	const theme = useTheme();
	return (
		<Stack>
			<RadioGroup aria-labelledby="actions-radio-buttons-group-label" value={action} name="actions-radio-buttons-group">
				<Stack key={action} sx={{ width: "100%" }}>
					<Tooltip title={!isAuthorized && t("unauthorized")} placement={"right"}>
						<Stack sx={{ width: "100%" }}>
							<FormControlLabel
								disabled={!isAuthorized}
								label={
									<Stack direction={"column"}>
										<Typography>{t(label)}</Typography>
										{labelInfo && (
											<Typography color={theme.palette.grey[500]} variant={"body2"}>
												{t(labelInfo)}
											</Typography>
										)}
									</Stack>
								}
								control={<Radio checked={action === selectedAction} />}
								onChange={(_, __) => onChange()}
							/>
						</Stack>
					</Tooltip>
				</Stack>
			</RadioGroup>
		</Stack>
	);
};

export type AbstractWizardChooseActionStep = AbstractWizardProps & {
	isAuthorized: boolean;
};

export const AbstractsChooseActionStep = (props: AbstractWizardChooseActionStep) => {
	const { isAuthorized, useWizardResult } = props;
	const component = "abstract.bulkActions.steps.chooseAction";

	const [currentSelection] = useWizardResult("abstractSelection");
	const [currentAction, setCurrentAction] = useWizardResult("chooseAction");

	const handleChoiceChange = useCallback(
		(action: AbstractWizardActionType) => {
			setCurrentAction(action);
		},
		[setCurrentAction],
	);

	return (
		<AbstractStepLayout
			slotProps={{
				buttons: {
					nextButton: {
						disabled: !currentAction,
					},
				},
			}}
		>
			<AbstractsSelectedWizardHeader i18nKey={`${component}.title`} {...props} />
			<Stack px={3} spacing={5}>
				<ActionOption
					label={t(`${component}.actions.dissociate.label`, { count: currentSelection?.length ?? 0 })}
					labelInfo={t(`${component}.actions.dissociate.labelInfo`, { count: currentSelection?.length ?? 0 })}
					isAuthorized={isAuthorized}
					action={"dissociate"}
					selectedAction={currentAction}
					onChange={() => handleChoiceChange("dissociate")}
				/>
				<ActionOption
					label={t(`${component}.actions.associate.label`, { count: currentSelection?.length ?? 0 })}
					labelInfo={t(`${component}.actions.associate.labelInfo`, { count: currentSelection?.length ?? 0 })}
					isAuthorized={isAuthorized}
					action={"associate"}
					selectedAction={currentAction}
					onChange={() => handleChoiceChange("associate")}
				/>
			</Stack>
		</AbstractStepLayout>
	);
};
