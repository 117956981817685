import { useTheme } from "@mui/material";
import type { SessionStatus } from "@mykey4/core";
import { useMemo } from "react";
import { getSessionStatusConfiguration } from "./configuration";

export const useSessionStatusConfigurations = () => {
	const theme = useTheme();
	return useMemo(() => getSessionStatusConfiguration(theme), [theme]);
};

export interface UseSessionStatusConfigurationArgs {
	status: SessionStatus;
}

export const useSessionStatusConfiguration = (args: UseSessionStatusConfigurationArgs) => {
	const { status } = args;
	const configurations = useSessionStatusConfigurations();
	return useMemo(() => configurations[status], [configurations, status]);
};
