import { submitMatching } from "@application/Configurations/Matching";
import { getFacultyType } from "@application/Mappers";
import type { UserInformationWithMetadata } from "@application/Types";
import { MatchingOverviewCard } from "@application/components/Organisms/MatchingOverviewCard/MatchingOverviewCard";
import { MatchingProposalCard } from "@application/components/Organisms/MatchingProposalCard/MatchingProposalCard";
import {
	type ActivityReplacementDto,
	IconOld,
	Services,
	TitleOld,
	type TypeApiResponsePagined,
	queryFilters,
	useContextModule,
} from "@key4-front-library/core";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { FilteringOperator, Link } from "@mykey4/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useState } from "react";
import type React from "react";
import { useNavigate, useParams } from "react-router-dom";

export const MatchingDetails = (): React.ReactNode => {
	const component = "old.common.matchingDetails";
	const { matchingId } = useParams();
	const theme = useTheme();
	const { client, event } = useContextModule();
	const navigate = useNavigate();
	const [userSelected, setUserSelected] = useState<UserInformationWithMetadata | undefined>();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { data: activityData } = useQuery({
		queryKey: ["matching", client.id, event.id, "faculty", matchingId],
		queryFn: (): Promise<TypeApiResponsePagined<Array<ActivityReplacementDto>>> =>
			Services.Programme.Clients.ActivityService.getActivitiesList(client.id, event.id, queryFilters(`id${FilteringOperator.Equal + matchingId}`)),
		enabled: !!matchingId,
	});

	const handleSave = async (): Promise<void> => {
		if (!userSelected || !activityData?.data[0]) {
			enqueueSnackbar(t(`${component}.save.notSelected`), { variant: "error" });
			return;
		}

		try {
			setIsLoading(true);
			const activity = activityData.data[0];
			const isSuccess = await submitMatching(client, event, {
				id: userSelected.id,
				userType: userSelected.type,
				facultyType: getFacultyType(activity.type),
				sessionId: activity.session.id,
				presentationId: activity.presentation?.id,
				currentParticipantId: activity.participantId,
			});

			if (isSuccess) {
				enqueueSnackbar(t(`${component}.save.success`), {
					variant: "success",
				});
				void queryClient.invalidateQueries({
					queryKey: ["matching", "faculty"],
				});
				navigate("..");
			} else {
				enqueueSnackbar(t(`${component}.save.error`), {
					variant: "error",
				});
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleSaveButtonClick = (): void => {
		void handleSave();
	};

	return (
		<Stack>
			<Stack pt={3} direction={"row"}>
				<Button
					color="inherit"
					variant="text"
					onClick={() => {
						navigate("..");
					}}
				>
					<Stack direction="row" spacing={1}>
						<IconOld iconName="chevron-left" />
						<Typography>{t(`${component}.back`)}</Typography>
					</Stack>
				</Button>
			</Stack>
			<Stack>
				<TitleOld
					title={t(`${component}.title`)}
					reference={
						<Link to={`/${client.key}/${event.key}/sessions/${activityData?.data[0].session.id}`} color={theme.palette.primary.main} justifyContent={"center"}>
							{activityData?.data[0].session.key}
						</Link>
					}
				/>
				<Stack spacing={3}>
					<MatchingOverviewCard matchingId={matchingId} newProposal={userSelected?.user} onSaveClick={handleSaveButtonClick} isLoading={isLoading} />
					<MatchingProposalCard matchingId={matchingId} onUserSelectedChange={setUserSelected} userSelected={userSelected} />
				</Stack>
			</Stack>
		</Stack>
	);
};
