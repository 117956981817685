import {
	type AbstractRead,
	type PresentationBulkCreate,
	type PresentationBulkOperation,
	type PresentationBulkPatch,
	type PresentationRead,
	getAbstractsAll,
	getPresentationsAll,
	usePresentationBulkCreate,
	usePresentationBulkPatch,
} from "@api";
import type { AbstractAssociationResult, AbstractsAssociationResults } from "@components";
import { groupByFirst } from "@helpers";
import { filtersOrInToQueryString } from "@key4-front-library/core";
import { FilteringOperator, type UseMutationArgs, filtersOrInToBodyPostGet, overrideVersion2 } from "@mykey4/core";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";

interface UseAbstractAssociationMutationArgs {
	clientId: string;
	eventId: string;
	mutationArgs?: UseMutationArgs<{ sessionId: string; association: AbstractsAssociationResults }, { count: number }>;
}

export const useAbstractAssociationMutation = (args: UseAbstractAssociationMutationArgs) => {
	const { clientId, eventId, mutationArgs } = args;

	const bulkPatch = usePresentationBulkPatch({});
	const bulkCreate = usePresentationBulkCreate({});

	const getExistingSessionPresentations = useCallback(
		async (sessionId: string, presentations: { presentationId?: string }[]) => {
			const presentationsIds = presentations.filter((p) => !!p.presentationId).map((p) => p.presentationId);
			if (presentationsIds.length <= 0) {
				return [];
			}
			const queryStrings = overrideVersion2(filtersOrInToQueryString("id", FilteringOperator.Equal, presentationsIds));
			return await getPresentationsAll({ clientId, eventId, sessionId: sessionId, queryStrings: queryStrings });
		},
		[clientId, eventId],
	);

	const getExistingAbstracts = useCallback(
		async (request: AbstractsAssociationResults) => {
			const abstractIds = request.filter((p) => !!p.abstractId).map((p) => p.abstractId);
			if (abstractIds.length <= 0) {
				return [];
			}
			return await getAbstractsAll({
				clientId,
				eventId,
				body: filtersOrInToBodyPostGet("id", FilteringOperator.Equal, abstractIds),
				queryStrings: overrideVersion2([]),
			});
		},
		[clientId, eventId],
	);

	const getPresentationsAndAbstracts = useCallback(
		async (request: { sessionId: string; association: AbstractsAssociationResults }) => {
			const { sessionId, association } = request;
			const promises = [getExistingAbstracts(association), getExistingSessionPresentations(sessionId, association)];
			const results = await Promise.all(promises);
			return {
				abstracts: groupByFirst(results[0] as AbstractRead[], "id"),
				presentations: groupByFirst(results[1] as PresentationRead[], "id"),
			};
		},
		[getExistingAbstracts, getExistingSessionPresentations],
	);

	const buildOperations = useCallback(
		(
			association: AbstractAssociationResult,
			existingAbstracts: Map<string, AbstractRead>,
			existingPresentations: Map<string, PresentationRead>,
			patches: PresentationBulkPatch[],
			creates: PresentationBulkCreate[],
		) => {
			// Case 1: Association has an abstract but no presentation
			// In this case, we need to create a new presentation linked to this abstract
			if (!association.presentationId && association.abstractId) {
				const abstract = existingAbstracts.get(association.abstractId);
				if (!abstract) {
					return;
				}
				creates.push({
					title: association.overridePresentationTitle ? abstract.title || undefined : undefined,
					code: abstract.newAttributedNumber || undefined,
					abstractId: abstract.id,
				});
				return;
			}

			// Case 2: No valid presentation to update
			// If there's no presentation ID or the presentation doesn't exist in our records, we can't proceed
			const presentation = association.presentationId ? existingPresentations.get(association.presentationId) : undefined;
			if (!presentation) {
				return;
			}

			const operations: PresentationBulkOperation[] = [];

			// Case 3: Presentation has an abstract but association doesn't
			// We need to remove the abstract from the presentation
			if (presentation.abstractId && !association.abstractId) {
				operations.push({ op: "remove", path: "/abstractId", value: null });
			}

			// Case 4: Both presentation and association have abstracts, but they're different
			// We need to replace the abstract ID in the presentation
			if (presentation.abstractId && association.abstractId && presentation.abstractId !== association.abstractId) {
				operations.push({ op: "replace", path: "/abstractId", value: association.abstractId });
			}

			// Case 5: Presentation has no abstract but association does
			// We need to add the abstract to the presentation
			if (!presentation.abstractId && association.abstractId) {
				operations.push({ op: "add", path: "/abstractId", value: association.abstractId });
			}

			// Case 6: No title override needed or no valid abstract to get title from
			// Just apply the operations we've collected so far
			const abstract = association.abstractId ? existingAbstracts.get(association.abstractId) : undefined;
			if (!association.overridePresentationTitle || !abstract) {
				if (operations.length > 0) {
					patches.push({
						presentationId: presentation.id,
						operations,
					});
				}
				return;
			}

			// Case 7: Presentation has no title but abstract does
			// Add the abstract's title to the presentation
			if (!presentation.title && abstract.title) {
				operations.push({ op: "add", path: "/title", value: abstract.title || "" });
			}

			// Case 8: Both presentation and abstract have titles, but they're different
			// Replace the presentation's title with the abstract's title
			if (presentation.title && abstract.title && presentation.title !== abstract.title) {
				operations.push({ op: "replace", path: "/title", value: abstract.title || "" });
			}

			// Case 9: Presentation has a title but abstract doesn't
			// Remove the title from the presentation to match the abstract's lack of title
			if (presentation.title && !abstract.title) {
				operations.push({ op: "remove", path: "/title", value: null });
			}

			// Apply all collected operations
			if (operations.length > 0) {
				patches.push({
					presentationId: presentation.id,
					operations,
				});
			}
		},
		[],
	);

	const associationMutationFn = useCallback(
		async (request: { sessionId: string; association: AbstractsAssociationResults }) => {
			const { sessionId, association } = request;
			if (association.length <= 0) {
				return { count: 0 };
			}
			const patches: PresentationBulkPatch[] = [];
			const creates: PresentationBulkCreate[] = [];

			const { abstracts: existingAbstracts, presentations: existingPresentations } = await getPresentationsAndAbstracts(request);

			const newPresentations = association.filter((a) => !a.presentationId);
			const existing = association.filter((a) => a.presentationId);
			const uniqueAssociation = groupByFirst(existing, "presentationId");

			for (const association of [...uniqueAssociation.values(), ...newPresentations]) {
				buildOperations(association, existingAbstracts, existingPresentations, patches, creates);
			}

			if (patches.length > 0) {
				await bulkPatch.mutateAsync({ clientId, eventId, sessionId, body: patches });
			}

			if (creates.length > 0) {
				await bulkCreate.mutateAsync({ clientId, eventId, sessionId, body: creates });
			}

			return { count: patches.length + creates.length };
		},
		[clientId, eventId, getPresentationsAndAbstracts, buildOperations, bulkPatch.mutateAsync, bulkCreate.mutateAsync],
	);

	return useMutation({
		mutationFn: associationMutationFn,
		...mutationArgs,
	});
};
