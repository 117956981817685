import { Fragment, useCallback, useState } from "react";

import SettingsClashesEdit from "@application/components/settings/scheduler/clashes/SettingsClashesEdit";
import type { ISettingsClashesObject } from "@domain/interfaces/settings.clashes.interface";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import type { IconName } from "@mykey4/core";
import { t } from "i18next";

type SettingsClashesItemProps = {
	label: string;
	value: string;
};
const SettingsClashesItem = (props: SettingsClashesItemProps) => {
	const { label, value } = props;
	return (
		<Grid item xs={12} md={12} lg={6}>
			<Typography variant={"body2"} color={"text.secondary"}>
				{label}
			</Typography>
			<Typography variant={"body1"}>{value}</Typography>
		</Grid>
	);
};

type Props = {
	settings: ISettingsClashesObject;
	culture: string;
	saveSettings: (data: ISettingsClashesObject) => void;
};
const SettingsClashesView = (props: Props) => {
	const { settings, culture, saveSettings } = props;
	const component = "settingsClashesView";

	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const handleEditModaleOpen = useCallback((): void => {
		setModaleOpenState(true);
	}, []);

	const handleEditModaleClose = useCallback((): void => {
		setModaleOpenState(false);
	}, []);

	return (
		<Fragment>
			<IconCard
				title={t(`${component}.title`)}
				icon={"calendar-circle-exclamation"}
				button={{
					label: t("button.edit"),
					icon: "pen-to-square" as IconName,
					onClick: handleEditModaleOpen,
				}}
			>
				{
					<Grid container spacing={3}>
						<SettingsClashesItem label={t(`${component}.facultyClashDetectionInterval`)} value={settings.formatedValues.facultyClashDetectionInterval} />
						<SettingsClashesItem label={t(`${component}.roomClashDetectionInterval`)} value={settings.formatedValues.roomClashDetectionInterval} />
						<Grid item xs={12} md={12} lg={12}>
							<FormControlLabel
								control={<Checkbox checked={settings.formatedValues.isSpeakerBusySessionTimeSlot} disabled={true} />}
								label={t(`${component}.isSpeakerBusySessionTimeSlot`)}
							/>
						</Grid>
					</Grid>
				}
			</IconCard>
			<DefaultModal open={modaleOpenState} title={t(`${component}.title`)}>
				<SettingsClashesEdit settings={settings} culture={culture} onSave={saveSettings} handleModaleClose={handleEditModaleClose} />
			</DefaultModal>
		</Fragment>
	);
};

export default SettingsClashesView;
