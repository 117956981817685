import { type PathEntity, type ServiceArgs, get } from "@mykey4/core";
import type {
	DashboardFaculties,
	DashboardGetInvitationStatus,
	DashboardIssues,
	DashboardOverview,
	DashboardProgramme,
	DashboardSessionPublicationStatus,
	DashboardSessionStatus,
} from "./Dashboard.type";

const module = "programme";

const rootEntities: Array<PathEntity> = [{ entity: "dashboard" }];

export interface GetDashboardArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getDashboardOverview = async (args: GetDashboardArgs): Promise<DashboardOverview> => {
	return await get({
		module,
		entities: [...rootEntities, { entity: "overview" }],
		...args,
	});
};

export const getDashboardProgramme = async (args: GetDashboardArgs): Promise<DashboardProgramme> => {
	return await get({
		module,
		entities: [...rootEntities, { entity: "programme" }],
		...args,
	});
};

export const getDashboardFaculties = async (args: GetDashboardArgs): Promise<DashboardFaculties> => {
	return await get({
		module,
		entities: [...rootEntities, { entity: "faculties" }],
		...args,
	});
};

export const getDashboardSessionStatus = async (args: GetDashboardArgs): Promise<DashboardSessionStatus> => {
	return await get({
		module,
		entities: [...rootEntities, { entity: "sessions-status" }],
		...args,
	});
};

export const getDashboardInvitationStatus = async (args: GetDashboardArgs): Promise<DashboardGetInvitationStatus> => {
	return await get({
		module,
		entities: [...rootEntities, { entity: "invitations-status" }],
		...args,
	});
};

export const getDashboardSessionPublicationStatus = async (args: GetDashboardArgs): Promise<DashboardSessionPublicationStatus> => {
	return await get({
		module,
		entities: [...rootEntities, { entity: "sessions-publication-status" }],
		...args,
	});
};

export const getDashboardIssues = async (args: GetDashboardArgs): Promise<DashboardIssues> => {
	return await get({
		module,
		entities: [...rootEntities, { entity: "issues" }],
		...args,
	});
};
