import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import { useTagTypes } from "@api";
import IssuesDefinitions from "@application/Configurations/issues.configuration";
import SessionController from "@application/Controllers/SessionController";
import type { ISessionsGrid } from "@domain/interfaces/sessions.interface";
import K4ListGrid, { GridDisplayFormatEnum } from "@infrastructure/components/interface/list/K4ListGrid";
import { useContextModule } from "@key4-front-library/core";
import { usePaginationOld } from "@key4-front-library/core/Bo/Hooks";
import type { GridSortModel } from "@mui/x-data-grid-pro";
import { paginationToQueryString, sortToQueryString } from "@mykey4/core";

type Props = {
	queryBuilderString: string;
	search: string;
	isRefreshSessions: boolean;
	isWaitingForQueryBuilderChange: boolean;
	setRowsCount: (count: number) => void;
};

const SessionsGrid = (props: Props) => {
	const DEFAULT_PAGE = 0;

	const { queryBuilderString, search, setRowsCount, isRefreshSessions } = props;
	const { client, event } = useContextModule();
	const { page, pageSize, totalRowCount, handleChangePage, handleChangePageSize, setTotalRowCount } = usePaginationOld();
	const tagsQuery = useTagTypes(client.id, event.id, {
		queryStrings: [...sortToQueryString([{ field: "order", sort: "asc" }]), ...paginationToQueryString({ page: 1, pageSize: 1 })],
	});

	const [sessions, setSessions] = useState<Array<ISessionsGrid>>([]);

	const [gridSortModelList, setGridSortModelList] = useState<GridSortModel>([]);

	const initComponent = useCallback(async () => {
		const sorts: Array<string> = [];

		for (const criteria of gridSortModelList) {
			sorts.push(`${criteria.sort === "asc" ? "" : "-"}${criteria.field}`);
		}

		const sessionsData = await SessionController.getSessionsDatagrid(
			client.id,
			event.id,
			search,
			sorts ?? [],
			{ page, pageSize, totalCount: totalRowCount },
			queryBuilderString,
		);

		const { pagination } = sessionsData;

		if (pagination.totalCount) {
			setTotalRowCount(pagination.totalCount);
			setRowsCount(pagination.totalCount);
		}
		setSessions(sessionsData.data);
	}, [gridSortModelList, event, search, page, pageSize, queryBuilderString]);

	useEffect(() => {
		initComponent();
	}, [gridSortModelList, search, page, pageSize, queryBuilderString, isRefreshSessions, initComponent]);

	useEffect(() => {
		handleChangePage(DEFAULT_PAGE);
	}, [search, queryBuilderString]);

	const handleChangeSortCriteria = (_gridSortModelList: GridSortModel) => {
		setGridSortModelList(_gridSortModelList);
	};

	return (
		<K4ListGrid
			page={page}
			pageSize={pageSize}
			totalRowCount={totalRowCount}
			handleChangePage={handleChangePage}
			handleChangePageSize={handleChangePageSize}
			data={sessions}
			handleChangeSortCriteria={handleChangeSortCriteria}
			schemeAndColumnTitles={{
				id: {
					headerName: "Id",
					reference: "id",
				},
				key: {
					headerName: t("old.common.session.tabHeader.key"),
					reference: "key",
					minWidth: 25,
					maxWidth: 80,
					displayFormat: {
						value: GridDisplayFormatEnum.LINK,
						to: `/${client.key}/${event.key}/sessions`,
					},
				},
				code: {
					headerName: "Code",
					reference: "code",
					maxWidth: 80,
				},
				primaryTag: {
					headerName: tagsQuery.data?.data[0].label ?? t("old.common.session.tabHeader.primaryTag"),
					reference: "primaryTag",
					displayFormat: {
						value: GridDisplayFormatEnum.TAG,
					},
					minWidth: 100,
				},
				title: {
					headerName: t("old.common.session.tabHeader.title"),
					reference: "title",
				},
				dates: {
					headerName: t("old.common.session.tabHeader.dates"),
					reference: "dates",
					minWidth: 260,
				},
				room: {
					headerName: t("old.common.session.tabHeader.rooms"),
					reference: "room",
					minWidth: 100,
				},
				hasAnomalies: {
					headerName: t("old.common.session.tabHeader.anomalies"),
					reference: "anomalies",
					displayFormat: {
						value: GridDisplayFormatEnum.ICON,
						iconName: IssuesDefinitions.anomalies.icon,
						color: IssuesDefinitions.anomalies.themeColor.main,
					},
					maxWidth: 100,
				},
				hasClashes: {
					headerName: t("old.common.session.tabHeader.clashes"),
					reference: "clashes",
					displayFormat: {
						value: GridDisplayFormatEnum.ICON,
						iconName: IssuesDefinitions.clashes.icon,
						color: IssuesDefinitions.clashes.themeColor.main,
					},
					maxWidth: 100,
				},
				status: {
					headerName: t("old.common.session.tabHeader.sessionStatus"),
					displayFormat: {
						value: GridDisplayFormatEnum.SESSIONSTATUS,
					},
					maxWidth: 150,
				},
				isPublished: {
					headerName: t("old.common.session.tabHeader.isPublished"),
					displayFormat: {
						value: GridDisplayFormatEnum.ISPUBLISHED,
					},
					maxWidth: 150,
				},
			}}
		/>
	);
};

export default SessionsGrid;
