import type { RoomRead, RoomWrite } from "@api";
import type { RoomForm } from "@domain/model/room.model";
import { emptyToUndefined } from "@mykey4/core";

export const roomReadToRoomForm = (data: RoomRead): RoomForm => ({
	id: data.id,
	name: data.name,
	order: data.order?.toString() ?? "",
	capacity: data.capacity?.toString() ?? "",
});

export const roomFormToRoomWrite = (data: RoomForm): RoomWrite => ({
	name: data.name,
	order: emptyToUndefined(data.order ?? undefined),
	capacity: emptyToUndefined(data.capacity ?? undefined),
});
