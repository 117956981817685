import { type SessionRead, useSessionCreate, useSessionUpdate } from "@api";
import {
	SessionForm,
	type SessionModalForm,
	sessionDefaultValues,
	sessionReadToSessionForm,
	sessionSchema,
	useEditPresentationScheduleDialog,
} from "@components";
import { sessionFormToSessionWrite } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { type CustomFieldForm, FormTabsCustomField, useContextModule } from "@key4-front-library/core";
import {
	Button,
	DialogAdvanced,
	type DialogComponentProps,
	Link,
	Stack,
	Typography,
	copyToClipboard,
	getDialogAdvanced,
	metaEnv,
	stringISOToDateTime,
	useAutoLoaderOverlay,
	useSnackbar,
} from "@mykey4/core";
import { getPath } from "@routes";
import { t } from "i18next";
import type { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { type FieldErrors, FormProvider, useForm } from "react-hook-form";
import { useSessionModal } from "./useSessionModal";
import { useSessionModalWatchPrimaryTag } from "./useSessionModalWatchPrimaryTag";

export interface InjectSessionData {
	roomId?: string | null;
	publicationDate?: DateTime | null;
	startDate?: DateTime | null;
	endDate?: DateTime | null;
}

interface SessionModalTitleProps {
	component: string;
	clientKey: string;
	eventKey: string;
	session?: SessionRead;
	onCopyClick: () => void | Promise<void>;
}

const SessionModalTitle = (props: SessionModalTitleProps) => {
	const { component, clientKey, eventKey, session, onCopyClick } = props;
	return (
		<Stack direction="row" spacing={1} alignItems={"center"}>
			<Typography variant="h6">{session ? t(`${component}.edit.advancedDialog.save.title`) : t(`${component}.create.advancedDialog.save.title`)}</Typography>
			{session && (
				<Stack direction="row" spacing={1} alignItems={"center"}>
					<Typography variant="h6">{" - "}</Typography>
					<Link to={getPath("sessionDetails", [clientKey, eventKey, session.id])}>
						<Typography component={"span"} variant="h6">
							{session.key}
						</Typography>
					</Link>
					<Button variant="text" onClick={onCopyClick} isIcon startIcon={{ name: "link-simple" }} />
				</Stack>
			)}
		</Stack>
	);
};

export interface SessionModalProps {
	sessionId?: string;
	injectSessionData?: InjectSessionData;
}

export const SessionModal = (props: DialogComponentProps<SessionModalProps>) => {
	const component = "sessionModal";
	const {
		open,
		onClose,
		payload: { sessionId, injectSessionData },
	} = props;

	const { client, event } = useContextModule();
	const snackbar = useSnackbar();
	const [isPresentationScheduleOpen, setIsPresentationScheduleOpen] = useState(false);
	const [isFormTabLoaded, setIsFormTabLoaded] = useState(false);

	const { open: openEditPresentationSchedule } = useEditPresentationScheduleDialog({ clientId: client.id, eventId: event.id });

	const { isFetching, session, isAbstractModuleActivated, sessionTagTypes, rooms, tagTypes } = useSessionModal({
		clientId: client.id,
		eventId: event.id,
		sessionId: sessionId,
	});

	const form = useForm<CustomFieldForm<SessionModalForm>>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: sessionDefaultValues(injectSessionData),
		resolver: zodResolver(sessionSchema),
	});

	const { isFetching: isFetchingWatchPrimaryTag } = useSessionModalWatchPrimaryTag({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		form,
	});

	const sessionCreate = useSessionCreate({
		onSuccess: async (id, variable) => {
			setIsPresentationScheduleOpen(true);
			openEditPresentationSchedule({
				sessionId: id,
				oldSession: session.data,
				newSession: variable.body,
			}).finally(() => onClose());
		},
	});

	const sessionEdit = useSessionUpdate({
		onSuccess: async (_, variable) => {
			if (!session.data) {
				await onClose();
				return;
			}
			setIsPresentationScheduleOpen(true);
			openEditPresentationSchedule({
				sessionId: session.data.id,
				oldSession: session.data,
				newSession: variable.body,
			}).finally(() => onClose());
		},
	});

	const handleError = useCallback(
		(_errors: FieldErrors<CustomFieldForm<SessionModalForm>>) => {
			snackbar.enqueue({ type: "invalidForm", translation: t("form.validation") });
		},
		[snackbar.enqueue],
	);

	const handleSaveClick = (): void => {
		void form.handleSubmit(async (data) => {
			if (sessionId) {
				sessionEdit.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId: sessionId,
					body: sessionFormToSessionWrite(data, isAbstractModuleActivated.data),
				});
			} else {
				sessionCreate.mutate({
					clientId: client.id,
					eventId: event.id,
					body: sessionFormToSessionWrite(data, isAbstractModuleActivated.data),
				});
			}
		}, handleError)();
	};

	const handleCopyClick = useCallback((): void => {
		if (!session.data?.id) {
			return;
		}
		copyToClipboard(`${metaEnv.myKey4.endPoint.programme}${getPath("sessionDetails", [client.key, event.key, session.data.id])}`);
		snackbar.enqueue({ type: "copy" });
	}, [client.key, event.key, session.data?.id]);

	useEffect(() => {
		// TODO CHANGE THIS OMG WTF !!! form.getValues("customFields") And isLoaded (Data exchange between this component and FormTabsCustomField via form and callback)
		if (session.data && session.isFetched && sessionTagTypes.isFetched && sessionTagTypes.data && isFormTabLoaded) {
			form.reset({
				...sessionReadToSessionForm(session.data, form.getValues("customFields"), sessionTagTypes.data),
				...injectSessionData,
			});
		}
	}, [form.reset, session.isFetched, session.data, sessionTagTypes.isFetched, sessionTagTypes.data, form.getValues, injectSessionData, isFormTabLoaded]);

	const { isLoading: overlayLoading } = useAutoLoaderOverlay(isFetching || isFetchingWatchPrimaryTag);

	if (overlayLoading) {
		return undefined;
	}

	if (overlayLoading) {
		return undefined;
	}

	return (
		<DialogAdvanced
			open={open && !overlayLoading && !isPresentationScheduleOpen}
			{...getDialogAdvanced({
				type: "save",
				translation: sessionId ? `${component}.edit` : `${component}.create`,
				options: {
					onCancelClick: onClose,
					onActionClick: handleSaveClick,
					isLoading: form.formState.isSubmitting || sessionCreate.isPending || sessionEdit.isPending,
				},
			})}
			title={{
				component: "div",
				children: <SessionModalTitle component={component} clientKey={client.key} eventKey={event.key} session={session.data} onCopyClick={handleCopyClick} />,
			}}
			fullWidth
		>
			<FormProvider {...form}>
				<FormTabsCustomField
					id={sessionId}
					onIsLoaded={() => setIsFormTabLoaded(true)}
					module="programme"
					scope="session"
					businessForm={
						<SessionForm
							eventStartDate={stringISOToDateTime(event.startDate)}
							rooms={rooms.data?.data ?? []}
							primaryTagType={tagTypes.data?.primary}
							secondaryTagType={tagTypes.data?.secondaries ?? []}
							isAbstractModuleActivated={isAbstractModuleActivated.data}
						/>
					}
				/>
			</FormProvider>
		</DialogAdvanced>
	);
};
