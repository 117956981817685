import type { Theme } from "@mui/material";
import { t } from "i18next";
import type { SessionPublicationStatusConfigurations } from "./type";

export const getSessionPublicationStatusConfiguration = (theme: Theme): SessionPublicationStatusConfigurations => ({
	published: {
		backgroundColor: theme.palette.success.background,
		borderColor: theme.palette.success.background,
		color: theme.palette.success.main,
		name: t("sessionPublicationStatus.published"),
		translationKey: "sessionPublicationStatus.published",
	},
	unpublished: {
		backgroundColor: theme.palette.error.background,
		borderColor: theme.palette.error.background,
		color: theme.palette.error.main,
		name: t("sessionPublicationStatus.unpublished"),
		translationKey: "sessionPublicationStatus.unpublished",
	},
});
