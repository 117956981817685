import { Box, Tooltip, Typography } from "@mui/material";
import { Switch, type SwitchProps } from "@mykey4/core";

export type AbstractAssociationDisplayAllSwitchProps = SwitchProps & {
	toolTipText?: string;
};

export const AbstractAssociationDisplayAllSwitch = (props: AbstractAssociationDisplayAllSwitchProps) => {
	const { toolTipText, ...others } = props;

	if (!toolTipText) {
		return <Switch {...others} />;
	}

	return (
		<Tooltip
			arrow
			title={
				<Box sx={{ p: 0.5 }}>
					<Typography variant="body2">{toolTipText}</Typography>
				</Box>
			}
		>
			<Box>
				<Switch {...others} />
			</Box>
		</Tooltip>
	);
};
