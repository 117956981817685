import { EnumSessionFacultyClashesEntityType } from "@application/enum/Clashes.enum";
import { Services, queryFilters, queryStringPagination } from "@key4-front-library/core";
import { FilteringOperator } from "@mykey4/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

export interface UseSessionDetailsCounterArgs {
	clientId: string;
	eventId: string;
	sessionId?: string;
}

export interface UseSessionDetailsCountersReturn {
	isLoading: boolean;
	anomalies: number;
	clashes: number;
	presentations: number;
	chairs: number;
	invalidate: () => Promise<void>;
}

// TODO UPGRADE TO PROGRAMME V2
export const useSessionDetailsCounters = (props: UseSessionDetailsCounterArgs): UseSessionDetailsCountersReturn => {
	const { clientId, eventId, sessionId } = props;

	const queryClient = useQueryClient();

	const anomaliesQuery = useMemo(() => {
		return [
			...queryFilters(`onlyActive${FilteringOperator.Equal}true`),
			...queryStringPagination({
				page: 0,
				pageSize: 1,
			}),
		];
	}, []);

	const clashesQuery = useMemo(() => {
		return [
			...queryFilters(
				`ignoredDate${FilteringOperator.Equal}null${FilteringOperator.And}entityType${FilteringOperator.Equal + EnumSessionFacultyClashesEntityType.ALL}`,
			),
			...queryStringPagination({
				page: 0,
				pageSize: 1,
			}),
		];
	}, []);

	const chairsQuery = useMemo(() => {
		return [
			...queryStringPagination({
				page: 0,
				pageSize: 1,
			}),
		];
	}, []);

	const presentationsQuery = useMemo(() => {
		return [
			...queryStringPagination({
				page: 0,
				pageSize: 1,
			}),
		];
	}, []);

	const anomaliesCounter = useQuery({
		queryKey: ["Programme", "sessions", clientId, eventId, sessionId, "counter", "anomalies", anomaliesQuery],
		queryFn: async () => await Services.Events.Programme.SessionsService.getListPaginedAnomalies(clientId, eventId, sessionId ?? "", anomaliesQuery),
		select: (anomalies) => anomalies?.pagination.totalCount ?? 0,
		enabled: !!sessionId,
	});

	const clashesCounter = useQuery({
		queryKey: ["Programme", "sessions", clientId, eventId, sessionId, "counter", "clashes", clashesQuery],
		queryFn: async () => await Services.Events.Programme.SessionsService.getListPaginedClashes(clientId, eventId, sessionId ?? "", clashesQuery),
		select: (clashes) => clashes?.pagination.totalCount ?? 0,
		enabled: !!sessionId,
	});

	const chairsCounter = useQuery({
		queryKey: ["Programme", "sessions", clientId, eventId, sessionId, "counter", "chairs", chairsQuery],
		select: (chairs) => chairs.pagination.totalCount ?? 0,
		enabled: !!sessionId,
		queryFn: async () => {
			return await Services.Events.Programme.SessionsService.getListPaginedChair(clientId, eventId, sessionId ?? "", chairsQuery);
		},
	});

	const presentationCounter = useQuery({
		queryKey: ["Programme", "sessions", clientId, eventId, sessionId, "counter", "presentation"],
		queryFn: async () => await Services.Events.Programme.SessionsService.getListPaginedPresentations(clientId, eventId, sessionId ?? "", presentationsQuery),
		select: (presentation) => presentation?.pagination.totalCount ?? 0,
		enabled: !!sessionId,
	});

	const invalidate = useCallback(async () => {
		if (!sessionId) {
			return;
		}
		await queryClient.invalidateQueries({ queryKey: ["Programme", "sessions", clientId, eventId, sessionId, "counter"] });
	}, [queryClient.invalidateQueries, clientId, eventId, sessionId]);

	return useMemo(() => {
		return {
			isLoading: anomaliesCounter.isFetching || clashesCounter.isFetching || presentationCounter.isFetching || chairsCounter.isFetching,
			anomalies: anomaliesCounter.data ?? 0,
			clashes: clashesCounter.data ?? 0,
			presentations: presentationCounter.data ?? 0,
			chairs: chairsCounter.data ?? 0,
			invalidate,
		};
	}, [
		anomaliesCounter.isFetching,
		clashesCounter.isFetching,
		presentationCounter.isFetching,
		chairsCounter.isFetching,
		anomaliesCounter.data,
		clashesCounter.data,
		presentationCounter.data,
		chairsCounter.data,
		invalidate,
	]);
};
