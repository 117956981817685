import { type RoomRead, type RoomWrite, type RoomsOrder, getRoom, getRooms, postRoom, putRoom, putRoomsOrder } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseMutationArgs, type UseQueryArgs, overrideVersion2 } from "@mykey4/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const useRooms = <TData = Paginated<RoomRead>>(
	clientId: string,
	eventId: string,
	args: UseQueryArgs<Paginated<RoomRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.rooms, ...queryKeyPart, "Get", "List", clientId, eventId, queryStringOverride],
		queryFn: ({ signal }) => getRooms({ clientId, eventId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export const useRoom = (clientId: string, eventId: string, id: string, args: UseQueryArgs<RoomRead> = {}): UseQueryResult<RoomRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery<RoomRead>({
		queryKey: [...QUERY_KEYS_PROGRAMME.rooms, ...queryKeyPart, "Get", clientId, eventId, id, queryStringOverride],
		queryFn: ({ signal }) => getRoom({ clientId, eventId, id, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export interface UseRoomCreateArgs {
	clientId: string;
	eventId: string;
	body: RoomWrite;
	signal?: AbortSignal;
}

export const useRoomCreate = (args: UseMutationArgs<UseRoomCreateArgs> = {}): UseMutationResult<boolean, Error, UseRoomCreateArgs> => {
	return useMutation({
		mutationFn: (request: UseRoomCreateArgs) => postRoom(request),
		...args,
	});
};

export interface UseRoomUpdateArgs {
	clientId: string;
	eventId: string;
	id: string;
	body: RoomWrite;
	signal?: AbortSignal;
}

export const useRoomUpdate = (args: UseMutationArgs<UseRoomUpdateArgs> = {}): UseMutationResult<boolean, Error, UseRoomUpdateArgs> => {
	return useMutation({
		mutationFn: (request: UseRoomUpdateArgs) => putRoom(request),
		...args,
	});
};

export interface UseRoomsOrderArgs {
	clientId: string;
	eventId: string;
	body: RoomsOrder;
	signal?: AbortSignal;
}

export const useRoomsOrder = (args: UseMutationArgs<UseRoomsOrderArgs> = {}): UseMutationResult<boolean, Error, UseRoomsOrderArgs> => {
	return useMutation({
		mutationFn: (request: UseRoomsOrderArgs) =>
			putRoomsOrder({
				...request,
			}),
		...args,
	});
};
