export enum EnumPresentationFormKey {
	GENERAL = "general",
	ABSTRACT = "abstract",
}

export enum EnumPresentationSectionKey {
	MAIN = "main",
}

export enum EnumPresentationFieldKey {
	ABSTRACT_EMBARGO_DATE = "abstractEmbargoDate",
	ABSTRACT_URL = "abstractUrl",
	CODE = "code",
	DESCRIPTION = "description",
	DURATION = "duration",
	EMPTY = "empty",
	END_DATE = "endDate",
	END_HOUR = "endHour",
	START_DATE = "startDate",
	START_HOUR = "startHour",
	TITLE = "title",
	AUTOCOMPLETE_CONTACT_OR_PARTICIPANT = "participantOrContact",
	QUALITY = "quality",
}
