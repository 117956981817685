import { t } from "i18next";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";

import SelectableListItemsHelper from "@application/components/_common/QuickSearch/components/SelectableListItems/helper/SelectableListItems.Helper";
import type { ISelectableListItemsDefinition } from "@application/components/_common/QuickSearch/components/SelectableListItems/interface/ISelectableListItemsDefinition.interface";
import type ISelectionState from "@application/components/_common/QuickSearch/interface/ISelectionState.interface";
import { EQuickSearchReducerAction } from "@application/components/_common/QuickSearch/model/TQuickSearchReducerAction";
import QuickSearchReducer from "@application/components/_common/QuickSearch/reducer/QuickSearchReducer";
import useLocalStorage from "@infrastructure/hooks/localStorage.hook";
import { ApplicationColors, DebugConfig, localStorageQuickSearchKey, transformArraySelectionToSieveString } from "@key4-front-library/core";
import SelectableListItems from "@key4-front-library/core/Bo/Components/SelectableListItems";
import { Button, Grid, Stack, Typography, alpha } from "@mui/material";

const debugQuickSearchState = DebugConfig.QuickSearchState;

const initialState: Array<ISelectionState> = [];

type Props = {
	localStoragePrefixKey?: string;
	quickSearchFieldsDefinition: Array<ISelectableListItemsDefinition>;
	handleQueryBuilder: (query: string) => void;
};
const QuickSearch = (props: Props) => {
	const { localStoragePrefixKey, quickSearchFieldsDefinition, handleQueryBuilder } = props;

	const [definitions, setDefinitions] = useState<Array<ISelectableListItemsDefinition>>([]);
	const [state, dispatch] = useReducer(QuickSearchReducer, initialState);
	const [stateCopy, setStateCopy] = useState<Array<ISelectionState>>(state);
	const [stateSaved, setStateSaved] = useLocalStorage<Array<ISelectionState>>(`${localStoragePrefixKey}-${localStorageQuickSearchKey}`, initialState);

	const selectableListItemRefs = useRef(new Array(quickSearchFieldsDefinition.length));

	const handleSelection = (_id: string, _searchKey: string, _selection: Array<string>) => {
		dispatch({
			type: EQuickSearchReducerAction.SELECT,
			id: _id,
			searchKey: _searchKey,
			selection: _selection,
		});
	};

	const initializeState = useCallback((_states: Array<ISelectionState>) => {
		_states.forEach((_state) => {
			dispatch({
				type: EQuickSearchReducerAction.SELECT,
				id: _state.id,
				searchKey: _state.searchKey,
				selection: _state.selection,
			});
		});
	}, []);

	const handleClearSelection = (_id: string) => {
		dispatch({
			type: EQuickSearchReducerAction.CLEAR,
			id: _id,
		});
	};
	const handleClearAll = () => {
		dispatch({
			type: EQuickSearchReducerAction.CLEAR_ALL,
		});
		quickSearchFieldsDefinition.forEach((_, _index) => {
			selectableListItemRefs.current[_index]?.clear();
		});
	};

	const initializeSelection = useCallback(
		(_definitions: Array<ISelectableListItemsDefinition>) => {
			_definitions.forEach((_definition, _index) => {
				const fieldStateSaved = state.find((_field) => _field.id === _definition.key);
				if (fieldStateSaved?.selection && fieldStateSaved.selection.length > 0) {
					selectableListItemRefs.current[_index]?.initializeSelection(fieldStateSaved.selection);
				}
			});
		},
		[state],
	);

	useEffect(() => {
		const sortedDefinitions = quickSearchFieldsDefinition.toSorted((a, b) => a.order - b.order);
		setDefinitions(sortedDefinitions);
		initializeSelection(sortedDefinitions);
	}, [quickSearchFieldsDefinition, initializeSelection]);

	useEffect(() => {
		if (stateSaved !== stateCopy && stateSaved !== initialState) {
			initializeState(stateSaved);
		}
	}, [stateCopy, stateSaved, initializeState]);

	useEffect(() => {
		const renderQuery = (): void => {
			const query = state // mergedStates
				.filter((filter: ISelectionState) => filter.selection && filter.selection.length > 0)
				.map((filter: ISelectionState) => {
					return transformArraySelectionToSieveString(filter.searchKey!, filter.selection);
				})
				.join(",");

			handleQueryBuilder(query);
			setStateCopy(state);
			setStateSaved(state);
		};

		if (state !== stateCopy) {
			renderQuery();
		}
	}, [state, stateCopy, setStateCopy, setStateSaved, handleQueryBuilder]);

	return (
		<Stack>
			{definitions.length > 0 && (
				<Grid container spacing={1}>
					<Grid item xs={12} md={12} lg={12}>
						<Stack direction={"row"} justifyContent={"space-between"}>
							<Typography variant={"h5"} color="primary">
								{t("old.common.quickSearch.title")}
							</Typography>
							<Button
								onClick={() => {
									handleClearAll();
								}}
								variant={"outlined"}
								color={"secondary"}
							>
								{t("old.common.quickSearch.clear-all")}
							</Button>
						</Stack>
					</Grid>

					{definitions.map((_definition, _index) => {
						const items = SelectableListItemsHelper.TransformEntityListToItems(_definition);
						return (
							<Grid item xs={12} md={6} lg={4} key={_definition.key}>
								<SelectableListItems
									ref={(ref: any) => (selectableListItemRefs.current[_index] = ref)}
									id={`${_index}.${_definition.key}`}
									key={`${_index}.${_definition.key}`}
									label={_definition.name}
									items={items}
									setSelection={(_selection: Array<string>) => {
										handleSelection(_definition.key, _definition.searchKey, _selection);
									}}
									clearSelection={() => {
										handleClearSelection(_definition.key);
									}}
									useEmptyRow={_definition.addEmptyRow}
								/>
							</Grid>
						);
					})}

					{debugQuickSearchState && (
						<Grid
							item
							xs={12}
							md={12}
							lg={12}
							sx={{
								backgroundColor: alpha(ApplicationColors.orange.main, 0.5),
								borderWidth: "1px",
								borderStyle: "solid",
								borderColor: ApplicationColors.orange.main,
								color: ApplicationColors.orange.dark,
								whiteSpace: "pre-wrap",
								minHeight: "36px",
								m: 1,
							}}
						>
							{state.length > 0 ? JSON.stringify(state, null, "\t") : "Debug QuickSearch"}
						</Grid>
					)}
				</Grid>
			)}
		</Stack>
	);
};

export default QuickSearch;
