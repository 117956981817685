import { KeyTitleHeader } from "@components";
import { useTheme } from "@mui/material";
import { Grid, Icon, Typography } from "@mykey4/core";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { type AbstractDissociationItem, AbstractsDissociationTranslation } from "../common";
import { AbstractDissociationSwitch } from "./AbstractDissociationSwitch";
import { AbstractDissociationSwitchContainer } from "./AbstractDissociationSwitchContainer";

export interface AbstractDissociationContainerProps {
	abstract: AbstractDissociationItem;
	onDissociateAllChange: (abstractId: string, value: boolean) => void;
	onDissociatePresentationChange: (abstractId: string, presentationId: string, value: boolean) => void;
	onSessionKeyClick?: (abstractId: string) => void;
	onAbstractKeyClick?: (abstractId: string) => void;
	onPresentationKeyClick?: (presentationId: string) => void;
}

export const AbstractDissociationContainer = (props: AbstractDissociationContainerProps) => {
	const { abstract, onDissociateAllChange, onDissociatePresentationChange, onAbstractKeyClick, onSessionKeyClick, onPresentationKeyClick } = props;
	const theme = useTheme();
	const { t } = useTranslation();

	const presentations = useMemo(() => abstract.presentations.values().toArray(), [abstract]);
	const checked = presentations.some((item) => item.dissociate);

	const handlePresentationDissociationChange = useCallback(
		(presentationId: string, value: boolean) => onDissociatePresentationChange(abstract.id, presentationId, value),
		[abstract.id, onDissociatePresentationChange],
	);

	const handleDissociateAllChange = useCallback((value: boolean) => onDissociateAllChange(abstract.id, value), [abstract.id, onDissociateAllChange]);

	const handleAbstractKeyClick = useCallback(() => {
		if (abstract) {
			onAbstractKeyClick?.(abstract.id);
		}
	}, [abstract, onAbstractKeyClick]);

	return (
		<KeyTitleHeader
			key={abstract.id}
			keyText={abstract.key}
			title={abstract.title}
			sx={{
				p: 2,
				backgroundColor: theme.palette.background.tone,
				borderRadius: theme.spacing(2),
			}}
			slots={{
				iconBefore: Icon,
				topRight: presentations.length > 0 ? <AbstractDissociationSwitch checked={checked} onCheckedChange={handleDissociateAllChange} /> : undefined,
			}}
			slotProps={{ iconBefore: { name: "memo-circle-check", color: theme.palette.primary.main } }}
			onKeyClick={handleAbstractKeyClick}
		>
			<Grid container spacing={1}>
				{presentations.length <= 0 && (
					<Typography variant={"body1"} fontStyle={"italic"}>
						{t(`${AbstractsDissociationTranslation}.noPresentation`)}
					</Typography>
				)}
				{presentations.map((presentation) => (
					<Grid key={presentation.id} item>
						<AbstractDissociationSwitchContainer
							sessionKey={presentation.sessionKey ?? ""}
							presentationKey={presentation.key ?? ""}
							checked={presentation.dissociate}
							onCheckedChange={(value) => handlePresentationDissociationChange(presentation.id, value)}
							onPresentationKeyClick={() => onPresentationKeyClick?.(presentation.id)}
							onSessionKeyClick={() => onSessionKeyClick?.(presentation.sessionId)}
						/>
					</Grid>
				))}
			</Grid>
		</KeyTitleHeader>
	);
};
