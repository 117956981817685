import { K4Button } from "@infrastructure/components/interface/button/K4Button";
import { Stack } from "@mui/material";
import { t } from "i18next";

type Props = {
	saveButtonClick: () => void;
	cancelButtonClick: () => void;
	disabledSaveButton: boolean;
};

const ValidationFormButtons = (props: Props) => {
	const { saveButtonClick, cancelButtonClick, disabledSaveButton } = props;

	return (
		<Stack display={"flex"} flexDirection={"row-reverse"} mx={0} mt={3}>
			<K4Button label={t("old.form.buttons.save")} variant="contained" color="secondary" handleClick={saveButtonClick} disabled={disabledSaveButton} />
			<K4Button label={t("old.form.buttons.cancel")} variant="outlined" handleClick={cancelButtonClick} sx={{ mr: 1 }} />
		</Stack>
	);
};

export default ValidationFormButtons;
