import type { SessionRead } from "@api";
import type { AbstractDissociationResults, AbstractsAssociationResults, StepConfigs, WizardProps } from "@components";

export const AbstractWizardSteps = ["abstractSelection", "chooseAction", "action", "completed"] as const;
export type AbstractWizardStep = (typeof AbstractWizardSteps)[number];

export type AbstractWizardActionType = "associate" | "dissociate";

export type AbstractWizardActionResult = {
	sessionSelected?: SessionRead;
	associate?: AbstractsAssociationResults;
	dissociate?: AbstractDissociationResults;
};

export type AbstractWizardStepValueTypes = {
	abstractSelection: string[];
	chooseAction: AbstractWizardActionType | undefined;
	action: AbstractWizardActionResult;
	completed: unknown;
};

export type AbstractWizardStepInitialData = {
	search: string;
};

export type AbstractWizardProps = WizardProps<AbstractWizardStep, AbstractWizardStepValueTypes, AbstractWizardStepInitialData>;

export type AbstractWizardConfigs = StepConfigs<AbstractWizardStep, AbstractWizardStepValueTypes, AbstractWizardStepInitialData>;
