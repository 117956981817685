import type { FieldValues, UseFormGetValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";

import { EnumSessionFieldKey, EnumSessionFormKey, EnumSessionSectionKey } from "@application/Enums/SessionEnum";
import type { TypeSessionStaticField } from "@application/Types/SessionType";
import HelpersApp from "@application/helpers";
import type { ISessionModel } from "@domain/model/session.model";
import FormHelper from "@infrastructure/helpers/form.helper";
import { ApplicationColors, EnumFormControlKind, type TPickForm, ThemeColors } from "@key4-front-library/core";
import { EnumSessionStatus } from "@key4-front-library/core/Enums";

// TODO
const statusObject: {
	[key in EnumSessionStatus]: {
		value: EnumSessionStatus;
		label: string;
		bgColor: string;
		fontColor?: string;
	};
} = {
	[EnumSessionStatus.DRAFT]: {
		value: EnumSessionStatus.DRAFT,
		label: "old.common.session_status.draft",
		bgColor: ThemeColors.warning.main,
		fontColor: ThemeColors.warning.contrastText,
	},
	[EnumSessionStatus.VALIDATED]: {
		value: EnumSessionStatus.VALIDATED,
		label: "old.common.session_status.validated",
		bgColor: ThemeColors.success.main,
		fontColor: ThemeColors.success.contrastText,
	},
	[EnumSessionStatus.CANCELLED]: {
		value: EnumSessionStatus.CANCELLED,
		label: "old.common.session_status.cancelled",
		bgColor: ApplicationColors.blueLight.dark!,
		fontColor: ApplicationColors.blueLight.contrastText,
	},
};

const statusArray = [EnumSessionStatus.DRAFT, EnumSessionStatus.VALIDATED, EnumSessionStatus.CANCELLED];

const defaultModel2: Omit<ISessionModel, "clashesCount" | "anomaliesCount"> = {
	tagTypes: [],
	id: "",
	code: null,
	startDate: null,
	startHour: null,
	endDate: null,
	endHour: null,
	timeZone: "UTC",
	status: EnumSessionStatus.DRAFT,
	publicationDate: null,
	title: null,
	maxPax: null,
	order: 0,
	isPrivate: false,
	description: null,
	organizedBy: null,
	expectedAudience: null,
	duration: null,
	room: {
		id: "",
		capacity: 0,
		name: "",
		hasClashes: false,
	},
	customFieldValues: undefined,
};

const editFieldsGeneral: TPickForm = {
	key: EnumSessionFormKey.GENERAL,
	label: "",
	sections: [
		{
			key: EnumSessionSectionKey.MAIN,
			label: "",
			fields: [
				{
					...FormHelper.baseField.pick.text,
					key: "code",
				},
				{
					...FormHelper.baseField.pick.selectChipColor,
					key: "primaryTag",
				},
				{
					...FormHelper.baseField.pick.text,
					key: "organizedBy",
				},
				{
					...FormHelper.baseField.pick.text,
					key: "title",
				},
				{
					...FormHelper.baseField.pick.textArea,
					key: "description",
					textArea: {
						...FormHelper.baseField.pick.textArea.textArea,
						minRows: 5,
						maxRows: 5,
					},
				},
				{
					...FormHelper.baseField.pick.checkbox,
					key: "isPrivate",
				},
			],
		},
	],
};

const editFieldsRoomSchedule: TPickForm = {
	key: EnumSessionFormKey.ROOMSCHEDULE,
	label: "",
	sections: [
		{
			key: EnumSessionSectionKey.WHERE,
			label: "",
			fields: [
				{
					...FormHelper.baseField.pick.numeric,
					key: "expectedAudience",
					numeric: {
						...FormHelper.baseField.pick.numeric.numeric,
						min: 0,
					},
				},
				{
					...FormHelper.baseField.pick.autoCompleteSubLabel,
					key: "roomId",
				},
			],
		},
		{
			key: EnumSessionSectionKey.WHEN,
			label: "",
			fields: [
				{
					...FormHelper.baseField.pick.dateTimePicker,
					key: "startDate",
				},
				{
					...FormHelper.baseField.pick.dateTimePicker,
					key: "endDate",
				},
			],
		},
	],
};

const editFieldsWorkflowTags: TPickForm = {
	key: EnumSessionFormKey.WORKFLOWTAGS,
	label: "",
	sections: [
		{
			key: EnumSessionSectionKey.MAIN,
			label: "",
			fields: [
				{
					...FormHelper.baseField.pick.autoCompleteBulletColor,
					key: "status",
					autoCompleteBulletColor: {
						...FormHelper.baseField.pick.autoCompleteBulletColor.autoCompleteBulletColor,
						items: statusArray.map((statusEnum: EnumSessionStatus) => {
							return {
								key: statusObject[statusEnum].value,
								label: statusObject[statusEnum].label,
								color: statusObject[statusEnum].bgColor,
							};
						}),
					},
				},
				{
					...FormHelper.baseField.pick.checkbox,
					key: "isPublished",
				},
				{
					...FormHelper.baseField.pick.dateTimePicker,
					key: "publicationDate",
				},
			],
		},
	],
};

const pickFormsStatic: Array<TPickForm> = [editFieldsGeneral, editFieldsRoomSchedule, editFieldsWorkflowTags];

const staticListFormControlsObject: {
	[key in EnumSessionFormKey]: {
		[key in EnumSessionSectionKey]?: TypeSessionStaticField;
	};
} = {
	[EnumSessionFormKey.GENERAL]: {
		[EnumSessionSectionKey.MAIN]: {
			[EnumSessionFieldKey.CODE]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.CODE,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.CODE}`,
					},
				},
			},
			[EnumSessionFieldKey.PRIMARY_TAG_ID]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.PRIMARY_TAG_ID,
					kind: EnumFormControlKind.CONTAINER_PRIMARY_TAG_SELECT,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.PRIMARY_TAG_ID}`,
					},
				},
			},
			[EnumSessionFieldKey.ORGANIZED_BY]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.ORGANIZED_BY,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.ORGANIZED_BY}`,
					},
				},
			},
			[EnumSessionFieldKey.TITLE]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.TITLE,
					gridSize: { xs: 12, md: 12, lg: 12 },
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.TITLE}`,
					},
				},
			},
			[EnumSessionFieldKey.DESCRIPTION]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.DESCRIPTION,
					kind: EnumFormControlKind.TEXT_AREA,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.DESCRIPTION}`,
						minRows: 5,
						maxRows: 5,
					},
				},
			},
			[EnumSessionFieldKey.IS_PRIVATE]: {
				value: false,
				component: {
					id: EnumSessionFieldKey.IS_PRIVATE,
					kind: EnumFormControlKind.CHECKBOX,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.IS_PRIVATE}`,
					},
				},
			},
		},
	},
	[EnumSessionFormKey.ROOMSCHEDULE]: {
		[EnumSessionSectionKey.WHERE]: {
			[EnumSessionFieldKey.EXPECTED_AUDIENCE]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.EXPECTED_AUDIENCE,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.EXPECTED_AUDIENCE}`,
						type: "number",
						min: 0,
					},
				},
			},
		},
		[EnumSessionSectionKey.WHEN]: {
			[EnumSessionFieldKey.START_DATE]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.START_DATE,
					kind: EnumFormControlKind.DATE_PICKER,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.START_DATE}`,
					},
					cbChange: (
						name: string,
						value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const { basePath, pathOf } = HelpersApp.SessionHelper.pathHelper(name);

						if (!getValues(pathOf(EnumSessionFieldKey.END_DATE)) && value) {
							setValues(pathOf(EnumSessionFieldKey.END_DATE), value);
						}

						if (!getValues(pathOf(EnumSessionFieldKey.DURATION)) && value) {
							HelpersApp.SessionHelper.setDurationValues(basePath, setValues, getValues);
						}

						if (getValues(pathOf(EnumSessionFieldKey.DURATION)) && value) {
							HelpersApp.SessionHelper.changeDatesOnDuration(basePath, setValues, getValues);
						}

						HelpersApp.SessionHelper.triggerDates(basePath, trigger);
					},
				},
			},
			[EnumSessionFieldKey.START_HOUR]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.START_HOUR,
					kind: EnumFormControlKind.TIME_PICKER,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.START_HOUR}`,
					},
					cbChange: (
						name: string,
						value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const { basePath, pathOf } = HelpersApp.SessionHelper.pathHelper(name);

						if (!getValues(pathOf(EnumSessionFieldKey.DURATION)) && value) {
							HelpersApp.SessionHelper.setDurationValues(basePath, setValues, getValues);
						}

						if (getValues(pathOf(EnumSessionFieldKey.DURATION)) && value) {
							HelpersApp.SessionHelper.changeDatesOnDuration(basePath, setValues, getValues);
						}

						HelpersApp.SessionHelper.triggerDates(basePath, trigger);
					},
				},
			},
			[EnumSessionFieldKey.END_DATE]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.END_DATE,
					kind: EnumFormControlKind.DATE_PICKER,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.END_DATE}`,
					},
					cbChange: (
						name: string,
						_value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const { basePath } = HelpersApp.SessionHelper.pathHelper(name);

						HelpersApp.SessionHelper.setDurationValues(basePath, setValues, getValues);

						HelpersApp.SessionHelper.triggerDates(basePath, trigger);
					},
				},
			},
			[EnumSessionFieldKey.END_HOUR]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.END_HOUR,
					kind: EnumFormControlKind.TIME_PICKER,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.END_HOUR}`,
					},
					cbChange: (
						name: string,
						_value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const { basePath } = HelpersApp.SessionHelper.pathHelper(name);

						HelpersApp.SessionHelper.setDurationValues(basePath, setValues, getValues);
						HelpersApp.SessionHelper.triggerDates(basePath, trigger);
					},
				},
			},
			[EnumSessionFieldKey.DURATION]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.DURATION,
					kind: EnumFormControlKind.AUTOCOMPLETE,
					propsComponent: {
						label: `old.common.formControls.labels.${EnumSessionFieldKey.DURATION}`,
						freeSolo: true,
						items: [],
					},
					gridSize: { xs: 12, md: 4, lg: 4 },
					cbChange: (
						name: string,
						value: any,
						setValues: UseFormSetValue<FieldValues>,
						getValues: UseFormGetValues<FieldValues>,
						trigger: UseFormTrigger<FieldValues>,
					) => {
						const { basePath, pathOf } = HelpersApp.SessionHelper.pathHelper(name);

						HelpersApp.SessionHelper.changeDatesOnDuration(basePath, setValues, getValues);

						if (!value) {
							setValues(pathOf(EnumSessionFieldKey.END_HOUR), null);
						}

						HelpersApp.SessionHelper.triggerDates(basePath, trigger);
					},
				},
			},
		},
	},
	[EnumSessionFormKey.WORKFLOWTAGS]: {
		[EnumSessionSectionKey.WORKFLOW]: {
			[EnumSessionFieldKey.STATUS]: {
				value: EnumSessionStatus.DRAFT,
				component: {
					id: EnumSessionFieldKey.STATUS,
					kind: EnumFormControlKind.SELECT_BULLET_COLOR,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.STATUS}`,
						items: statusArray.map((statusEnum: EnumSessionStatus) => {
							return {
								key: statusObject[statusEnum].value,
								label: statusObject[statusEnum].label,
								color: statusObject[statusEnum].bgColor,
							};
						}),
						isNullable: false,
					},
				},
			},
			[EnumSessionFieldKey.IS_PUBLISHED]: {
				value: false,
				component: {
					id: EnumSessionFieldKey.IS_PUBLISHED,
					kind: EnumFormControlKind.CHECKBOX,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.IS_PUBLISHED}`,
					},
				},
			},
			[EnumSessionFieldKey.PUBLICATION_DATE]: {
				value: null,
				component: {
					id: EnumSessionFieldKey.PUBLICATION_DATE,
					kind: EnumFormControlKind.DATE_TIME_PICKER,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.PUBLICATION_DATE}`,
					},
				},
			},
		},
		[EnumSessionSectionKey.TAGS]: {
			[EnumSessionFieldKey.TAG_IDS]: {
				value: [],
				component: {
					id: EnumSessionFieldKey.TAG_IDS,
					kind: EnumFormControlKind.CONTAINER_SECONDARIES_TAGS_LIST,
					propsComponent: {
						label: `old.common.session.formControls.labels.${EnumSessionFieldKey.TAG_IDS}`,
					},
				},
			},
		},
	},
};

const SessionConfiguration = {
	defaultModel2,
	pickFormsStatic,
	staticListFormControlsObject,
};

export default SessionConfiguration;
