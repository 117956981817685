import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { useContextModule } from "@key4-front-library/core";
import type { IconName } from "@mykey4/core";
import { Checkbox, Grid, GridLabel, type InvitationSettingsRead, QUERY_KEYS_PROGRAMME, Stack, useDialogs, useInvitationSettings } from "@mykey4/core";
import { type UseQueryResult, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import type { ReactNode } from "react";
import { InvitationSettingsModal } from "../../../../Components/Organisms/InvitationSettingsModal";

export const InvitationSettings = (): ReactNode => {
	const component = "invitationSettings";

	const { client, event } = useContextModule();
	const queryClient = useQueryClient();
	const dialogs = useDialogs();

	const invitationSettingsQuery: UseQueryResult<InvitationSettingsRead> = useInvitationSettings(client.id, event.id);

	const handleOnSubmit = async () => {
		await queryClient.invalidateQueries({ queryKey: QUERY_KEYS_PROGRAMME.settings });
	};

	return (
		<IconCard
			title={t(`${component}.title`)}
			icon={"envelope"}
			button={{
				label: t("button.edit"),
				icon: "pen-to-square" as IconName,
				onClick: () => dialogs.open(InvitationSettingsModal, { onCallbackSubmit: handleOnSubmit }),
			}}
		>
			<Stack spacing={2}>
				<Grid container spacing={2}>
					<GridLabel label={t(`${component}.shouldDisplayConflictOfInterestQuestion`)}>
						<Checkbox checked={invitationSettingsQuery.data?.shouldDisplayConflictOfInterestQuestion === true} isDisabled />
					</GridLabel>
					<GridLabel label={t(`${component}.shouldDisplayConflictOfInterestDetailsQuestion`)}>
						<Checkbox checked={invitationSettingsQuery.data?.shouldDisplayConflictOfInterestDetailsQuestion === true} isDisabled />
					</GridLabel>
				</Grid>
			</Stack>
		</IconCard>
	);
};
