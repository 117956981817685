import { useSessionTemplates, useTagTypes, useTags } from "@api";
import { ConfirmationDialog } from "@components";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { DataGridOld, getColumns, useContextModule } from "@key4-front-library/core";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import { Stack } from "@mui/material";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";
import { sortToQueryString, useDialogs } from "@mykey4/core";
import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { SessionTemplateModal } from "../../../../Components/Organisms/SessionTemplateModal";
import { buildTagRows, dataGridHeaders } from "./SessionTemplates.configuration";

export const SessionTemplates = () => {
	const { client, event } = useContextModule();
	const dialog = useDialogs();
	const { enqueueSnackbar } = useSnackbar();
	const [row, setRow] = useState<Array<GridValidRowModel>>([]);

	const tagTypesQuery = useTagTypes(client.id, event.id, {
		queryStrings: sortToQueryString([{ field: "order", sort: "asc" }]),
	});
	const tagsQuery = useTags(client.id, event.id, tagTypesQuery.data?.data?.[0].id ?? "", {
		enabled: !!tagTypesQuery.data?.data?.[0].id,
		queryStrings: sortToQueryString([{ field: "order", sort: "asc" }]),
	});

	const sessionTemplates = useSessionTemplates(client.id, event.id);

	useEffect(() => {
		const tags = tagsQuery.data?.data ?? [];
		const templates = sessionTemplates.data?.data ?? [];
		setRow(
			buildTagRows(tags, templates, {
				edit: onClickEditRowButton,
				delete: onClickDeleteRowButton,
				create: onClickAddTemplateButton,
			}),
		);
	}, [tagsQuery.data?.data, sessionTemplates.data?.data]);

	const getSessionTemplate = (primaryTagId: string) => {
		if (!sessionTemplates.data?.data) {
			return null;
		}
		return sessionTemplates.data?.data.find((template) => template.primaryTagId === primaryTagId);
	};

	const getTranslatedColumnLabels = (): Array<string> => {
		return dataGridHeaders.map((colDef) => t(`old.programme.settings.sessionTemplates.datagrid.headerLabels.${colDef.field}`));
	};

	const onClickAddTemplateButton = (primaryTagId: string) => {
		dialog
			.open(SessionTemplateModal, {
				clientId: client.id,
				eventId: event.id,
				primaryTagId,
			})
			.then(() => sessionTemplates.refetch());
	};

	const onClickEditRowButton = async (primaryTagId: string) => {
		const sessionTemplateToUpdate = getSessionTemplate(primaryTagId);
		if (!sessionTemplateToUpdate) {
			return;
		}
		dialog
			.open(SessionTemplateModal, {
				clientId: client.id,
				eventId: event.id,
				primaryTagId,
				existing: sessionTemplateToUpdate,
			})
			.then(() => sessionTemplates.refetch());
	};

	const onClickDeleteRowButton = async (primaryTagId: string): Promise<void> => {
		const sessionTemplateToDelete = getSessionTemplate(primaryTagId);
		if (!sessionTemplateToDelete) {
			return;
		}

		const confirmed = await dialog.open(ConfirmationDialog, { content: t("old.programme.settings.sessionTemplates.modal.confirmation.delete.message") });
		if (!confirmed) {
			return;
		}
		try {
			await ControllersBo.SessionTemplatesController.deleteEntity(client.id, event.id, sessionTemplateToDelete.id);
			enqueueSnackbar(t("old.programme.settings.sessionTemplates.modal.confirmation.delete.success"), {
				variant: "success",
			});
		} catch (error) {
			enqueueSnackbar(t("old.common.errors.generic"), {
				variant: "error",
			});
		}

		await sessionTemplates.refetch();
	};

	return (
		<IconCard title={t("old.programme.settings.sessionTemplates.title")} icon="paste">
			<Stack spacing={3.5} sx={{ mt: -3 }}>
				<DataGridOld columns={getColumns(getTranslatedColumnLabels(), dataGridHeaders)} rows={row} isAutoHeight={true} isHideFooter={true} />
			</Stack>
		</IconCard>
	);
};
