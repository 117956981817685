import { useDashboardSessionStatus } from "@api";
import { useSessionStatusConfigurations } from "@configurations";
import { useContextModule } from "@key4-front-library/core";
import type { RegularBreakpoints } from "@mui/material/Grid/Grid";
import { SessionStatusTypes } from "@mykey4/core";
import { t } from "i18next";
import DashboardDonutChart from "../Common/DashboardDonutChart";

type Props = RegularBreakpoints & {};

export const DashboardSessionsStatus = (props: Props) => {
	const { client, event } = useContextModule();
	const sessionStatusConfigurations = useSessionStatusConfigurations();

	const statusStatistics = useDashboardSessionStatus({
		clientId: client.id,
		eventId: event.id,
		select: (data) => {
			return SessionStatusTypes.map((status) => {
				const config = sessionStatusConfigurations[status];
				return {
					name: config.name,
					value: data[status],
					color: config.color,
				};
			});
		},
	});

	return (
		<DashboardDonutChart
			{...props}
			title={t("old.programme.dashboard.charts.donuts.sessions.title")}
			statistics={statusStatistics.data ?? []}
			isLoading={statusStatistics.isFetching}
		/>
	);
};
