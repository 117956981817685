import { type TagRead, useBulkUpdatePresentationsDatesUpdate, usePresentations, useSession, useSessionUpdate, useTags } from "@api";
import { ScheduleUpdate } from "@application/components/Molecules";
import { TitleLink } from "@application/components/Molecules/TitleLink";
import { zodResolver } from "@hookform/resolvers/zod";
import { EMenuPath, useContextModule } from "@key4-front-library/core";
import { Box } from "@mui/material";
import { DialogAdvanced, type DialogComponentProps, TitleDivider, Typography, getButton, getTitleDivider, metaEnv, sortToQueryString } from "@mykey4/core";
import { t } from "i18next";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
	type FormPresentationsEdit,
	type PresentationEditSchedule,
	type SessionEditSchedule,
	formPresentationEditValidationSchema,
	presentationEditScheduleToPresentationBulkDateUpdate,
	reschedulePresentations,
	sessionEditScheduleToSessionWrite,
} from "../index";

export interface EditPresentationsScheduleProps {
	sessionEditSchedule: SessionEditSchedule | undefined;
}

export const EditPresentationsSchedule = (props: DialogComponentProps<EditPresentationsScheduleProps>): React.ReactNode => {
	const {
		payload: { sessionEditSchedule },
		open,
		onClose,
	} = props;

	const component = "editPresentationsSchedule";
	const { client, event } = useContextModule();
	const snackbar = useSnackbar();

	const [presentationEditSchedules, setPresentationEditSchedules] = useState<Record<string, PresentationEditSchedule>>({});
	const presentationEditSchedulesValues = Object.values(presentationEditSchedules);

	const getSessionQuery = useSession(client.id, event.id, sessionEditSchedule?.id ?? "", {
		queryKeyPart: ["EditPresentationsSchedule"],
		enabled: !!sessionEditSchedule,
	});

	const getPresentationBySessionQuery = usePresentations(client.id, event.id, sessionEditSchedule?.id ?? "", {
		queryStrings: sortToQueryString([{ field: "startDate", sort: "asc" }]),
		enabled: !!sessionEditSchedule,
	});

	const sessionSubmitSuccess = useCallback(() => {
		snackbar.enqueueSnackbar(t(`${component}.sessionSubmitSuccess`), { variant: "success" });
	}, [snackbar]);

	const presentationsSubmitSuccess = useCallback(() => {
		snackbar.enqueueSnackbar(t(`${component}.presentationsSubmitSuccess`), { variant: "success" });
	}, [snackbar]);

	const sessionUpdate = useSessionUpdate({ onSuccess: sessionSubmitSuccess });
	const sessionTagQuery = useTags(client.id, event.id, sessionEditSchedule?.id ?? "", { enabled: !!sessionEditSchedule });
	const presentationsBulkDateUpdate = useBulkUpdatePresentationsDatesUpdate({ onSuccess: presentationsSubmitSuccess });

	useEffect(() => {
		if (getPresentationBySessionQuery.data && sessionEditSchedule?.newDateTimeStart) {
			const presentationsRecord = reschedulePresentations(getPresentationBySessionQuery.data.data, sessionEditSchedule.newDateTimeStart).reduce(
				(acc, presentation) => {
					acc[presentation.id] = presentation;
					return acc;
				},
				{} as Record<string, PresentationEditSchedule>,
			);
			form.reset({
				session: sessionEditSchedule,
				presentations: presentationsRecord,
			});
			setPresentationEditSchedules(presentationsRecord);
		}
	}, [getPresentationBySessionQuery.data, sessionEditSchedule]);

	const form = useForm<FormPresentationsEdit>({
		mode: "onSubmit",
		defaultValues: { session: sessionEditSchedule },
		resolver: zodResolver(formPresentationEditValidationSchema(component)),
	});

	const newDateTimeStart = form.watch("session.newDateTimeStart");

	useEffect(() => {
		const end = form.getValues("session.newDateTimeEnd");
		if (!newDateTimeStart || !newDateTimeStart.isValid || !end?.isValid) {
			return;
		}
		form.setValue("session.newDateTimeEnd", end.set({ year: newDateTimeStart.year, month: newDateTimeStart.month, day: newDateTimeStart.day }));
	}, [newDateTimeStart, form.getValues, form.setValue]);

	const handleSubmit = useCallback(
		async (formValues: FormPresentationsEdit) => {
			const sessionNewStart = formValues.session.newDateTimeStart;
			const sessionNewEnd = formValues.session.newDateTimeEnd;
			if (!getSessionQuery.data || !sessionEditSchedule?.id || !sessionNewStart?.isValid || !sessionNewEnd?.isValid) {
				throw new Error("Session not found");
			}
			if (
				!getSessionQuery.data.startDate ||
				!sessionNewStart.equals(DateTime.fromISO(getSessionQuery.data.startDate)) ||
				!getSessionQuery.data.endDate ||
				!sessionNewEnd.equals(DateTime.fromISO(getSessionQuery.data.endDate))
			) {
				const sessionWrite = sessionEditScheduleToSessionWrite(
					formValues.session,
					getSessionQuery.data,
					sessionTagQuery.data?.data?.map((x: TagRead) => x.id) ?? [],
				);
				await sessionUpdate.mutateAsync({
					clientId: client.id,
					eventId: event.id,
					sessionId: sessionEditSchedule.id ?? "",
					body: sessionWrite,
				});
			}
			if (sessionNewStart?.isValid) {
				const presentationBulkDateUpdate = presentationEditScheduleToPresentationBulkDateUpdate(Object.values(formValues.presentations), sessionNewStart);
				await presentationsBulkDateUpdate.mutateAsync({
					clientId: client.id,
					eventId: event.id,
					sessionId: sessionEditSchedule.id,
					body: presentationBulkDateUpdate,
				});
			}
			onClose?.();
		},
		[getSessionQuery.data, sessionUpdate, client.id, event.id, sessionEditSchedule?.id, presentationsBulkDateUpdate, sessionTagQuery.data, onClose],
	);

	const handleSaveButtonClick = (): void => {
		void form.handleSubmit(handleSubmit)();
	};

	const handleClose = async () => {
		await onClose();
	};

	const isSessionMultiDays = () =>
		sessionEditSchedule?.newDateTimeStart &&
		sessionEditSchedule.newDateTimeEnd &&
		sessionEditSchedule.newDateTimeEnd.toISODate() !== sessionEditSchedule.newDateTimeStart.toISODate();

	if (!getPresentationBySessionQuery.isFetched || !getSessionQuery.isFetched) {
		return null;
	}

	if (isSessionMultiDays()) {
		return (
			<DialogAdvanced
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="md"
				title={{ variant: "h6", children: t(`${component}.title`) }}
				buttonGroupEnd={{
					buttons: [
						{
							onClick: handleClose,
							...getButton({ type: "ok" }),
						},
					],
				}}
			>
				<Typography> {t(`${component}.moreThanOneDayError`)}</Typography>
			</DialogAdvanced>
		);
	}

	return (
		<DialogAdvanced
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="xl"
			title={{ variant: "h6", children: t(`${component}.title`) }}
			buttonGroupEnd={{
				buttons: [
					{ onClick: handleClose, ...getButton({ type: "ignore" }) },
					{
						disabled: false,
						onClick: handleSaveButtonClick,
						isLoading: form.formState.isSubmitting,
						...getButton({ type: "save" }),
					},
				],
			}}
		>
			<TitleLink
				titleProps={{ children: `${t("session")} - ` }}
				linkProps={{
					to: [metaEnv.myKey4.endPoint.programme, client.key, event.key, EMenuPath.SESSIONS, sessionEditSchedule?.id].join("/"),
					children: <>{` ${getSessionQuery.data?.code ?? t("noCode")}`}</>,
				}}
			/>

			<FormProvider {...form}>
				<ScheduleUpdate
					title={getSessionQuery.data?.title ?? ""}
					code={getSessionQuery.data?.code ?? ""}
					oldDateBlockProps={{
						isDisabled: true,
						displayDate: "session.oldDateTimeStart",
						startDateField: "session.oldDateTimeStart",
						endDateField: "session.oldDateTimeEnd",
					}}
					newDateBlockProps={{
						displayDate: "session.newDateTimeStart",
						startDateField: "session.newDateTimeStart",
						endDateField: "session.newDateTimeEnd",
						defaultCalendarDate: sessionEditSchedule?.newDateTimeStart,
					}}
				/>

				{presentationEditSchedulesValues.length > 0 && (
					<Box paddingTop={3}>
						<TitleDivider
							{...getTitleDivider({
								type: "sectionTitle",
								translation: t(`${component}.presentations.title`),
							})}
						/>
						{presentationEditSchedulesValues.map((presentation) => (
							<Box key={presentation.id} paddingTop={2}>
								<ScheduleUpdate
									title={presentation.title ?? ""}
									code={presentation.code}
									oldDateBlockProps={{
										isDisabled: true,
										startDateField: `presentations.${presentation.id}.oldDateTimeStart`,
										endDateField: `presentations.${presentation.id}.oldDateTimeEnd`,
									}}
									newDateBlockProps={{
										startDateField: `presentations.${presentation.id}.newDateTimeStart`,
										endDateField: `presentations.${presentation.id}.newDateTimeEnd`,
									}}
								/>
							</Box>
						))}
					</Box>
				)}
			</FormProvider>
		</DialogAdvanced>
	);
};
