import { Link, LinksCropped } from "@mykey4/core";
import { getPath } from "@routes";
import { memo } from "react";

const LinkMemo = memo(Link);

export interface AbstractPresentationCellProps {
	clientKey: string;
	eventKey: string;
	presentations: { key: string; sessionId: string }[];
}

export const AbstractPresentationCell = memo((props: AbstractPresentationCellProps) => {
	const { clientKey, eventKey, presentations } = props;
	return (
		<LinksCropped
			linksElementGroup={{
				elements: presentations.map((presentation, index) => ({
					variant: "body2",
					underline: "always",
					to: getPath("sessionDetailsPresentations", [clientKey, eventKey, presentation.sessionId]),
					children: (
						<>
							{presentation.key}
							{presentations.length === index + 1 ? "" : ","}
						</>
					),
				})),
				Component: LinkMemo,
			}}
			limit={2}
		/>
	);
});
