import type { AbstractRead, PresentationRead } from "@api";
import type { AbstractsAssociationResults } from "@components";
import { useCallback, useEffect, useState } from "react";
import { buildPresentationAbstractItem, buildPresentationAbstractItems } from "./AbstractsAssociation.helper";
import { mapAssociationToResults } from "./AbstractsAssociation.mapper";
import { type EnrichedAbstractAssociation, isAbstractOnly, isBoth, isEmpty, isPresentationOnly } from "./common";

export interface UseAbstractsAssociationArgs {
	displayAll: boolean;
	presentations: PresentationRead[];
	abstracts: AbstractRead[];
	selectedAbstractIds: string[];
	onAssociationsChange?: (associations: AbstractsAssociationResults) => void;
}

export interface UseAbstractsAssociationReturns {
	items: Map<string, EnrichedAbstractAssociation>;
	move: (fromId: string, toId: string) => void;
	overridePresentationName: (id: string, value: boolean) => void;
}

export const useAbstractsAssociation = (args: UseAbstractsAssociationArgs): UseAbstractsAssociationReturns => {
	const { presentations, abstracts, selectedAbstractIds, displayAll, onAssociationsChange } = args;

	const [items, setItems] = useState<Map<string, EnrichedAbstractAssociation>>(new Map<string, EnrichedAbstractAssociation>());
	const [filteredItems, setFilteredItems] = useState<Map<string, EnrichedAbstractAssociation>>(items);

	const handleMove = useCallback((fromId: string, toId: string) => {
		// Skip if trying to move to the same position
		if (fromId === toId) {
			return;
		}

		setItems((currentItems) => {
			// Get the source and destination items
			const from = currentItems.get(fromId);
			const to = currentItems.get(toId);

			// Return unchanged if either item doesn't exist
			if (!from || !to) {
				return currentItems;
			}

			// Skip if source has no abstract to move
			if (isEmpty(from) || isPresentationOnly(from)) {
				return currentItems;
			}

			// Create a new map to hold the updated state
			const newItems = new Map(currentItems);

			// Track if destination was empty for later use
			const destinationIsEmpty = isEmpty(to);

			// Create new objects with swapped abstracts and update
			const newFrom = { ...from, abstract: to.abstract };
			const newTo = { ...to, abstract: from.abstract };
			newItems.set(fromId, newFrom);
			newItems.set(toId, newTo);

			// Reset title override if source has no abstract
			if (isPresentationOnly(newFrom)) {
				newFrom.overridePresentationTitle = false;
			}

			// Reset title override if destination has no abstract
			if (isPresentationOnly(newTo)) {
				newTo.overridePresentationTitle = false;
			}

			// Remove completely empty items
			if (isEmpty(newFrom)) {
				newItems.delete(newFrom.id);
			}

			// If we moved to an empty slot, create a new empty slot
			if (destinationIsEmpty) {
				newTo.overridePresentationTitle = isAbstractOnly(newTo);
				const newItem = buildPresentationAbstractItem({});
				newItems.set(newItem.id, newItem);
			}

			return newItems;
		});
	}, []);

	const overridePresentationName = useCallback((id: string, value: boolean) => {
		setItems((currentItems) => {
			const item = currentItems.get(id);
			if (!item) {
				return currentItems;
			}
			const newItems = new Map(currentItems);
			newItems.set(id, {
				...item,
				overridePresentationTitle: value,
			});
			return newItems;
		});
	}, []);

	useEffect(() => setFilteredItems(new Map([...items].filter(([_, value]) => displayAll || !isBoth(value)))), [items, displayAll]);
	useEffect(() => setItems(buildPresentationAbstractItems(presentations, abstracts, selectedAbstractIds)), [presentations, abstracts, selectedAbstractIds]);
	useEffect(() => onAssociationsChange?.(mapAssociationToResults(items)), [items, onAssociationsChange]);

	return { items: filteredItems, move: handleMove, overridePresentationName };
};
