import { MenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import SettingsAnomaliesSkeleton from "@application/components/settings/anomalies/Skeleton/SettingsAnomaliessSkeleton";
import StatusAnomaliesEditModale from "@application/components/settings/anomalies/Status/StatusAnomaliesEditModale";
import StatusAnomaliesRender from "@application/components/settings/anomalies/Status/StatusAnomaliesRender";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import GetTranslationsForAnomalies from "@application/helpers/translations/anomalies/GetTranslationsForAnomalies";
import GetTranslationsForSettingsAnomalies from "@application/helpers/translations/settings/GetTranslationsForSettingsAnomalies";
import type { ISettingsAnomaliesHookFormValues } from "@domain/interfaces/settings.anomalies.interface";
import type { IAnomaly, ISettingsAnomaliesModel } from "@domain/model/settings.anomalies.model";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import { Services, TitleOld, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import type { EnumAnomaly } from "@key4-front-library/core/Enums";
import { Fragment, useCallback, useEffect, useState } from "react";

export const Anomalies = () => {
	const { sendSuccess, sendError } = useSnackBarHook();
	const { client, event } = useContextModule();
	const translations = GetTranslationsForSettingsAnomalies();
	const translationsAnomalies = GetTranslationsForAnomalies();

	const [anomalies, setAnomalies] = useState<Array<ISettingsAnomaliesModel>>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [openAnomaliesEditModale, setOpenAnomaliesEditModale] = useState(false);
	const editConfig = MenuActionsConfigurations[MenuActionsReference.EDIT];

	const fetchAnomalies = useCallback((clientId: string, eventId: string) => {
		setIsLoading(true);
		Services.Events.Programme.AnomaliesService.getListSettings(clientId, eventId, [{ key: "onlyEnabled", value: "false" }])
			.then((_data: Array<any>) => setAnomalies(_data))
			.catch(sendError)
			.finally(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		fetchAnomalies(client.id, event.id);
	}, [event, fetchAnomalies]);

	const handleModaleAnomaliesOpen = () => {
		setOpenAnomaliesEditModale(true);
	};

	const handleModaleAnomaliesClose = () => {
		setOpenAnomaliesEditModale(false);
	};

	const handleSetFormDefaultValues = useCallback((_data: Array<ISettingsAnomaliesModel>) => {
		return SettingsFactory.TransformSettingsAnomaliesDataForDefaultValuesForm({
			anomalies: _data,
		});
	}, []);

	const handleFormSubmit = (_data: ISettingsAnomaliesHookFormValues) => {
		const payload = SettingsFactory.TransformSettingsAnomaliesDataForWriting({
			anomaliesForm: _data,
		});
		const saveAnomalies = (clientId: string, eventId: string, payload: Array<IAnomaly>) => {
			setIsLoading(true);
			Services.Events.Programme.AnomaliesService.putSettings(
				clientId,
				eventId,
				payload.map((anomaly) => {
					return {
						type: anomaly.type as unknown as EnumAnomaly,
						enabled: anomaly.enabled,
					};
				}),
			)
				.then(() => {
					fetchAnomalies(client.id, event.id);
					sendSuccess(translations.returnMessages.success_modification);
				})
				.catch(sendError)
				.finally(() => handleModaleAnomaliesClose());
		};

		saveAnomalies(client.id, event.id, payload);
	};

	if (isLoading) {
		return <SettingsAnomaliesSkeleton quantity={10} />;
	}

	return (
		anomalies && (
			<Fragment>
				<IconCard
					title={translationsAnomalies.anomalies.title}
					icon={"calendar-circle-plus"}
					button={{
						label: translations.buttons.edit,
						icon: editConfig.icon,
						onClick: handleModaleAnomaliesOpen,
					}}
				>
					<StatusAnomaliesRender
						anomalies={SettingsFactory.TransformSettingsAnomaliesData({
							anomalies,
							translations,
							translationsAnomalies,
						})}
					/>
				</IconCard>

				<DefaultModal open={openAnomaliesEditModale} title={<TitleOld modaleMode={true} title={translationsAnomalies.anomalies.title} />} maxWidth={"lg"}>
					<StatusAnomaliesEditModale
						anomalies={SettingsFactory.TransformSettingsAnomaliesData({
							anomalies,
							translations,
							translationsAnomalies,
							isEdit: true,
						})}
						onModaleClose={handleModaleAnomaliesClose}
						defaulValues={handleSetFormDefaultValues(anomalies)}
						onFormSubmit={handleFormSubmit}
					/>
				</DefaultModal>
			</Fragment>
		)
	);
};
