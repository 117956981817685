import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { type DtoQuality, type IEntity, NoData, Services, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import DefaultModal from "@key4-front-library/core/Bo/Components/Modal/DefaultModal";
import { Skeleton, Stack } from "@mui/material";
import SettingsSpeakerQualityEdit from "@presentation/pages/advancedSettings/speakersTypes/SpeakerQualityEdit";
import { t } from "i18next";
import update from "immutability-helper";
import { uniqueId } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useSpeakerQualities } from "@api";
import { emptyToUndefined } from "@mykey4/core";
import SettingsSpeakerQualityRender from "@presentation/pages/advancedSettings/speakersTypes/SpeakerQualityRender";
import { dtoQualityToActivityForm } from "./Activity.mapper";
import type { ActivityForm } from "./Activity.type";

export const SpeakersTypes = () => {
	const { client, event } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();

	const [currentSpeakerQuality, setCurrentSpeakerQuality] = useState<ActivityForm | null>(null);
	const [speakerQualities, setSpeakerQualities] = useState<Array<DtoQuality> | undefined>(undefined);

	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const handleTopButtonOpenModalSpeakerQualityAdd = () => {
		setCurrentSpeakerQuality(null);
		setModaleOpenState(true);
	};

	const handleEditModaleClose = (): void => {
		setModaleOpenState(false);
	};

	const qualitiesQueries = useSpeakerQualities(client.id, event.id);

	useEffect(() => {
		setSpeakerQualities(qualitiesQueries.data?.data ?? []);
	}, [qualitiesQueries.data?.data]);

	const refresh = useCallback(async () => {
		void qualitiesQueries.refetch();
	}, [qualitiesQueries.refetch]);

	const handleUpdateSpeakerQuality = useCallback(
		async (_data: ActivityForm) => {
			if (!currentSpeakerQuality) {
				await Services.Events.Programme.SpeakersService.postQuality(client.id, event.id, {
					label: _data.label,
					order: emptyToUndefined(_data.order ?? undefined),
				})
					.then((_response: IEntity) => {
						refresh();
						sendSuccess(t("old.programme.advancedSettings.speakers.returnMessages.success_creation"));
					})
					.catch(() => {
						sendError(t("old.common.errors.generic"));
					});
			} else {
				await Services.Events.Programme.TagsService.put(client.id, event.id, currentSpeakerQuality.id, currentSpeakerQuality.id, {
					label: _data.label,
					order: emptyToUndefined(_data.order ?? undefined),
				})
					.then((_response: boolean) => {
						refresh();
						sendSuccess(t("old.programme.advancedSettings.speakers.returnMessages.success_modification"));
					})
					.catch(() => {
						sendError(t("old.common.errors.generic"));
					});
			}
		},
		[currentSpeakerQuality, client.id, event.id, refresh, sendSuccess, sendError],
	);

	const handleDeleteSpeakerQuality = useCallback(
		async (tagTypeId: string, tagId: string) => {
			await Services.Events.Programme.TagsService.deleteEntity(client.id, event.id, tagTypeId, tagId)
				.then(() => {
					setSpeakerQualities(speakerQualities?.filter((x) => x.id !== tagId));
					sendSuccess(t("old.programme.advancedSettings.speakers.returnMessages.success_deletion"));
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				});
		},
		[client.id, event.id, speakerQualities, sendSuccess, sendError],
	);

	const renderIconCardChildren = useCallback(() => {
		if (!speakerQualities?.length) {
			return <NoData />;
		}
		return (
			<Stack spacing={2} key={uniqueId()}>
				{speakerQualities.map((speakerQuality, index) => renderSpeakerQuality(speakerQuality, index))}
			</Stack>
		);
	}, [speakerQualities]);

	const moveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const qualities = speakerQualities ?? [];
			const orderedQualities = update(qualities, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, qualities[dragIndex]],
				],
			});

			Services.Events.Programme.SpeakersService.putQualityReOrder(client.id, event.id, { ids: orderedQualities.map((x) => x.id) })
				.then(() => refresh())
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				});

			setSpeakerQualities(orderedQualities);

			return orderedQualities;
		},
		[speakerQualities, client.id, event.id, refresh, sendError],
	);

	const renderSpeakerQuality = useCallback(
		(_speakerQuality: DtoQuality, index: number) => {
			const handleOpenModalSpeakerQualityEdit = (_entity: DtoQuality) => {
				setCurrentSpeakerQuality(dtoQualityToActivityForm(_entity));
				setModaleOpenState(true);
			};

			return (
				<DndProvider backend={HTML5Backend} key={uniqueId()}>
					<Stack key={_speakerQuality.id}>
						<SettingsSpeakerQualityRender
							index={index}
							speakerQuality={_speakerQuality}
							speakerQualitiesCount={speakerQualities?.length ?? 0}
							handleOpenModalSpeakerQualityEdit={handleOpenModalSpeakerQualityEdit}
							handleDeleteSpeakerQuality={handleDeleteSpeakerQuality}
							moveCard={moveCard}
						/>
					</Stack>
				</DndProvider>
			);
		},
		[moveCard, handleDeleteSpeakerQuality, speakerQualities?.length],
	);

	return (
		<>
			<IconCard
				title={t("old.programme.advancedSettings.speakers.title")}
				icon={"chalkboard-user"}
				button={{
					label: t("old.form.buttons.add"),
					icon: "plus",
					onClick: handleTopButtonOpenModalSpeakerQualityAdd,
				}}
			>
				{qualitiesQueries.isFetching ? <Skeleton height={200} /> : renderIconCardChildren()}
			</IconCard>

			<DefaultModal
				open={modaleOpenState}
				maxWidth={"sm"}
				title={
					currentSpeakerQuality ? t("old.programme.advancedSettings.speakers.modale.edition") : t("old.programme.advancedSettings.speakers.modale.creation")
				}
			>
				<SettingsSpeakerQualityEdit
					speakerQuality={currentSpeakerQuality}
					updateSpeakerQuality={handleUpdateSpeakerQuality}
					editModaleClose={handleEditModaleClose}
				/>
			</DefaultModal>
		</>
	);
};
