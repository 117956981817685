import { EnumContextualMenu, EnumIcon } from "@key4-front-library/core";
import type { TypeContextualMenu } from "@key4-front-library/core/Types";
import { t } from "i18next";

export interface BuildContextualMenuArgs {
	editAction: (presentationId?: string) => Promise<void> | void;
	cloneAction: (presentationId?: string) => Promise<void> | void;
	deleteAction: (presentationId?: string) => Promise<void> | void;
	moveUpAction: (presentationId?: string, index?: number) => Promise<void> | void;
	moveDownAction: (presentationId?: string, index?: number) => Promise<void> | void;
	addSpeakerAction: (presentationId?: string) => Promise<void> | void;
}

export const buildContextualMenu = (args: BuildContextualMenuArgs): TypeContextualMenu => {
	const { editAction, cloneAction, moveUpAction, moveDownAction, addSpeakerAction, deleteAction } = args;
	return {
		items: [
			{
				label: t("old.programme.presentations.buttons.addSpeaker"),
				reference: EnumContextualMenu.ADD,
				icon: EnumIcon.PLUS,
				onClick: (id?: string) => addSpeakerAction(id),
			},
			"divider",
			{
				label: t("old.form.buttons.edit"),
				reference: EnumContextualMenu.EDIT,
				icon: EnumIcon.PEN_TO_SQUARE,
				onClick: (id?: string) => editAction(id),
			},
			{
				label: t("old.form.buttons.clone"),
				reference: EnumContextualMenu.CLONE,
				icon: EnumIcon.CLONE,
				onClick: (id?: string) => cloneAction(id),
			},
			"divider",
			{
				label: t("old.programme.presentations.buttons.moveUp"),
				reference: EnumContextualMenu.UP,
				icon: EnumIcon.UP_LONG,
				onClick: (id?: string, index?: number) => moveUpAction(id, index),
			},
			{
				label: t("old.programme.presentations.buttons.moveDown"),
				reference: EnumContextualMenu.DOWN,
				icon: EnumIcon.DOWN_LONG,
				onClick: (id?: string, index?: number) => moveDownAction(id, index),
			},
			"divider",
			{
				label: t("old.form.buttons.delete"),
				reference: EnumContextualMenu.DELETE,
				icon: EnumIcon.TRASH_CAN,
				onClick: (id?: string) => deleteAction(id),
			},
		],
	};
};
