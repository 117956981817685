import { type SessionTemplateRead, type SessionTemplateWrite, getSessionTemplates, postSessionTemplate, putSessionTemplate } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseMutationArgs, type UseQueryArgs, overrideVersion2 } from "@mykey4/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const useSessionTemplates = (
	clientId: string,
	eventId: string,
	args: UseQueryArgs<Paginated<SessionTemplateRead>> = {},
): UseQueryResult<Paginated<SessionTemplateRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery<Paginated<SessionTemplateRead>>({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessionTemplates, ...queryKeyPart, "Get", "List", clientId, eventId, queryStringOverride],
		queryFn: ({ signal }) => getSessionTemplates({ clientId, eventId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export interface SessionTemplateCreateRequest {
	clientId: string;
	eventId: string;
	body: SessionTemplateWrite;
	signal?: AbortSignal;
}

export const useSessionTemplateCreate = (
	args: UseMutationArgs<SessionTemplateCreateRequest, string>,
): UseMutationResult<string, Error, SessionTemplateCreateRequest> => {
	const queryStrings = overrideVersion2([]);
	return useMutation<string, Error, SessionTemplateCreateRequest>({
		mutationFn: (request: SessionTemplateCreateRequest) =>
			postSessionTemplate({
				...request,
				queryStrings,
			}),
		...args,
	});
};

export interface SessionTemplateUpdateRequest {
	clientId: string;
	eventId: string;
	sessionTemplateId: string;
	body: SessionTemplateWrite;
	signal?: AbortSignal;
}

export const useSessionTemplateUpdate = (
	args: UseMutationArgs<SessionTemplateUpdateRequest, boolean>,
): UseMutationResult<boolean, Error, SessionTemplateUpdateRequest> => {
	const queryStrings = overrideVersion2([]);
	return useMutation<boolean, Error, SessionTemplateUpdateRequest>({
		mutationFn: (request: SessionTemplateUpdateRequest) =>
			putSessionTemplate({
				...request,
				queryStrings,
			}),
		...args,
	});
};
