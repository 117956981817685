import { type ElementTab, Tab, useTabsRoute } from "@mykey4/core";
import { uniqueId } from "lodash";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionDetailsContext } from "../Providers";
import { getSessionsDetailsTabs } from "../SessionDetails.configuration";

export interface UseSessionDetailsTabsReturn {
	activeTab: number;
	tabs: ElementTab[];
}

export const useSessionDetailsTabs = (): UseSessionDetailsTabsReturn => {
	const { sessionCounters } = useSessionDetailsContext();
	const { chairs, clashes, presentations, anomalies, invalidate: invalidateCounter } = sessionCounters;

	const navigate = useNavigate();

	const handleNavigation = useCallback(
		async (to: string | undefined) => {
			invalidateCounter();
			navigate(to ?? "", { replace: true, preventScrollReset: true });
		},
		[invalidateCounter],
	);

	const tabs = useMemo(() => {
		const config = getSessionsDetailsTabs({
			presentationCount: presentations,
			chairCount: chairs,
			clashCount: clashes,
			anomalyCount: anomalies,
		});
		return {
			props: config,
			paths: config.map((tab) => tab.to) ?? [],
			elements: config.map((tab) => {
				const { to, ...others } = tab;
				return <Tab key={uniqueId("tab-")} {...others} onClick={() => handleNavigation(to)} />;
			}),
		};
	}, [chairs, anomalies, clashes, presentations, handleNavigation]);

	const { active } = useTabsRoute(tabs.paths);

	return useMemo(() => {
		return {
			activeTab: active,
			tabs: tabs.elements,
		};
	}, [active, tabs.elements]);
};
