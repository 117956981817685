import {
	type PresentationRead,
	usePresentationClone,
	usePresentationDelete,
	usePresentationOrderUpdate,
	usePresentations,
	usePresentationsSpeakers,
} from "@api";
import { ConfirmationDialog, PresentationModal, SpeakerModal } from "@components";
import { AppBox, NoData, useSnackBarHook } from "@key4-front-library/core";
import { IconOld } from "@key4-front-library/core/Bo/Components";
import AccordionGroup from "@key4-front-library/core/Bo/Components/AccordionGroup";
import { ButtonTitle } from "@key4-front-library/core/Bo/Components/Button/ButtonTitle";
import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FilteringOperator, useDialogs } from "@mykey4/core";
import { t } from "i18next";
import update from "immutability-helper";
import { useCallback, useMemo } from "react";
import { useSessionDetailsContext } from "../../Providers";
import PresentationItemDetails from "./PresentationItemDetails";
import PresentationItemSummary from "./PresentationItemSummary";
import { buildContextualMenu } from "./SessionPresentationsTab.configuration";
import { groupSpeakersByPresentation } from "./SessionPresentationsTab.helper";

export const SessionPresentationsTab = () => {
	const {
		clientId,
		eventId,
		sessionId,
		sessionCounters: { invalidate: invalidateCounter },
	} = useSessionDetailsContext();
	const theme = useTheme();
	const { sendSuccess, sendError } = useSnackBarHook();
	const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
	const dialog = useDialogs();

	const presentationsQuery = usePresentations(clientId, eventId, sessionId, {
		select: (data) => {
			return {
				ids: data?.data.map((presentation) => presentation.id),
				presentations: data.data,
			};
		},
	});

	const hasPresentations = presentationsQuery.data && presentationsQuery.data.ids.length > 0;

	const speakersQuery = usePresentationsSpeakers(clientId, eventId, {
		enabled: hasPresentations,
		body: {
			filters: hasPresentations ? `presentationId${FilteringOperator.Equal}${presentationsQuery.data.ids.join(FilteringOperator.OrIn)}` : "",
		},
		select: groupSpeakersByPresentation,
	});

	const clonePresentation = usePresentationClone({
		onSuccess: () => {
			sendSuccess(t("old.programme.presentations.create_edit_modale.snackbar.cloned"));
			void refresh();
		},
		onError: (_) => sendError(t("old.common.errors.generic")),
	});

	const deletePresentation = usePresentationDelete({
		onSuccess: () => {
			sendSuccess(t("old.programme.presentations.create_edit_modale.snackbar.deleted"));
			void refresh();
		},
		onError: (_) => sendError(t("old.common.errors.generic")),
	});

	const updatePresentationOrder = usePresentationOrderUpdate({
		onSuccess: () => refresh(),
	});

	const refresh = useCallback(async () => {
		void invalidateCounter();
		void presentationsQuery.refetch();
	}, [presentationsQuery.refetch, invalidateCounter]);

	const handlePresentationMove = useCallback(
		async (dragIndex: number, hoverIndex: number) => {
			const presentations = presentationsQuery.data?.presentations ?? [];
			const list = update(presentations, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, presentations[dragIndex]],
				],
			});
			updatePresentationOrder.mutate({
				clientId,
				eventId,
				sessionId,
				body: {
					ids: list.map((presentation) => presentation.id),
				},
			});
		},
		[clientId, eventId, sessionId, presentationsQuery.data, updatePresentationOrder.mutate],
	);

	const handlePresentationAddSpeaker = useCallback(
		async (presentationId?: string) => {
			if (!presentationId) {
				return;
			}
			await dialog.open(SpeakerModal, {
				sessionId: sessionId,
				presentationId: presentationId,
			});
			await speakersQuery.refetch();
		},
		[sessionId, speakersQuery.refetch, dialog.open],
	);

	const handlePresentationCreate = useCallback(async () => {
		await dialog.open(PresentationModal, { sessionId: sessionId });
		await refresh();
	}, [sessionId, refresh, dialog.open]);

	const handlePresentationEdit = useCallback(
		async (presentationId?: string) => {
			if (!presentationId) {
				return;
			}
			await dialog.open(PresentationModal, {
				sessionId: sessionId,
				presentationId: presentationId,
			});
			await refresh();
		},
		[sessionId, refresh, dialog.open],
	);

	const handlePresentationClone = useCallback(
		(presentationId?: string) => {
			if (!presentationId) {
				return;
			}
			clonePresentation.mutate({
				clientId,
				eventId,
				sessionId,
				presentationId,
			});
		},
		[clientId, eventId, sessionId, clonePresentation.mutate],
	);

	const handlePresentationMoveUp = useCallback(
		async (_?: string, index?: number) => {
			if (index === undefined || index < 0) {
				return;
			}
			await handlePresentationMove(index, index - 1);
		},
		[handlePresentationMove],
	);

	const handlePresentationMoveDown = useCallback(
		async (_?: string, index?: number) => {
			if (index === undefined || index < 0) {
				return;
			}
			await handlePresentationMove(index, index + 1);
		},
		[handlePresentationMove],
	);

	const handlePresentationDelete = useCallback(
		async (presentationId?: string) => {
			if (!presentationId) {
				return;
			}
			const confirmed = await dialog.open(ConfirmationDialog, { content: t("old.form.confirmationModal.delete") });
			if (!confirmed) {
				return;
			}
			deletePresentation.mutate({
				clientId,
				eventId,
				sessionId,
				presentationId,
			});
		},
		[clientId, eventId, sessionId, deletePresentation.mutate, dialog.open],
	);

	const menu = useMemo(
		() =>
			buildContextualMenu({
				addSpeakerAction: handlePresentationAddSpeaker,
				editAction: handlePresentationEdit,
				cloneAction: handlePresentationClone,
				moveUpAction: handlePresentationMoveUp,
				moveDownAction: handlePresentationMoveDown,
				deleteAction: handlePresentationDelete,
			}),
		[
			handlePresentationAddSpeaker,
			handlePresentationEdit,
			handlePresentationClone,
			handlePresentationMoveUp,
			handlePresentationMoveDown,
			handlePresentationDelete,
		],
	);

	const renderSummary = useCallback(
		(presentation: PresentationRead, isExpanded: boolean) => {
			const presentationSpeaker = speakersQuery.data?.[presentation.id] || [];
			return <PresentationItemSummary isExpanded={isExpanded} presentation={presentation} speakers={presentationSpeaker} />;
		},
		[speakersQuery.data],
	);

	const renderDetails = useCallback(
		(presentation: PresentationRead) => {
			const presentationSpeaker = speakersQuery.data?.[presentation.id] || [];
			return (
				<PresentationItemDetails
					presentation={presentation}
					sessionId={sessionId}
					speakers={presentationSpeaker}
					updateSpeakers={() => void speakersQuery.refetch()}
				/>
			);
		},
		[sessionId, speakersQuery.data, speakersQuery.refetch],
	);

	if (!presentationsQuery.isLoading && !presentationsQuery.data) {
		return <NoData />;
	}

	return (
		<AppBox>
			<Stack direction={"column"} spacing={1}>
				<Stack direction={isMobileSize ? "column" : "row"} justifyContent={"space-between"} alignItems={"baseline"} spacing={1} py={1}>
					<Stack direction={"row"} alignItems={"baseline"} spacing={1}>
						<IconOld iconName={"podium"} prefix={"fal"} size={"3x"} color={theme.palette.primary.light} />
						<Typography variant={"h4"} fontWeight={"300"}>
							{t("old.programme.presentations.title")}
						</Typography>
					</Stack>

					<Box mb={2} alignSelf={isMobileSize ? "end" : "auto"}>
						<ButtonTitle
							icon={{ prefix: "fal", iconName: "plus" }}
							color="primary"
							label={t("old.programme.sessionDetails.presentations.titleButton")}
							handleClick={() => handlePresentationCreate()}
						/>
					</Box>
				</Stack>
				{presentationsQuery.data?.presentations ? (
					<>
						<Divider color={theme.palette.grey[100]} />
						<Stack py={1}>
							<AccordionGroup
								isLoading={presentationsQuery.isFetching || speakersQuery.isFetching}
								isDraggable={false}
								items={presentationsQuery.data?.presentations ?? []}
								menu={menu}
								renderSummary={renderSummary}
								renderDetails={renderDetails}
								onChangeOrder={() => {
									console.error("AccordionGroup onChangeOrder is not implemented");
								}}
							/>
						</Stack>
					</>
				) : (
					<Stack pt={4}>
						<Stack
							py={5}
							alignItems={"center"}
							sx={{
								backgroundColor: theme.palette.background.tone,
							}}
						>
							<Typography variant="body1">{t("old.common.no-data")}</Typography>
						</Stack>
					</Stack>
				)}
			</Stack>
		</AppBox>
	);
};
