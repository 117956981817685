import { type ActivityInvitationStatus, BulletText, useActivityStatusConfiguration } from "@mykey4/core";

export interface ActivityInvitationStatusBulletTextProps {
	value: ActivityInvitationStatus;
}

export const ActivityInvitationStatusBulletText = (props: ActivityInvitationStatusBulletTextProps) => {
	const { value } = props;
	const configuration = useActivityStatusConfiguration({ status: value });
	return (
		<BulletText
			text={configuration.name}
			slotProps={{
				icon: { color: configuration.color },
				typography: { variant: "body2" },
			}}
		/>
	);
};
