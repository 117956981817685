import { type BodyPostGet, PAGE_SIZE, type Paginated, type QueryString, overrideQueryParams } from "@mykey4/core";

type QueryType = QueryString[] | BodyPostGet;

const extractPageSize = <TQuery extends QueryType>(query?: TQuery): number => {
	if (query && Array.isArray(query)) {
		const queryCast = query as QueryString[];
		return extractNumericQueryParam(queryCast, "pageSize", PAGE_SIZE);
	}
	if (query && typeof query === "object" && !Array.isArray(query)) {
		const queryCast = query as BodyPostGet;
		return queryCast.pageSize ?? PAGE_SIZE;
	}
	throw new Error("Bad query parameter type");
};

export const buildQuery = <TQuery extends QueryType>(page: number, pageSize: number, query?: TQuery): TQuery => {
	if (Array.isArray(query)) {
		return overrideQueryParams(query, [
			{ key: "page", value: `${page}` },
			{ key: "pageSize", value: `${pageSize}` },
		]) as TQuery;
	}

	if (query && typeof query === "object" && !Array.isArray(query)) {
		return {
			...query,
			page,
			pageSize,
		} as TQuery;
	}

	throw new Error("Bad query parameter type");
};

export const getAllData = async <TData, TQuery extends QueryType>(
	dataSource: (query: TQuery) => Promise<Paginated<TData>>,
	query?: TQuery,
): Promise<TData[]> => {
	let pageSize: number = extractPageSize(query);
	pageSize = !pageSize || pageSize <= 0 ? PAGE_SIZE : pageSize;

	const firstResult = await dataSource(buildQuery(1, pageSize, query));

	if (!firstResult.pagination || !firstResult.hasMore || !firstResult.pagination.totalCount || pageSize <= 0) {
		return firstResult.data;
	}

	const totalPages = Math.ceil(firstResult.pagination.totalCount / pageSize);

	const promises = Array.from({ length: totalPages - 1 }, (_, i) => i + 2).map((page) => dataSource(buildQuery(page, pageSize, query)));

	const results = await Promise.all(promises);

	return [...firstResult.data, ...results.flatMap((result) => result.data)];
};

export const extractNumericQueryParam = (queryStrings: QueryString[] | undefined, paramName: string, defaultValue: number) => {
	// Find the query string with the matching key
	const paramString = queryStrings?.find((queryString) => queryString.key === paramName)?.value;

	// If the parameter is not found, return the default value
	if (!paramString) {
		return defaultValue;
	}

	// Try to parse the parameter as a number
	const parsedValue = Number.parseInt(paramString);

	// Return the parsed value if it's a valid integer, otherwise return the default
	return Number.isInteger(parsedValue) ? parsedValue : defaultValue;
};
