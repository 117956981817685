import { type IMenuActionsConfiguration, MenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import { Badge, MAIN_TAB } from "@key4-front-library/core";
import type { TabProps } from "@mykey4/core";
import { t } from "i18next";

export interface SessionDetailsActionConfigs {
	clone: IMenuActionsConfiguration | undefined;
	delete: IMenuActionsConfiguration | undefined;
}

export const getSessionDetailsActionConfigs = (): SessionDetailsActionConfigs => {
	return {
		clone: MenuActionsConfigurations[MenuActionsReference.CLONE],
		delete: MenuActionsConfigurations[MenuActionsReference.DELETE],
	};
};

export interface GetSessionsDetailsTabsArgs {
	presentationCount: number;
	chairCount: number;
	clashCount: number;
	anomalyCount: number;
}

export const getSessionsDetailsTabs = (args: GetSessionsDetailsTabsArgs): Array<TabProps> => [
	{
		label: t("sessionDetails.tab.general"),
		to: MAIN_TAB,
	},
	{
		label: t("sessionDetails.tab.chairs"),
		to: "chairs",
		iconPosition: "end",
		icon: <Badge showZero badgeContent={args.chairCount} color={"secondary"} />,
	},
	{
		label: t("sessionDetails.tab.presentations"),
		to: "presentations",
		iconPosition: "end",
		icon: <Badge showZero badgeContent={args.presentationCount} color={"secondary"} />,
	},
	{
		label: t("sessionDetails.tab.sessionInvitation"),
		to: "sessionInvitation",
	},
	{
		label: t("sessionDetails.tab.clashes"),
		to: "clashes",
		iconPosition: "end",
		icon: <Badge showZero badgeContent={args.clashCount} color={"error"} />,
	},
	{
		label: t("sessionDetails.tab.anomalies"),
		to: "anomalies",
		iconPosition: "end",
		icon: <Badge showZero badgeContent={args.anomalyCount} color={"warning"} />,
	},
];
