import type { TagRead } from "@api";
import type { ActionItem } from "@key4-front-library/core";
import type { AutocompleteProps, LabelWithCaptionProps, TagProps } from "@mykey4/core";
import { t } from "i18next";
import type { UseFormReturn } from "react-hook-form";
import type { SessionModalForm } from "../SessionModal";
import type { RoomSessionForm, SessionFormTagType } from "./SessionForm.type";

export const tagsReadToItems = (tags: Array<TagRead>): Array<ActionItem<TagProps>> =>
	tags.map((tag) => ({
		value: tag.id,
		name: tag.label,
		component: {
			backgroundColor: tag.backgroundColor,
			borderColor: tag.borderColor,
			fontColor: tag.fontColor,
			children: tag.label,
		},
	}));

export const roomsToAutocompleteItem = (rooms: Array<RoomSessionForm>): Array<ActionItem<LabelWithCaptionProps>> => rooms.map(roomToAutocompleteItem);

export const roomToAutocompleteItem = (room: RoomSessionForm): ActionItem<LabelWithCaptionProps> => {
	return {
		value: room.id,
		name: room.name,
		component: {
			label: room.name,
			caption: room.capacity ? t("room.capacity", { capacity: room.capacity }) : t("room.capacityNotDefined"),
		},
	};
};

export interface SecondariesTagsSelectReturn {
	key: string;
	autocomplete: Omit<AutocompleteProps<ActionItem<TagProps>>, "options">;
	max?: number;
	options: Array<ActionItem<TagProps>>;
}

export const tagsToSecondariesTagsSelect = (
	secondaryTagTypes: SessionFormTagType[],
	form: UseFormReturn<SessionModalForm, undefined>,
): Array<SecondariesTagsSelectReturn> =>
	secondaryTagTypes.map((tagType) => {
		if (!form.getValues(`tagsByTagTypes.${tagType.id}`)) {
			form.setValue(`tagsByTagTypes.${tagType.id}`, []);
		}
		return {
			key: tagType.id,
			max: tagType.max,
			autocomplete: { label: tagType.max ? `${tagType.label} (${tagType.max} max)` : tagType.label },
			options: tagsReadToItems(tagType.tags),
		};
	});
