import { useRoom } from "@api";
import { roomDefaultValues, roomReadToRoomForm, roomSchema } from "@components";
import type { RoomForm } from "@domain/model/room.model";
import { zodResolver } from "@hookform/resolvers/zod";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import { useContextModule } from "@key4-front-library/core";
import { DialogActions, FormControlLabel } from "@mui/material";
import { Checkbox, type DialogComponentProps, FormControl, Stack, TextField, getTextField } from "@mykey4/core";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

type Props = {
	roomId?: string;
	handleSaveRoom: (room: RoomForm, isCreateAnotherRoom: boolean) => void;
	handleModaleClose: () => void;
};

export const RoomFormModal = ({ open, payload, onClose }: DialogComponentProps<Props>) => {
	const { roomId, handleSaveRoom, handleModaleClose } = payload;
	const component = "roomEditionForm";
	const [isCreateAnotherRoom, setIsCreateAnotherRoom] = useState(false);

	const { client, event } = useContextModule();

	const roomQuery = useRoom(client.id, event.id, roomId ?? "", { enabled: !!roomId });

	const formMethods = useForm<RoomForm>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: roomDefaultValues,
		resolver: zodResolver(roomSchema),
	});
	const {
		handleSubmit,
		reset,
		formState: { isSubmitting, isValid, isSubmitSuccessful },
	} = formMethods;

	useEffect(() => {
		if (roomQuery?.data && !roomQuery.isLoading && roomQuery.isFetched) {
			reset(roomReadToRoomForm(roomQuery.data));
		}
	}, [roomQuery?.data, roomQuery?.isLoading, roomQuery?.isFetched, reset]);

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset({ id: "", name: "", capacity: "", order: "" });
		}
	}, [isSubmitSuccessful, reset]);

	const handleCheckboxClick = () => {
		setIsCreateAnotherRoom((prev) => !prev);
	};

	const onSubmit = (_room: RoomForm) => {
		handleSaveRoom(_room, isCreateAnotherRoom);
		if (!isCreateAnotherRoom) {
			onClose();
		}
	};

	if (roomQuery?.isLoading || (roomQuery?.isFetched === false && roomId)) {
		return <></>;
	}

	return (
		<DefaultModal open={open} title={t(`${component}.title.${roomId ? "edit" : "create"}`)} maxWidth={"sm"}>
			<Stack spacing={2} mt={1}>
				<FormProvider {...formMethods}>
					<FormControl gridSize={{ xs: 12 }} name={"name"} render={(args) => <TextField {...args} label={t(`${component}.name`)} isRequired />} />
					<FormControl
						gridSize={{ xs: 12 }}
						name={"capacity"}
						render={(args) => <TextField {...args} {...getTextField({ type: "number" })} label={t(`${component}.capacity`)} />}
					/>
					<FormControl
						gridSize={{ xs: 12 }}
						name={"order"}
						render={(args) => <TextField {...args} {...getTextField({ type: "number" })} label={t(`${component}.order`)} />}
					/>
				</FormProvider>

				<DialogActions>
					<Stack alignItems={"flex-end"} justifyContent={"space-between"} direction={"row"} width={"100%"}>
						<div>
							{!roomId && (
								<FormControlLabel control={<Checkbox checked={isCreateAnotherRoom} onChange={handleCheckboxClick} />} label={t(`${component}.createAnother`)} />
							)}
						</div>
						<ValidationFormButtons
							cancelButtonClick={() => {
								handleModaleClose();
								onClose();
							}}
							saveButtonClick={handleSubmit(onSubmit, () => {
								enqueueSnackbar(t(`${component}.error`), { variant: "error" });
							})}
							disabledSaveButton={isSubmitting || !isValid}
						/>
					</Stack>
				</DialogActions>
			</Stack>
		</DefaultModal>
	);
};
