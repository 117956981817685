import type { ClashRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "clashes", id }];

interface GetClashesArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getClashes = async (args: GetClashesArgs): Promise<Paginated<ClashRead>> => {
	return await getPaginated({
		module,
		entities: rootEntities(),
		...args,
	});
};
