import { AbstractDataGrid } from "@components";
import { getSessionKey } from "@helpers";
import { useContextModule, useSnackBarHook } from "@key4-front-library/core";
import { FilteringOperator, filtersOrInToValueString, useLoaderOverlay } from "@mykey4/core";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { AbstractWizardProps } from "../common";
import { AbstractStepLayout, AbstractsSelectedWizardHeader } from "../components";
import { useAbstractAssociationMutation, useAbstractDissociationMutation } from "../hooks";

export const AbstractsConfirmationStep = (props: AbstractWizardProps) => {
	const component = "abstract.bulkActions.steps.confirmation";
	const { useWizardResult } = props;
	const { client, event } = useContextModule();
	const navigate = useNavigate();
	const { showLoader, isLoading } = useLoaderOverlay();
	const { t } = useTranslation();
	const { sendSuccess, sendError } = useSnackBarHook();

	const [selectedAbstractIds] = useWizardResult("abstractSelection");
	const [action] = useWizardResult("action");
	const [actionSelected] = useWizardResult("chooseAction");

	const associate = useAbstractAssociationMutation({
		clientId: client.id,
		eventId: event.id,
		mutationArgs: {
			onSuccess: ({ count }) => sendSuccess(t(`${component}.associate.success`, { count })),
			onError: () => sendError(t(`${component}.associate.error`)),
		},
	});

	const dissociate = useAbstractDissociationMutation({
		clientId: client.id,
		eventId: event.id,
		mutationArgs: {
			onSuccess: ({ count }) => sendSuccess(t(`${component}.dissociate.success`, { count })),
			onError: () => sendError(t(`${component}.associate.error`)),
		},
	});

	const isPending = associate.isPending || dissociate.isPending || isLoading;

	const handleNextClick = useCallback(async () => {
		const { hideLoader } = showLoader();
		try {
			if (action?.dissociate && action.dissociate.length > 0) {
				await dissociate.mutateAsync(action.dissociate);
			}
			if (action?.sessionSelected && action.associate && action.associate.length > 0) {
				await associate.mutateAsync({ sessionId: action.sessionSelected.id, association: action.associate });
			}
		} finally {
			hideLoader();
			navigate("./..");
		}
	}, [action, associate.mutateAsync, dissociate.mutateAsync, showLoader]);

	return (
		<AbstractStepLayout
			slotProps={{
				buttons: {
					previousButton: { disabled: isPending },
					nextButton: {
						disabled: isPending,
						onBeforeClick: handleNextClick,
					},
				},
			}}
		>
			<AbstractsSelectedWizardHeader
				i18nKey={`${component}.${actionSelected}.title`}
				{...props}
				i18nAdditionalValues={{ session: getSessionKey(action?.sessionSelected) }}
			/>
			<AbstractDataGrid search={filtersOrInToValueString("id", FilteringOperator.Equal, selectedAbstractIds ?? [])} rowSelectionModel={selectedAbstractIds} />
		</AbstractStepLayout>
	);
};
