import { type PresentationBulkPatch, getEventPresentationsAll, usePresentationBulkPatch } from "@api";
import type { AbstractDissociationResults } from "@components";
import { groupBy } from "@helpers";
import { FilteringOperator, type UseMutationArgs, filtersOrInToBodyPostGet } from "@mykey4/core";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";

interface UseAbstractDissociationMutationArgs {
	clientId: string;
	eventId: string;
	mutationArgs?: UseMutationArgs<AbstractDissociationResults, { count: number }>;
}

export const useAbstractDissociationMutation = (args: UseAbstractDissociationMutationArgs) => {
	const { clientId, eventId, mutationArgs } = args;

	const bulkPatch = usePresentationBulkPatch({});

	const getExistingPresentations = useCallback(
		async (request: { presentationId?: string }[]) => {
			const presentationsIds = request.filter((p) => !!p.presentationId).map((p) => p.presentationId);

			if (presentationsIds.length <= 0) {
				return [];
			}

			return await getEventPresentationsAll({
				clientId,
				eventId,
				body: filtersOrInToBodyPostGet("id", FilteringOperator.Equal, presentationsIds),
			});
		},
		[clientId, eventId],
	);

	const dissociationMutationFn = useCallback(
		async (request: AbstractDissociationResults) => {
			const promises: Promise<boolean>[] = [];
			const toHandle = request.filter((p) => p.dissociate);
			const presentations = await getExistingPresentations(toHandle);
			const presentationBySession = groupBy(presentations, "sessionId");
			let totalPatchs = 0;
			for (const key of presentationBySession.keys()) {
				const presentationsOfSession = presentationBySession.get(key);
				if (!presentationsOfSession) {
					continue;
				}
				const patches: PresentationBulkPatch[] = [];
				for (const presentation of presentationsOfSession) {
					if (presentation.abstractId) {
						patches.push({ presentationId: presentation.id, operations: [{ op: "remove", path: "/abstractId", value: null }] });
					}
				}
				if (patches.length > 0) {
					totalPatchs += patches.length;
					promises.push(bulkPatch.mutateAsync({ clientId, eventId, sessionId: key, body: patches }));
				}
			}

			await Promise.all(promises);
			return { count: totalPatchs };
		},
		[clientId, eventId, getExistingPresentations, bulkPatch.mutateAsync],
	);

	return useMutation({
		mutationFn: dissociationMutationFn,
		...mutationArgs,
	});
};
