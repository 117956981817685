export interface IndicatorDefinition {
	key: string;
	icon: string;
}

export type IndicatorDefinitionType = "sessions" | "rooms" | "presentations" | "invitations" | "faculties" | "chairs" | "speakers" | "clashes" | "anomalies";

export type IndicatorsDefinitions = {
	[K in IndicatorDefinitionType]: IndicatorDefinition;
};

export const IndicatorDefinitions: IndicatorsDefinitions = {
	sessions: {
		key: "sessions",
		icon: "clipboard-list",
	},
	rooms: {
		key: "rooms",
		icon: "screen-users",
	},
	presentations: {
		key: "presentations",
		icon: "podium",
	},
	invitations: {
		key: "invitations",
		icon: "envelopes-bulk",
	},
	faculties: {
		key: "faculties",
		icon: "user-group",
	},
	chairs: {
		key: "chairs",
		icon: "user-crown",
	},
	speakers: {
		key: "speakers",
		icon: "chalkboard-user",
	},
	clashes: {
		key: "clashes",
		icon: "calendar-circle-exclamation",
	},
	anomalies: {
		key: "anomalies",
		icon: "triangle-exclamation",
	},
};
