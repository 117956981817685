import type { AbstractStatus } from "@api";
import { useAbstractStatusConfiguration } from "@configurations";
import { BulletText } from "@mykey4/core";
import { memo } from "react";

export interface AbstractStatusBulletTextProps {
	value: AbstractStatus;
}

export const AbstractStatusBulletText = memo((props: AbstractStatusBulletTextProps) => {
	const { value } = props;
	const configuration = useAbstractStatusConfiguration({ status: value });
	return (
		<BulletText
			text={configuration.name}
			slotProps={{
				icon: { color: configuration.color },
				typography: { variant: "body2" },
			}}
		/>
	);
});
