import type React from "react";
import type { ReactNode } from "react";

import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { IUiButton } from "@infrastructure/model/interfaces/components/UiButton.interface";
import { AppBox, ButtonTitle } from "@key4-front-library/core";
import { CardContent, CardHeader, Stack, Typography, useTheme } from "@mui/material";
import type { IconName } from "@mykey4/core";
import type { IconPrefix } from "@mykey4/core";

type Props = {
	title: string;
	icon?: IconName;
	iconPrefix?: IconPrefix;
	button?: IUiButton;
	children: ReactNode;
	contentSpacing?: number;
};

const IconCard = (props: Props): React.ReactElement => {
	const { icon, iconPrefix = "fal", title, button, children, contentSpacing = 3 } = props;

	const theme = useTheme();

	return (
		<AppBox>
			<>
				<CardHeader
					{...(icon && {
						avatar: (
							<Stack alignContent={"baseline"}>
								<Icon prefix={iconPrefix} iconName={icon} size={"3x"} color={theme.palette.primary.light} />
							</Stack>
						),
					})}
					title={
						<Stack alignContent={"baseline"}>
							<Typography variant={"h4"} fontWeight={"300"}>
								{title}
							</Typography>
						</Stack>
					}
					action={button && <ButtonTitle label={button.label} color={"primary"} icon={{ prefix: "far", iconName: button.icon }} handleClick={button.onClick} />}
				/>
				<CardContent>
					<Stack spacing={contentSpacing} mt={1}>
						{children}
					</Stack>
				</CardContent>
			</>
		</AppBox>
	);
};

export default IconCard;
