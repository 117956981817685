import type { AbstractAssociation, AbstractAssociationStatus, AbstractItem, EnrichedAbstractAssociation, PresentationItem } from "./type";

export const isWithAbstract = <T extends AbstractAssociation>(item: T): item is T & { abstract: AbstractItem } => !!item.abstract;

export const isWithoutAbstract = <T extends AbstractAssociation>(item: T): item is T & { abstract?: undefined } => !item.abstract;

export const isWithPresentation = <T extends AbstractAssociation>(item: T): item is T & { presentation: PresentationItem } => !!item.presentation;

export const isWithoutPresentation = <T extends AbstractAssociation>(item: T): item is T & { presentation?: undefined } => !item.presentation;

export const isBoth = <T extends AbstractAssociation>(item: T): item is T & { presentation: PresentationItem; abstract: AbstractItem } =>
	isWithPresentation(item) && isWithAbstract(item);

export const isPresentationOnly = <T extends AbstractAssociation>(item: T): item is T & { presentation: PresentationItem; abstract?: undefined } =>
	isWithPresentation(item) && isWithoutAbstract(item);

export const isAbstractOnly = <T extends AbstractAssociation>(item: T): item is T & { presentation?: undefined; abstract: AbstractItem } =>
	isWithoutPresentation(item) && isWithAbstract(item);

export const isEmpty = <T extends AbstractAssociation>(item: T): item is T & { presentation?: undefined; abstract?: undefined } =>
	isWithoutPresentation(item) && isWithoutAbstract(item);

export const determineAbstractAssociationState = (association: EnrichedAbstractAssociation): AbstractAssociationStatus => {
	if (isWithoutPresentation(association)) {
		return isWithAbstract(association) ? "notExistAssociate" : "notExistNotAssociate";
	}

	if (isWithoutAbstract(association)) {
		return "existAndNotAssociate";
	}

	if (
		isWithAbstract(association) &&
		((association.existingAbstractId && association.existingAbstractId !== association.abstract.id) ||
			!association.existingAbstractId ||
			(association.overridePresentationTitle && association.abstract.title !== association.existingPresentationTitle))
	) {
		return "existAndAssociateUpdate";
	}

	return "existAndAssociate";
};
