import { Box, Card, Paper, Stack, Typography } from "@mui/material";
import { WizardStepper, type WizardStepperItem } from "./WizardStepper";

export interface WizardLayoutProps {
	title: string;
	steppers: WizardStepperItem[];
	currentStepIndex: number;
	children?: React.ReactNode;
}

export const WizardLayout = (props: WizardLayoutProps) => {
	const { title, steppers, currentStepIndex, children } = props;

	return (
		<Stack pt={5}>
			<Typography variant="h4">{title}</Typography>
			<Card sx={{ borderRadius: 2, marginTop: 2 }}>
				<Box sx={{ width: "100%" }} py={5}>
					<WizardStepper activeStep={currentStepIndex + 1} stepperItems={steppers} />
				</Box>
			</Card>
			<Paper sx={{ borderRadius: 2, padding: 4, marginTop: 2 }}>
				<Stack>{children}</Stack>
			</Paper>
		</Stack>
	);
};
