import type { TagRead } from "@api";
import { zodResolver } from "@hookform/resolvers/zod";
import ColorPicker from "@infrastructure/components/forms/ColorPicker/ColorPicker";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import { Box } from "@mui/material";
import { Chip, FormControl, Grid, TextField, getTextField } from "@mykey4/core";
import { t } from "i18next";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { type SessionTagForm, sessionTagModalFormDefaultValues, sessionTagSchema } from "./SessionTagModal.configuration";
import { tagReadToSessionTagForm } from "./SessionTagModal.mapper";

type Props = {
	tag: TagRead | null;
	updateTag: (data: SessionTagForm) => void;
	editModaleClose: () => void;
};

export const SessionTagModal = (props: Props) => {
	const { tag, updateTag: handleUpdateTag, editModaleClose: handleEditModaleClose } = props;
	const component = "sessionTagModal";
	const formMethods = useForm<SessionTagForm>({
		mode: "all",
		reValidateMode: "onChange",
		defaultValues: tag ? tagReadToSessionTagForm(tag) : sessionTagModalFormDefaultValues,
		resolver: zodResolver(sessionTagSchema()),
	});

	const {
		handleSubmit: onSubmit,
		formState: { isSubmitting, isValid },
	} = formMethods;

	const label = useWatch({ control: formMethods.control, name: "label" });
	const backgroundColor = useWatch({ control: formMethods.control, name: "backgroundColor" });
	const borderColor = useWatch({ control: formMethods.control, name: "borderColor" });
	const fontColor = useWatch({ control: formMethods.control, name: "fontColor" });

	const handleSubmit = (_values: SessionTagForm) => {
		handleUpdateTag(_values);
	};
	return (
		<FormProvider {...formMethods}>
			<Box component="form">
				<Grid container spacing={2}>
					<FormControl name="label" gridSize={{ xs: 8 }} render={(args) => <TextField {...args} label={t(`${component}.label`)} />} />
					<FormControl
						name="order"
						gridSize={{ xs: 4 }}
						render={(args) => <TextField {...args} {...getTextField({ type: "number" })} label={t(`${component}.order`)} />}
					/>
					<Grid item xs={12} sm={12}>
						<Grid container alignItems={"center"}>
							<Grid item xs={3} sm={3} display={"flex"} justifyContent={"center"} m={0} p={0}>
								<Chip label={label} backgroundColor={backgroundColor} borderColor={borderColor} fontColor={fontColor} />
							</Grid>
							<Grid item xs={9} sm={9}>
								<Grid container>
									<Grid item xs={12} sm={12}>
										<FormControl
											name="backgroundColor"
											render={(args) => (
												<ColorPicker {...args} color={args.value as string} name={"backgroundColor"} label={t(`${component}.backgroundColor`)} />
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<FormControl
											name="fontColor"
											render={(args) => <ColorPicker {...args} color={args.value as string} name={"fontColor"} label={t(`${component}.fontColor`)} />}
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<FormControl
											name="borderColor"
											render={(args) => <ColorPicker {...args} color={args.value as string} name={"borderColor"} label={t(`${component}.borderColor`)} />}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={12}>
						<ValidationFormButtons
							cancelButtonClick={handleEditModaleClose}
							saveButtonClick={onSubmit(handleSubmit)}
							disabledSaveButton={isSubmitting || !isValid}
						/>
					</Grid>
				</Grid>
			</Box>
		</FormProvider>
	);
};
