import type { SpeakerRead } from "@api";

export const groupSpeakersByPresentation = (speakers: SpeakerRead[]): Record<string, SpeakerRead[]> => {
	return speakers.reduce(
		(acc, speaker) => {
			if (!acc[speaker.presentationId]) {
				acc[speaker.presentationId] = [];
			}
			acc[speaker.presentationId].push(speaker);
			return acc;
		},
		{} as Record<string, SpeakerRead[]>,
	);
};
