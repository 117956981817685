import { useSessionPublicationStatusConfiguration } from "@configurations";
import { Tag, type TagProps } from "@mykey4/core";
import { SessionPublicationStatusBulletText } from "../SessionPublicationStatusBulletText";

export type SessionPublicationStatusTagProps = Omit<TagProps, "backgroundColor" | "borderColor" | "fontColor" | "children"> & {
	value: boolean;
};

export const SessionPublicationStatusTag = (props: SessionPublicationStatusTagProps) => {
	const { value, ...others } = props;
	const configuration = useSessionPublicationStatusConfiguration({ status: value });
	return (
		<Tag {...others} backgroundColor={configuration.backgroundColor} borderColor={configuration.borderColor} fontColor={configuration.color}>
			<SessionPublicationStatusBulletText value={value} />
		</Tag>
	);
};
