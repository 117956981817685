import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { Button } from "@mui/material";
import type { MouseEventHandler } from "react";

interface Props {
	label: string;
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonSave = (props: Props) => {
	const { label, isDisabled = false, onClick } = props;
	return (
		<Button color={"success"} disabled={isDisabled} onClick={onClick} variant="contained" startIcon={<Icon prefix="fal" iconName={"save"} size={"1x"} />}>
			{label}
		</Button>
	);
};

export default K4ButtonSave;
