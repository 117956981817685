import { patchSessionBulk } from "@api";
import ConfigurationApplication from "@application/Configurations/configuration";
import type { TPatchFilter } from "@infrastructure/helpers/bulkAction.helper";
import BulkActionHelper from "@infrastructure/helpers/bulkAction.helper";
import type { TJSONPatch } from "@infrastructure/model/interfaces/api.interface";
import { EField, type TFilter, type TPickField, type TPickForm, type TUseFormBulkAction, type UseCustomFieldDefinitionsReturn } from "@key4-front-library/core";
import type { GridRowsProp } from "@mui/x-data-grid-pro";
import type { FieldRead, ItemRead } from "@mykey4/core";

export const getSessionCustomFieldPickForms = (customFieldDefinition?: UseCustomFieldDefinitionsReturn): Array<TPickForm> => {
	if (!customFieldDefinition?.forms?.[0]) {
		return [];
	}

	const sections = customFieldDefinition.sections?.filter((section) => section.formId === customFieldDefinition?.forms?.[0].id);

	if (!sections) {
		return [];
	}

	return sections.map((section) => ({
		key: section.key,
		label: section.label,
		sections:
			customFieldDefinition.subSections
				?.filter((subSection) => subSection.sectionId === section.id)
				.map((subSection) => ({
					key: subSection.key,
					label: subSection.label,
					fields:
						customFieldDefinition.fields
							?.filter((field) => field.subSectionId === subSection.id)
							.map((field) => fieldReadToTPickForm(field, customFieldDefinition.items))
							.filter((field) => !!field) ?? [],
				})) ?? [],
	}));
};

const fieldReadToTPickForm = (field: FieldRead, itemsField?: Array<ItemRead>): TPickField | undefined => {
	const commonProps = {
		isStatic: false,
		key: field.id,
		kindCustomField: field.kind,
	};
	switch (field.kind) {
		case "checkBox":
			return {
				...commonProps,
				kind: EField.CHECKBOX,
				checkbox: {
					label: field.label,
					tooltip: field.tooltip,
					legend: field.legend,
					id: field.id,
				},
			};
		case "date":
			return {
				...commonProps,
				kind: EField.DATE_PICKER,
				datePicker: {
					label: field.label,
					tooltip: field.tooltip,
					legend: field.legend,
				},
			};
		case "hour":
			return {
				...commonProps,
				kind: EField.TIME_PICKER,
				timePicker: {
					label: field.label,
					legend: field.legend,
				},
			};
		case "numeric":
			return {
				...commonProps,
				kind: EField.NUMERIC,
				numeric: {
					type: "number",
					label: field.label,
					tooltip: field.tooltip,
					placeholder: field.placeHolder,
					legend: field.legend,
					id: field.id,
				},
			};
		case "integer":
			return {
				...commonProps,
				kind: EField.INTEGER,
				integer: {
					type: "number",
					label: field.label,
					tooltip: field.tooltip,
					placeholder: field.placeHolder,
					legend: field.legend,
					id: field.id,
				},
			};
		case "select":
		case "radio": {
			const items =
				itemsField
					?.filter((item) => field.id === item.fieldListId)
					.map((item) => ({
						key: item.id,
						label: item.label,
					})) ?? [];
			return {
				...commonProps,
				kind: EField.AUTO_COMPLETE,
				autoComplete: {
					label: field.label,
					tooltip: field.tooltip,
					legend: field.legend,
					items,
				},
			};
		}
		case "textArea":
			return {
				...commonProps,
				kind: EField.TEXT_AREA,
				textArea: {
					label: field.label,
					tooltip: field.tooltip,
					placeholder: field.placeHolder,
					legend: field.legend,
					id: field.id,
					minRows: field.rows,
					maxRows: field.rows,
				},
			};
		case "email":
		case "text":
			return {
				...commonProps,
				kind: EField.TEXT,
				text: {
					label: field.label,
					tooltip: field.tooltip,
					placeholder: field.placeHolder,
					legend: field.legend,
					id: field.id,
					type: "text",
				},
			};
	}
	return undefined;
};

/**
 * @deprecated
 */
export const patchSessionEditBulkAction = async (
	clientId: string,
	eventId: string,
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
	useFormValues: TUseFormBulkAction,
): Promise<boolean | string> => {
	return BulkActionHelper.fetchActionMiddleware(
		filters,
		isNoStepSelect,
		rows,
		selectedItems,
		async (patchFilters: TPatchFilter): Promise<boolean | string> => {
			const tagPatch = BulkActionHelper.getTagsPatch(useFormValues);

			let patch1: Array<TJSONPatch> = [];
			let patch2: Array<TJSONPatch> = [];

			if (tagPatch.operationsAdd[0].value.length > 0 && tagPatch.operationsRemove[0].value.length > 0) {
				patch1 = tagPatch.operationsRemove;
				patch2 = tagPatch.operationsAdd;
			} else if (tagPatch.operationsAdd[0].value.length > 0) {
				patch1 = tagPatch.operationsAdd;
			} else if (tagPatch.operationsRemove[0].value.length > 0) {
				patch1 = tagPatch.operationsRemove;
			}

			const operations: Array<TJSONPatch> = BulkActionHelper.mapBulkActionFormForPatch(useFormValues);

			await patchSessionBulk({
				clientId,
				eventId,
				body: {
					filters: patchFilters.filterString,
					excludedIds: patchFilters.excludedIds,
					operations: operations.concat(patch1),
				},
			});

			if (patch2.length > 0) {
				await patchSessionBulk({
					clientId,
					eventId,
					body: {
						filters: patchFilters.filterString,
						excludedIds: patchFilters.excludedIds,
						operations: patch2,
					},
				});
			}

			return Promise.resolve(true);
		},
		ConfigurationApplication.Session.sieveKeys,
	);
};
