import type { ChairRead, ChairWrite } from "@api";
import type { ChairModalForm } from "@components";
import {
	type CustomFieldForm,
	type CustomFieldsValuesForm,
	type UserInformationProps,
	customFieldValuesReadToCustomFieldsValuesForm,
	customFieldsValuesFormToCustomFieldValuesRead,
} from "@key4-front-library/core";
import { emptyToUndefined } from "@mykey4/core";

export const chairFormToChairWrite = (form: CustomFieldForm<ChairModalForm>): ChairWrite => {
	return {
		qualityId: emptyToUndefined(form.qualityId),
		order: emptyToUndefined(form.order ?? undefined),
		invitationStatus: form.invitationStatus,
		customFieldValues: customFieldsValuesFormToCustomFieldValuesRead(form.customFields),
	};
};

export const chairReadToChairForm = (customFields: CustomFieldsValuesForm, chair?: ChairRead): CustomFieldForm<ChairModalForm> => ({
	participantOrContact: null,
	qualityId: chair?.quality?.id ?? "",
	order: chair?.order?.toString() ?? "",
	invitationStatus: chair?.invitationStatus ?? "unsent",
	replacementEmail: chair?.replacement?.email ?? "",
	replacementFirstname: chair?.replacement?.firstname ?? "",
	replacementLastname: chair?.replacement?.lastname ?? "",
	replacementComment: chair?.replacement?.comment ?? "",
	customFields: customFieldValuesReadToCustomFieldsValuesForm(customFields, chair?.customFieldValues),
});

export const chairReadToUserInformationProps = (chair: ChairRead): UserInformationProps => ({
	email: chair.email,
	firstname: chair.firstname,
	lastname: chair.lastname,
});
