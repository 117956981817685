import SettingsClashesSkeleton from "@application/components/settings/scheduler/clashes/SettingsClashesSkeleton";
import SettingsClashesView from "@application/components/settings/scheduler/clashes/SettingsClashesView";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import type { ISettingsClashesObject } from "@domain/interfaces/settings.clashes.interface";
import { Services, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import { Cultures, DefaultCulture, useUser } from "@mykey4/core";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

export const Clashes = () => {
	const { client, event } = useContextModule();
	const { sendError } = useSnackBarHook();
	const { user } = useUser();

	const [culture, setCulture] = useState<string>(DefaultCulture.locale);
	const [settingsClashesData, setSettingsClashesData] = useState<ISettingsClashesObject>();
	const [isLoadingSettingsClashes, setIsLoadingSettingsClashes] = useState<boolean>(true);

	const fetchSettingsClashes = useCallback(
		async (clientId: string, eventId: string) => {
			await Services.Events.Programme.ClashesService.getSettings(clientId, eventId)
				.then((response) => remapAndSetSettingsClashes(response))
				.catch(() => sendError(t("old.common.errors.generic")));
		},
		[sendError],
	);

	const remapAndSetSettingsClashes = (_settingsClashesModel: any) => {
		const settingsClashesObject = SettingsFactory.TransformSettingsClashesDataForReading({
			settings: _settingsClashesModel,
			culture,
		});
		setSettingsClashesData(settingsClashesObject);
	};

	const handleSaveClashesSettings = useCallback(
		async (_data: ISettingsClashesObject) => {
			setIsLoadingSettingsClashes(true);
			try {
				const settingsClashesModel = SettingsFactory.TransformSettingsClashesDataForWriting({
					settings: _data,
					culture,
				});
				await Services.Events.Programme.ClashesService.putSettings(client.id, event.id, settingsClashesModel)
					.then(() => fetchSettingsClashes(client.id, event.id))
					.catch(() => sendError(t("old.common.errors.generic")));
			} finally {
				setIsLoadingSettingsClashes(false);
			}
		},
		[client.id, culture, event.id, fetchSettingsClashes, sendError],
	);

	useEffect(() => {
		const codeCulture = Cultures[user.locale].locale;
		setCulture(codeCulture);
	}, [user.locale]);

	useEffect(() => {
		setIsLoadingSettingsClashes(true);
		fetchSettingsClashes(client.id, event.id).finally(() => setIsLoadingSettingsClashes(false));
	}, [event.id, client.id]);

	return (
		<>
			{!isLoadingSettingsClashes ? (
				settingsClashesData && <SettingsClashesView culture={culture} settings={settingsClashesData} saveSettings={handleSaveClashesSettings} />
			) : (
				<SettingsClashesSkeleton />
			)}
		</>
	);
};
