import React from "react";

import type { RoomRead } from "@api";
import IssuesDefinitions from "@application/Configurations/issues.configuration";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { IUiMenu } from "@infrastructure/model/interfaces/components/UiMenu.interface";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Card, CardHeader, IconButton, Stack } from "@mui/material";
import type { IconName } from "@mykey4/core";
import { t } from "i18next";
import { RoomCardMenu } from "./RoomCardMenu";

type Props = {
	room: RoomRead;
	hasClashes: boolean;
	menu?: IUiMenu;
	handleCardSingleClick: (data: RoomRead) => void;
	handleCardDoubleClick: (data: RoomRead) => void;
};

const RoomCard = (props: Props) => {
	const { room, hasClashes, menu, handleCardSingleClick, handleCardDoubleClick } = props;

	const component = "roomCard";

	const [anchorElementMenu, setAnchorElementMenu] = React.useState<null | HTMLElement>(null);
	const menuOpenState = Boolean(anchorElementMenu);

	const styles = {
		onHover: {
			opacity: ".6",
			cursor: "pointer",
		},
	};

	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElementMenu(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorElementMenu(null);
	};

	if (!menu?.items) {
		return <></>;
	}

	return (
		<Card onDoubleClick={() => handleCardDoubleClick(room)}>
			<CardHeader
				avatar={<DragIndicatorIcon />}
				title={
					<Box
						component="span"
						onClick={() => {
							handleCardSingleClick(room);
						}}
						sx={{
							"&:hover": styles.onHover,
						}}
					>
						{room.name}
					</Box>
				}
				subheader={t(`${component}.capacity`, { capacity: room.capacity })}
				action={
					<Stack direction={"row"} alignItems={"center"}>
						{hasClashes && <Icon color={IssuesDefinitions.clashes.themeColor.main} prefix="fas" iconName={IssuesDefinitions.clashes.icon as IconName} />}
						{menu && (
							<>
								<IconButton aria-label="settings" aria-haspopup="true" aria-expanded={menuOpenState ? "true" : undefined} onClick={handleOpenMenu}>
									<MoreVertIcon />
								</IconButton>
								<RoomCardMenu anchorElementMenu={anchorElementMenu} isOpen={menuOpenState} room={room} items={menu.items} handleCloseMenu={handleCloseMenu} />
							</>
						)}
					</Stack>
				}
			/>
		</Card>
	);
};

export default RoomCard;
