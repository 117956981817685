import type { AbstractStatus } from "@api";
import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { getAbstractStatusConfiguration } from "./configuration";

export const useAbstractStatusConfigurations = () => {
	const theme = useTheme();
	return useMemo(() => getAbstractStatusConfiguration(theme), [theme]);
};

export interface UseAbstractStatusConfigurationArgs {
	status: AbstractStatus;
}

export const useAbstractStatusConfiguration = (args: UseAbstractStatusConfigurationArgs) => {
	const { status } = args;
	const configurations = useAbstractStatusConfigurations();
	return useMemo(() => configurations[status], [configurations, status]);
};
