import { zodResolver } from "@hookform/resolvers/zod";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import { Box, Grid } from "@mui/material";
import { integerZod } from "@mykey4/core";
import { FormControl, TextField, getTextField } from "@mykey4/core";
import type { ActivityForm } from "@pages";
import { t } from "i18next";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

export interface SpeakerQualityForm {
	id: string;
	label: string;
	order?: string;
}

type Props = {
	speakerQuality: ActivityForm | null;
	editModaleClose: () => void;
	updateSpeakerQuality: (data: ActivityForm) => void;
};

const SpeakerQualityEdit = (props: Props) => {
	const { speakerQuality, updateSpeakerQuality: handleUpdateSpeakerQuality, editModaleClose: handleEditModaleClose } = props;

	const component = "speakerQualityEdit";

	const Schema = z.object({
		id: z.string().or(z.literal("")),
		label: z.string({ required_error: t("form.validation.required") }).min(1, t("form.validation.required")),
		order: integerZod().or(z.literal("")),
	});

	const form = useForm<SpeakerQualityForm>({
		mode: "all",
		reValidateMode: "onChange",
		defaultValues: {
			id: speakerQuality?.id ?? "",
			label: speakerQuality?.label ?? "",
			order: speakerQuality?.order ?? "",
		},
		resolver: zodResolver(Schema),
	});

	const handleSaveButtonClick = (_values: ActivityForm) => {
		handleUpdateSpeakerQuality(_values);
		handleEditModaleClose();
	};

	return (
		<FormProvider {...form}>
			<Box component="form">
				<Grid container>
					<FormControl name="label" gridSize={{ xs: 12 }} render={(args) => <TextField {...args} label={t(`${component}.label`)} />} />
					<FormControl
						name="order"
						gridSize={{ xs: 12 }}
						render={(args) => <TextField {...args} {...getTextField({ type: "number" })} label={t(`${component}.order`)} />}
					/>
					<Grid item xs={12} sm={12}>
						<ValidationFormButtons
							cancelButtonClick={handleEditModaleClose}
							saveButtonClick={form.handleSubmit(handleSaveButtonClick)}
							disabledSaveButton={form.formState.isSubmitting || !form.formState.isValid}
						/>
					</Grid>
				</Grid>
			</Box>
		</FormProvider>
	);
};

export default SpeakerQualityEdit;
