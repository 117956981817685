import type { CSSProperties } from "react";

import { type IconDefinition, type IconLookup, type IconPrefix, findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconOldProps } from "@infrastructure/model/@types/icons/icon";
import { ApplicationColors, ThemeColors } from "@key4-front-library/core";
import { type SxProps, Tooltip, alpha } from "@mui/material";
import Box from "@mui/material/Box";

type StructureDisplayProps = {
	isInline?: boolean;
	sx?: SxProps;
};
export const Icon = (props: IconOldProps & StructureDisplayProps) => {
	// #region 'Definition'
	const { prefix, iconName, isInline, sx, title, ...properties } = props;
	const { size, color, spin, border, pull, flip, rotation, isCenterIcon } = properties;

	const prefixValue: IconPrefix = prefix ?? "far";

	const iconLookup: IconLookup = {
		prefix: prefixValue,
		iconName,
	};

	const iconIconDefinition: IconDefinition = findIconDefinition(iconLookup);
	// #endregion 'Definition'

	// #region 'Error - icon undefined'
	if (iconIconDefinition === undefined) {
		console.info(`%cIcon not found : { prefix: "${prefix}", iconName: "${iconName}" }`, `color: ${ApplicationColors.orange}`);
		return <></>;
	}
	// #endregion 'Error - icon undefined'

	const iconStyle = {
		"--fa-primary-color": ThemeColors.secondary.main,
		"--fa-secondary-color": alpha(ThemeColors.secondary.main, 0.7),
	};

	// #region 'Rendering'
	return (
		<Tooltip title={title ?? ""}>
			<Box {...(isInline && { component: "span" })} color={color} data-testid="icon" sx={{ ...sx }}>
				{isCenterIcon ? (
					<svg width="24" height="24" viewBox="0 0 24 20" preserveAspectRatio="xMidYMid meet">
						<FontAwesomeIcon
							icon={iconIconDefinition}
							size={size}
							spin={spin}
							rotation={rotation}
							border={border}
							pull={pull}
							flip={flip}
							style={iconStyle as CSSProperties}
						/>
					</svg>
				) : (
					<Box {...(isInline && { component: "span" })} color={color} data-testid="icon" sx={{ ...sx }}>
						<FontAwesomeIcon
							icon={iconIconDefinition}
							size={size}
							spin={spin}
							rotation={rotation}
							border={border}
							pull={pull}
							flip={flip}
							style={iconStyle as CSSProperties}
						/>
					</Box>
				)}
			</Box>
		</Tooltip>
	);
	// #endregion 'Rendering'
};
