import type { PresentationRead } from "@api";
import type { PresentationEditSchedule } from "@components";
import { MAX_DATE_STRING } from "@mykey4/core";
import { DateTime, Duration } from "luxon";

const getDuration = (start?: DateTime, end?: DateTime, duration?: string): Duration<true> | null => {
	let result: Duration<true> | null = null;
	if (start?.isValid && end?.isValid && end > start) {
		const startTime = start.set({ year: 1970, month: 1, day: 1 });
		const endTime = end.set({ year: 1970, month: 1, day: 1 });
		result = endTime.diff(startTime);
	} else if (duration) {
		const durationParsed = Duration.fromISO(duration);
		if (durationParsed.isValid) {
			result = durationParsed;
		}
	}
	return result;
};

export const reschedulePresentations = (presentations: Array<PresentationRead>, newStartDate: DateTime): PresentationEditSchedule[] => {
	let currentStartDate: DateTime = newStartDate;
	const endOfDay = newStartDate.endOf("day");

	return presentations
		.toSorted(
			(a, b) =>
				(a.startDate ? DateTime.fromISO(a.startDate) : DateTime.fromISO(MAX_DATE_STRING)).diff(
					b.startDate ? DateTime.fromISO(b.startDate) : DateTime.fromISO(MAX_DATE_STRING),
				).milliseconds,
		)
		.map((presentation) => {
			const result: PresentationEditSchedule = {
				id: presentation.id,
				title: presentation.title,
				code: presentation.code,
				oldDateTimeStart: presentation.startDate ? DateTime.fromISO(presentation.startDate) : undefined,
				oldDateTimeEnd: presentation.endDate ? DateTime.fromISO(presentation.endDate) : undefined,
				newDateTimeStart: null,
				newDateTimeEnd: null,
			};

			const duration = getDuration(result.oldDateTimeStart, result.oldDateTimeEnd, presentation.duration);

			if (!duration) {
				return result;
			}

			result.newDateTimeStart = currentStartDate;

			// Calculate the potential end time
			const potentialEndTime = result.newDateTimeStart.plus(duration);

			// Check if the end time would cross into the next day
			if (potentialEndTime <= endOfDay) {
				result.newDateTimeEnd = potentialEndTime;
				currentStartDate = result.newDateTimeEnd;
			}

			return result;
		});
};

export const isPeriodOverlap = (startHour1: string, endHour1: string, startHour2: string, endHour2: string) => {
	const toMinutes = (time: string) => {
		const [hours, minutes] = time.split(":").map(Number);
		return hours * 60 + minutes;
	};

	const start1Minutes = toMinutes(startHour1);
	const end1Minutes = toMinutes(endHour1);
	const start2Minutes = toMinutes(startHour2);

	const end2Minutes = toMinutes(endHour2);
	return start1Minutes < end2Minutes && start2Minutes < end1Minutes;
};
