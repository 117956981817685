import type { DashboardIssueItem, DashboardIssues } from "@api";
import IssuesDefinitions from "@application/Configurations/issues.configuration";
import type { SimplePaletteColorOptions } from "@mui/material";
import { t } from "i18next";

type IssueAccessorKey = keyof DashboardIssueItem;

export interface DashboardIssueValues {
	key: string;
	name: string;
	value: number;
}

export interface DashboardIssueType {
	key: string;
	name: string;
	icon: string;
	themeColor: SimplePaletteColorOptions;
	values: DashboardIssueValues[];
	count: number;
}

export const mapToIndicators = (data: DashboardIssues) => {
	const accessors: IssueAccessorKey[] = ["sessions", "presentations", "invitations"];

	const anomaliesValues: Array<DashboardIssueValues> = [];
	const clashesValues: Array<DashboardIssueValues> = [];

	for (const accessor of accessors) {
		if (data.anomalies[accessor] > 0) {
			anomaliesValues.push({
				key: `${IssuesDefinitions.anomalies.key}-${accessor}`,
				name: t(`old.programme.dashboard.alerts.anomalies.issues.${accessor}`),
				value: data.anomalies[accessor],
			});
		}
		if (data.clashes[accessor] > 0) {
			clashesValues.push({
				key: `${IssuesDefinitions.clashes.key}-${accessor}`,
				name: t(`old.programme.dashboard.alerts.clashes.issues.${accessor}`),
				value: data.clashes[accessor],
			});
		}
	}

	return [
		{
			key: IssuesDefinitions.anomalies.key,
			name: t("old.programme.dashboard.alerts.anomalies.title"),
			icon: IssuesDefinitions.anomalies.icon,
			themeColor: IssuesDefinitions.anomalies.themeColor,
			values: anomaliesValues,
			count: anomaliesValues.reduce((sum, issue) => sum + (issue.value || 0), 0),
		},
		{
			key: IssuesDefinitions.clashes.key,
			name: t("old.programme.dashboard.alerts.clashes.title"),
			icon: IssuesDefinitions.clashes.icon,
			themeColor: IssuesDefinitions.clashes.themeColor,
			values: clashesValues,
			count: clashesValues.reduce((sum, issue) => sum + (issue.value || 0), 0),
		},
	] as Array<DashboardIssueType>;
};
