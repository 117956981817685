import type { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { Box, type SxProps, Tooltip } from "@mui/material";

import { Icon } from "./Icon";

export interface IK4StatusBullet {
	color: string;
	size?: string;
	sx?: SxProps;
	title?: string;
}

const K4StatusBullet = (props: IK4StatusBullet) => {
	const { color, size = "1x", title = "" } = props;

	return (
		<Tooltip title={title}>
			<Box sx={{ color, pt: 0 }} className="circle" display="flex" flexDirection="row">
				<Icon prefix="fas" iconName="circle" size={size as SizeProp} />
			</Box>
		</Tooltip>
	);
};

export default K4StatusBullet;
