import { SessionDetailsProvider } from "./Providers";
import { SessionDetails } from "./SessionDetails";

export const SessionDetailsRoot = () => {
	return (
		<SessionDetailsProvider>
			<SessionDetails />
		</SessionDetailsProvider>
	);
};
