import type { SessionRead } from "@api";
import { Grid, GridLabel, PaperTitle, Stack, Typography } from "@mykey4/core";
import { t } from "i18next";

export interface RoomSectionSessionProps {
	session: SessionRead;
}

export const RoomSectionSession = (props: RoomSectionSessionProps): React.ReactNode => {
	const component = "roomSectionSession";
	const { session } = props;

	return (
		<Stack>
			<PaperTitle
				title={{
					icon: { variant: "h5", prefix: "fal", name: "screen-users", color: "primary.light" },
					children: <Typography variant="h5">{t(`${component}.title`)}</Typography>,
				}}
			>
				<Grid container spacing={2}>
					<GridLabel label={t(`${component}.displayer.room`)}>
						<Typography>{session.room?.name ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.capacity`)}>
						<Typography>{session.room?.capacity ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.expectedAudience`)}>
						<Typography>{session.expectedAudience ?? <br />}</Typography>
					</GridLabel>
				</Grid>
			</PaperTitle>
		</Stack>
	);
};
