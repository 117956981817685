import { DateTime } from "luxon";

import type { ICalendarSession } from "@domain/interfaces/calendar.interface";
import type { EventImpl } from "@fullcalendar/core/internal";
import { SessionSchedulerCard } from "./SessionSchedulerCard";

type Props = {
	event: EventImpl;
};

const K4CalendarEvent = ({ event }: Props) => {
	const dateStart = event.start ? event.start : new Date();
	const dateStartDateTime = DateTime.fromJSDate(dateStart).toISOTime();

	const dateEnd = event.end ? event.end : new Date();
	const dateEndtDateTime = DateTime.fromJSDate(dateEnd).toISOTime() ?? undefined;

	const session: ICalendarSession = {
		id: event.id,
		code: event.extendedProps.code,
		title: event.title,
		start: dateStartDateTime ?? undefined,
		end: dateEndtDateTime ?? undefined,
		hourStart: event.extendedProps.hourStart,
		hourEnd: event.extendedProps.hourEnd,
		status: event.extendedProps.status,
		hasClashes: event.extendedProps.hasClashes,
		hasAnomalies: event.extendedProps.hasAnomalies,
		minDuration: event.extendedProps.minDuration,
		tagsId: event.extendedProps.tagsId,
		resourceId: undefined,
		roomId: event.extendedProps.roomId,
		timezone: event.extendedProps.timezone,
		invitationStatusAccepted: event.extendedProps.invitationStatusAccepted,
		isReadOnly: event.extendedProps.isReadOnly,
	};
	return (
		<SessionSchedulerCard
			session={session}
			popover={{
				anchorOrigin: {
					vertical: "top",
					horizontal: "right",
				},
				transformOrigin: {
					vertical: "top",
					horizontal: "left",
				},
			}}
		/>
	);
};

export default K4CalendarEvent;
