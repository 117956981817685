import { useSessionStatusConfiguration } from "@configurations";
import type { SessionStatus } from "@mykey4/core";
import { BulletText } from "@mykey4/core";

export interface SessionStatusBulletTextProps {
	value: SessionStatus;
}

export const SessionStatusBulletText = (props: SessionStatusBulletTextProps) => {
	const { value } = props;
	const configuration = useSessionStatusConfiguration({ status: value });
	return (
		<BulletText
			text={configuration.name}
			slotProps={{
				icon: { color: configuration.color },
				typography: { variant: "body2" },
			}}
		/>
	);
};
