import type { ClashRead, RoomRead, RoomsOrder } from "@api";

export const roomReadsToRoomsOrder = (rooms: Array<RoomRead>): RoomsOrder => ({ ids: rooms.map((room) => room.id) });

export const clashReadsToClashReadsByRoomId = (clashes: Array<ClashRead>): Record<string, ClashRead[]> => {
	return clashes?.reduce(
		(acc, clash) => {
			const roomId = clash.session1.room?.id ?? clash.session2?.room?.id;
			if (!roomId) {
				return acc;
			}

			if (!acc[roomId]) {
				acc[roomId] = [];
			}

			acc[roomId].push(clash);
			return acc;
		},
		{} as Record<string, ClashRead[]>,
	);
};
