import {
	type BulkUpdatePresentationsDatesArgs,
	type PresentationBulkCreate,
	type PresentationBulkPatch,
	type PresentationRead,
	type PresentationWrite,
	bulkUpdatePresentationsDates,
	clonePresentation,
	deletePresentation,
	getEventPresentationsAll,
	getPresentation,
	getPresentations,
	patchPresentationBulk,
	postPresentation,
	postPresentationBulk,
	postPresentationsSearchPaginated,
	putPresentation,
	putPresentationOrder,
} from "@api";
import {
	API_VERSION_QUERY_PARAM,
	type BodyPostGet,
	type Paginated,
	QUERY_KEYS_PROGRAMME,
	type UseMutationArgs,
	type UseQueryArgs,
	overrideQueryParams,
	overrideVersion2,
} from "@mykey4/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import { getAllData } from "../../common";

export const UsePresentationQueryKeys = {
	eventPresentations: (clientId: string, eventId: string, queryKeyPart: string[]) => [
		...QUERY_KEYS_PROGRAMME.presentations,
		clientId,
		eventId,
		...queryKeyPart,
	],
	eventPresentationsAll: (clientId: string, eventId: string, queryKeyPart: string[]) => [
		...UsePresentationQueryKeys.eventPresentations(clientId, eventId, queryKeyPart),
		"All",
	],
	presentations: (clientId: string, eventId: string, sessionId: string, queryKeyPart: string[]) => [
		...QUERY_KEYS_PROGRAMME.presentations,
		clientId,
		eventId,
		sessionId,
		...queryKeyPart,
	],
	presentation: (clientId: string, eventId: string, sessionId: string, presentationId: string, queryKeyPart: string[]) => [
		...UsePresentationQueryKeys.presentations(clientId, eventId, sessionId, queryKeyPart),
		presentationId,
	],
};

export type UseEventPresentationsArgs<TData> = UseQueryArgs<PresentationRead[], TData> & {
	body: BodyPostGet;
};

export const useEventPresentationsAll = <TData = PresentationRead[]>(
	clientId: string,
	eventId: string,
	args: UseEventPresentationsArgs<TData>,
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, body, ...others } = args;
	return useQuery({
		queryKey: [...UsePresentationQueryKeys.eventPresentationsAll(clientId, eventId, queryKeyPart), queryStrings],
		queryFn: ({ signal }) =>
			getEventPresentationsAll({
				clientId,
				eventId,
				queryStrings,
				signal,
				body,
			}),
		...others,
	});
};

export const usePresentations = <TData = Paginated<PresentationRead>>(
	clientId: string,
	eventId: string,
	sessionId: string,
	args: UseQueryArgs<Paginated<PresentationRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...UsePresentationQueryKeys.presentations(clientId, eventId, sessionId, queryKeyPart), overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getPresentations({
				clientId,
				eventId,
				sessionId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};

export const usePresentation = (
	clientId: string,
	eventId: string,
	sessionId: string,
	id: string,
	args: UseQueryArgs<PresentationRead> = {},
): UseQueryResult<PresentationRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery<PresentationRead>({
		queryKey: [...UsePresentationQueryKeys.presentation(clientId, eventId, sessionId, id, queryKeyPart), overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getPresentation({
				clientId,
				eventId,
				sessionId,
				id,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export const useBulkUpdatePresentationsDatesUpdate = (
	args: UseMutationArgs<BulkUpdatePresentationsDatesArgs> = {},
): UseMutationResult<boolean, Error, BulkUpdatePresentationsDatesArgs> => {
	return useMutation<boolean, Error, BulkUpdatePresentationsDatesArgs>({
		mutationFn: (request: BulkUpdatePresentationsDatesArgs) => bulkUpdatePresentationsDates(request),
		...args,
	});
};

export interface PresentationCreateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: PresentationWrite;
	signal?: AbortSignal;
}

export const usePresentationCreate = (
	args: UseMutationArgs<PresentationCreateRequest, string>,
): UseMutationResult<string, Error, PresentationCreateRequest> => {
	return useMutation({
		mutationFn: (request: PresentationCreateRequest) =>
			postPresentation({
				...request,
				queryStrings: overrideVersion2([]),
			}),
		...args,
	});
};

export interface PresentationUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	body: PresentationWrite;
	signal?: AbortSignal;
}

export const usePresentationUpdate = (args: UseMutationArgs<PresentationUpdateRequest>): UseMutationResult<boolean, Error, PresentationUpdateRequest> => {
	return useMutation({
		mutationFn: (request: PresentationUpdateRequest) =>
			putPresentation({
				...request,
				queryStrings: overrideVersion2([]),
			}),
		...args,
	});
};

export interface PresentationCloneRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	signal?: AbortSignal;
}

export const usePresentationClone = (args: UseMutationArgs<PresentationCloneRequest, string>): UseMutationResult<string, Error, PresentationCloneRequest> => {
	return useMutation({
		mutationFn: (request: PresentationCloneRequest) =>
			clonePresentation({
				...request,
				queryStrings: overrideVersion2([]),
			}),
		...args,
	});
};

export interface PresentationDeleteRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	signal?: AbortSignal;
}

export const usePresentationDelete = (args: UseMutationArgs<PresentationDeleteRequest>): UseMutationResult<boolean, Error, PresentationCloneRequest> => {
	return useMutation({
		mutationFn: (request: PresentationCloneRequest) =>
			deletePresentation({
				...request,
				queryStrings: overrideVersion2([]),
			}),
		...args,
	});
};

export interface PresentationOrderUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: {
		ids: string[];
	};
	signal?: AbortSignal;
}

export const usePresentationOrderUpdate = (
	args: UseMutationArgs<PresentationOrderUpdateRequest>,
): UseMutationResult<boolean, Error, PresentationOrderUpdateRequest> => {
	return useMutation({
		mutationFn: (request: PresentationOrderUpdateRequest) =>
			putPresentationOrder({
				...request,
				queryStrings: overrideVersion2([]),
			}),
		...args,
	});
};

export const usePresentationsSearch = <TData = Paginated<PresentationRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Paginated<PresentationRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringsOverride = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.presentations, ...queryKeyPart, "Post", "Search", queryStringsOverride, body],
		queryFn: ({ signal }) =>
			postPresentationsSearchPaginated({
				clientId,
				eventId,
				body,
				queryStrings: queryStringsOverride,
				signal,
			}),
		...others,
	});
};

export const usePresentationsSearchAll = <TData = PresentationRead[]>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<PresentationRead[], TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;

	const queryStringsOverride = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);

	const postQueryFn = async (signal: AbortSignal | undefined): Promise<PresentationRead[]> => {
		return getAllData(
			(b) =>
				postPresentationsSearchPaginated({
					clientId,
					eventId,
					body: b,
					queryStrings: queryStringsOverride,
					signal,
				}),
			body,
		);
	};

	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.presentations, ...queryKeyPart, "Post", "Search", "All", queryStringsOverride, body],
		queryFn: ({ signal }) => postQueryFn(signal),
		...others,
	});
};

export interface PresentationPatchBulkRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: PresentationBulkPatch[];
	signal?: AbortSignal;
}

export const usePresentationBulkPatch = (
	args: UseMutationArgs<PresentationPatchBulkRequest>,
): UseMutationResult<boolean, Error, PresentationPatchBulkRequest> => {
	return useMutation({
		mutationFn: (request: PresentationPatchBulkRequest) =>
			patchPresentationBulk({
				...request,
				queryStrings: overrideQueryParams([], [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
			}),
		...args,
	});
};

export interface PresentationCreateBulkRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: PresentationBulkCreate[];
	signal?: AbortSignal;
}

export const usePresentationBulkCreate = (
	args: UseMutationArgs<PresentationCreateBulkRequest>,
): UseMutationResult<boolean, Error, PresentationCreateBulkRequest> => {
	return useMutation({
		mutationFn: (request: PresentationCreateBulkRequest) =>
			postPresentationBulk({
				...request,
				queryStrings: overrideVersion2([]),
			}),
		...args,
	});
};
