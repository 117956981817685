import type { ISettingsSessionTagType } from "@domain/interfaces/settings.session-tag-type.interface";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import {
	ActionReference,
	ApplicationColors,
	ConfirmationModal,
	DataGridOld,
	EConfirmationModalAction,
	EnumActionsReference,
	EnumApiErrorStatus,
	EnumContextualMenu,
	EnumIcon,
	type IEntity,
	IconOld,
	Services,
	filtersOrInToValueString,
	getColumns,
	useContextModule,
	useSnackBarHook,
} from "@key4-front-library/core";
import ContextualMenu from "@key4-front-library/core/Bo/Components/ContextualMenu";
import DefaultModal from "@key4-front-library/core/Bo/Components/Modal/DefaultModal";
import { Box, Button, Grid, List, ListItemButton, ListItemText, Skeleton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";
import { t } from "i18next";
import { useEffect, useState } from "react";

import { type TagRead, type TagTypeRead, getTagTypes, postSessionsTags } from "@api";
import { type SessionTagForm, SessionTagModal, sessionTagFormToDtoTagWrite } from "@components";
import DataGridCellButtons from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import { FilteringOperator, sortToQueryString } from "@mykey4/core";
import AdvancedSettingsSessionTagTypeEditModal from "@presentation/pages/advancedSettings/tags/SettingsSessionTagTypeEdit";
import { tagsColDef } from "./Tags.configuration";

export interface TagTypeWithTags extends TagTypeRead {
	tags?: Array<TagRead>;
}

export const Tags = () => {
	const { client, event } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();
	const theme = useTheme();
	const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));

	const [dataGridRow, setDataGridRow] = useState<Array<GridValidRowModel>>([]);
	const [selectedIndex, setSelectedIndex] = useState(0);

	// TAG variables
	const [currentTag, setCurrentTag] = useState<TagRead | null>(null);
	const [isEditTagModalOpen, setIsEditTagModalOpen] = useState<boolean>(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState<boolean>(false);

	// TAG TYPES variables
	const [currentTagType, setCurrentTagType] = useState<TagTypeWithTags | null>(null);
	const [isEditTagTypeModalOpen, setIsEditTagTypeModalOpen] = useState<boolean>(false);
	const [isOpenConfirmationModalTagType, setIsOpenConfirmationModalTagType] = useState<boolean>(false);
	const [isLoadingTagType, setIsLoadingTagType] = useState<boolean>(false);
	const [tagTypes, setTagTypes] = useState<Array<TagTypeWithTags>>([]);

	const getTranslatedColumnLabels = (): Array<string> => {
		const headersNames: Array<string> = [];
		for (const header of tagsColDef) {
			headersNames.push(t(`old.programme.advancedSettings.tags.datagrid.headerLabels.${header.field}`));
		}
		return headersNames;
	};

	const fetchTagsAndTagTypes = async () => {
		setIsLoadingTagType(true);
		await getTagTypes({ clientId: client.id, eventId: event.id, queryStrings: sortToQueryString([{ field: "order", sort: "asc" }]) })
			.then(async (_data) => {
				await postSessionsTags({
					clientId: client.id,
					eventId: event.id,
					body: {
						filters: filtersOrInToValueString(
							"tagTypeId",
							FilteringOperator.Equal,
							_data.data.map((d) => d.id),
						),
						sorts: "order",
						page: 1,
						pageSize: 1000,
					},
				}).then((_tags) => {
					const tagsByTagType = Object.groupBy(_tags, (t) => t.tagTypeId);
					const tagTypesWithTags = _data.data.map((tagType) => ({
						...tagType,
						tags: tagsByTagType[tagType.id] ?? [],
					}));
					setTagTypes(tagTypesWithTags);
					if (!currentTagType) {
						setCurrentTagType(tagTypesWithTags[0]);
					}
				});
			})
			.catch((error: string) => {
				sendError(error);
			})
			.finally(() => {
				setIsLoadingTagType(false);
			});
	};

	const getActionButtons = (totalTagsNumber: number, index: number, tag: TagRead) => {
		const emptyButton = {
			disabled: true,
			onClick: () => {},
		};

		return {
			listButtons: [
				{
					...ActionReference[EnumActionsReference.EDIT],
					tooltip: t("old.programme.advancedSettings.tags.buttons.edit"),
					onClick: () => {
						setCurrentTag(tag);
						setIsEditTagModalOpen(true);
					},
				},
				index !== 0
					? {
							...ActionReference[EnumActionsReference.UP],
							tooltip: t("old.programme.advancedSettings.tags.buttons.moveUp"),
							onClick: () => {
								handleTagMove(tag.id, EnumActionsReference.UP);
							},
						}
					: emptyButton,
				index !== totalTagsNumber - 1
					? {
							...ActionReference[EnumActionsReference.DOWN],
							tooltip: t("old.programme.advancedSettings.tags.buttons.moveDown"),
							onClick: () => {
								handleTagMove(tag.id, EnumActionsReference.DOWN);
							},
						}
					: emptyButton,
				{
					...ActionReference[EnumActionsReference.DELETE],
					tooltip: t("old.programme.advancedSettings.tags.buttons.delete"),
					onClick: () => {
						setCurrentTag(tag);
						setIsOpenConfirmationModal(true);
					},
				},
			],
		};
	};

	// Actions on TAGS TYPES
	const handleCreateOrUpdateTagType = async (_data: ISettingsSessionTagType) => {
		if (!currentTagType) {
			try {
				await Services.Events.Programme.SessionsService.postTagType(client.id, event.id, _data).then((_response: any) => {
					fetchTagsAndTagTypes();
					setIsEditTagTypeModalOpen(false);
					sendSuccess(t("old.programme.advancedSettings.tagTypes.returnMessages.success_creation"));
				});
			} catch (error: any) {
				if (error.status === EnumApiErrorStatus.STATUS_422) {
					await sendError(t("old.programme.advancedSettings.tagTypes.returnMessages.tag_type_already_exist"));
				} else {
					await sendError(t("old.common.errors.generic"));
				}
			}
		} else {
			try {
				await Services.Events.Programme.TagTypesService.put(client.id, event.id, currentTagType.id, _data).then((_response: boolean) => {
					fetchTagsAndTagTypes();
					setIsEditTagTypeModalOpen(false);
					sendSuccess(t("old.programme.advancedSettings.tagTypes.returnMessages.success_modification"));
				});
			} catch (error: any) {
				if (error.status === EnumApiErrorStatus.STATUS_422) {
					await sendError(t("old.programme.advancedSettings.tagTypes.returnMessages.tag_type_already_exist"));
				} else {
					await sendError(t("old.common.errors.generic"));
				}
			}
		}
	};

	const handleDeleteTagType = async () => {
		if (!currentTagType) {
			throw new Error("Tag type not found");
		}
		await Services.Events.Programme.TagTypesService.deleteEntity(client.id, event.id, currentTagType?.id)
			.then(() => {
				fetchTagsAndTagTypes();
				setIsOpenConfirmationModalTagType(false);
				setSelectedIndex(0);
				sendSuccess(t("old.programme.advancedSettings.tagTypes.returnMessages.success_deletion"));
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, tagType: TagTypeWithTags) => {
		setCurrentTagType(tagType);
		setSelectedIndex(index);
	};

	const handleTagTypeMove = async (tagId: string, action: string) => {
		const tagTypeListId: Array<string> = [];

		for (const element of tagTypes ?? []) {
			tagTypeListId.push(element.id);
		}

		const tagTypeIdIndex: number = tagTypeListId.map((id) => id).indexOf(tagId);
		const newTagTypeIndex = action === EnumActionsReference.UP ? tagTypeIdIndex - 1 : tagTypeIdIndex + 1;
		const tagsTypeOrderedList: Array<string> = tagTypeListId;

		tagsTypeOrderedList.splice(newTagTypeIndex, 0, tagsTypeOrderedList.splice(tagTypeIdIndex, 1)[0]);

		await Services.Events.Programme.TagTypesService.putReOrderTagTypes(client.id, event.id, {
			ids: tagsTypeOrderedList,
		})
			.then(() => {
				setSelectedIndex(newTagTypeIndex);
				fetchTagsAndTagTypes();
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	// Actions on TAGS
	const handleDeleteTag = async () => {
		if (!currentTagType || !currentTag) {
			return;
		}
		await Services.Events.Programme.TagsService.deleteEntity(client.id, event.id, currentTagType?.id, currentTag?.id)
			.then(() => {
				setIsOpenConfirmationModal(false);
				fetchTagsAndTagTypes();
				sendSuccess(t("old.programme.advancedSettings.tags.returnMessages.success_deletion"));
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	const handleCreateOrUpdateTag = async (_data: SessionTagForm) => {
		if (!currentTagType) {
			throw new Error("Tag type not found");
		}
		if (!_data.id) {
			try {
				await Services.Events.Programme.TagsService.post(client.id, event.id, currentTagType?.id, sessionTagFormToDtoTagWrite(_data)).then(
					(_response: IEntity) => {
						fetchTagsAndTagTypes();
						sendSuccess(t("old.programme.advancedSettings.tags.returnMessages.success_creation"));
						setIsEditTagModalOpen(false);
					},
				);
			} catch (error: any) {
				if (error.status === EnumApiErrorStatus.STATUS_422) {
					sendError(t("old.programme.advancedSettings.tags.returnMessages.tag_already_exist"));
				} else {
					sendError(t("old.common.errors.generic"));
				}
			}
		} else {
			try {
				if (!currentTag) {
					throw new Error("Tag not found");
				}
				await Services.Events.Programme.TagsService.put(client.id, event.id, currentTagType?.id, currentTag.id, sessionTagFormToDtoTagWrite(_data)).then(
					(_response: boolean) => {
						fetchTagsAndTagTypes();
						setIsEditTagModalOpen(false);
						sendSuccess(t("old.programme.advancedSettings.tags.returnMessages.success_modification"));
					},
				);
			} catch (error: any) {
				if (error.status === EnumApiErrorStatus.STATUS_422) {
					sendError(t("old.programme.advancedSettings.tags.returnMessages.tag_already_exist"));
				} else {
					sendError(t("old.common.errors.generic"));
				}
			}
		}
	};

	const handleTagMove = async (tagId: string, action: string) => {
		if (!currentTagType) {
			return;
		}
		const tagListId: Array<string> = [];
		for (const element of tagTypes[selectedIndex]?.tags ?? []) {
			tagListId.push(element.id);
		}

		const tagIdIndex: number = tagListId.map((id) => id).indexOf(tagId);
		const newTagTypeIndex = action === EnumActionsReference.UP ? tagIdIndex - 1 : tagIdIndex + 1;

		const tagsOrderedList: Array<string> = tagListId;

		tagsOrderedList.splice(newTagTypeIndex, 0, tagsOrderedList.splice(tagIdIndex, 1)[0]);

		await Services.Events.Programme.TagsService.putReOrder(client.id, event.id, currentTagType?.id, { ids: tagsOrderedList })
			.then(() => {
				fetchTagsAndTagTypes();
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	useEffect(() => {
		const rows: Array<GridValidRowModel> = [];

		tagTypes[selectedIndex]?.tags?.forEach((tag, index: number) => {
			rows.push({
				id: tag.id,
				label: {
					chip: {
						label: tag.label,
						backgroundColor: tag.backgroundColor,
						fontColor: tag.fontColor,
						borderColor: tag.borderColor,
					},
				},
				actions: DataGridCellButtons(getActionButtons(tagTypes[selectedIndex]?.tags?.length ?? 0, index, tag)),
			});
		});

		setDataGridRow(rows);
	}, [tagTypes, selectedIndex]);

	useEffect(() => {
		fetchTagsAndTagTypes();
	}, []);

	const renderIconCardChildren = () => {
		return isLoadingTagType ? (
			<Skeleton height={500} />
		) : (
			<Grid container direction="row" spacing={3}>
				<Grid item xs={12} md={3} sx={{ ml: mobileSize ? 0 : -3 }}>
					<Stack spacing={2} sx={{ mt: mobileSize ? 0 : -1 }}>
						<Box
							sx={{
								width: "100%",
								maxWidth: 360,
								bgcolor: ApplicationColors.greyLight.light,
								border: `2px solid ${ApplicationColors.greyLight.main}`,
							}}
						>
							<List component="nav">
								{tagTypes.map((tagType, index) => {
									return (
										<ListItemButton
											key={tagType.id}
											selected={selectedIndex === index}
											onClick={(event) => {
												handleListItemClick(event, index, tagType);
											}}
										>
											<ListItemText primary={tagType.label} />
											<ContextualMenu
												index={index}
												minIndex={1}
												maxIndex={tagTypes.length}
												menu={{
													items: [
														{
															label: t("old.programme.advancedSettings.tagTypes.buttons.edit"),
															reference: EnumContextualMenu.EDIT,
															icon: EnumIcon.PEN_TO_SQUARE,
															onClick: () => {
																setIsEditTagTypeModalOpen(true);
															},
														},
														{
															label: t("old.programme.advancedSettings.tagTypes.buttons.moveUp"),
															reference: EnumContextualMenu.UP,
															icon: EnumIcon.UP_LONG,
															onClick: () => {
																currentTagType && handleTagTypeMove(currentTagType.id, EnumActionsReference.UP);
															},
														},

														{
															label: t("old.programme.advancedSettings.tagTypes.buttons.moveDown"),
															reference: EnumContextualMenu.DOWN,
															icon: EnumIcon.DOWN_LONG,
															onClick: () => {
																currentTagType && handleTagTypeMove(currentTagType.id, EnumActionsReference.DOWN);
															},
														},
														{
															label: t("old.programme.advancedSettings.tagTypes.buttons.delete"),
															reference: EnumContextualMenu.DELETE,
															icon: EnumIcon.TRASH_CAN,
															onClick: () => {
																setIsOpenConfirmationModalTagType(true);
															},
														},
													],
												}}
											/>
										</ListItemButton>
									);
								})}
							</List>
						</Box>
						<Button
							sx={{ maxWidth: "fit-content" }}
							variant="contained"
							onClick={() => {
								setCurrentTagType(null);
								setIsEditTagTypeModalOpen(true);
							}}
						>
							<IconOld iconName="plus" mr={2} />
							<Typography ml={1}>{t("old.programme.advancedSettings.tagTypes.buttons.add")}</Typography>
						</Button>
					</Stack>
				</Grid>
				<Grid item xs={12} md={9} sx={{ mt: mobileSize ? 0 : -7 }}>
					<Stack spacing={2}>
						<Stack direction={mobileSize ? "column" : "row"} justifyContent="space-between">
							<Typography variant="h6"> {tagTypes[selectedIndex]?.label}</Typography>
							{tagTypes[selectedIndex]?.max && (
								<Typography variant="h6">
									{selectedIndex === 0 && `${t("old.programme.advancedSettings.tags.mainTag")} / `}
									{tagTypes[selectedIndex].max > 1
										? t("old.programme.advancedSettings.tags.maxSelectionNumberPlural", { entity: tagTypes[selectedIndex].max })
										: t("old.programme.advancedSettings.tags.maxSelectionNumberSingular", { entity: tagTypes[selectedIndex].max })}
								</Typography>
							)}
						</Stack>
						<DataGridOld
							isAutoHeight
							isHideFooter
							sx={{ minHeight: "30rem" }}
							columns={getColumns(getTranslatedColumnLabels(), tagsColDef)}
							rows={dataGridRow}
						/>
						<Button
							sx={{ maxWidth: "fit-content", alignSelf: "end" }}
							variant="contained"
							onClick={() => {
								setCurrentTag(null);
								setIsEditTagModalOpen(true);
							}}
						>
							<IconOld iconName="plus" />
							<Typography ml={1}>{t("old.programme.advancedSettings.tags.buttons.add")}</Typography>
						</Button>
					</Stack>
				</Grid>
			</Grid>
		);
	};

	return (
		<>
			<IconCard title={t("old.programme.advancedSettings.tags.title")} icon={"tag"} contentSpacing={mobileSize ? 0 : 3}>
				{renderIconCardChildren()}
			</IconCard>

			<DefaultModal
				open={isEditTagModalOpen}
				title={currentTag ? t("old.programme.advancedSettings.tags.modale.edit.title") : t("old.programme.advancedSettings.tags.modale.create.title")}
			>
				<SessionTagModal
					tag={currentTag}
					updateTag={handleCreateOrUpdateTag}
					editModaleClose={() => {
						setIsEditTagModalOpen(false);
					}}
				/>
			</DefaultModal>

			<ConfirmationModal
				open={isOpenConfirmationModal}
				text={t("old.programme.advancedSettings.tags.modale.delete.message")}
				action={EConfirmationModalAction.DELETE}
				handleModaleClose={() => {
					setIsOpenConfirmationModal(false);
				}}
				handleAction={handleDeleteTag}
				maxWidth={"sm"}
			/>

			<ConfirmationModal
				open={isOpenConfirmationModalTagType}
				text={t("old.programme.advancedSettings.tagTypes.modale.delete.message")}
				action={EConfirmationModalAction.DELETE}
				handleModaleClose={() => {
					setIsOpenConfirmationModalTagType(false);
				}}
				handleAction={handleDeleteTagType}
				maxWidth={"sm"}
			/>

			<DefaultModal
				open={isEditTagTypeModalOpen}
				title={
					currentTagType ? t("old.programme.advancedSettings.tagTypes.modale.edit.title") : t("old.programme.advancedSettings.tagTypes.modale.create.title")
				}
			>
				<AdvancedSettingsSessionTagTypeEditModal
					tagType={currentTagType}
					editModaleClose={() => {
						setIsEditTagTypeModalOpen(false);
					}}
					isPrimaryTag={selectedIndex === 0 && currentTagType !== null}
					updateTagType={(data: ISettingsSessionTagType) => {
						handleCreateOrUpdateTagType(data);
					}}
				/>
			</DefaultModal>
		</>
	);
};
