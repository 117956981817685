import { type SessionRead, useSession } from "@api";
import { type DtoClient, type DtoEvent, useContextModule } from "@key4-front-library/core";
import { Loader, useNonNullableContext } from "@mykey4/core";
import { useQueryClient } from "@tanstack/react-query";
import { type ReactElement, createContext, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { type UseSessionDetailsCountersReturn, useSessionDetailsCounters } from "../Hooks";

export interface SessionDetailsContextType {
	clientId: string;
	client: DtoClient;
	eventId: string;
	event: DtoEvent;
	sessionId: string;
	session: SessionRead;
	sessionCounters: UseSessionDetailsCountersReturn;
	invalidateSession: () => Promise<void>;
}

export const SessionDetailsContext = createContext<SessionDetailsContextType | null>(null);

export const useSessionDetailsContext = (): SessionDetailsContextType => {
	return useNonNullableContext(SessionDetailsContext);
};

export interface SessionDetailsProviderProps {
	children?: ReactElement;
}

export const SessionDetailsProvider = (props: SessionDetailsProviderProps) => {
	const { children } = props;
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { client, event } = useContextModule();
	const { sessionId = "" } = useParams();

	const sessionHook = useSession(client.id, event.id, sessionId);
	const sessionCounters = useSessionDetailsCounters({
		clientId: client.id,
		eventId: event.id,
		sessionId: sessionHook.data?.id,
	});

	const invalidateSession = useCallback(async () => {
		await queryClient.invalidateQueries({
			predicate: (query) => {
				const queryKey = query.queryKey;
				return Array.isArray(queryKey) && queryKey.length >= 3 && queryKey.includes(client.id) && queryKey.includes(event.id) && queryKey.includes(sessionId);
			},
		});
	}, [client.id, event.id, sessionId, queryClient]);

	const context = useMemo(() => {
		if (!sessionHook.data) {
			return null;
		}
		return {
			clientId: client.id,
			client,
			eventId: event.id,
			event,
			sessionId: sessionId,
			session: sessionHook.data,
			sessionCounters: sessionCounters,
			invalidateSession,
		};
	}, [client, event, sessionId, sessionHook.data, invalidateSession, sessionCounters]);

	if (!sessionHook.data && sessionHook.isFetching) {
		return <Loader />;
	}

	if (sessionHook.isError || !context) {
		navigate("/403");
	}

	return <SessionDetailsContext.Provider value={context}>{children}</SessionDetailsContext.Provider>;
};
