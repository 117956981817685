import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import type { IconName } from "@mykey4/core";

export interface IMenuActionsConfiguration {
	reference: MenuActionsReference;
	icon: IconName;
}

export type MenuActionsConfigurationsType = {
	[key in MenuActionsReference]: IMenuActionsConfiguration;
};

export const MenuActionsConfigurations: MenuActionsConfigurationsType = {
	add: {
		reference: MenuActionsReference.ADD,
		icon: "plus",
	},
	edit: {
		reference: MenuActionsReference.EDIT,
		icon: "pen-to-square",
	},
	delete: {
		reference: MenuActionsReference.DELETE,
		icon: "trash-can",
	},
	clone: {
		reference: MenuActionsReference.CLONE,
		icon: "clone",
	},
	"drag-and-drop": {
		reference: MenuActionsReference.DRAG_AND_DROP,
		icon: "grip-dots-vertical",
	},
	menu: {
		reference: MenuActionsReference.MENU,
		icon: "ellipsis-vertical",
	},
	up: {
		reference: MenuActionsReference.UP,
		icon: "up-long",
	},
	down: {
		reference: MenuActionsReference.DOWN,
		icon: "down-long",
	},
	details: {
		reference: MenuActionsReference.DETAILS,
		icon: "magnifying-glass",
	},
	redirection: {
		reference: MenuActionsReference.REDIRECTION,
		icon: "up-right-from-square",
	},
	filter: {
		reference: MenuActionsReference.FILTER,
		icon: "filter",
	},
};
