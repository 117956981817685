import { fromTimeWithSecondToDateTime } from "@mykey4/core";
import { t } from "i18next";
import { DateTime, Duration } from "luxon";
import { z } from "zod";

const dateTimeNullable = z
	.unknown()
	.optional()
	.nullable()
	.transform((n) => {
		if (DateTime.isDateTime(n) && n.isValid) {
			return n;
		}
		if (typeof n === "string") {
			return fromTimeWithSecondToDateTime(n);
		}
		return null;
	});

const sessionTemplateObject = z.object({
	primaryTagId: z.string(),
	startHour: dateTimeNullable,
	endHour: dateTimeNullable,
	duration: dateTimeNullable,
	presentationCount: z.coerce
		.number()
		.int(t("old.form.fields.error.integer", { fieldName: "presentationCount" }))
		.positive(t("old.form.fields.error.notBelow", { minValue: 0 }))
		.optional()
		.nullable(),
	presentationDuration: dateTimeNullable,
	status: z.enum(["draft", "validated", "cancelled"]),
	isPublished: z.boolean(),
	publicationDate: dateTimeNullable,
	chairInvitationStatus: z.enum(["unsent", "declined", "replaced", "cancelled", "waitingForAnswer", "accepted"]),
	speakerInvitationStatus: z.enum(["unsent", "declined", "replaced", "cancelled", "waitingForAnswer", "accepted"]),
	isPrivate: z.boolean(),
});

type SessionTemplateFormObject = z.infer<typeof sessionTemplateObject>;
type KeyOfPropertyDateTime = Extract<
	keyof SessionTemplateFormObject,
	{ [K in keyof SessionTemplateFormObject]: SessionTemplateFormObject[K] extends DateTime<true> | null ? K : never }[keyof SessionTemplateFormObject]
>;

const hoursValidation = (data: SessionTemplateFormObject, ctx: z.RefinementCtx) => {
	if (!data.startHour) {
		return;
	}
	if (!data.endHour) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t("old.programme.settings.sessionTemplates.modal.errors.endTimeMissing"),
			path: ["endHour"],
		});
	} else if (data.startHour >= data.endHour) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t("old.common.formControl.error.timePicker.endSuperiorToStart"),
			path: ["endHour"],
		});
	}
};

const durationValidation = (key: KeyOfPropertyDateTime, data: SessionTemplateFormObject, ctx: z.RefinementCtx) => {
	const value = data[key];

	if (!value) {
		return;
	}

	const durationObject = Duration.fromObject({
		hours: value.hour,
		minutes: value.minute,
	});

	if (durationObject > Duration.fromISO("P0DT24H00M")) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t("old.programme.presentations.validation.durationSuperior24"),
			path: [key],
		});
	}
};

const durationFitInSessionValidation = (data: SessionTemplateFormObject, ctx: z.RefinementCtx) => {
	const { startHour, endHour, presentationCount, presentationDuration, duration } = data;

	if (!presentationCount || !presentationDuration) {
		return;
	}

	const currentDuration =
		startHour && endHour ? endHour.diff(startHour, "minutes") : duration ? Duration.fromObject({ hours: duration.hour, minutes: duration.minute }) : null;

	if (!currentDuration) {
		return;
	}

	const parsedDuration = Duration.fromObject({
		hours: presentationDuration.hour,
		minutes: presentationDuration.minute,
	});

	const totalDuration = parsedDuration.as("minutes") * presentationCount;

	if (currentDuration.as("minutes") < totalDuration) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t("old.programme.settings.sessionTemplates.modal.errors.totalDurationExceedsSessionSchedule"),
			path: ["presentationDuration"],
		});
	}
};

export const sessionTemplateSchema = sessionTemplateObject.superRefine((data, ctx) => {
	hoursValidation(data, ctx);
	durationValidation("duration", data, ctx);
	durationValidation("presentationDuration", data, ctx);
	durationFitInSessionValidation(data, ctx);
});

export type SessionTemplateFormType = z.infer<typeof sessionTemplateSchema>;
