import { MenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import ClashesFilters from "@application/components/_common/Clashes/ClashesFilters";
import ClashesRender from "@application/components/_common/Clashes/ClashesRender";
import ClashesSkeleton from "@application/components/_common/Clashes/ClashesSkeleton";
import { EnumSessionFacultyClashesEntityType } from "@application/enum/Clashes.enum";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import ClashesFactory from "@application/factory/clashes/ClashesFactory";
import GetTranslationsForClashes from "@application/helpers/translations/clashes/GetTranslationsForClashes";
import { useInitBadgesCountersTabs } from "@application/hooks/tabs/useInitBadgesCountersTabs";
import { useSessionStatusConfigurations } from "@configurations";
import type { ISessionFacultyClashesModel } from "@domain/model/clashes.model";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import { EMenuPath, type IK4Card, NoData, queryFilters, queryStringPagination, useSnackBarHook } from "@key4-front-library/core";
import { useContextModule, useNotificationContext } from "@key4-front-library/core/Contexts";
import { Services } from "@key4-front-library/core/Services";
import { Stack } from "@mui/material";
import { FilteringOperator } from "@mykey4/core";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const entityType = [EnumSessionFacultyClashesEntityType.ALL, EnumSessionFacultyClashesEntityType.SESSION, EnumSessionFacultyClashesEntityType.INVITATION];

const RoomClashes = () => {
	const { sendSuccess, sendError } = useSnackBarHook();
	const { client, event } = useContextModule();
	const { fetchCounterActiveClashes } = useNotificationContext();

	const { roomId } = useParams();
	const translations = GetTranslationsForClashes();
	const navigate = useNavigate();
	const { initBadgesCountersTabs } = useInitBadgesCountersTabs();

	const [clashes, setClashes] = useState<Array<ISessionFacultyClashesModel>>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [tabIndex, setTabIndex] = useState<number>(0);
	const [isOnlyActive, setIsOnlyActive] = useState<boolean>(true);

	const sessionStatusConfigurations = useSessionStatusConfigurations();
	const detailsConfig = MenuActionsConfigurations[MenuActionsReference.DETAILS];

	const menuItems = [
		{
			label: translations.buttons.details,
			icon: detailsConfig.icon,
			handleClick: (data: IK4Card) => {
				handleSessionClick(data);
			},
		},
	];

	const fetchClashes = useCallback(
		(clientId: string, eventId: string, roomId: string, entityType: Array<string>) => {
			setIsLoading(true);
			Services.Events.Programme.RoomsService.getListPaginedClashes(clientId, eventId, roomId, [
				...queryFilters(`ignoredDate${FilteringOperator.Equal}null${FilteringOperator.And}entityType${FilteringOperator.Equal + entityType[tabIndex]}`),
				...queryStringPagination({
					page: 0,
					pageSize: 0,
				}),
			])
				.then((_response: any) => {
					const { data } = _response;
					setClashes(data);
					// we don't have a mechanism of websocket to maintain the counter of active clashes
					// in navbar up to date when we activate/desactivate a clash.
					// So we manually set this counter on each activate/desactivate action.
					fetchCounterActiveClashes();
					// set also the active clashes counter related to the room inside the tabs
					initBadgesCountersTabs();
				})
				.catch(sendError)
				.finally(() => {
					setIsLoading(false);
				});
		},
		[fetchCounterActiveClashes, initBadgesCountersTabs, tabIndex],
	);

	useEffect(() => {
		if (roomId) {
			fetchClashes(client.id, event.id, roomId, entityType);
		}
	}, [event, roomId, tabIndex, fetchClashes]);

	const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	const handleIsOnlyActiveChange = () => {
		setIsOnlyActive((prev) => !prev);
	};

	const handleSessionClick = (session: IK4Card) => {
		navigate(["", client.key, event.key, EMenuPath.SESSIONS, session.id].join("/"));
	};

	const handleClashesUpdate = (clientId: string, eventId: string, clashId: string, comments: string, isIgnored: boolean, snackbarMessage?: string) => {
		Services.Events.Programme.ClashesService.patch(clientId, eventId, clashId, {
			comments,
			isIgnored,
		})
			.then(() => {
				if (roomId) {
					fetchClashes(client.id, event.id, roomId, entityType);
					if (snackbarMessage) sendSuccess(snackbarMessage);
				}
			})
			.catch(sendError);
	};

	return (
		<AppBox>
			<Stack spacing={2}>
				<ClashesFilters tabIndex={tabIndex} isOnlyActive={isOnlyActive} onTabChange={handleTabChange} OnIsOnlyActiveChange={handleIsOnlyActiveChange} />
				{isLoading ? (
					<ClashesSkeleton quantity={4} />
				) : (
					<Stack spacing={2}>
						{clashes && clashes.length > 0 ? (
							clashes.map((clash) => {
								return (
									<ClashesRender
										key={clash.id}
										clashes={ClashesFactory.TransformSessionClashesDataForReading({
											clash,
											client: client.key,
											event,
											translations,
											sessionStatusConfigurations,
										})}
										translations={translations}
										handleClashesUpdate={handleClashesUpdate}
										menuItems={menuItems}
										handleCardClick={handleSessionClick}
									/>
								);
							})
						) : (
							<NoData />
						)}
					</Stack>
				)}
			</Stack>
		</AppBox>
	);
};

export default RoomClashes;
