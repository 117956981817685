import FieldLabel from "@infrastructure/components/interface/titles/FieldLabel";
import type { IK4Tag } from "@infrastructure/model/interfaces/tags.interface";
import { pickTextColorBasedOnBgColor } from "@infrastructure/services/color/color.service";
import { Box, Chip, Grid, Stack, Tooltip } from "@mui/material";

type TagsProps = {
	tags: Array<IK4Tag>;
};

const K4Tags = (props: TagsProps) => {
	// #region 'Definition'
	const { tags } = props;

	const styles = {
		labelEllipsis: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	};
	// #endregion 'Definition'

	// #region 'Rendering'
	return (
		<Grid
			container
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
			}}
		>
			<Stack spacing={2} width={"100%"}>
				{tags.map((element) => {
					return (
						<Box key={element.id} width={"100%"}>
							<FieldLabel label={element.label} />
							{element.tags.map((tag) => {
								return (
									<Tooltip key={tag.id} title={tag.label} placement="top">
										<Chip
											label={tag.label}
											style={{
												backgroundColor: tag.backgroundColor,
											}}
											sx={{
												mr: 0.375,
												mb: 0.625,
												"& .MuiChip-label": {
													color: tag.fontColor !== "" || tag.fontColor ? tag.fontColor : pickTextColorBasedOnBgColor(tag.backgroundColor ?? "").textColor,
													...styles.labelEllipsis,
												},
												border: tag.borderColor && `solid 1px ${tag.borderColor}`,
												maxWidth: "100%",
											}}
											size="small"
										/>
									</Tooltip>
								);
							})}
						</Box>
					);
				})}
			</Stack>
		</Grid>
	);
	// #endregion 'Rendering'
};

export default K4Tags;
