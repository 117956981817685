import IssuesDefinitions from "@application/Configurations/issues.configuration";
import AnomalyDescription from "@application/components/_common/Anomalies/AnomalyDescription";
import AnomaliesFactory from "@application/factory/anomalies/anomaliesFactory";
import HelpersApp from "@application/helpers";
import GetTranslationsForAnomalies from "@application/helpers/translations/anomalies/GetTranslationsForAnomalies";
import { useSessionStatusConfiguration } from "@configurations";
import type { IAnomaliesByType } from "@domain/interfaces/anomalies.interface";
import type { ISessionAnomaliesModel, ISessionAnomaly } from "@domain/model/anomalies.model";
import TitleAndIndicators from "@infrastructure/components/interface/card/cardElements/TitleAndIndicators";
import TitleSubtitleAndTags from "@infrastructure/components/interface/card/cardElements/TitleSubtitleAndTags";
import { ApplicationColors, useContextModule } from "@key4-front-library/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, CardContent, CardHeader, Collapse, IconButton, type IconButtonProps, Stack, styled } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
}));

interface AnomaliesBySessionCardHeaderProps {
	session: ISessionAnomaliesModel;
	handleCardSingleClick: (session: ISessionAnomaliesModel) => void;
	expanded: boolean;
	handleExpandClick: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

const AnomaliesBySessionCardHeader = (props: AnomaliesBySessionCardHeaderProps) => {
	const { session, handleCardSingleClick, expanded, handleExpandClick } = props;
	const sessionStatusConfiguration = useSessionStatusConfiguration({ status: session.status });
	return (
		<CardHeader
			title={
				<TitleAndIndicators
					headerTitle={session.startDate && session.endDate ? HelpersApp.SessionHelper.getSessionEntireDate(session.startDate, session.endDate) : ""}
					hasClashes={session.hasClashes}
					hasAnomalies={session.hasAnomalies}
					status={{
						value: session.status,
						label: sessionStatusConfiguration.translationKey,
						fontColor: sessionStatusConfiguration.color,
						bgColor: sessionStatusConfiguration.backgroundColor,
					}}
					onTitleClick={() => {
						handleCardSingleClick(session);
					}}
				/>
			}
			action={
				<ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
					<ExpandMoreIcon />
				</ExpandMore>
			}
		/>
	);
};

type Props = {
	session: ISessionAnomaliesModel;
	handleCardSingleClick: (session: ISessionAnomaliesModel) => void;
	anomaliesCheckboxes: Array<IAnomaliesByType>;
};
const AnomaliesBySession = (props: Props) => {
	const { session, handleCardSingleClick, anomaliesCheckboxes } = props;
	const navigate = useNavigate();
	const { client, event } = useContextModule();
	const translationsAnomalies = GetTranslationsForAnomalies();
	const [expanded, setExpanded] = useState(false);

	const filteredAnomaliesChecked = session.anomalies?.reduce((acc: Array<ISessionAnomaly>, curr) => {
		anomaliesCheckboxes.forEach((el) => {
			if (el.anomaly === curr.type && el.checked) {
				acc.push(curr);
			}
		});
		return acc;
	}, []);

	const handleExpandClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
		event.stopPropagation();
		setExpanded(!expanded);
	};

	return (
		<Card
			onClick={(event) => {
				event.stopPropagation();
				handleExpandClick(event);
			}}
			sx={{
				minWidth: "250px",
				minHeight: "100px",
				boxShadow: `0 1px 4px ${ApplicationColors.grey.light}`,
				backgroundColor: ApplicationColors.orange.contrastText,
			}}
		>
			<Stack direction={"row"}>
				<Stack sx={{ flexGrow: 1 }}>
					<AnomaliesBySessionCardHeader
						session={session}
						expanded={expanded}
						handleExpandClick={handleExpandClick}
						handleCardSingleClick={handleCardSingleClick}
					/>
					<CardContent
						sx={{
							mt: -1,
						}}
					>
						<Stack spacing={2}>
							<TitleSubtitleAndTags
								contentTitle={session.title ?? ""}
								contentSubtitle={session.code ?? ""}
								contentTitleStyle={{
									color: ApplicationColors.orange.main,
								}}
								onTitleSubtitleClick={() => {
									handleCardSingleClick(session);
								}}
							/>
							<Collapse in={expanded} timeout="auto" unmountOnExit>
								<Stack spacing={1}>
									{client &&
										event &&
										filteredAnomaliesChecked?.map((anomaly, index: number) => {
											const iconColorAnomaly = anomaly.ignoredDate ? ApplicationColors.greyStone.light : IssuesDefinitions.anomalies.themeColor.main;
											return (
												<AnomalyDescription
													key={anomaly.id}
													item={AnomaliesFactory.TransformSessionAnomalyDataForReading({
														anomaly,
														translationsAnomalies,
														clientKey: client.key,
														eventCode: event.key,
														navigate,
													})}
													index={index}
													styles={{
														iconColor: iconColorAnomaly,
														chipColor: iconColorAnomaly,
														chipBackgroundColor: ApplicationColors.orange.contrastText ?? "",
														textColor: iconColorAnomaly,
														boxBackgroundColor: anomaly.ignoredDate ? ApplicationColors.white.dark : "",
													}}
												/>
											);
										})}
								</Stack>
							</Collapse>
						</Stack>
					</CardContent>
				</Stack>
			</Stack>
		</Card>
	);
};

export default AnomaliesBySession;
