import { DndRow, KeyTitleHeader } from "@components";
import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Checkbox, Icon, Stack } from "@mykey4/core";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AbstractsAssociationTranslation } from "../common";
import { AbstractSelectButton } from "./AbstractSelectButton";

interface AbstractRowProps {
	hasAbstracts: boolean;
	abstractKey?: string;
	abstractTitle?: string;
	selected?: boolean;
	onSelectClick?: () => void;
	highlight?: boolean;
	onKeyClick?: () => void;
}

const AbstractRow = memo((props: AbstractRowProps) => {
	const { hasAbstracts, abstractKey, abstractTitle, selected, onSelectClick, highlight, onKeyClick } = props;
	const theme = useTheme();
	if (!hasAbstracts) {
		return undefined;
	}
	return (
		<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} p={1}>
			<Stack direction={"row"} alignItems={"center"} spacing={1} overflow={"hidden"}>
				{highlight && <Icon prefix={"fas"} name={"star"} color={theme.palette.mode === "light" ? "#FFDF00" : "#FFD700"} />}
				<KeyTitleHeader
					keyText={abstractKey}
					title={abstractTitle}
					variant={"body1"}
					slots={{ iconBefore: Icon }}
					slotProps={{ iconBefore: { name: "memo-circle-check", color: theme.palette.primary.main } }}
					onKeyClick={onKeyClick}
				/>
			</Stack>
			<AbstractSelectButton iconName={"arrow-up-arrow-down"} selected={selected} onClick={onSelectClick} />
		</Stack>
	);
});

interface DndAbstractRowProps {
	rowId: { id: string };
	abstract?: {
		key?: string;
		title?: string;
		highlight?: boolean;
	};
	layoutId?: string;
	selected?: boolean;
	onSelectClick?: () => void;
	onKeyClick?: () => void;
}

const DndAbstractRow = (props: DndAbstractRowProps) => {
	const { rowId, abstract, layoutId, selected, onSelectClick = () => {}, onKeyClick } = props;
	const theme = useTheme();

	const itemRow = useMemo(() => {
		return (
			<AbstractRow
				hasAbstracts={!!abstract}
				abstractKey={abstract?.key}
				abstractTitle={abstract?.title}
				selected={selected}
				onSelectClick={onSelectClick}
				highlight={abstract?.highlight}
				onKeyClick={onKeyClick}
			/>
		);
	}, [abstract, selected, onSelectClick, onKeyClick]);

	return (
		<DndRow
			item={rowId}
			layoutId={layoutId}
			draggableDisabled={!abstract}
			sx={{ border: !abstract ? `2px dashed ${theme.palette.border.primary}` : undefined }}
		>
			{itemRow}
		</DndRow>
	);
};

interface AbstractCheckboxContainerProps {
	show: boolean;
	overridePresentationName: boolean;
	onOverridePresentationNameClick?: (value: boolean) => void;
}

const AbstractCheckboxContainer = (props: AbstractCheckboxContainerProps) => {
	const { show, overridePresentationName, onOverridePresentationNameClick = (_) => {} } = props;
	const { t } = useTranslation();

	const handleOverridePresentationNameClick = useCallback(
		(_: React.ChangeEvent<HTMLInputElement>, value: boolean) => onOverridePresentationNameClick(value),
		[onOverridePresentationNameClick],
	);
	if (!show) {
		return undefined;
	}
	return (
		<Checkbox checked={overridePresentationName} onChange={handleOverridePresentationNameClick}>
			{t(`${AbstractsAssociationTranslation}.rename`)}
		</Checkbox>
	);
};

interface AbstractContainerProps {
	rowId: { id: string };
	abstract?: {
		key?: string;
		title?: string;
		highlight?: boolean;
	};
	layoutId?: string;
	selected?: boolean;
	overridePresentationName: boolean;
	onSelectClick?: () => void;
	onOverridePresentationNameClick?: (value: boolean) => void;
	onKeyClick?: () => void;
}

export const AbstractContainer = memo((props: AbstractContainerProps) => {
	const { abstract } = props;
	return (
		<Box>
			<DndAbstractRow {...props} />
			<AbstractCheckboxContainer show={!!abstract} {...props} />
		</Box>
	);
});
