import { ActivityInvitationStatusBulletText, SessionPublicationStatusBulletText, SessionStatusBulletText } from "@components";
import { extendedRowsPerPage } from "@infrastructure/constants/default-pagination.constant";
import { Box, Chip, TablePagination } from "@mui/material";
import {
	DataGridPro,
	type GridColDef,
	type GridColumnHeaderParams,
	type GridRenderCellParams,
	type GridRowsProp,
	type GridSortModel,
	type GridValidRowModel,
	frFR,
	gridPageSelector,
	useGridApiContext,
	useGridSelector,
} from "@mui/x-data-grid-pro";
import type { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { Link } from "@mykey4/core";
import i18next, { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { Icon } from "../icons/Icon";

type GridColumns<T> = GridColDef<GridValidRowModel, T>[];

export type IGridModel = {
	initialState: GridInitialStateCommunity;
	columnsDefinition: GridColumns<GridValidRowModel>;
};

export enum GridDisplayFormatEnum {
	TEXT = 0,
	ICON = 1,
	SESSIONSTATUS = 2,
	INVITATIONSTATUS = 3,
	TAG = 4,
	LINK = 5,
	ISPUBLISHED = 6,
}

const GridInitialState: GridInitialStateCommunity = {
	columns: {
		columnVisibilityModel: {
			id: false,
			room: false,
		},
	},
};

const dataGridFormatDefaultValues = {
	minWidth: 100,
	maxWidth: 800,
};

type displayFormatType = {
	value: GridDisplayFormatEnum;
	iconName?: string;
	color?: string;
	to?: string;
};

type schemeAndColumnTitlesType = {
	headerName: string;
	reference?: string;
	minWidth?: number;
	maxWidth?: number;
	hide?: boolean;
	displayFormat?: displayFormatType;
};

type IDataGrid = {
	data: GridRowsProp;
	schemeAndColumnTitles: { [key: string]: schemeAndColumnTitlesType };
	page: number;
	pageSize: number;
	totalRowCount: number;
	handleChangePage: (page: number) => void;
	handleChangePageSize: (pageSize: number) => void;
	handleChangeSortCriteria: (criteria: GridSortModel) => void;
};

/**
 *
 * @deprecated
 */
const K4ListGrid = (props: IDataGrid) => {
	const { data, schemeAndColumnTitles, page, pageSize, totalRowCount, handleChangePage, handleChangePageSize, handleChangeSortCriteria } = props;

	const [dataGridModel, setDataGridModel] = useState<IGridModel>();

	const customRenderCell = (params: GridRenderCellParams, displayFormat: any) => {
		const { value, id } = params;
		if (displayFormat === undefined) {
			return <Box>{value}</Box>;
		}

		switch (displayFormat.value) {
			case GridDisplayFormatEnum.TEXT:
				return <Box>{value}</Box>;
			case GridDisplayFormatEnum.ICON:
				return <Box>{value && <Icon prefix="fas" iconName={displayFormat.iconName} color={displayFormat.color} />}</Box>;
			case GridDisplayFormatEnum.SESSIONSTATUS:
				return <SessionStatusBulletText value={value} />;
			case GridDisplayFormatEnum.INVITATIONSTATUS:
				return <ActivityInvitationStatusBulletText value={value} />;
			case GridDisplayFormatEnum.ISPUBLISHED:
				return <SessionPublicationStatusBulletText value={value} />;
			case GridDisplayFormatEnum.TAG: {
				const currentTag = params.row.primaryTag[0];
				return (
					<Box>
						{currentTag.label && (
							<Chip
								sx={{
									backgroundColor: currentTag.backgroundColor,
									color: currentTag.fontColor,
									borderColor: currentTag.borderColor,
									borderWidth: "1px",
									borderStyle: "solid",
								}}
								label={currentTag.label}
							/>
						)}
					</Box>
				);
			}
			case GridDisplayFormatEnum.LINK:
				return <Link to={`${displayFormat.to}/${id}`}>{value}</Link>;

			default:
				return <Box>{value}</Box>;
		}
	};

	const customRenderHeader = (params: GridColumnHeaderParams) => {
		const { colDef } = params;
		return <Box className="MuiDataGrid-columnHeaderTitle">{`${colDef.headerName}`}</Box>;
	};

	const defaultGridColDef: any = {
		renderHeader: customRenderHeader,
		editable: false,
		hideable: false,
		flex: 1,
	};

	useEffect(() => {
		const GetGridColDefinition = (modelDefinition: {
			[key: string]: schemeAndColumnTitlesType;
		}): GridColumns<GridValidRowModel> => {
			const result: GridColumns<GridValidRowModel> = [];
			Object.keys(modelDefinition).forEach((key: any) => {
				const item = modelDefinition[key];
				let gridColDef = defaultGridColDef;

				if (key === "id" || key === "hasAnomalies" || key === "hasClashes") {
					gridColDef = {
						...defaultGridColDef,
						hideable: true,
						sortable: false,
					};
				}
				if (key === "primaryTag") {
					gridColDef = {
						...defaultGridColDef,
						hideable: false,
						sortable: true,
					};
				}
				if (key === "room") {
					gridColDef = {
						...defaultGridColDef,
						hideable: true,
						sortable: true,
					};
				}

				result.push({
					...gridColDef,
					field: key.toString(),
					headerName: item.headerName,
					minWidth: item.minWidth ?? dataGridFormatDefaultValues.minWidth,
					maxWidth: item.maxWidth ?? dataGridFormatDefaultValues.maxWidth,
					filterable: false,
					renderCell: (params: GridRenderCellParams) => customRenderCell(params, item.displayFormat),
				});
			});
			return result;
		};

		const GetGrid = (): IGridModel => {
			return {
				initialState: GridInitialState,
				columnsDefinition: GetGridColDefinition(schemeAndColumnTitles),
			};
		};
		if (data && schemeAndColumnTitles) {
			setDataGridModel(GetGrid());
		}
	}, [data, schemeAndColumnTitles, t]);

	const CustomPagination = () => {
		const apiRef = useGridApiContext();
		const page = useGridSelector(apiRef, gridPageSelector);
		return (
			<TablePagination
				component={"div"}
				count={totalRowCount}
				onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
					handleChangePage(newPage);
				}}
				page={page}
				rowsPerPage={pageSize}
				rowsPerPageOptions={extendedRowsPerPage}
				onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					handleChangePageSize(Number.parseInt(event.target.value, 10));
				}}
				showFirstButton
				showLastButton
			/>
		);
	};

	return (
		<Fragment>
			{dataGridModel && data && (
				<DataGridPro
					disableRowSelectionOnClick
					disableColumnMenu
					sx={{
						"& .MuiDataGrid-row:hover": {
							cursor: "default",
						},
						"& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
							outline: "none",
						},
					}}
					initialState={{
						...dataGridModel.initialState,
					}}
					autoHeight={true}
					checkboxSelection={false}
					columns={dataGridModel.columnsDefinition}
					rows={data}
					rowCount={totalRowCount}
					disableVirtualization
					density="compact"
					pagination={true}
					paginationMode="server"
					paginationModel={{
						page,
						pageSize,
					}}
					editMode={"row"}
					sortingMode="server"
					{...(i18next.language === "fr"
						? {
								localeText: frFR.components.MuiDataGrid.defaultProps.localeText,
							}
						: {})}
					onSortModelChange={handleChangeSortCriteria}
					slots={{
						pagination: CustomPagination,
					}}
				/>
			)}
		</Fragment>
	);
};

export default K4ListGrid;
