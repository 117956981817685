import ProgrammeExportConfiguration from "@application/Configurations/ProgrammeExportConfiguration";
import ContainerProgrammeExportModal from "@application/Containers/ContainerProgrammeExportModal";
import ProgrammeExportHelper from "@application/helpers/ProgrammeExportHelper";
import ProgrammeExportHook from "@application/hooks/ProgrammeExportHook";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { ConfirmationModal, getColumns, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import { EConfirmationModalAction } from "@key4-front-library/core/Enums/Modal";
import { Skeleton, Stack } from "@mui/material";
import type { IconName } from "@mykey4/core";
import { DataGrid, getProgrammeExport, useDialogs, useProgrammeExportCreate, useProgrammeExportDelete } from "@mykey4/core";
import { t } from "i18next";
import { useMemo, useState } from "react";

export const ProgrammeExport = () => {
	const component: string = "old.programme.settings.programmeExport";

	const { client, event } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();
	const dialogs = useDialogs();
	const [currentProgrammeExportTemplateId, setCurrentProgrammeExportTemplateId] = useState<string | null>(null);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState<boolean>(false);
	const { readListDataGrid: readList } = ProgrammeExportHook.useEntity();

	const deleteHook = useProgrammeExportDelete({
		onSuccess: async () => {
			sendSuccess(t(`${component}.actions.deleted`));
			await refetch();
		},
		onError: (error) => sendError(error.message),
	});

	const columns = useMemo(
		() =>
			getColumns(
				ProgrammeExportConfiguration.datagridHeaders.map((header) => t(`${component}.datagrid.headerLabels.${header.field}`)),
				ProgrammeExportConfiguration.datagridHeaders,
			),
		[],
	);

	const createHook = useProgrammeExportCreate({
		onSuccess: async () => {
			sendSuccess(t(`${component}.actions.cloned`));
			await refetch();
		},
		onError: (error) => sendError(error.message),
	});

	const handleButtonAddClick = async (): Promise<void> => {
		await openProgrammeExportModal(null);
	};

	const handleButtonEditClick = async (exportProgrammeId: string): Promise<void> => {
		await openProgrammeExportModal(exportProgrammeId);
	};

	const handleButtonDeleteClick = (exportProgrammeId: string): void => {
		setCurrentProgrammeExportTemplateId(exportProgrammeId);
		setIsOpenConfirmationModal(true);
	};

	const handleButtonCloneClick = async (exportProgrammeId: string) => {
		try {
			const document = await getProgrammeExport({
				clientId: client.id,
				operationId: event.id,
				programmeExportId: exportProgrammeId,
			});
			await createHook.mutateAsync({
				clientId: client.id,
				operationId: event.id,
				body: ProgrammeExportHelper.mapDtoDocumentToDtoProgrammeExportForCloning(document),
			});
		} finally {
			setCurrentProgrammeExportTemplateId(null);
			setIsOpenConfirmationModal(false);
		}
	};

	const { data, isFetching, refetch } = readList(client.id, event.id, {
		clone: handleButtonCloneClick,
		edit: handleButtonEditClick,
		delete: handleButtonDeleteClick,
	});

	const openProgrammeExportModal = async (id: string | null): Promise<void> => {
		await dialogs.open(ContainerProgrammeExportModal, {
			clientId: client.id,
			eventId: event.id,
			id,
		});
		await refetch();
	};

	const handleConfirmationModalDelete = (): void => {
		if (!currentProgrammeExportTemplateId) {
			return;
		}
		deleteHook
			.mutateAsync({
				clientId: client.id,
				operationId: event.id,
				programmeExportId: currentProgrammeExportTemplateId,
			})
			.finally(() => {
				setCurrentProgrammeExportTemplateId(null);
				setIsOpenConfirmationModal(false);
			});
	};

	const handleConfirmationModalClose = (): void => {
		setCurrentProgrammeExportTemplateId(null);
		setIsOpenConfirmationModal(false);
	};

	if (isFetching) {
		return <Skeleton sx={{ width: "100%", height: 600 }} animation="wave" variant="rectangular" />;
	}

	return (
		data && (
			<IconCard
				title={t("old.programme.settings.tabs.programmeExport.label")}
				icon={"book" as IconName}
				button={{
					label: t("old.form.buttons.add"),
					icon: "plus" as IconName,
					onClick: handleButtonAddClick,
				}}
			>
				<Stack spacing={3.5} sx={{ mt: -3 }}>
					<DataGrid columns={columns} rows={data} initialState={{ columns: { columnVisibilityModel: { id: false } } }} />
					<ConfirmationModal
						open={isOpenConfirmationModal}
						text={t(`${component}.confirmationModal.prompt`)}
						action={EConfirmationModalAction.DELETE}
						handleAction={handleConfirmationModalDelete}
						handleModaleClose={handleConfirmationModalClose}
						maxWidth={"sm"}
					/>
				</Stack>
			</IconCard>
		)
	);
};
