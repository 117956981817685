import type { IEntity } from "@infrastructure/model/interfaces/api/api-entity.interface";
import { Services, queryStringOld } from "@key4-front-library/core";
import type { DtoSearchFilterPut } from "@key4-front-library/core/Dto";
import type { EnumSearchFilterScope } from "@key4-front-library/core/Enums";

const getList = async (clientId: string, eventId: string, scope: EnumSearchFilterScope): Promise<Array<any>> => {
	return await Services.Events.Programme.SearchFiltersService.getList(clientId, eventId, queryStringOld("scope", scope));
};

const create = async (clientId: string, eventId: string, name: string, value: string, scope: EnumSearchFilterScope): Promise<IEntity> => {
	return await Services.Events.Programme.SearchFiltersService.post(clientId, eventId, {
		scope,
		name,
		value,
	});
};

const update = async (clientId: string, eventId: string, searchFilterId: string, query: DtoSearchFilterPut): Promise<void> => {
	await Services.Events.Programme.SearchFiltersService.put(clientId, eventId, searchFilterId, {
		scope: query.scope,
		name: query.name,
		value: query.value,
	});
};

const remove = async (clientId: string, eventId: string, filterQueryId: string): Promise<void> => {
	await Services.Events.Programme.SearchFiltersService.deleteEntity(clientId, eventId, filterQueryId);
};

const SearchFilterController = {
	getList,
	create,
	update,
	remove,
};

export default SearchFilterController;
