import { type CoAuthorRead, postCoAuthorsSearchPaginated } from "@api";
import { API_VERSION_QUERY_PARAM, type BodyPostGet, type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { getAllData } from "../../common";

export const useCoAuthorsSearch = <TData = Paginated<CoAuthorRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Paginated<CoAuthorRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringsOverride = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.coAuthor, ...queryKeyPart, "GetPost", queryStringsOverride, body],
		queryFn: ({ signal }) =>
			postCoAuthorsSearchPaginated({
				clientId,
				eventId,
				body,
				queryStrings: queryStringsOverride,
				signal,
			}),
		...others,
	});
};

export const useCoAuthorsSearchAll = <TData = Array<CoAuthorRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Array<CoAuthorRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;

	const queryStringsOverride = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);

	const postQueryFn = async (signal: AbortSignal | undefined): Promise<CoAuthorRead[]> => {
		return getAllData(
			(b) =>
				postCoAuthorsSearchPaginated({
					clientId,
					eventId,
					body: b,
					queryStrings: queryStringsOverride,
					signal,
				}),
			body,
		);
	};

	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.coAuthor, ...queryKeyPart, "GetPost", "All", queryStringsOverride, body],
		queryFn: ({ signal }) => postQueryFn(signal),
		...others,
	});
};
