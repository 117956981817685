import { SchedulerCard, SessionCardPopover } from "@components";
import type { ICalendarSession } from "@domain/interfaces/calendar.interface";
import { ApplicationColors } from "@key4-front-library/core";
import { Stack, lighten } from "@mui/material";
import type { PopoverProps } from "@mykey4/core";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import { useRef } from "react";

const SESSION_POPOVER_TIMEOUT = 750;

export interface SessionSchedulerCardProps {
	session: ICalendarSession;
	popover?: Omit<PopoverProps, "open">;
}

export const SessionSchedulerCard = ({ session, popover }: SessionSchedulerCardProps) => {
	const timeout = useRef<NodeJS.Timeout | null>(null);

	const handleOnMouseEnter = (e: React.MouseEvent<HTMLElement>, onMouseOver: (e: React.MouseEvent<HTMLElement>) => void) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}

		const currentTarget = e.currentTarget;
		timeout.current = setTimeout(() => {
			e.currentTarget = currentTarget;
			onMouseOver(e);
		}, SESSION_POPOVER_TIMEOUT);
	};

	const handleMouseLeave = (e: React.MouseEvent, onMouseLeave: (event: React.MouseEvent) => void) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}

		onMouseLeave(e);
	};
	return (
		<PopupState variant="popover">
			{(popupState) => (
				<Stack
					{...bindHover(popupState)}
					onMouseOver={(e) => handleOnMouseEnter(e, bindHover(popupState).onMouseOver)}
					onMouseLeave={(e) => handleMouseLeave(e, bindHover(popupState).onMouseLeave)}
					px=".8em"
					py=".4em"
					id="full-calendar"
					sx={{
						cursor: session.isReadOnly ? "default" : "pointer",
						fontSize: "1.2em;",
						overflow: "hidden",
						height: "100%",
						backgroundImage:
							session.invitationStatusAccepted === false
								? [
										"repeating-linear-gradient(",
										"-45deg,",
										"transparent 0px 5px,",
										[lighten(ApplicationColors.grey.light ?? "", 0.5), " 2px 10px"].join(""),
										")",
									].join("")
								: undefined,
					}}
				>
					<Stack>
						<SchedulerCard session={session} />
						<SessionCardPopover popover={{ ...popover, ...bindPopover(popupState) }} sessionId={session.id} />
					</Stack>
				</Stack>
			)}
		</PopupState>
	);
};
