import type { AbstractWizardProps } from "../common";
import { AbstractsAssociationStep } from "./AbstractsAssociationStep";
import { AbstractsDissociationStep } from "./AbstractsDissociationStep";

export const AbstractsActionStep = (props: AbstractWizardProps) => {
	const { useWizardResult } = props;
	const [action] = useWizardResult("chooseAction");
	const isAssociate = action ? action === "associate" : false;
	const isDissociate = action ? action === "dissociate" : false;
	return (
		<>
			{isAssociate && <AbstractsAssociationStep {...props} />}
			{isDissociate && <AbstractsDissociationStep {...props} />}
		</>
	);
};
