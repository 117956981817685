import { t } from "i18next";

import ConfigurationsApp from "@application/Configurations";
import { ESessionTagField, type ISessionSecondaryTagsBulkAction, type ISessionTagsBulkAction } from "@domain/interfaces/session.interface";
import FormHelper from "@infrastructure/helpers/form.helper";
import { EBulkActionEntity, EField, type TPickField, type TPickForm, type TPickFormSection } from "@key4-front-library/core";
import type { TAutoCompleteBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteBulletColor";
import type {
	PropsFormControlAutocompleteSubLabel,
	TypeAutoCompleteSubLabelItem,
} from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteSubLabel";
import type { PropsFormControlSelectChipColor } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelectChipColor";

import RoomController from "./RoomController";
import SessionController from "./SessionController";

/**
 * @deprecated
 */
const getForms = async (clientId: string, eventId: string, entityType: EBulkActionEntity): Promise<Array<TPickForm>> => {
	let fullForms: Array<TPickForm> = [];

	// // Get static fields from an entity
	if (entityType === EBulkActionEntity.SESSION) {
		let rooms: TypeAutoCompleteSubLabelItem[] = [];
		let tagsType: ISessionTagsBulkAction;

		try {
			rooms = await RoomController.getRoomListBulkAction(clientId, eventId, t);
			tagsType = await SessionController.getTagsTypeBulkAction(clientId, eventId);
		} catch (_) {
			return [];
		}

		// Remap to get Trads for forms, sections and fields
		fullForms = [
			...ConfigurationsApp.SessionConfiguration.pickFormsStatic.map((form: TPickForm) => {
				return {
					key: form.key,
					label: t(`old.common.session.forms.${form.key}`),
					sections: form.sections.map((section: TPickFormSection) => {
						return {
							...section,
							label: t(`old.common.session.formSections.${section.key}`),
							fields: section.fields
								// Remove primaryTagId field if primaryTag is not set (can't be done in a map)
								.filter((field) => !(field.key === "primaryTag" && !tagsType.primaryTag))
								// add Trads with this mapping
								.map((field: TPickField) => {
									const label = t(`old.common.session.formFields.labels.${field.key}`);
									switch (field.kind) {
										case EField.TEXT:
											return {
												...field,
												text: {
													...field.text,
													label,
												},
											};
										case EField.TEXT_AREA:
											return {
												...field,
												textArea: {
													...field.textArea,
													label,
												},
											};
										case EField.CHECKBOX:
											return {
												...field,
												checkbox: {
													...field.checkbox,
													label,
												},
											};
										case EField.DATE_PICKER:
											return {
												...field,
												datePicker: {
													...field.datePicker,
													label,
												},
											};
										case EField.TIME_PICKER:
											return {
												...field,
												timePicker: {
													...field.timePicker,
													label,
												},
											};
										case EField.DATE_TIME_PICKER:
											return {
												...field,
												dateTimePicker: {
													...field.dateTimePicker,
													label,
												},
											};
										case EField.AUTO_COMPLETE:
											return {
												...field,
												autoComplete: {
													...field.autoComplete,
													label,
												},
											};
										case EField.NUMERIC:
											return {
												...field,
												numeric: {
													...field.numeric,
													label,
												},
											};
										case EField.INTEGER:
											return {
												...field,
												integer: {
													...field.integer,
													label,
												},
											};
										case EField.AUTO_COMPLETE_SUB_LABEL: {
											const autoCompleteSubLabel: PropsFormControlAutocompleteSubLabel = {
												...field.autoCompleteSubLabel,
												label,
											};
											// Add room list items
											if (field.key === "roomId") {
												autoCompleteSubLabel.items = rooms;
											}
											return {
												...field,
												autoCompleteSubLabel,
											};
										}
										case EField.AUTO_COMPLETE_BULLET_COLOR:
											return {
												...field,
												autoCompleteBulletColor: {
													...field.autoCompleteBulletColor,
													label,
													items: field.autoCompleteBulletColor.items.map((option: TAutoCompleteBulletColorItem) => {
														return {
															...option,
															label: t(option.label),
														};
													}),
												},
											};
										case EField.SELECT_CHIP_COLOR: {
											let selectChipColor: PropsFormControlSelectChipColor = {
												...field.selectChipColor,
												label,
											};
											let ids: Array<string> = [];
											// Add primary tag
											// TODO D1 : primary tag
											if (field.key === "primaryTag" && tagsType.primaryTag) {
												ids = tagsType.primaryTag.ids;
												selectChipColor = tagsType.primaryTag.selectChipColor;
											}
											return {
												...field,
												ids,
												selectChipColor,
											};
										}
										case EField.AUTOCOMPLETE_MULTI_CHIP_COLOR:
											return {
												...field,
												labelField: field.autocompleteMultiChipColor.label,
												autocompleteMultiChipColor: {
													...field.autocompleteMultiChipColor,
													label,
												},
											};
										default:
											return {
												...field,
												[field.kind]: {
													label,
												},
											};
									}
								}),
						};
					}),
				};
			}),
		];

		// ! On form static change check if tags are not broken or at wrong position
		const secondaryTags: Array<TPickField> = tagsType.secondaryTags.map((tag: ISessionSecondaryTagsBulkAction) => {
			if (tag.kind === ESessionTagField.MULTISELECT) {
				return {
					...FormHelper.baseField.pick.autocompleteMultiChipColor,
					ids: tag.ids,
					key: `mf__tags__${tag.id}`,
					isMultiSelect: true,
					labelField: tag.autocompleteMultiChipColor.label,
					autocompleteMultiChipColor: tag.autocompleteMultiChipColor,
				};
			}
			return {
				...FormHelper.baseField.pick.selectChipColor,
				ids: tag.ids,
				key: `mf__tags__${tag.id}`,
				selectChipColor: tag.selectChipColor,
			};
		});
		fullForms[2].sections[0].fields = fullForms[2].sections[0].fields.concat(secondaryTags);
	}

	return fullForms;
};

const CustomFieldController = {
	getForms,
};

export default CustomFieldController;
