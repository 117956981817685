import type { InvitationSettingsWrite } from "@mykey4/core";
import { z } from "zod";

export const invitationSettingsDefaultValues: InvitationSettingsWrite = {
	shouldDisplayConflictOfInterestQuestion: true,
	shouldDisplayConflictOfInterestDetailsQuestion: true,
};

export const invitationSettingsSchema = z.object({
	shouldDisplayConflictOfInterestQuestion: z.boolean(),
	shouldDisplayConflictOfInterestDetailsQuestion: z.boolean(),
});
