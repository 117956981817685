import {
	type PresentationBulkCreate,
	type PresentationBulkDateUpdate,
	type PresentationBulkPatch,
	type PresentationRead,
	type PresentationWrite,
	getAllData,
} from "@api";
import {
	type BodyPostGet,
	type Paginated,
	type PathEntity,
	type ServiceArgs,
	deleteResponse,
	get,
	getPaginated,
	overrideVersion2,
	patch,
	post,
	postCreate,
	postPaginated,
	put,
} from "@mykey4/core";

const module = "programme";

const rootEntities = (sessionId?: string, id?: string): Array<PathEntity> => [
	{ entity: "sessions", id: sessionId },
	{ entity: "presentations", id },
];

interface EventPresentationServiceArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BodyPostGet;
}

export const getEventPresentations = async (args: EventPresentationServiceArgs): Promise<Paginated<PresentationRead>> => {
	const { body, queryStrings, ...others } = args;
	return await postPaginated(
		{
			module,
			entities: rootEntities(),
			queryStrings: overrideVersion2(queryStrings),
			...others,
		},
		body,
	);
};

export const getEventPresentationsAll = async (args: EventPresentationServiceArgs): Promise<PresentationRead[]> => {
	const { body } = args;
	return await getAllData((b) => getEventPresentations({ ...args, body: b }), body);
};

interface PresentationServiceArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getPresentations = async (args: PresentationServiceArgs): Promise<Paginated<PresentationRead>> => {
	const { sessionId, queryStrings, ...others } = args;
	return await getPaginated<PresentationRead>({
		module,
		entities: rootEntities(sessionId),
		queryStrings: overrideVersion2(queryStrings),
		...others,
	});
};

export const getPresentationsAll = async (args: PresentationServiceArgs): Promise<PresentationRead[]> => {
	const { queryStrings, ...others } = args;
	return await getAllData((query) => getPresentations({ ...others, queryStrings: query }), queryStrings);
};

export interface GetPresentationArgs extends ServiceArgs {
	id: string;
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getPresentation = async (args: GetPresentationArgs): Promise<PresentationRead> => {
	const { sessionId, id, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId, id),
		...others,
	});
};

export interface BulkUpdatePresentationsDatesArgs extends PresentationServiceArgs {
	body: Array<PresentationBulkDateUpdate>;
}

export const bulkUpdatePresentationsDates = async (args: BulkUpdatePresentationsDatesArgs): Promise<boolean> => {
	const { sessionId, body, queryStrings, ...others } = args;
	return await put<Array<PresentationBulkDateUpdate>>(
		{
			module,
			entities: [...rootEntities(sessionId), { entity: "bulk" }, { entity: "dates" }],
			...others,
		},
		body,
	);
};

interface PostPresentationArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: PresentationWrite;
}

export const postPresentation = async (args: PostPresentationArgs): Promise<string> => {
	const { body, sessionId, ...others } = args;
	return await postCreate<PresentationWrite>(
		{
			module,
			entities: rootEntities(sessionId),
			...others,
		},
		body,
	);
};

interface PutPresentationArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	body: PresentationWrite;
}

export const putPresentation = async (args: PutPresentationArgs): Promise<boolean> => {
	const { body, sessionId, presentationId, ...others } = args;
	return await put<PresentationWrite>(
		{
			module,
			entities: rootEntities(sessionId, presentationId),
			...others,
		},
		body,
	);
};

interface ClonePresentationArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
}

export const clonePresentation = async (args: ClonePresentationArgs): Promise<string> => {
	const { sessionId, presentationId, ...others } = args;
	return await postCreate({
		module,
		entities: [...rootEntities(sessionId, presentationId), { entity: "clone" }],
		...others,
	});
};

interface DeletePresentationArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
}

export const deletePresentation = async (args: DeletePresentationArgs): Promise<boolean> => {
	const { sessionId, presentationId, ...others } = args;
	return await deleteResponse({
		module,
		entities: rootEntities(sessionId, presentationId),
		...others,
	});
};

interface PatchPresentationOrderArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: {
		ids: string[];
	};
}

export const putPresentationOrder = async (args: PatchPresentationOrderArgs): Promise<boolean> => {
	const { sessionId, body, ...others } = args;
	return await put(
		{
			module,
			entities: [...rootEntities(sessionId), { entity: "order" }],
			...others,
		},
		body,
	);
};

interface PostPresentationsSearchArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BodyPostGet;
}

export const postPresentationsSearch = async (args: PostPresentationsSearchArgs): Promise<Array<PresentationRead>> => {
	const { body, ...others } = args;
	return await post(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};

export const postPresentationsSearchPaginated = async (args: PostPresentationsSearchArgs): Promise<Paginated<PresentationRead>> => {
	const { body, ...others } = args;
	return await postPaginated(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};

interface PatchPresentationBulkArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: PresentationBulkPatch[];
}

export const patchPresentationBulk = async (args: PatchPresentationBulkArgs): Promise<boolean> => {
	const { sessionId, body, ...others } = args;
	return await patch(
		{
			module,
			entities: [...rootEntities(sessionId), { entity: "bulk" }],
			...others,
		},
		body,
	);
};

interface PostPresentationBulkArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: PresentationBulkCreate[];
}

export const postPresentationBulk = async (args: PostPresentationBulkArgs): Promise<boolean> => {
	const { sessionId, body, ...others } = args;
	return await post(
		{
			module,
			entities: [...rootEntities(sessionId), { entity: "bulk" }],
			...others,
		},
		body,
	);
};
