import { Typography } from "@mui/material";
import { Trans } from "react-i18next";
import type { AbstractWizardProps } from "../common";

export type AbstractsSelectedWizardHeaderProps = AbstractWizardProps & {
	i18nKey: string;
	i18nAdditionalValues?: Record<string, unknown>;
};

export const AbstractsSelectedWizardHeader = (props: AbstractsSelectedWizardHeaderProps) => {
	const { i18nKey, useWizardResult, i18nAdditionalValues } = props;
	const [currentSelection] = useWizardResult("abstractSelection");
	return (
		<Typography variant="h6">
			<Trans
				i18nKey={i18nKey}
				components={{
					primary: <Typography component="span" variant={"h6"} color="primary" />,
				}}
				count={currentSelection?.length ?? 0}
				values={i18nAdditionalValues}
			/>
		</Typography>
	);
};
