import "@application/design/styles/scrollbar/scrollbar.scss";
import { Core, DefaultQueryClient } from "@mykey4/core";
import ReactDOM from "react-dom/client";
import App from "./App";
import { getProgrammeTranslation } from "./Translations/Translations.configuration";

const rootElement = document.getElementById("root");
const moduleTranslation = getProgrammeTranslation();

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<Core
			queryClient={DefaultQueryClient}
			queryDebug={true}
			moduleTranslation={moduleTranslation}
			paletteMode={"light"}
			loaderOverlay={{ minimumLoadingTime: 1000, delayBeforeShow: 500 }}
		>
			<App />
		</Core>,
	);
}
