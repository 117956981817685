import type { DashboardOverview } from "@api";
import type { ApexOptions } from "apexcharts";
import { t } from "i18next";
import { DateTime } from "luxon";
import { defaultOverviewChartOptions } from "./DashboardOverview.configuration";

export const buildOverviewChartOptions = (data: DashboardOverview): { options: ApexOptions; series: ApexAxisChartSeries } => {
	const defaultOptions = defaultOverviewChartOptions();

	if (defaultOptions.xaxis) {
		defaultOptions.xaxis.type = "category";
		defaultOptions.xaxis.categories = data.sessionsByDay.map((element) => DateTime.fromMillis(element.day, { zone: "UTC" }).toFormat("dd/MM"));
	}

	return {
		options: defaultOptions,
		series: [
			{
				name: t("old.programme.dashboard.charts.bar.series.sessionsByDay.name"),
				data: data.sessionsByDay.map((session) => session.count),
			},
			{
				name: t("old.programme.dashboard.charts.bar.series.invitationsByDay.name"),
				data: data.invitationsByDay.map((invitation) => invitation.count),
			},
		],
	};
};
