import type { TagTypeRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "sessions" }, { entity: "tag-types", id }];

interface GetTagTypesArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getTagTypes = async (args: GetTagTypesArgs): Promise<Paginated<TagTypeRead>> => {
	return await getPaginated({
		module,
		entities: rootEntities(),
		...args,
	});
};
