import type { Theme } from "@mui/system";
import { t } from "i18next";
import type { AbstractStatusConfigurations } from "./type";

export const getAbstractStatusConfiguration = (theme: Theme): AbstractStatusConfigurations => ({
	draft: {
		backgroundColor: theme.palette.warning.light,
		borderColor: theme.palette.warning.main,
		color: theme.palette.warning.dark,
		name: t("abstract.status.draft"),
		translationKey: "abstract.status.draft",
	},
	submitted: {
		backgroundColor: theme.palette.info.light,
		borderColor: theme.palette.info.main,
		color: theme.palette.info.dark,
		name: t("abstract.status.submitted"),
		translationKey: "abstract.status.submitted",
	},
	accepted: {
		backgroundColor: theme.palette.success.light,
		borderColor: theme.palette.success.main,
		color: theme.palette.success.dark,
		name: t("abstract.status.accepted"),
		translationKey: "abstract.status.accepted",
	},
	refused: {
		backgroundColor: theme.palette.error.light,
		borderColor: theme.palette.error.main,
		color: theme.palette.error.dark,
		name: t("abstract.status.refused"),
		translationKey: "abstract.status.refused",
	},
	updateRequested: {
		backgroundColor: theme.palette.warning.light,
		borderColor: theme.palette.warning.main,
		color: theme.palette.warning.dark,
		name: t("abstract.status.updateRequested"),
		translationKey: "abstract.status.updateRequested",
	},
	deleted: {
		backgroundColor: theme.palette.mode === "dark" ? "#4B0000" : "#FFDDDD",
		borderColor: theme.palette.mode === "dark" ? "#FF6B6B" : "#D32F2F",
		color: theme.palette.mode === "dark" ? "#FF6B6B" : "#B71C1C",
		name: t("abstract.status.deleted"),
		translationKey: "abstract.status.deleted",
	},
});
