import { AbstractDataGrid } from "@components";
import type { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { Typography } from "@mykey4/core";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import type { AbstractWizardProps } from "../common";
import { AbstractStepLayout } from "../components";

export const AbstractsSelectionStep = (props: AbstractWizardProps) => {
	const { initialData, useWizardResult } = props;
	const { t } = useTranslation();
	const [currentSelection, setCurrentSelection] = useWizardResult("abstractSelection");

	const handle = useCallback(
		(selection: GridRowSelectionModel) => {
			setCurrentSelection([...selection.map((id) => id as string)]);
		},
		[setCurrentSelection],
	);

	return (
		<AbstractStepLayout
			slotProps={{
				buttons: {
					nextButton: {
						disabled: !currentSelection || currentSelection.length === 0,
					},
				},
			}}
		>
			<Typography variant="h6">{t("abstract.bulkActions.steps.abstractSelection.title")}</Typography>
			<AbstractDataGrid
				search={initialData?.search ?? ""}
				checkboxSelection
				rowSelectionModel={currentSelection}
				onRowSelectionModelChange={(selection, _) => handle(selection)}
			/>
		</AbstractStepLayout>
	);
};
