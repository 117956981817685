import { API_VERSION_QUERY_PARAM, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import {
	getDashboardFaculties,
	getDashboardInvitationStatus,
	getDashboardIssues,
	getDashboardOverview,
	getDashboardProgramme,
	getDashboardSessionPublicationStatus,
	getDashboardSessionStatus,
} from "./Dashboard.service";
import type {
	DashboardFaculties,
	DashboardGetInvitationStatus,
	DashboardIssues,
	DashboardOverview,
	DashboardProgramme,
	DashboardSessionPublicationStatus,
	DashboardSessionStatus,
} from "./Dashboard.type";

export const UseDashboardQueryKeys = {
	dashboard: (clientId: string, eventId: string) => [...QUERY_KEYS_PROGRAMME.dashboard, clientId, eventId],
	dashboardOverview: (clientId: string, eventId: string) => [...UseDashboardQueryKeys.dashboard(clientId, eventId), "Overview"],
	dashboardProgramme: (clientId: string, eventId: string) => [...UseDashboardQueryKeys.dashboard(clientId, eventId), "Programme"],
	dashboardFaculties: (clientId: string, eventId: string) => [...UseDashboardQueryKeys.dashboard(clientId, eventId), "Faculties"],
	dashboardSessionStatus: (clientId: string, eventId: string) => [...UseDashboardQueryKeys.dashboard(clientId, eventId), "SessionStatus"],
	dashboardSessionPublicationStatus: (clientId: string, eventId: string) => [...UseDashboardQueryKeys.dashboard(clientId, eventId), "SessionPublicationStatus"],
	dashboardInvitationStatus: (clientId: string, eventId: string) => [...UseDashboardQueryKeys.dashboard(clientId, eventId), "InvitationStatus"],
	dashboardIssues: (clientId: string, eventId: string) => [...UseDashboardQueryKeys.dashboard(clientId, eventId), "Issues"],
};

export type UseDashboardArgs<TQueryFnData = unknown, TData = TQueryFnData> = UseQueryArgs<TQueryFnData, TData> & {
	clientId: string;
	eventId: string;
};

export const useDashboardOverview = <TData = DashboardOverview>(args: UseDashboardArgs<DashboardOverview, TData>): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, clientId, eventId, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [UseDashboardQueryKeys.dashboardOverview(args.clientId, args.eventId), ...queryKeyPart, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getDashboardOverview({
				clientId,
				eventId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};

export const useDashboardProgramme = <TData = DashboardProgramme>(args: UseDashboardArgs<DashboardProgramme, TData>): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, clientId, eventId, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [UseDashboardQueryKeys.dashboardProgramme(args.clientId, args.eventId), ...queryKeyPart, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getDashboardProgramme({
				clientId,
				eventId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};

export const useDashboardFaculties = <TData = DashboardFaculties>(args: UseDashboardArgs<DashboardFaculties, TData>): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, clientId, eventId, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [UseDashboardQueryKeys.dashboardFaculties(args.clientId, args.eventId), ...queryKeyPart, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getDashboardFaculties({
				clientId,
				eventId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};

export const useDashboardSessionStatus = <TData = DashboardSessionStatus>(args: UseDashboardArgs<DashboardSessionStatus, TData>): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, clientId, eventId, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [UseDashboardQueryKeys.dashboardSessionStatus(args.clientId, args.eventId), ...queryKeyPart, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getDashboardSessionStatus({
				clientId,
				eventId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};

export const useDashboardInvitationStatus = <TData = DashboardGetInvitationStatus>(
	args: UseDashboardArgs<DashboardGetInvitationStatus, TData>,
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, clientId, eventId, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [UseDashboardQueryKeys.dashboardInvitationStatus(args.clientId, args.eventId), ...queryKeyPart, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getDashboardInvitationStatus({
				clientId,
				eventId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};

export const useDashboardSessionPublicationStatus = <TData = DashboardSessionPublicationStatus>(
	args: UseDashboardArgs<DashboardSessionPublicationStatus, TData>,
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, clientId, eventId, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [UseDashboardQueryKeys.dashboardSessionPublicationStatus(args.clientId, args.eventId), ...queryKeyPart, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getDashboardSessionPublicationStatus({
				clientId,
				eventId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};

export const useDashboardIssues = <TData = DashboardIssues>(args: UseDashboardArgs<DashboardIssues, TData>): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, clientId, eventId, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [UseDashboardQueryKeys.dashboardIssues(args.clientId, args.eventId), ...queryKeyPart, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getDashboardIssues({
				clientId,
				eventId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};
