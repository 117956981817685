import type { StepId, WizardStepStore } from "@components";
import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export const useWizardStepStore = <StepType extends StepId>(storeKey: string, stepIds: readonly StepType[]) => {
	return create<
		WizardStepStore<StepType>,
		[["zustand/subscribeWithSelector", never], ["zustand/immer", never], ["zustand/persist", WizardStepStore<StepType>]]
	>(
		subscribeWithSelector(
			immer(
				persist(
					(set, get) => ({
						steps: stepIds,
						currentStepIndex: 0,
						completedSteps: [],

						setCurrentStepIndex: (index: number) => {
							set((state) => {
								state.currentStepIndex = index;
							});
						},

						completeStep: (stepId: StepType) =>
							set((state) => {
								state.completedSteps = [...state.completedSteps, stepId];
							}),

						goToStep: (index) => {
							if (index >= 0 && index < get().steps.length) {
								set((state) => {
									state.currentStepIndex = index;
								});
							}
						},

						goToNext: () => {
							const { currentStepIndex, steps } = get();
							if (currentStepIndex < steps.length - 1) {
								set((state) => {
									state.currentStepIndex = currentStepIndex + 1;
								});
							}
						},

						goToPrevious: () => {
							const { currentStepIndex } = get();
							if (currentStepIndex > 0) {
								set((state) => {
									state.currentStepIndex = currentStepIndex - 1;
								});
							}
						},

						reset: () => set({ steps: stepIds, currentStepIndex: 0, completedSteps: [] }),
					}),
					{ name: `${storeKey ?? "wizard"}-step-store` },
				),
			),
		),
	);
};
