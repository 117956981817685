import { dateNullableZod, stringISOToDateTimeNullable } from "@mykey4/core";
import { z } from "zod";

export const sessionStatusSchema = z.object({
	status: z.enum(["draft", "validated", "cancelled"]),
	isPublished: z.boolean(),
	publicationDate: dateNullableZod(),
});

export type SessionStatusFormType = z.infer<typeof sessionStatusSchema>;

export const sessionStatusDefaultValues = (existingValues?: SessionStatusFormType): SessionStatusFormType => {
	return {
		status: existingValues ? existingValues.status : "draft",
		isPublished: existingValues ? existingValues.isPublished : false,
		publicationDate: existingValues ? stringISOToDateTimeNullable(existingValues.publicationDate) : null,
	};
};
