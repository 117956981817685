import { type SessionPublicationStatus, SessionPublicationStatusTypes, useDashboardSessionPublicationStatus } from "@api";
import { useContextModule } from "@key4-front-library/core";
import { useTheme } from "@mui/material";
import type { RegularBreakpoints } from "@mui/material/Grid/Grid";
import { t } from "i18next";
import DashboardDonutChart from "../Common/DashboardDonutChart";

type PublicationStatusConfig = {
	[key in SessionPublicationStatus]: {
		value: SessionPublicationStatus;
		name: string;
		color: string;
	};
};

const usePublicationStatusConfigs = (): PublicationStatusConfig => {
	const theme = useTheme();
	return {
		notPublished: {
			value: "notPublished",
			name: "old.common.publication_status.notPublished",
			color: theme.palette.grey[500],
		},
		planned: {
			value: "planned",
			name: "old.common.publication_status.planned",
			color: theme.palette.info.main,
		},
		published: {
			value: "published",
			name: "old.common.publication_status.published",
			color: theme.palette.success.main,
		},
	};
};

type Props = RegularBreakpoints & {};

const DashboardSessionPublicationStatus = (props: Props) => {
	const { client, event } = useContextModule();
	const configurations = usePublicationStatusConfigs();

	const statusStatistics = useDashboardSessionPublicationStatus({
		clientId: client.id,
		eventId: event.id,
		select: (data) => {
			return SessionPublicationStatusTypes.map((status) => {
				const config = configurations[status];
				return {
					name: config.name,
					value: data[status],
					color: config.color,
				};
			});
		},
	});

	return (
		<DashboardDonutChart
			{...props}
			title={t("old.programme.dashboard.charts.donuts.publications.title")}
			statistics={statusStatistics.data ?? []}
			isLoading={statusStatistics.isFetching}
		/>
	);
};

export default DashboardSessionPublicationStatus;
