import { ListItemIcon, MenuItem } from "@mui/material";
import { type GridActionsCellItemProps, useGridRootProps } from "@mui/x-data-grid-pro";
import { cloneElement, forwardRef } from "react";

/**
 * 🚨 PROCEED WITH CAUTION 🚨
 * DONT EDIT
 * If you don't know EXACTLY what you're doing,
 * please step away from the keyboard.
 * https://github.com/mui/mui-x/blob/v6.20.5/packages/grid/x-data-grid/src/components/cell/GridActionsCellItem.tsx
 * https://github.com/mui/mui-x/blob/master/packages/x-data-grid/src/components/cell/GridActionsCellItem.tsx#L50
 * https://github.com/mui/mui-x/issues/12900
 * FIXME TODO
 * 🚨 PROCEED WITH CAUTION 🚨
 */
export const GridActionsCellItem = forwardRef<HTMLElement, GridActionsCellItemProps>((props, ref) => {
	const rootProps = useGridRootProps();

	if (!props.showInMenu) {
		const { label, icon, showInMenu, onClick, ...other } = props;

		const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onClick?.(event);
		};

		// Hardcoded value : { fontSize: "small" } => { fontSize: "inherit" }
		// See https://github.com/mui/mui-x/issues/12900 Fixed V8 ?
		return (
			<rootProps.slots.baseIconButton
				ref={ref}
				size="small"
				role="menuitem"
				aria-label={label}
				{...other}
				onClick={handleClick}
				{...rootProps.slotProps?.baseIconButton}
			>
				{cloneElement(icon!, { fontSize: "inherit" })}
			</rootProps.slots.baseIconButton>
		);
	}

	const { label, icon, showInMenu, onClick, closeMenuOnClick = true, closeMenu, ...other } = props;

	const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
		onClick?.(event);
		if (closeMenuOnClick) {
			closeMenu?.();
		}
	};

	return (
		<MenuItem ref={ref} {...(other as any)} onClick={handleClick}>
			{icon && <ListItemIcon>{icon}</ListItemIcon>}
			{label}
		</MenuItem>
	);
});
