import { useKeyTitle } from "@hooks/useKeyTitle";
import { Typography } from "@mui/material";
import type { Theme } from "@mui/material/styles/createTheme";
import type { SxProps } from "@mui/system";
import { Link, Stack } from "@mykey4/core";
import type { TextVariant } from "@mykey4/core/Theme/Theme.types";

export interface KeyTitleProps {
	keyText?: string;
	title?: string;
	onClick?: () => void;
	sx?: SxProps<Theme>;
	variant?: TextVariant;
}

export const KeyTitle = (props: KeyTitleProps) => {
	const { keyText, title, onClick, variant = "body1", sx } = props;

	const { key: displayKey, title: displayTitle } = useKeyTitle({ key: keyText, title: title });
	const isKeyDefined = !!displayKey;
	const isTitleDefined = !!displayTitle;

	return (
		<Stack direction={"row"} alignItems={"center"} spacing={1} overflow={"hidden"} minWidth={0} flexShrink={1} sx={sx}>
			<Link
				onClick={onClick}
				variant={variant}
				color={isKeyDefined ? "primary" : ""}
				fontStyle={isKeyDefined ? "normal" : "italic"}
				flexShrink={0}
				sx={{ textDecoration: isKeyDefined ? "underline" : "none", cursor: "pointer" }}
			>
				{displayKey}
			</Link>
			<Typography variant={variant}>{"-"}</Typography>
			<Typography
				variant={variant}
				overflow={"hidden"}
				textOverflow={"ellipsis"}
				whiteSpace={"nowrap"}
				fontStyle={isTitleDefined ? "normal" : "italic"}
				flexShrink={1}
				minWidth={0}
				pr={1}
			>
				{displayTitle}
			</Typography>
		</Stack>
	);
};
