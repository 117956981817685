import type { PresentationRead, SpeakerRead } from "@api";
import { SpeakerModal } from "@components";
import {
	ActionReference,
	ConfirmationModal,
	DataGridOld,
	EnumActionsReference,
	NoData,
	type PropsButtonIcon,
	Services,
	getColumns,
	getPersonTitle,
	statusInvitationParticipantObject,
	useContextModule,
	useSnackBarHook,
} from "@key4-front-library/core";
import type { PropsDataGridCellButtons } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import DataGridCellButtons from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import { EConfirmationModalAction } from "@key4-front-library/core/Enums/Modal";
import { Box, Stack } from "@mui/material";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";
import { metaEnv, useDialogs } from "@mykey4/core";
import { getExternalPath } from "@routes";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { speakerColDef } from "./PresentationItemDetails.configuration";

type PropsPresentationDetails = {
	presentation: PresentationRead;
	sessionId: string;
	speakers: Array<SpeakerRead>;
	updateSpeakers: () => void | Promise<void>;
};

const PresentationItemDetails = (props: PropsPresentationDetails) => {
	const { presentation, sessionId, speakers = [], updateSpeakers } = props;
	const { id: presentationId } = presentation;

	const dialog = useDialogs();

	const { client, event } = useContextModule();
	const { sendError } = useSnackBarHook();

	const [isDatagridLoading, setIsDatagridLoading] = useState<boolean>();
	const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState<boolean>(false);

	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});

	const [speakersRows, setSpeakersRows] = useState<Array<GridValidRowModel>>([]);

	useEffect(() => {
		const rows: Array<GridValidRowModel> = speakers.map((faculty: SpeakerRead) => ({
			id: faculty.participantId,
			faculty: getPersonTitle(faculty),
			type: faculty.quality?.label ?? t("old.common.speaker"),
			status: {
				label: t(statusInvitationParticipantObject[faculty.invitationStatus].label),
				iconColor: statusInvitationParticipantObject[faculty.invitationStatus].bgColor,
			},
			actions: DataGridCellButtons(getActionButtons(faculty, speakers)),
		}));

		setSpeakersRows(rows);
	}, [speakers]);

	const handleSpeakerEditModaleOpen = async (id: string) => {
		await dialog.open(SpeakerModal, {
			sessionId,
			presentationId,
			speakerParticipantId: id,
		});
		await updateSpeakers();
	};

	const handleConfirmationModalOpen = () => {
		setConfirmationModalIsOpen(true);
	};

	const handleConfirmationModalClose = () => {
		setConfirmationModalIsOpen(false);
	};

	const handleSpeakerDelete = async (speakerId: string) => {
		setIsDatagridLoading(true);
		try {
			await Services.Events.Programme.PresentationsService.deleteSpeaker(client.id, event.id, sessionId, presentationId, speakerId).then(() =>
				updateSpeakers(),
			);
		} catch (error) {
			sendError(t("old.common.errors.generic"));
			setIsDatagridLoading(false);
		}

		setIsDatagridLoading(false);
	};

	const handleSpeakerMoveUp = (speakerId: string, speakers: Array<SpeakerRead>) => handleSpeakerMove(speakerId, speakers, EnumActionsReference.UP);

	const handleSpeakerMoveDown = (speakerId: string, speakers: Array<SpeakerRead>) => handleSpeakerMove(speakerId, speakers, EnumActionsReference.DOWN);

	const handleSpeakerMove = async (speakerId: string, speakersList: Array<SpeakerRead>, action: string) => {
		const speakerIdIndex: number = speakersList.map((speaker) => speaker.participantId).indexOf(speakerId);
		const speakersOrderedList: Array<SpeakerRead> = speakersList;
		const newSpeakerIndex = action === EnumActionsReference.UP ? speakerIdIndex - 1 : speakerIdIndex + 1;

		speakersOrderedList.splice(newSpeakerIndex, 0, speakersOrderedList.splice(speakerIdIndex, 1)[0]);

		setIsDatagridLoading(true);

		try {
			await Services.Events.Programme.PresentationsService.putSpeakerOrder(client.id, event.id, sessionId, presentationId, {
				ids: speakersOrderedList.map((speaker) => speaker.participantId),
			}).then(() => {
				updateSpeakers();
			});
		} catch (error) {
			sendError(t("old.common.errors.generic"));
			setIsDatagridLoading(false);
		}
		setIsDatagridLoading(false);
	};

	const getActionButtons = (data: SpeakerRead, speakers: Array<SpeakerRead>): PropsDataGridCellButtons => {
		const emptyButton: PropsButtonIcon = {
			disabled: true,
			tooltip: undefined,
			onClick: () => {},
		};

		const listButtons: PropsDataGridCellButtons = {
			listButtons: [
				{
					...ActionReference[EnumActionsReference.DETAILS],
					tooltip: t("old.programme.presentations.speakers.buttons.details"),
					onClick: () => {
						window.location.href = getExternalPath(metaEnv.myKey4.endPoint.registration, "participantDetails", [client.key, event.key, data.participantId]);
					},
				},
				{
					...ActionReference[EnumActionsReference.EDIT],
					tooltip: t("old.programme.presentations.speakers.buttons.edit"),
					onClick: () => {
						handleSpeakerEditModaleOpen(data.participantId);
					},
				},
			],
		};

		if (speakers.map((speaker) => speaker.participantId).indexOf(data.participantId) !== 0) {
			listButtons.listButtons.push({
				...ActionReference[EnumActionsReference.UP],
				tooltip: t("old.programme.presentations.speakers.buttons.moveUp"),
				onClick: () => {
					handleSpeakerMoveUp(data.participantId, speakers);
				},
			});

			if (speakers.map((speaker) => speaker.participantId).indexOf(data.participantId) === speakers.length - 1 && speakers.length > 2) {
				listButtons.listButtons.push(emptyButton);
			}
		}

		if (speakers.map((speaker) => speaker.participantId).indexOf(data.participantId) !== speakers.length - 1) {
			if (speakers.map((speaker) => speaker.participantId).indexOf(data.participantId) === 0 && speakers.length > 2) {
				listButtons.listButtons.push(emptyButton);
			}

			listButtons.listButtons.push({
				...ActionReference[EnumActionsReference.DOWN],
				tooltip: t("old.programme.presentations.speakers.buttons.moveDown"),
				onClick: () => {
					handleSpeakerMoveDown(data.participantId, speakers);
				},
			});
		}

		listButtons.listButtons.push({
			...ActionReference[EnumActionsReference.DELETE],
			tooltip: t("old.programme.presentations.speakers.buttons.delete"),
			onClick: () => {
				confirmationModalProps.current = {
					action: EConfirmationModalAction.DELETE,
					handleAction: () => {
						handleSpeakerDelete(data.participantId).then(() => {
							handleConfirmationModalClose();
						});
					},
				};
				handleConfirmationModalOpen();
			},
		});

		return listButtons;
	};

	const getTranslatedColumnLabels = (): Array<string> =>
		speakerColDef.map((column) => t(["old", "programme", "sessionDetails", "datagrid", "headerLabels", column.field].join(".")));

	if (!speakers) {
		return <NoData />;
	}

	return (
		<Stack direction={"column"} spacing={1} m={0} p={0}>
			<Box component="span">
				<DataGridOld
					columns={getColumns(getTranslatedColumnLabels(), speakerColDef)}
					rows={speakersRows}
					isAutoHeight={true}
					isHideFooter
					isLoading={isDatagridLoading}
					sx={{ border: "none" }}
				/>
			</Box>
			<ConfirmationModal
				open={confirmationModalIsOpen}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleConfirmationModalClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
		</Stack>
	);
};

export default PresentationItemDetails;
