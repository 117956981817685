import type { QuickAccessProps } from "@mykey4/core";
import { getPath } from "@routes/Routes.helper";
import { t } from "i18next";

export const getQuickAccessConfiguration = (clientKey: string, eventKey: string): QuickAccessProps => {
	return {
		quickAccessLinks: [
			{
				link: { to: getPath("sessions", [clientKey, eventKey]) },
				iconText: {
					typography: { children: t("quickAccess.sessions") },
					icon: { name: "clipboard-list" },
				},
			},
			{
				link: { to: getPath("faculties", [clientKey, eventKey]) },
				iconText: {
					typography: { children: t("quickAccess.faculties") },
					icon: { name: "user-group" },
				},
			},
			{
				link: { to: getPath("scheduler", [clientKey, eventKey]) },
				iconText: {
					typography: { children: t("quickAccess.scheduler") },
					icon: { name: "calendar-circle-plus" },
				},
			},
			{
				link: { to: getPath("sessions", [clientKey, eventKey]) },
				iconText: {
					typography: { children: t("quickAccess.numbering") },
					icon: { name: "list-ol" },
				},
			},
			{
				link: { to: getPath("faculties", [clientKey, eventKey]) },
				iconText: {
					typography: {
						children: t("quickAccess.invitations"),
					},
					icon: { name: "envelopes-bulk" },
				},
			},
			{
				link: { to: getPath("sessions", [clientKey, eventKey]) },
				iconText: {
					typography: {
						children: t("quickAccess.publication"),
					},
					icon: { name: "earth-europe" },
				},
			},
		],
	};
};
