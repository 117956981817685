import type { RoomRead, RoomWrite, RoomsOrder } from "@api";
import { API_VERSION_QUERY_PARAM, type Paginated, type PathEntity, type ServiceArgs, get, getPaginated, overrideQueryParams, post, put } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "rooms", id }];

interface GetRoomsArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getRooms = async (args: GetRoomsArgs): Promise<Paginated<RoomRead>> => {
	return await getPaginated({
		module,
		entities: rootEntities(),
		...args,
	});
};

interface GetRoomArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	id: string;
}
export const getRoom = async (args: GetRoomArgs): Promise<RoomRead> => {
	return await get({
		module,
		entities: rootEntities(args.id),
		...args,
	});
};

export interface PostRoomArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: RoomWrite;
}

export const postRoom = async (args: PostRoomArgs): Promise<boolean> => {
	const { body } = args;
	return await post(
		{
			module,
			entities: rootEntities(),
			...args,
		},
		body,
	);
};

export interface PutRoomArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	id: string;
	body: RoomWrite;
}

export const putRoom = async (args: PutRoomArgs): Promise<boolean> => {
	const { body } = args;
	return await put<RoomWrite>(
		{
			module,
			entities: rootEntities(args.id),
			...args,
		},
		body,
	);
};

export interface PutRoomsOrderArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: RoomsOrder;
}

export const putRoomsOrder = async (args: PutRoomsOrderArgs): Promise<boolean> => {
	const { body, queryStrings, ...others } = args;
	return await put<RoomsOrder>(
		{
			module,
			entities: [...rootEntities(), { entity: "order" }],
			queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
			...others,
		},
		body,
	);
};
