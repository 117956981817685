import { TypographyWithTooltip } from "@components";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, alpha } from "@mui/material";
import { Stack, styled } from "@mui/system";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AbstractsDissociationTranslation } from "../common";

const SwitchIcon = memo(() => <FontAwesomeIcon icon={faTrash} color={"inherit"} />);

const StyledSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		height: "28px",
		width: "28px",
		margin: "5px 4px",
		"&:not(.Mui-checked)": {
			color: theme.palette.grey[400],
			backgroundColor: theme.palette.error.contrastText,
			transform: "translateX(-4px)",
			"&:hover": {
				backgroundColor: alpha(theme.palette.error.contrastText, 0.7),
			},
			"+ .MuiSwitch-track": {
				backgroundColor: theme.palette.grey[400],
			},
		},
		"&.Mui-checked": {
			color: theme.palette.error.contrastText,
			backgroundColor: theme.palette.error.main,
			transform: "translateX(26px)",
			"&:hover": {
				backgroundColor: alpha(theme.palette.error.main, 0.7),
			},
			"+ .MuiSwitch-track": {
				backgroundColor: theme.palette.error.main,
			},
		},
	},
}));

interface AbstractDissociationSwitchProps {
	label?: string;
	labelColor?: string;
	checked: boolean;
	onCheckedChange: (checked: boolean) => void;
	children?: React.ReactNode;
}

export const AbstractDissociationSwitch = (props: AbstractDissociationSwitchProps) => {
	const { label, labelColor, checked, onCheckedChange, children } = props;
	const { t } = useTranslation();
	const handleChecked = useCallback((event: React.ChangeEvent<HTMLInputElement>) => onCheckedChange(event.target.checked), [onCheckedChange]);
	return (
		<Stack direction="row" alignItems="center" spacing={1}>
			<StyledSwitch checked={checked} onChange={handleChecked} icon={<SwitchIcon />} checkedIcon={<SwitchIcon />} />
			{!children && (
				<TypographyWithTooltip overflow={"hidden"} textOverflow="ellipsis" whiteSpace={"nowrap"} color={labelColor}>
					{!label ? t(`${AbstractsDissociationTranslation}.switch`) : label}
				</TypographyWithTooltip>
			)}
			{children}
		</Stack>
	);
};
