import {
	type BulkSessionPublishWrite,
	type BulkSessionStatusWrite,
	type LiteTagRead,
	type SessionRead,
	type SessionReadV1,
	type SessionStatusWrite,
	type SessionWrite,
	type TagRead,
	type TokenRead,
	getAllData,
} from "@api";
import {
	type BodyPostGet,
	type Paginated,
	type PatchPayload,
	type PathEntity,
	type ServiceArgs,
	get,
	getPaginated,
	patch,
	post,
	postCreate,
	put,
} from "@mykey4/core";

const module = "programme";

const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "sessions", id }];

interface GetSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSession = async (args: GetSessionArgs): Promise<SessionRead> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};

interface GetSessionsArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getSessions = async (args: GetSessionsArgs): Promise<Paginated<SessionRead>> => {
	return await getPaginated({
		module,
		entities: rootEntities(),
		...args,
	});
};

export const getSessionsAll = async (args: GetSessionsArgs): Promise<SessionRead[]> => {
	const { queryStrings, ...others } = args;
	return await getAllData((query) => {
		return getSessions({ ...others, queryStrings: query });
	}, queryStrings);
};

interface GetSessionV1Args extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionV1 = async (args: GetSessionV1Args): Promise<SessionReadV1> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};

interface PostSessionsTagArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BodyPostGet;
}

export const postSessionsTags = async (args: PostSessionsTagArgs): Promise<Array<TagRead>> => {
	const { body, ...others } = args;
	return await post(
		{
			module,
			entities: [...rootEntities(), { entity: "tags" }],
			...others,
		},
		body,
	);
};

interface GetSessionTagArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionTags = async (args: GetSessionTagArgs): Promise<Paginated<LiteTagRead>> => {
	const { sessionId, ...others } = args;
	return await getPaginated({
		module,
		entities: [...rootEntities(sessionId), { entity: "tags" }],
		...others,
	});
};

interface PostSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: SessionWrite;
}

export const postSession = async (args: PostSessionArgs): Promise<string> => {
	const { body, ...others } = args;
	return await postCreate<SessionWrite>(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};

interface PutSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: SessionWrite;
}

export const putSession = async (args: PutSessionArgs): Promise<boolean> => {
	const { body, sessionId, ...others } = args;
	return await put<SessionWrite>(
		{
			module,
			entities: rootEntities(sessionId),
			...others,
		},
		body,
	);
};

interface GetSessionTokensArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionTokens = async (args: GetSessionTokensArgs): Promise<Paginated<TokenRead>> => {
	const { sessionId, ...others } = args;
	return await getPaginated({
		module,
		entities: [...rootEntities(sessionId), { entity: "tokens" }],
		...others,
	});
};

interface PatchSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: PatchPayload;
}

export const patchSessionBulk = async (args: PatchSessionArgs): Promise<boolean> => {
	const { body, ...others } = args;
	return await patch(
		{
			module,
			entities: [...rootEntities(), { entity: "bulk" }],
			...others,
		},
		body,
	);
};

interface PutSessionBulkStatusArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BulkSessionStatusWrite;
}

export const putSessionBulkStatus = async (args: PutSessionBulkStatusArgs): Promise<boolean> => {
	const { body, ...others } = args;
	return await put<BulkSessionStatusWrite>(
		{
			module,
			entities: [...rootEntities(), { entity: "bulk" }, { entity: "status" }],
			...others,
		},
		body,
	);
};

interface PutSessionBulkPublishArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BulkSessionPublishWrite;
}

export const putSessionBulkPublish = async (args: PutSessionBulkPublishArgs): Promise<boolean> => {
	const { body, ...others } = args;
	return await put<BulkSessionPublishWrite>(
		{
			module,
			entities: [...rootEntities(), { entity: "bulk" }, { entity: "publish" }],
			...others,
		},
		body,
	);
};

interface PutSessionStatusArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: SessionStatusWrite;
}

export const putSessionStatus = async (args: PutSessionStatusArgs): Promise<boolean> => {
	const { sessionId, body, ...others } = args;
	return await put(
		{
			module,
			entities: [...rootEntities(sessionId), { entity: "status" }],
			...others,
		},
		body,
	);
};
