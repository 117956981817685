import type { DtoDefinition, TAdvancedSearchFieldDefinition } from "@key4-front-library/core";
import { TypeField } from "@mykey4/core";
export const dtoDefinitionListToTAdvancedSearchFieldDefinitionList = (definitions: Array<DtoDefinition>): Array<TAdvancedSearchFieldDefinition> =>
	definitions.map((def) => {
		return {
			name: def.name ?? "",
			fields: def.fields
				? def.fields.map((field) => {
						return {
							key: field.key ?? "",
							type: field.type ? (field.type as TypeField) : TypeField.Text,
							resources: field.resources,
						};
					})
				: [],
			foreignKeys: def.foreignKeys ?? [],
			resources: def.resources ?? undefined,
		};
	});
