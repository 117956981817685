import type { AbstractCategoryRead } from "@api";
import { type BodyPostGet, type PathEntity, type ServiceArgs, post } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "abstract-categories", id }];

interface PostAbstractCategoriesArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BodyPostGet;
}

export const postAbstractCategoriesSearch = async (args: PostAbstractCategoriesArgs): Promise<Array<AbstractCategoryRead>> => {
	const { body, ...others } = args;
	return await post(
		{
			module,
			entities: [...rootEntities(), { entity: "search" }],
			...others,
		},
		body,
	);
};
