import type { StepId, StepValueTypes, WizardDataStore } from "@components";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useWizardDataStore = <StepType extends StepId, ValueTypes extends StepValueTypes<StepType>, TInitialData = undefined>(storeKey: string) => {
	return create<WizardDataStore<StepType, ValueTypes, TInitialData>, [["zustand/persist", WizardDataStore<StepType, ValueTypes, TInitialData>]]>(
		persist(
			(set, get) => ({
				initialData: undefined,
				results: {},
				setInitialData: (data: TInitialData): void => set({ initialData: data }),
				getValue: <K extends StepType>(step: K): ValueTypes[K] | undefined => get().results[step],
				setValue: <K extends StepType>(step: K, value: ValueTypes[K]): void =>
					set((state) => {
						return { results: { ...state.results, [step]: value } };
					}),
				hasValue: (step: StepType): boolean => {
					return step in get().results;
				},
				reset: () => set({ initialData: undefined, results: {} }),
				resetSteps: (steps: StepType[]) =>
					set((state) => {
						const newResults = { ...state.results };
						for (const step of steps) {
							delete newResults[step];
						}
						return { results: newResults };
					}),
			}),
			{ name: `${storeKey ?? "wizard"}-data-store` },
		),
	);
};
