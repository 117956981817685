import { Stack } from "@mui/material";

type DataGridHeaderProps = {
	field: string;
	headerName?: string;
};

const DataGridHeader = (props: DataGridHeaderProps) => {
	const { headerName } = props;

	return <Stack className="MuiDataGrid-columnHeaderTitle">{headerName}</Stack>;
};

export default DataGridHeader;
