import type { AbstractFiltersState } from "@components";
import { FilteringOperator } from "@mykey4/core";

export const buildSearchQuery = (filters: AbstractFiltersState): string => {
	const searchParts: string[] = [];

	const addArrayFilter = <T>(array: T[] | undefined, filterMapper: (item: T) => string) => {
		if (array?.length) {
			searchParts.push(`(${array.map(filterMapper).join(FilteringOperator.Or)})`);
		}
	};

	addArrayFilter(
		filters.presenter,
		(opt) => `(CoAuthors.Id${FilteringOperator.Equal}${opt.id}${FilteringOperator.And}CoAuthors.IsPresenter${FilteringOperator.Equal}true)`,
	);
	addArrayFilter(filters.category, (opt) => `CategoryId${FilteringOperator.Equal}${opt.id}`);
	addArrayFilter(filters.status, (val) => `Status${FilteringOperator.Equal}${val}`);
	addArrayFilter(filters.finalType, (opt) => `TypeId${FilteringOperator.Equal}${opt.id}`);
	addArrayFilter(filters.theme, (opt) => `ThemeId${FilteringOperator.Equal}${opt.id}`);
	addArrayFilter(filters.subTheme, (opt) => `Keywords.Keyword.Id${FilteringOperator.Equal}${opt.id}`);
	addArrayFilter(filters.session, (opt) => `Presentations.SessionId${FilteringOperator.Equal}${opt.id}`);
	addArrayFilter(filters.presentation, (opt) => `Presentations.Id${FilteringOperator.Equal}${opt.id}`);

	if (filters.number) {
		searchParts.push(`Number${FilteringOperator.Equal}${filters.number}`);
	}
	if (filters.newAttributedNumber) {
		searchParts.push(`NewAttributedNumber${FilteringOperator.Equal}${filters.newAttributedNumber}`);
	}
	if (filters.isIntegrated) {
		searchParts.push(`Presentations.AbstractId${FilteringOperator.NotEqual}null`);
	}
	if (filters.searchTerm) {
		searchParts.push(`Title|Number@=*${filters.searchTerm}`);
	}

	return searchParts.join(FilteringOperator.And);
};

export const isFilterActive = (filters: AbstractFiltersState): boolean =>
	filters.isIntegrated ||
	filters.presenter.length > 0 ||
	filters.category.length > 0 ||
	filters.status.length > 0 ||
	filters.finalType.length > 0 ||
	filters.theme.length > 0 ||
	filters.subTheme.length > 0 ||
	filters.number !== "" ||
	filters.newAttributedNumber !== "" ||
	filters.session.length > 0 ||
	filters.presentation.length > 0;
