import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import DateHelper from "@application/helpers/date.helper";
import {
	CalendarSettings,
	EFilterUnplannedSessions,
	type ICalendarSession,
	type TFilterUnplannedSessionsForm,
	filtersUnplannedSessionsObject,
} from "@domain/interfaces/calendar.interface";
import type { TSessionCalendar } from "@domain/model/calendar.model";
import { EnumParticipantStatusInvitation, EnumSessionStatus, Services, escapeSieveString, inArraySieveString, queryFilters } from "@key4-front-library/core";
import type { TAutocompleteMultiChipItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiChip";
import type { DtoCalendarGetFaculty, DtoCalendarGetSession, DtoFacultyGetDetails } from "@key4-front-library/core/Dto";
import { FilteringOperator, isoDurationToString, stringToIsoDuration } from "@mykey4/core";
import { DateTime } from "luxon";

const getCalendarSettings = async (clientId: string, eventId: string): Promise<CalendarSettings> => {
	const calendarSettings = await Services.Events.Programme.CalendarService.getSettings(clientId, eventId);
	let rooms = null;
	if (calendarSettings.rooms) {
		rooms = calendarSettings.rooms.map((element) => {
			return {
				id: element.id,
				title: element.name ?? "N/A",
			};
		});
	}

	const calendarProgrammeSettings = await Services.Events.Programme.SettingsService.getScheduler(clientId, eventId);

	const formattedCalendarProgrammeSettings = DateHelper.getCalendarProgrammeSettings(calendarProgrammeSettings);

	return new CalendarSettings(
		formattedCalendarProgrammeSettings.date,
		formattedCalendarProgrammeSettings.hour,
		formattedCalendarProgrammeSettings.dateDurationDays,
		rooms ?? undefined,
	);
};

const getSessionsByRoom = async (clientId: string, eventId: string, roomIds: Array<string>): Promise<Array<ICalendarSession>> => {
	if (roomIds.length === 0) {
		throw new Error("no roomIds set");
	}

	const roomIdsQuery = roomIds.join(FilteringOperator.OrIn);

	const sessionsData: Array<DtoCalendarGetSession> = await Services.Events.Programme.CalendarService.getAllSessions(clientId, eventId, [
		...queryFilters(
			`roomId${FilteringOperator.Equal + roomIdsQuery + FilteringOperator.And}isplanned${FilteringOperator.Equal}true${FilteringOperator.And}status${FilteringOperator.NotEqual}cancelled`,
		),
	]);

	const sessions: Array<ICalendarSession> = [];
	for (const session of sessionsData) {
		if (!session.startDate || !session.endDate) {
			continue;
		}

		const hourStart = session.startHour ? DateTime.fromISO(session.startHour).toLocaleString(DateTime.TIME_24_SIMPLE) : null;
		const hourEnd = session.endHour ? DateTime.fromISO(session.endHour).toLocaleString(DateTime.TIME_24_SIMPLE) : null;

		let tagsId: Array<string> | null = null;
		if (session.tags !== null) {
			tagsId = [];
			if (session.tags) {
				for (const tag of session.tags) {
					tagsId?.push(tag.id);
				}
			}
		}
		const start = `${session.startDate} ${session.startHour}`;
		const end = `${session.endDate} ${session.endHour}`;

		sessions.push({
			id: session.id,
			title: session.title ?? undefined,
			start,
			end,
			hasClashes: session.hasClashes,
			hasAnomalies: session.hasAnomalies,
			code: session.code ?? null,
			hourStart,
			hourEnd,
			status: EnumSessionStatus.VALIDATED,
			minDuration: session.minDuration ?? null,
			duration: session.duration ? stringToIsoDuration(session.duration) : undefined,
			backgroundColor: session.style?.backgroundColor ?? SchedulerConfiguration.defaultSessionColor.backgroundColor,
			borderColor: session.style?.borderColor ?? SchedulerConfiguration.defaultSessionColor.borderColor,
			textColor: session.style?.fontColor ?? SchedulerConfiguration.defaultSessionColor.textColor,
			tagsId,
			resourceId: session.roomId ?? undefined,
			roomId: session.roomId ?? null,
			timezone: session.timeZone ?? null,
		});
	}

	return sessions;
};

function getUnplannedSessionDatesFilter(values: Array<TAutocompleteMultiChipItem> | Array<string>): Array<string> {
	const datesFilter: Array<string> = [];
	values.map((value) => {
		if (!(value === null || typeof value !== "string")) {
			datesFilter.push(
				`(${inArraySieveString(
					filtersUnplannedSessionsObject[EFilterUnplannedSessions.DATE].sieveKey,
					[DateTime.fromISO(value).toFormat("yyyy-MM-dd")],
					FilteringOperator.GreaterThanOrEqualTo,
				)},${inArraySieveString(
					filtersUnplannedSessionsObject[EFilterUnplannedSessions.DATE].sieveKey,
					[DateTime.fromISO(value).plus({ days: 1 }).toFormat("yyyy-MM-dd")],
					FilteringOperator.LesserThan,
				)})`,
			);
		}
	});
	return datesFilter;
}

const getUnplannedSessions = async (
	clientId: string,
	eventId: string,
	search: string,
	advancedFilter: TFilterUnplannedSessionsForm,
): Promise<Array<ICalendarSession>> => {
	let filterSieveString =
		search && search !== ""
			? `${FilteringOperator.And}(` +
				`code${FilteringOperator.CaseInsensitiveContain}${escapeSieveString(search)}${FilteringOperator.Or}title${FilteringOperator.CaseInsensitiveContain}${escapeSieveString(search)})`
			: "";
	// Remap Items autocompletes to sieve query
	const searchFilterAdvanced: Array<string> = [];
	for (const filterKey in advancedFilter) {
		if (filterKey === "isPublished" && advancedFilter[filterKey] !== null) {
			searchFilterAdvanced.push(`(${inArraySieveString(filtersUnplannedSessionsObject[filterKey].sieveKey, [`${advancedFilter[filterKey]}`])})`);
			continue;
		}

		if ((filterKey !== "rooms" && filterKey !== "sessionStatus" && filterKey !== "dates" && filterKey !== "tags") || filterKey === "tags") {
			continue;
		}
		if (advancedFilter[filterKey].length > 0) {
			// #MYK4-6708 - Filter by date (fullDay instead of absolute equality)
			if (filterKey === EFilterUnplannedSessions.DATE && advancedFilter[filterKey].length > 0) {
				searchFilterAdvanced.push(getUnplannedSessionDatesFilter(advancedFilter[EFilterUnplannedSessions.DATE]).join(FilteringOperator.Or));
				continue;
			}

			searchFilterAdvanced.push(
				`(${inArraySieveString(
					filtersUnplannedSessionsObject[filterKey].sieveKey,
					advancedFilter[filterKey].map((value) => (typeof value === "string" ? value : value.key)),
				)})`,
			);
		}
	}

	// Remap Dynamic tags type autocompletes to sieve

	// Merge tags type Ids selected in a array string
	const mappedTags: Array<string> =
		advancedFilter.tags?.reduce((acc: Array<string>, curr) => {
			if (curr && curr.length > 0) {
				acc = acc.concat(curr.map((value) => (typeof value === "string" ? value : value.key)));
			}

			return acc;
		}, []) ?? [];

	// Change array strings to sieve query
	if (mappedTags.length > 0) {
		searchFilterAdvanced.push(`(${inArraySieveString(filtersUnplannedSessionsObject.tags.sieveKey, mappedTags)})`);
	}

	if (searchFilterAdvanced.length > 0) {
		filterSieveString += FilteringOperator.And + searchFilterAdvanced.join(FilteringOperator.And);
	}

	const sessionsData: Array<DtoCalendarGetSession> = await Services.Events.Programme.CalendarService.getAllSessions(clientId, eventId, [
		...queryFilters(`isplanned${FilteringOperator.Equal}false${FilteringOperator.And}status${FilteringOperator.NotEqual}cancelled${filterSieveString}`),
	]);

	const sessions: Array<ICalendarSession> = [];
	for (const session of sessionsData) {
		const hourStart = session.startHour ? DateTime.fromISO(session.startHour).toLocaleString(DateTime.TIME_24_SIMPLE) : null;

		const hourEnd = session.endHour ? DateTime.fromISO(session.endHour).toLocaleString(DateTime.TIME_24_SIMPLE) : null;

		let tagsId: Array<string> | null = null;
		if (session.tags) {
			tagsId = [];
			for (const tag of session.tags) {
				tagsId?.push(tag.id);
			}
		}

		sessions.push({
			id: session.id,
			title: session.title ?? undefined,
			start: `${session.startDate} ${session.startHour}`,
			end: `${session.endDate} ${session.endHour}`,
			hasClashes: session.hasClashes,
			hasAnomalies: session.hasAnomalies,
			code: session.code ?? null,
			hourStart,
			hourEnd,
			status: EnumSessionStatus.VALIDATED,
			minDuration: session.minDuration ?? null,
			duration: session.duration ? isoDurationToString(session.duration) : undefined,
			backgroundColor: session.style?.backgroundColor ?? undefined,
			borderColor: session.style?.borderColor ?? undefined,
			textColor: session.style?.fontColor ?? undefined,
			tagsId,
			resourceId: session.roomId ?? undefined,
			roomId: null,
			timezone: session.timeZone ?? null,
		});
	}

	return sessions;
};

const getFacultySessions = async (
	clientId: string,
	eventId: string,
	participantId: string,
	isSpeakerBusySessionTimeSlot: boolean,
	isReadOnly?: boolean,
): Promise<Array<ICalendarSession>> => {
	const facultySessions: Array<DtoCalendarGetFaculty> = await Services.Events.Programme.CalendarService.getListFaculties(clientId, eventId, participantId, [
		...queryFilters(`isplanned${FilteringOperator.Equal}false`),
	]);

	const details: DtoFacultyGetDetails = await Services.Events.Programme.FacultiesService.getDetails(clientId, eventId, participantId);

	const sessions: Array<ICalendarSession> = [];
	for (const session of facultySessions) {
		let invitationStatusAccepted = false;
		const sessionDetails = details.sessions?.find((detail) => detail.id === session.id);
		const chair = sessionDetails?.chairs?.find((chair) => chair.participantId === participantId);
		if (chair?.invitationStatus === EnumParticipantStatusInvitation.ACCEPTED) {
			invitationStatusAccepted = true;
		}
		const speakers = sessionDetails?.presentations?.map((presentation) => presentation.speakers?.find((speaker) => speaker.participantId === participantId));
		for (const speaker of speakers ?? []) {
			if (speaker?.invitationStatus === EnumParticipantStatusInvitation.ACCEPTED) {
				invitationStatusAccepted = true;
			}
		}

		let hourStart = session.startHour ? DateTime.fromISO(session.startHour).toLocaleString(DateTime.TIME_24_SIMPLE) : null;
		let hourEnd = session.endHour ? DateTime.fromISO(session.endHour).toLocaleString(DateTime.TIME_24_SIMPLE) : null;

		let id = session.id;
		let code = session.code ?? null;
		let title = session.title;
		let timezone = session.timeZone ?? null;
		let hasClashes = session.hasClashes;
		let hasAnomalies = session.hasAnomalies;

		let start = `${session.startDate} ${session.startHour}`;
		let end = `${session.endDate} ${session.endHour}`;

		// if 'isSpeakerBusySessionTimeSlot' is not 'on' on settings we display presentation information instead of session
		if (
			!isSpeakerBusySessionTimeSlot &&
			session.presentations &&
			session.presentations.length > 0 &&
			(session.presentations[0].startHour || session.presentations[0].endHour)
		) {
			hourStart = session.presentations[0].startHour ? DateTime.fromISO(session.presentations[0].startHour).toLocaleString(DateTime.TIME_24_SIMPLE) : null;

			hourEnd = session.presentations[0].endHour ? DateTime.fromISO(session.presentations[0].endHour).toLocaleString(DateTime.TIME_24_SIMPLE) : null;

			start = `${session.presentations[0].startDate} ${session.presentations[0].startHour}`;
			end = `${session.presentations[0].endDate} ${session.presentations[0].endHour}`;
			id = session.presentations[0].id;
			code = session.presentations[0].code ?? null;
			title = session.presentations[0].title;
			timezone = session.presentations[0].timeZone ?? null;
			hasClashes = session.presentations[0].hasClashes;
			hasAnomalies = session.presentations[0].hasAnomalies;
		}

		sessions.push({
			id,
			title: title ?? undefined,
			start,
			end,
			hasClashes,
			hasAnomalies,
			code,
			hourStart,
			hourEnd,
			status: session.status ?? EnumSessionStatus.VALIDATED,
			minDuration: null,
			backgroundColor: session.style?.backgroundColor ?? undefined,
			borderColor: session.style?.borderColor ?? undefined,
			textColor: session.style?.fontColor ?? undefined,
			tagsId: null,
			resourceId: session.roomId ?? undefined,
			roomId: session.roomId ?? null,
			timezone,
			invitationStatusAccepted,
			isReadOnly,
		});
	}

	return sessions;
};

// #region 'PUT'
const putSession = async (clientId: string, eventId: string, sessionId: string, session: TSessionCalendar): Promise<null> => {
	await Services.Events.Programme.CalendarService.putSession(clientId, eventId, sessionId, session);
	return null;
};

const CalendarController = {
	getCalendarSettings,
	getSessionsByRoom,
	getUnplannedSessions,
	putSession,
	getFacultySessions,
};

export default CalendarController;
