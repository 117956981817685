import { ApplicationColors } from "@key4-front-library/core";
import { HEXADECIMAL_COLOR_REGEX, integerZod } from "@mykey4/core";
import { t } from "i18next";
import { z } from "zod";

export interface SessionTagForm {
	id?: string;
	label: string;
	backgroundColor?: string;
	fontColor?: string;
	borderColor?: string;
	order?: string;
	createAnother?: boolean;
}

export const sessionTagModalFormDefaultValues: SessionTagForm = {
	id: "",
	label: "",
	backgroundColor: ApplicationColors.greyStoneLight.main,
	fontColor: ApplicationColors.black.main,
	borderColor: ApplicationColors.greyStoneLight.main,
	order: "",
	createAnother: false,
};

export const sessionTagSchema = () =>
	z.object({
		id: z.string().or(z.literal("")),
		label: z.string({ required_error: t("form.validation.required") }).min(1, t("form.validation.required")),
		backgroundColor: z.string({ required_error: t("form.validation.required") }).regex(HEXADECIMAL_COLOR_REGEX, t("form.validation.color")),
		fontColor: z.string({ required_error: t("form.validation.required") }).regex(HEXADECIMAL_COLOR_REGEX, t("form.validation.color")),
		borderColor: z.string({ required_error: t("form.validation.required") }).regex(HEXADECIMAL_COLOR_REGEX, t("form.validation.color")),
		order: integerZod().or(z.literal("")),
		createAnother: z.boolean().optional(),
	});
