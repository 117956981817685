import type { AbstractRead } from "@api";
import { useContextModule } from "@key4-front-library/core";
import { type PageType, getPath } from "@routes";
import { useCallback } from "react";

export interface UseOpenWindowReturn {
	open: (type: PageType, sessionId?: string) => void;
	openSession: (sessionId: string) => void;
	openSessionPresentations: (sessionId: string) => void;
	openAbstract(abstract: AbstractRead): void;
}

export const useOpenWindow = (): UseOpenWindowReturn => {
	const { client, event } = useContextModule();

	const openPath = useCallback((path: string) => {
		window.open(`${window.location.origin}${path}`, "_blank", "noopener,noreferrer");
	}, []);

	const open = useCallback(
		(type: PageType, sessionId?: string) => {
			const path = getPath(type, [client.key, event.key, sessionId ?? ""]);
			openPath(path);
		},
		[client.key, event.key, openPath],
	);

	const openSession = useCallback((sessionId?: string) => open("sessionDetails", sessionId), [open]);
	const openSessionPresentations = useCallback((sessionId?: string) => open("sessionDetailsPresentations", sessionId), [open]);
	const openAbstract = useCallback(
		(abstract: AbstractRead) => window.open(abstract.url, abstract.title, "width=900,height=800,resizable=yes,scrollbars=yes"),
		[],
	);

	return {
		open,
		openSession,
		openSessionPresentations,
		openAbstract,
	};
};
