import { useDashboardInvitationStatus } from "@api";
import { useContextModule } from "@key4-front-library/core";
import { useTheme } from "@mui/material";
import type { RegularBreakpoints } from "@mui/material/Grid/Grid";
import { ActivityInvitationStatusTypes, getActivityStatus } from "@mykey4/core";
import { t } from "i18next";
import DashboardDonutChart from "../Common/DashboardDonutChart";

type Props = RegularBreakpoints & {};

const DashboardInvitationStatus = (props: Props) => {
	const { client, event } = useContextModule();
	const theme = useTheme();
	const configuration = getActivityStatus(theme);

	const statusStatistics = useDashboardInvitationStatus({
		clientId: client.id,
		eventId: event.id,
		select: (data) => {
			return ActivityInvitationStatusTypes.map((status) => {
				const config = configuration[status];
				return {
					name: config.name,
					value: data[status],
					color: config.color,
				};
			});
		},
	});

	return (
		<DashboardDonutChart
			{...props}
			title={t("old.programme.dashboard.charts.donuts.invitations.title")}
			statistics={statusStatistics.data ?? []}
			isLoading={statusStatistics.isLoading}
		/>
	);
};

export default DashboardInvitationStatus;
