import { SessionInvitation } from "@application/components/SessionInvitation/SessionInvitation";
import { ModuleSpace } from "@components";
import { SettingsProvider } from "@infrastructure/contexts/settingsContext";
import {
	CFEditorCustomField,
	CustomFieldDefinition,
	EMenuPath,
	ErrorTemplateOld,
	LegalNotice,
	MAIN_TAB,
	NotificationProvider,
	OutletModule,
	Redirection,
	TermsOfSale,
	UsersPage,
} from "@key4-front-library/core";
import { EnumCustomFieldScope } from "@key4-front-library/core/Enums";
import ProvidersCore from "@key4-front-library/core/Providers";
import {
	Abstracts,
	AbstractsBulkAction,
	AdvancedSettings,
	Anomalies,
	ChairsTypes,
	Clashes,
	Dashboard,
	Emailing,
	MailTemplateTab,
	Numbering,
	ProgrammeExport,
	SessionTemplates,
	SpeakersTypes,
	Tags,
} from "@pages";
import { SessionDetailsRoot, SessionGeneralTab, SessionPresentationsTab } from "@pages";
import { Alerts } from "@presentation/pages/alerts/Alerts";
import AlertsAnomalies from "@presentation/pages/alerts/anomalies/AlertsAnomalies";
import AlertsClashes from "@presentation/pages/alerts/clashes/AlertsClashes";
import BulkActionFaculty from "@presentation/pages/faculties/BulkAction/BulkActionFaculty";
import { Faculties } from "@presentation/pages/faculties/Faculties";
import { FacultyCalendar } from "@presentation/pages/faculties/FacultiesCalendar";
import { MatchingDetails } from "@presentation/pages/faculties/MatchingDetails/MatchingDetails";
import { MatchingList } from "@presentation/pages/faculties/MatchingList";
import RoomDetails from "@presentation/pages/rooms/RoomDetails";
import { Rooms } from "@presentation/pages/rooms/Rooms";
import RoomAnomalies from "@presentation/pages/rooms/roomDetails/anomalies/RoomAnomalies";
import RoomClashes from "@presentation/pages/rooms/roomDetails/clashes/RoomClashes";
import { Scheduler } from "@presentation/pages/scheduler/Scheduler";
import BulkActionSession from "@presentation/pages/sessions/BulkAction/BulkActionSession";
import PageListSessions from "@presentation/pages/sessions/PageListSessions";
import SessionAnomalies from "@presentation/pages/sessions/SessionDetails/anomalies/SessionAnomalies";
import SessionClashes from "@presentation/pages/sessions/SessionDetails/clashes/SessionClashes";
import { InvitationSettings } from "@presentation/pages/settings/Invitations/InvitationSettings";
import { Settings } from "@presentation/pages/settings/Settings";
import SettingsDocumentsManager from "@presentation/pages/settings/documentsManager/SettingsDocumentsManager";
import SettingsKeywordsManager from "@presentation/pages/settings/keywordsManager/SettingsKeywordsManager";
import SettingsScheduler from "@presentation/pages/settings/scheduler/SettingsScheduler";
import TabSessionsViewTemplateEditor from "@presentation/pages/settings/sessionsViewTemplateEditor/TabSessionsViewTemplateEditor";
import { Navigate } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import SessionChairsTab from "../Pages/Sessions/SessionDetails/Tabs/SessionChairsTab/SessionChairsTab";

const routes: Array<RouteObject> = [
	{
		path: EMenuPath.ROOT,
		children: [
			{
				path: EMenuPath.EMPTY,
				element: (
					<ProvidersCore.ProviderModule>
						<Redirection />
					</ProvidersCore.ProviderModule>
				),
			},
			{
				path: EMenuPath.AUTH,
				children: [
					{
						path: EMenuPath.LOGIN,
						element: <Navigate replace to={localStorage.getItem("redirectUri") ?? EMenuPath.EMPTY} />,
					},
					{
						path: EMenuPath.LOGOUT,
						element: <Navigate replace to={EMenuPath.ROOT} />,
					},
				],
			},
			{
				path: EMenuPath.TERMS_OF_SALE,
				element: <TermsOfSale />,
			},
			{
				path: EMenuPath.LEGAL_NOTICE,
				element: <LegalNotice />,
			},
			{
				path: EMenuPath.FORBIDDEN,
				element: (
					<ProvidersCore.ProviderModule>
						<ErrorTemplateOld code={403} />
					</ProvidersCore.ProviderModule>
				),
			},
			{
				path: EMenuPath.CLIENT,
				children: [
					{
						path: EMenuPath.EVENT,
						element: (
							<ProvidersCore.ProviderModule>
								<OutletModule />
							</ProvidersCore.ProviderModule>
						),
						children: [
							{
								element: (
									<NotificationProvider>
										<ModuleSpace />
									</NotificationProvider>
								),
								children: [
									{
										path: EMenuPath.EMPTY,
										element: <Navigate replace to={EMenuPath.DASHBOARD} />,
									},
									{
										path: EMenuPath.DASHBOARD,
										element: <Dashboard />,
									},
									{
										path: EMenuPath.SCHEDULER,
										element: <Scheduler />,
									},
									{
										path: EMenuPath.SESSIONS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: (
													<CustomFieldDefinition scope={[EnumCustomFieldScope.SESSION, EnumCustomFieldScope.PARTICIPANT, EnumCustomFieldScope.CHAIR]}>
														<PageListSessions />
													</CustomFieldDefinition>
												),
											},
											{
												path: EMenuPath.SESSION,
												element: <SessionDetailsRoot />,
												children: [
													{
														path: "",
														element: <Navigate replace to={MAIN_TAB} />,
													},
													{
														path: "*",
														element: <Navigate replace to={MAIN_TAB} />,
													},
													{
														path: MAIN_TAB,
														element: <SessionGeneralTab />,
													},
													{
														path: "chairs",
														element: <SessionChairsTab />,
													},
													{
														path: "presentations",
														element: <SessionPresentationsTab />,
													},
													{
														path: "sessionInvitation",
														element: <SessionInvitation />,
													},
													{
														path: "clashes",
														element: <SessionClashes />,
													},
													{
														path: "anomalies",
														element: <SessionAnomalies />,
													},
												],
											},
											{
												path: EMenuPath.BULKACTION,
												children: [
													{
														path: EMenuPath.STEP,
														element: <BulkActionSession />,
													},
												],
											},
										],
									},
									{
										path: EMenuPath.FACULTIES,
										children: [
											{
												path: EMenuPath.BULKACTION,
												children: [
													{
														path: EMenuPath.STEP,
														element: <BulkActionFaculty />,
													},
												],
											},
											{
												path: EMenuPath.EMPTY,
												element: (
													<CustomFieldDefinition scope={[EnumCustomFieldScope.PARTICIPANT]}>
														<Faculties />
													</CustomFieldDefinition>
												),
											},
											{
												path: "matching",
												children: [
													{
														path: EMenuPath.EMPTY,
														element: <MatchingList />,
													},
													{
														path: ":matchingId",
														element: <MatchingDetails />,
													},
												],
											},
											{
												path: EMenuPath.CALENDAR,
												element: <FacultyCalendar />,
											},
										],
									},
									{
										path: EMenuPath.ROOMS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: <Rooms />,
											},
											{
												path: EMenuPath.ROOM,
												children: [
													{
														path: EMenuPath.EMPTY,
														element: <RoomDetails />,
														children: [
															{
																path: EMenuPath.CLASHES,
																element: <RoomClashes />,
															},
															{
																path: EMenuPath.ANOMALIES,
																element: <RoomAnomalies />,
															},
														],
													},
												],
											},
										],
									},
									{
										path: EMenuPath.ALERTS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: <Alerts />,
												children: [
													{
														path: EMenuPath.CLASHES,
														element: <AlertsClashes />,
													},
													{
														path: EMenuPath.ANOMALIES,
														element: <AlertsAnomalies />,
													},
												],
											},
										],
									},
									{
										path: EMenuPath.SETTINGS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: (
													<SettingsProvider>
														<Settings />
													</SettingsProvider>
												),
												children: [
													{
														path: EMenuPath.SCHEDULER,
														element: <SettingsScheduler />,
													},
													{
														path: EMenuPath.INVITATIONS,
														element: <InvitationSettings />,
													},
													{
														path: EMenuPath.MAIL_TEMPLATES,
														element: <MailTemplateTab />,
													},
												],
											},
										],
									},
									{
										path: "advancedSettings",
										children: [
											{
												path: EMenuPath.EMPTY,
												element: (
													<SettingsProvider>
														<AdvancedSettings />
													</SettingsProvider>
												),
												children: [
													{
														path: EMenuPath.TAGS,
														element: <Tags />,
													},
													{
														path: EMenuPath.CHAIRS_TYPES,
														element: <ChairsTypes />,
													},
													{
														path: EMenuPath.SPEAKERS_TYPES,
														element: <SpeakersTypes />,
													},
													{
														path: EMenuPath.CLASHES,
														element: <Clashes />,
													},
													{
														path: EMenuPath.SESSION_TEMPLATES,
														element: <SessionTemplates />,
													},
													{
														path: EMenuPath.NUMBERING,
														element: <Numbering />,
													},
													{
														path: EMenuPath.ANOMALIES,
														element: <Anomalies />,
													},
													{
														path: EMenuPath.PROGRAMME_EXPORT,
														element: <ProgrammeExport />,
													},
													{
														path: EMenuPath.EMAILING,
														element: <Emailing />,
													},
												],
											},
										],
									},
									{
										path: EMenuPath.USERS,
										element: <UsersPage />,
									},
									{
										path: EMenuPath.ABSTRACTS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: <Abstracts />,
											},
											{
												path: EMenuPath.BULKACTION,
												element: <AbstractsBulkAction />,
											},
										],
									},
									{
										path: EMenuPath.SESSION_VIEW_TEMPLATES_EDITOR,
										element: <TabSessionsViewTemplateEditor />,
									},
									{
										path: EMenuPath.KEYWORDS_MANAGER,
										element: <SettingsKeywordsManager />,
									},
									{
										path: EMenuPath.DOCUMENTS_MANAGER,
										element: <SettingsDocumentsManager />,
									},
									{
										path: EMenuPath.FORM_EDITOR,
										element: <CFEditorCustomField />,
									},
								],
							},
						],
					},
					{
						path: EMenuPath.EMPTY,
						element: (
							<ProvidersCore.ProviderModule>
								<ErrorTemplateOld code={404} />
							</ProvidersCore.ProviderModule>
						),
					},
				],
			},
		],
	},
	{
		path: EMenuPath.WILDCARD,
		element: (
			<ProvidersCore.ProviderModule>
				<ErrorTemplateOld code={404} />
			</ProvidersCore.ProviderModule>
		),
	},
	{
		path: EMenuPath.WILDCARD,
		element: (
			<ProvidersCore.ProviderModule>
				<ErrorTemplateOld code={404} />
			</ProvidersCore.ProviderModule>
		),
	},
];

export { routes };
