import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { getSessionPublicationStatusConfiguration } from "./configuration";
import type { SessionPublicationStatusConfigurationKey } from "./type";

export const useSessionPublicationStatusConfigurations = () => {
	const theme = useTheme();
	return useMemo(() => getSessionPublicationStatusConfiguration(theme), [theme]);
};

export interface UseSessionPublicationStatusConfigurationArgs {
	status: SessionPublicationStatusConfigurationKey | boolean;
}

export const useSessionPublicationStatusConfiguration = (args: UseSessionPublicationStatusConfigurationArgs) => {
	const { status } = args;
	const configurations = useSessionPublicationStatusConfigurations();
	return useMemo(() => {
		const key = typeof status === "boolean" ? (status ? "published" : "unpublished") : status;
		return configurations[key];
	}, [configurations, status]);
};
