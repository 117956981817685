import type { EntityPath, PageType } from "@routes";

export const getPath = (type: PageType, keys?: Array<string>): string => generatePath(getPathEntities(type, keys));
export const getExternalPath = (externalBaseUrl: string, type: PageType, keys?: Array<string>): string =>
	generatePath(getPathEntities(type, keys), externalBaseUrl);

const generatePath = (pathEntities: Array<EntityPath>, externalBaseUrl?: string): string => {
	let path = pathEntities
		.map((entity) => {
			if (entity.entity) {
				return entity.id ? `${entity.entity}/${entity.id}` : entity.entity;
			}
			return entity.id ? entity.id : "";
		})
		.join("/");

	if (path.endsWith("/")) {
		path = path.slice(0, -1);
	}

	if (!externalBaseUrl) {
		return `/${path}`;
	}

	const separator = externalBaseUrl.endsWith("/") ? "" : "/";
	return `${externalBaseUrl}${separator}${path}`;
};

const getPathEntities = (type: PageType, keys?: Array<string>): Array<EntityPath> => {
	const commonEntities = [{ id: keys?.[0] ?? ":clientKey" }, { id: keys?.[1] ?? ":eventKey" }];
	switch (type) {
		case "sessions":
			return [...commonEntities, { entity: "sessions" }];
		case "faculties":
			return [...commonEntities, { entity: "faculties" }];
		case "scheduler":
			return [...commonEntities, { entity: "scheduler" }];
		case "advancedSettings":
			return [...commonEntities, { entity: "advancedSettings" }];
		case "sessionDetails":
			return [...commonEntities, { entity: "sessions", id: keys?.[2] ?? ":sessionId" }];
		case "sessionDetailsPresentations":
			return [...commonEntities, { entity: "sessions", id: keys?.[2] ?? ":sessionId" }, { entity: "presentations" }];
		case "bulkActionAbstract":
			return [...commonEntities, { entity: "abstracts" }, { entity: "bulkAction", id: keys?.[2] }];
		case "alertsClashes":
			return [...commonEntities, { entity: "alerts" }, { entity: "clashes" }];
		case "alertsAnomalies":
			return [...commonEntities, { entity: "alerts" }, { entity: "anomalies" }];
		case "participants":
			return [...commonEntities, { entity: "participants" }];
		case "participantDetails":
			return [...commonEntities, { entity: "participants", id: keys?.[2] ?? ":participantId" }];
	}
};
