import type { GridColDef } from "@mui/x-data-grid-pro";
import { getColumnAction } from "@mykey4/core";

export const invitationColDef: GridColDef[] = [
	{
		field: "key",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "lastname",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "firstname",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "email",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{ ...getColumnAction(), minWidth: 40 },
];
