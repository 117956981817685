import type { RoomForm } from "@domain/model/room.model";
import { integerZod, stringRequiredZod } from "@mykey4/core";
import { z } from "zod";

export const roomDefaultValues: RoomForm = {
	id: "",
	name: "",
	order: "",
	capacity: "",
};

export const roomSchema = z.object({
	id: z.string().or(z.literal("")),
	name: stringRequiredZod(),
	order: integerZod().or(z.literal("")),
	capacity: integerZod().min(1).or(z.literal("")),
});
