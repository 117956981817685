import type { ICalendarSession } from "@domain/interfaces/calendar.interface";
import { IconOld } from "@key4-front-library/core";
import { Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";

export interface SchedulerCardProps {
	session: ICalendarSession;
}

export const SchedulerCard = (props: SchedulerCardProps) => {
	const { session } = props;
	const theme = useTheme();
	return (
		<Stack>
			<Stack direction={"row"}>
				{(session.hourStart || session.hourEnd) && (
					<Stack>
						<Typography color={"inherit"} fontSize={"inherit"}>
							{`${session.hourStart} - ${session.hourEnd}`}
						</Typography>
					</Stack>
				)}
				{/* TODO Waiting API change to add icons status/hasClashes/hasAnomalies */}
				<Stack sx={{ marginLeft: "auto" }} direction={"row"} spacing={".5em"}>
					{session.hasAnomalies && <IconOld size="lg" color={theme.palette.warning.main} prefix="fas" iconName={"triangle-exclamation"} />}
					{session.hasClashes && <IconOld size="lg" color={theme.palette.error.main} prefix="fas" iconName={"calendar-circle-exclamation"} />}
				</Stack>
			</Stack>
			<Stack pt={".3em"} alignItems={"flex-start"}>
				<Typography color={"inherit"} fontSize={"inherit"} fontWeight={"bold"} align={"left"}>
					{session.title && session.title !== "undefined" ? session.title : ""}
				</Typography>
			</Stack>
			<Stack pt={".3em"} alignItems={"flex-start"}>
				<Typography color={"inherit"} fontSize={"inherit"}>
					{session.code}
				</Typography>
			</Stack>
		</Stack>
	);
};
