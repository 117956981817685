import SettingsController from "@application/Controllers/SettingsController";
import type { ISettingsSchedulerEditType } from "@application/components/settings/scheduler/scheduler/SettingsSchedulerEdit";
import SettingsSchedulerEdit from "@application/components/settings/scheduler/scheduler/SettingsSchedulerEdit";
import SettingsSchedulerSkeleton from "@application/components/settings/scheduler/scheduler/SettingsSchedulerSkeleton";
import { IconItemReference } from "@application/enum/IconItemReference.enum";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import GetTranslationsForSettingsScheduler from "@application/helpers/translations/settings/GetTranslationsForSettingsScheduler";
import type { ISettingsSchedulerObject } from "@domain/interfaces/settings.scheduler.interface";
import IconCardItem from "@infrastructure/components/interface/card/IconCardItem";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import { useSettingsContext } from "@infrastructure/contexts/settingsContext";
import { Services, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import type { DtoEvent } from "@key4-front-library/core/Dto";
import { Grid } from "@mui/material";
import type { IconName } from "@mykey4/core";
import { Cultures, useUser } from "@mykey4/core";
import { t } from "i18next";
import { Fragment, useCallback, useEffect, useState } from "react";

const SettingsScheduler = () => {
	const component = "settingsScheduler";
	const { client, event } = useContextModule();
	const { settingsContext } = useSettingsContext();

	const { sendSuccess, sendError } = useSnackBarHook();

	const translations = GetTranslationsForSettingsScheduler();

	const { user } = useUser();
	const culture = Cultures[user.locale].locale;

	const [isLoadingSettingsScheduler, setIsLoadingSettingsScheduler] = useState<boolean>(true);

	const [settingsSchedulerData, setSettingsSchedulerData] = useState<ISettingsSchedulerObject>();
	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const handleEditModaleOpen = (): void => {
		setModaleOpenState(true);
	};
	const handleEditModaleClose = (): void => {
		setModaleOpenState(false);
	};

	const schedulerIconName = settingsContext?.icons.find((module) => module.reference === IconItemReference.SCHEDULER)?.icon;

	const fetchSchedulerSettings = useCallback(
		async (event: DtoEvent) => {
			await Services.Events.Programme.SettingsService.getScheduler(client.id, event.id)
				.then((_data: any) => {
					const settingsSchedulerObject = SettingsFactory.TransformSettingsSchedulerDataForReading({
						settings: _data,
						culture,
					});
					setSettingsSchedulerData(settingsSchedulerObject);
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				})
				.finally(() => {
					setIsLoadingSettingsScheduler(false);
				});
		},
		[event, culture],
	);

	useEffect(() => {
		setIsLoadingSettingsScheduler(true);
		fetchSchedulerSettings(event);
	}, [event]);

	const handleSaveSchedulerSettings = async (form: ISettingsSchedulerEditType) => {
		try {
			if (await SettingsController.updateSettingScheduler(client.id, event.id, form)) {
				await fetchSchedulerSettings(event);
				sendSuccess(t(`${component}.success`));
			} else {
				sendError(t("old.common.errors.generic"));
			}
		} catch {
			sendError(t("old.common.errors.generic"));
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{!isLoadingSettingsScheduler ? (
					settingsSchedulerData && (
						<Fragment>
							<IconCard
								title={t(`${component}.title`)}
								icon={schedulerIconName as IconName}
								button={{
									label: t("old.form.buttons.edit"),
									icon: "pen-to-square" as IconName,
									onClick: handleEditModaleOpen,
								}}
							>
								<Grid container spacing={3}>
									<IconCardItem label={t(`${component}.startDate`)} value={settingsSchedulerData.formatedValues.programmeStartDate} />
									<IconCardItem label={t(`${component}.startTime`)} value={settingsSchedulerData.formatedValues.schedulerStartHour} />
									<IconCardItem
										label={t(`${component}.dayTimeSlot`)}
										value={`${settingsSchedulerData.time.interval} ${(settingsSchedulerData.time.interval ?? 0 > 1) ? translations.unities.hours : translations.unities.hour}`}
									/>
									<IconCardItem label={t(`${component}.endDate`)} value={settingsSchedulerData.formatedValues.programmeEndDate} />
									<IconCardItem label={t(`${component}.endTime`)} value={settingsSchedulerData.formatedValues.schedulerEndHour} />
									<IconCardItem
										label={t(`${component}.programmeDuration`)}
										value={`${settingsSchedulerData.dates.interval} ${(settingsSchedulerData.dates.interval ?? 0 > 1) ? translations.unities.days : translations.unities.day}`}
									/>
								</Grid>
							</IconCard>
							<DefaultModal open={modaleOpenState} title={t(`${component}.title`)}>
								<SettingsSchedulerEdit
									settings={settingsSchedulerData}
									culture={culture}
									onSave={handleSaveSchedulerSettings}
									handleModaleClose={handleEditModaleClose}
								/>
							</DefaultModal>
						</Fragment>
					)
				) : (
					<SettingsSchedulerSkeleton />
				)}
			</Grid>
		</Grid>
	);
};

export default SettingsScheduler;
