import type { AbstractRead, PresentationRead, SessionRead } from "@api";
import { Stack } from "@mui/system";
import { useCallback } from "react";
import { useAbstractDissociation } from "./AbstractsDissociation.hook";
import type { AbstractDissociationResults } from "./AbstractsDissociation.type";
import { AbstractDissociationContainer } from "./components";
import { AbstractDissociationSwitch } from "./components/AbstractDissociationSwitch";

export interface AbstractDissociationProps {
	sessions?: SessionRead[];
	presentations?: PresentationRead[];
	abstracts?: AbstractRead[];
	onDissociationChange?: (associations: AbstractDissociationResults) => void;
	onSessionKeyClick?: (session: SessionRead) => void;
	onPresentationKeyClick?: (presentation: PresentationRead) => void;
	onAbstractKeyClick?: (abstract: AbstractRead) => void;
}

export const AbstractsDissociation = (props: AbstractDissociationProps) => {
	const { sessions, presentations, abstracts, onSessionKeyClick, onPresentationKeyClick, onAbstractKeyClick } = props;

	const { items, dissociateAllChange, dissociateAbstractChange, presentationDissociationChange } = useAbstractDissociation(props);

	const checked = items.some((abstract) => abstract.presentations.values().some((item) => item.dissociate));
	const canRenderSwitch = items.some((abstract) => abstract.presentations.values().toArray().length > 0);

	const handleSessionKeyClick = useCallback(
		(sessionId: string) => {
			const session = sessions?.find((p) => p.id === sessionId);
			if (session) {
				onSessionKeyClick?.(session);
			}
		},
		[sessions, onSessionKeyClick],
	);

	const handlePresentationKeyClick = useCallback(
		(presentationId: string) => {
			const presentation = presentations?.find((p) => p.id === presentationId);
			if (presentation) {
				onPresentationKeyClick?.(presentation);
			}
		},
		[presentations, onPresentationKeyClick],
	);

	const handleAbstractKeyClick = useCallback(
		(abstractId: string) => {
			const abstract = abstracts?.find((p) => p.id === abstractId);
			if (abstract) {
				onAbstractKeyClick?.(abstract);
			}
		},
		[abstracts, onAbstractKeyClick],
	);

	return (
		<Stack direction="column" spacing={1}>
			{canRenderSwitch && (
				<Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ width: "100%" }} paddingRight={2} paddingY={1} paddingLeft={1}>
					<AbstractDissociationSwitch checked={checked} onCheckedChange={dissociateAllChange} />
				</Stack>
			)}
			{items.map((abstract) => (
				<AbstractDissociationContainer
					key={abstract.id}
					abstract={abstract}
					onDissociateAllChange={dissociateAbstractChange}
					onDissociatePresentationChange={presentationDissociationChange}
					onSessionKeyClick={handleSessionKeyClick}
					onPresentationKeyClick={handlePresentationKeyClick}
					onAbstractKeyClick={handleAbstractKeyClick}
				/>
			))}
		</Stack>
	);
};
