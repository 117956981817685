import { MenuItems } from "@application/Configurations/menu.configuration";
import { HeaderNotifications, getQuickAccessConfiguration } from "@components";
import { BreadcrumbModule, useContextModule } from "@key4-front-library/core";
import Layout from "@key4-front-library/core/Bo/Layout/Layout";
import {
	Loader,
	PermissionContext,
	QuickAccess,
	RolePermissionContext,
	useIsModuleActivatedOnOperation,
	useModuleApplicationUserPermissions,
	useModuleApplicationUserRole,
} from "@mykey4/core";
import { useMemo } from "react";

export const ModuleSpace = (): React.ReactNode => {
	const { event, client } = useContextModule();

	const rolePermissionsQuery = useModuleApplicationUserRole(client.id, event.id, "programme");
	const permissionsQuery = useModuleApplicationUserPermissions(client.id, event.id, "programme");

	// Keep useMemo to force initial load of the permissions before any render to avoid having 403 on children components/pages
	const rolePermission = useMemo(() => rolePermissionsQuery.data?.type ?? "moduleBasic", [rolePermissionsQuery.data]);
	const permissions = useMemo(() => permissionsQuery.data?.permissions ?? [], [permissionsQuery.data]);
	const quickAccessData = useMemo(() => getQuickAccessConfiguration(client.key, event.key), [client.key, event.key]);

	const isAbstractModuleActivated = useIsModuleActivatedOnOperation(client.id, event.id, "Abstracts");
	if (rolePermissionsQuery.isLoading || permissionsQuery.isLoading || isAbstractModuleActivated.isLoading) {
		return <Loader />;
	}

	const canSeeAdvancedSettings = rolePermission.includes("moduleAdministrator");

	return (
		<RolePermissionContext.Provider value={rolePermission}>
			<PermissionContext.Provider value={permissions}>
				<Layout
					menu={{
						items: [
							MenuItems.dashboard,
							MenuItems.sessions,
							MenuItems.scheduler,
							MenuItems.faculties,
							MenuItems.rooms,
							{ ...MenuItems.abstracts, disabled: !isAbstractModuleActivated.data },
							MenuItems.alerts,
							MenuItems.settings,
							{ ...MenuItems.advancedSettings, disabled: !canSeeAdvancedSettings },
							MenuItems.users,
							MenuItems.divider,
							MenuItems.support,
							MenuItems.divider,
						],
					}}
					breadcrumb={<BreadcrumbModule />}
					notification={<HeaderNotifications />}
					quickAccess={<QuickAccess {...quickAccessData} />}
				/>
			</PermissionContext.Provider>
		</RolePermissionContext.Provider>
	);
};
