import ConfigurationsApp from "@application/Configurations";
import { EnumSessionFieldKey, EnumSessionFormKey, EnumSessionSectionKey } from "@application/Enums/SessionEnum";
import type { SessionStatusConfigurations } from "@configurations";
import type { TSessionDisplay, TSessionUseFormModal } from "@domain/interfaces/session.interface";
import DateService from "@infrastructure/services/dates/date.service";
import {
	CustomFieldHelper,
	type DtoEvent,
	type DtoSession,
	type DtoSettingsScheduler,
	type DtoTagType,
	EnumFormControlKind,
	type ITagTypeModelWithTags,
	type TypeCustomFieldForm,
	type TypeUseFormListForms,
	padStartDigitWith0,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabsOld";
import type { TAutocompleteMultiBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiBulletColor";
import { SessionConfig } from "@key4-front-library/core/Bo/Configurations";
import { DefaultCulture, type SessionStatus, SessionStatusTypes } from "@mykey4/core";
import { t } from "i18next";
import { concat, drop, toPairs } from "lodash";
import { DateTime, Duration } from "luxon";
import type { FieldValues, UseFormGetValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import DateHelper from "./date.helper";

const DATETIME_FIELD_KEYS = [EnumSessionFieldKey.START_DATE, EnumSessionFieldKey.END_DATE, EnumSessionFieldKey.START_HOUR, EnumSessionFieldKey.END_HOUR];

const setDurationValues = (path: string, setValues: UseFormSetValue<FieldValues>, getValues: UseFormGetValues<FieldValues>) => {
	// If duration doesn't exist compute duration
	if (getValues(`${path}.${EnumSessionFieldKey.END_HOUR}`) && getValues(`${path}.${EnumSessionFieldKey.START_HOUR}`)) {
		const dateStart: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumSessionFieldKey.START_DATE}`) ?? DateTime.now().toFormat("yyyy-MM-dd")} ${(
				getValues(`${path}.${EnumSessionFieldKey.START_HOUR}`) as DateTime
			).toFormat("HH:mm")}`,
			"yyyy-MM-dd HH:mm",
		);
		const dateEnd: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumSessionFieldKey.END_DATE}`) ?? DateTime.now().toFormat("yyyy-MM-dd")} ${(getValues(`${path}.${EnumSessionFieldKey.END_HOUR}`) as DateTime).toFormat("HH:mm")}`,
			"yyyy-MM-dd HH:mm",
		);
		const duration: Duration = dateEnd.diff(dateStart, ["hours", "minutes"]);
		if (!Number.isNaN(duration.minutes)) {
			if (duration.minutes > 0 || duration.hours > 0) {
				setValues(
					`${path}.${EnumSessionFieldKey.DURATION}`,
					`${padStartDigitWith0(duration.hours.toString())}:${padStartDigitWith0(duration.minutes.toString())}`,
				);
			} else {
				setValues(`${path}.${EnumSessionFieldKey.DURATION}`, null);
			}
		}
	}
};

const changeDatesOnDuration = (path: string, setValues: UseFormSetValue<FieldValues>, getValues: UseFormGetValues<FieldValues>) => {
	const startDate: string | null = getValues(`${path}.${EnumSessionFieldKey.START_DATE}`);
	const startHour: DateTime | null = getValues(`${path}.${EnumSessionFieldKey.START_HOUR}`);

	const durationValue = getValues(`${path}.${EnumSessionFieldKey.DURATION}`);

	if (startHour && durationValue && startDate !== "Invalid DateTime" && startHour.isValid) {
		const dateStart: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumSessionFieldKey.START_DATE}`) ?? DateTime.now().toFormat("yyyy-MM-dd")} ${(
				getValues(`${path}.${EnumSessionFieldKey.START_HOUR}`) as DateTime
			).toFormat("HH:mm")}`,
			"yyyy-MM-dd HH:mm",
		);

		let duration;

		if (typeof durationValue === "string") {
			duration = Duration.fromISOTime(durationValue);
		} else {
			duration = Duration.fromISOTime(durationValue.key);
		}

		if (duration.isValid) {
			const dateEnd: DateTime = dateStart.plus(duration);
			setValues(`${path}.${EnumSessionFieldKey.END_HOUR}`, dateEnd);
		}
	}
};

const triggerDates = (path: string, trigger: UseFormTrigger<FieldValues>) => {
	DATETIME_FIELD_KEYS.forEach((key: string) => {
		trigger(`${path}.${key}`);
	});
};

/**
 * Transform session or default session to POST or PUT model
 * @param session
 * @param tagTypesWithTagConfig
 * @returns
 */
const getSessionDisplay = (
	session: any = ConfigurationsApp.SessionConfiguration.defaultModel2,
	tagTypesWithTagConfig?: Array<ITagTypeModelWithTags>,
): TSessionDisplay => {
	// Transform exploded TagTypes & TagIds to a flat TagIds array, all blended.
	let flatTagIds: Array<string> = [];
	let primaryTagId: string | null = null;

	session.tagTypes.forEach((tagtype: any) => {
		tagtype.tags.forEach((tag: any) => {
			flatTagIds.push(tag.id);
		});
	});

	// Find in tagtype configuration if a primary tag has been set previously.
	if (tagTypesWithTagConfig && tagTypesWithTagConfig.length > 0) {
		const primaryTagType: ITagTypeModelWithTags = tagTypesWithTagConfig[0];
		primaryTagType.tags.forEach((tag) => {
			if (flatTagIds.includes(tag.id)) {
				primaryTagId = tag.id;
			}
		});
	}

	// We need to remove primaryTagId from flatTagIds list to avoid a double values later.
	if (primaryTagId !== null) {
		flatTagIds = flatTagIds.filter((v) => v !== primaryTagId);
	}

	const sessionDisplay: TSessionDisplay = {
		id: session.id === "" ? null : session.id,
		description: session.description,
		duration: session.duration,
		endDate: session.endDate,
		code: session.code,
		endHour: session.endHour,
		expectedAudience: session.expectedAudience,
		isPrivate: session.isPrivate,
		maxPax: session.maxPax,
		organizedBy: session.organizedBy,
		publicationDate: session.publicationDate ? session.publicationDate : null,
		roomId: session.room ? session.room.id : "",
		startDate: session.startDate,
		startHour: session.startHour,
		status: session.status,
		timeZone: session.timeZone,
		title: session.title,
		primaryTagId,
		tagIds: flatTagIds,
		customFieldValues: session.customFieldValues,
		room: session.room,
		key: session.key,
	};

	return sessionDisplay;
};

const getSessionEntireDate = (startDate: string, endDate: string) => {
	const dateStartString = DateService.GetLocaleStringFromIsoString(startDate);
	const dateEndString = DateService.GetLocaleStringFromIsoString(endDate);
	const dateStartObject = DateService.GetDateFromIsoString(startDate);
	const dateEndObject = DateService.GetDateFromIsoString(endDate);
	const hourStart = DateService.Format.TimeSimple({
		date: dateStartObject!,
	});
	const hourEnd = DateService.Format.TimeSimple({ date: dateEndObject! });

	const dateEnd = dateStartString === dateEndString ? "" : dateEndString;
	const displayEntireDate = `${dateStartString} ${hourStart} - ${dateEnd} ${hourEnd}`;
	return displayEntireDate;
};

const getSessionUseFormFromModel = (
	session: any = ConfigurationsApp.SessionConfiguration.defaultModel2,
	tagTypesWithTagConfig?: Array<ITagTypeModelWithTags>,
): TSessionUseFormModal => {
	return sessionDisplayToSessionUseForm(getSessionDisplay(session, tagTypesWithTagConfig));
};

const getStatusSessionListItems = (
	sessionStatusConfigurations: SessionStatusConfigurations,
	statusToExclude?: SessionStatus[],
): Array<TAutocompleteMultiBulletColorItem> => {
	return SessionStatusTypes.filter((value) => (statusToExclude ? !statusToExclude.includes(value) : true)).map((value) => {
		const config = sessionStatusConfigurations[value];
		return {
			key: value,
			label: config.name,
			color: config.color,
		};
	});
};

const mapFormTabsEditUseFormDefaultValue = (useFormData: TypeUseFormListForms, session: DtoSession, primaryTag: DtoTagType | null, event: DtoEvent) => {
	for (const [formId, form] of toPairs(useFormData)) {
		for (const [sectionId, section] of toPairs(form)) {
			if (section.metadata?.tagIds) {
				setUseFormSecondariesTagsFromSessionDto(session, primaryTag, useFormData, formId, sectionId);
			}

			for (const [fieldId, _] of toPairs(section)) {
				switch (fieldId) {
					case EnumSessionFieldKey.CODE:
						useFormData[formId][sectionId][fieldId] = session.code ?? null;
						break;
					case EnumSessionFieldKey.DESCRIPTION:
						useFormData[formId][sectionId][fieldId] = session.description ?? null;
						break;
					case EnumSessionFieldKey.START_DATE:
						useFormData[formId][sectionId][fieldId] = session.startDate ?? null;
						break;
					case EnumSessionFieldKey.END_DATE:
						useFormData[formId][sectionId][fieldId] = session.endDate ?? null;
						break;
					case EnumSessionFieldKey.END_HOUR:
						useFormData[formId][sectionId][fieldId] = session.endHour ? DateTime.fromFormat(session.endHour, "HH:mm:ss") : null;
						break;
					case EnumSessionFieldKey.DURATION:
						useFormData[formId][sectionId][fieldId] = session.duration ? Duration.fromISO(session.duration).toFormat("hh:mm") : null;
						break;
					case EnumSessionFieldKey.EXPECTED_AUDIENCE:
						useFormData[formId][sectionId][fieldId] = session.expectedAudience ?? null;
						break;
					case EnumSessionFieldKey.IS_PRIVATE:
						useFormData[formId][sectionId][fieldId] = session.isPrivate;
						break;
					case EnumSessionFieldKey.ORGANIZED_BY:
						useFormData[formId][sectionId][fieldId] = session.organizedBy ?? null;
						break;
					case EnumSessionFieldKey.PRIMARY_TAG_ID:
						if (session.tagTypes && primaryTag && session.tagTypes.length > 0 && session.tagTypes[0].id === primaryTag.id && session.tagTypes[0].tags?.[0]) {
							useFormData[formId][sectionId][fieldId] = session.tagTypes[0].tags[0].id;
						}
						break;
					case EnumSessionFieldKey.PUBLICATION_DATE:
						useFormData[formId][sectionId][fieldId] = session.publicationDate
							? DateTime.fromISO(session.publicationDate, {
									locale: event.culture ?? DefaultCulture.locale,
								})
							: null;
						break;
					case EnumSessionFieldKey.ROOM_ID:
						if (session.room) useFormData[formId][sectionId][fieldId] = session.room.id;
						break;
					case EnumSessionFieldKey.START_HOUR:
						useFormData[formId][sectionId][fieldId] = session.startHour ? DateTime.fromFormat(session.startHour, "HH:mm:ss") : null;
						break;
					case EnumSessionFieldKey.STATUS:
						useFormData[formId][sectionId][fieldId] = session.status;
						break;
					case EnumSessionFieldKey.TIMEZONE:
						useFormData[formId][sectionId][fieldId] = session.timeZone ?? null;
						break;
					case EnumSessionFieldKey.TITLE:
						useFormData[formId][sectionId][fieldId] = session.title ?? null;
						break;
				}
			}
		}
	}
};

const mapFormTabsPropsStaticFields = (
	formsData: Array<TypeCustomFieldForm>,
	componentData: PropsFormTabs,
	event: DtoEvent,
	dataSettingsScheduler?: DtoSettingsScheduler | null,
	sessionId?: string,
): void => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			// Add static fields
			if (
				(Object.values(EnumSessionFormKey) as Array<string>).includes(form.data.key) &&
				(Object.values(EnumSessionSectionKey) as Array<string>).includes(section.data.key)
			) {
				for (const val of toPairs(
					ConfigurationsApp.SessionConfiguration.staticListFormControlsObject[form.data.key as EnumSessionFormKey][section.data.key as EnumSessionSectionKey],
				)) {
					const formControl = val[1].component;
					let name = [form.data.id, section.data.id, formControl.id].join(".");
					formControl.propsComponent.label = t(formControl.propsComponent.label);

					if (formControl.id === EnumSessionFieldKey.DURATION) {
						formControl.propsComponent.items = DateHelper.durationSuggestionOptions(4, 15).map((d) => {
							return { key: d, label: d };
						});
					}

					switch (formControl.kind) {
						case EnumFormControlKind.CONTAINER_SECONDARIES_TAGS_LIST:
							name = [form.data.id, section.data.id].join(".");
							break;
						case EnumFormControlKind.SELECT_BULLET_COLOR:
							formControl.propsComponent.items = formControl.propsComponent.items.map((item) => {
								return {
									...item,
									label: t(item.label),
								};
							});
							break;
						case EnumFormControlKind.DATE_PICKER:
							formControl.propsComponent.referenceDate = DateTime.fromISO(event.startDate);
							if (dataSettingsScheduler?.programmeStartDate) {
								formControl.propsComponent.minDate = DateTime.fromISO(dataSettingsScheduler.programmeStartDate, {
									zone: "UTC",
								});
							}
							if (dataSettingsScheduler?.programmeEndDate) {
								formControl.propsComponent.maxDate = DateTime.fromISO(dataSettingsScheduler.programmeEndDate, {
									zone: "UTC",
								});
							}
							break;
						case EnumFormControlKind.CONTAINER_PRIMARY_TAG_SELECT:
							formControl.propsComponent.sessionId = sessionId;
							break;
						default:
							break;
					}

					componentData.tabs[i].content.sections[j].formControls.push({
						...formControl,
						name,
					});
				}
			}
		}
	}
};

const mapFormTabsUseFormDefaultValue = (
	formsData: Array<TypeCustomFieldForm>,
	useFormData: TypeUseFormListForms,
	secondariesTags: Array<DtoTagType> | null,
) => {
	for (const form of formsData) {
		const sections = form.sections;

		for (const section of sections) {
			if (
				(Object.values(EnumSessionFormKey) as Array<string>).includes(form.data.key) &&
				(Object.values(EnumSessionSectionKey) as Array<string>).includes(section.data.key)
			) {
				for (const [key, val] of toPairs(
					ConfigurationsApp.SessionConfiguration.staticListFormControlsObject[form.data.key as EnumSessionFormKey][section.data.key as EnumSessionSectionKey],
				)) {
					if (key === EnumSessionFieldKey.TAG_IDS) {
						if (secondariesTags) {
							for (const tag of secondariesTags) {
								useFormData[form.data.id][section.data.id][SessionConfig.SECONDARY_TAG_USE_FORM_KEY + tag.id] = !tag.max || tag.max > 1 ? [] : null;
							}
						}
					} else if (key === EnumSessionFieldKey.PRIMARY_TAG_ID) {
						useFormData[form.data.id][section.data.id][key] = val.value;
						useFormData[form.data.id][section.data.id].shouldApplySessionTemplate = false;
					} else {
						useFormData[form.data.id][section.data.id][key] = val.value;
					}

					useFormData[form.data.id][section.data.id].metadata![key as EnumSessionFieldKey] = {
						visibility: true,
					};
				}
			}
		}
	}
};

const setUseFormSecondariesTagsFromSessionDto = (
	session: DtoSession,
	primaryTag: DtoTagType | null,
	useFormData: TypeUseFormListForms,
	formId: string,
	sectionId: string,
) => {
	let tags = session.tagTypes;
	if (tags && tags.length > 0) {
		if (primaryTag && tags[0].id === primaryTag.id) tags = drop(tags);

		tags.forEach((tagType) => {
			const fieldIdTag = SessionConfig.SECONDARY_TAG_USE_FORM_KEY + tagType.id;
			if (tagType.tags) {
				tagType.tags.forEach((tagTypeTag) => {
					// Check if Select or MultiSelect tags
					if (!tagType.max || tagType.max > 1) {
						useFormData[formId][sectionId][fieldIdTag] = concat(useFormData[formId][sectionId][fieldIdTag] as Array<string>, tagTypeTag.id);
					} else {
						useFormData[formId][sectionId][fieldIdTag] = tagTypeTag.id;
					}
				});
			}
		});
	}
};

const PopulateTags = (session: any) => {
	return [
		{
			label: session.primaryTag?.label ?? "",
			backgroundColor: session.primaryTag?.backgroundColor ?? "",
			fontColor: session.primaryTag?.fontColor ?? "",
			borderColor: session.primaryTag?.borderColor ?? "",
		},
	];
};

const sessionDisplayToSessionUseForm = (session: TSessionDisplay): TSessionUseFormModal => {
	const sessionUseForm: TSessionUseFormModal = {
		id: session.id,
		description: session.description,
		duration: session.duration,
		endDate: session.endDate,
		code: session.code,
		endHour: session.endHour,
		expectedAudience: session.expectedAudience,
		isPrivate: session.isPrivate,
		maxPax: session.maxPax,
		organizedBy: session.organizedBy,
		publicationDate: session.publicationDate,
		roomId: session.roomId,
		startDate: session.startDate,
		startHour: session.startHour,
		status: session.status,
		timeZone: session.timeZone,
		title: session.title,
		primaryTagId: session.primaryTagId,
		tagIds: session.tagIds,
		customFieldValues: {},
		room: session.room,
		key: session.key,
	};

	// Add customFieldValues & keep legacy field
	sessionUseForm.customFieldValues = CustomFieldHelper.remapCustomFieldModelToUseForm(session.customFieldValues);

	return sessionUseForm;
};

const setFieldVisibility = (path: string, fieldKeys: Array<string>, value: boolean, setValues: UseFormSetValue<FieldValues>) => {
	fieldKeys.forEach((key: string) => setValues([path, "metadata", key, "visibility"].join("."), value));
};

export type SessionPathHelper = {
	splitName: string[];
	basePath: string;
	fieldPaths: Map<EnumSessionFieldKey, string>;
	pathOf: (field: EnumSessionFieldKey) => string;
};

const pathHelper = (name: string): SessionPathHelper => {
	const splitName = name.split(".");
	const basePath = `${splitName[0]}.${splitName[1]}`;
	const fieldPaths = new Map<EnumSessionFieldKey, string>();
	return {
		splitName,
		basePath,
		fieldPaths,
		pathOf: (field) => {
			if (fieldPaths.has(field)) {
				return fieldPaths.get(field)!;
			} else {
				const path = `${basePath}.${field}`;
				fieldPaths.set(field, path);
				return path;
			}
		},
	};
};

const SessionHelper = {
	getSessionEntireDate,
	getStatusSessionListItems,
	pathHelper,
	setFieldVisibility,
	setDurationValues,
	changeDatesOnDuration,
	triggerDates,
	PopulateTags,
	getSessionDisplay,
	getSessionUseFormFromModel,
	mapFormTabsUseFormDefaultValue,
	mapFormTabsPropsStaticFields,
	mapFormTabsEditUseFormDefaultValue,
};

export default SessionHelper;
