import { WizardButtons, type WizardButtonsComponentsProps } from "@components";
import { Stack } from "@mykey4/core";
import { memo } from "react";

export interface AbstractStepLayoutProps {
	slotProps?: {
		buttons?: WizardButtonsComponentsProps;
	};
	children?: React.ReactNode;
}

export const AbstractStepLayout = memo((props: AbstractStepLayoutProps) => {
	const { slotProps, children } = props;
	return (
		<Stack direction={"column"} spacing={3}>
			{children}
			<WizardButtons slotProps={slotProps?.buttons} />
		</Stack>
	);
});
