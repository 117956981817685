import type { LiteTagRead, SessionRead, SessionTemplateRead, SessionWrite, TagRead, TagTypeRead } from "@api";
import { type SessionModalForm, type SessionModalLiteTagTypes, type SessionModalTagType, type SessionModalTagTypes, roomToAutocompleteItem } from "@components";
import {
	type ActionItem,
	type CustomFieldForm,
	type CustomFieldsValuesForm,
	customFieldValuesReadToCustomFieldsValuesForm,
	customFieldsValuesFormToCustomFieldValuesRead,
} from "@key4-front-library/core";
import {
	type TagProps,
	dateTimeToISO,
	emptyToUndefined,
	fromIsoToDateTime,
	fromTimeWithSecondToDateTime,
	getDateTime,
	isoDurationToString,
	stringISOToDateTime,
	stringISOToDateTimeNullable,
	stringToIsoDuration,
} from "@mykey4/core";
import { groupBy, toPairs } from "lodash";
import type { UseFormReturn } from "react-hook-form";

export const sessionFormToSessionWrite = (form: CustomFieldForm<SessionModalForm>, shouldMapIsAbstract?: boolean): SessionWrite => {
	const secondariesTagsId: Array<string> = [];
	for (const tagsByTagType of toPairs(form.tagsByTagTypes)) {
		const tags = tagsByTagType[1].filter((x) => x.value !== form.primaryTagId);
		for (const tag of tags) {
			secondariesTagsId.push(tag.value);
		}
	}

	const startDate = form.startDate && form.startTime ? dateTimeToISO(getDateTime(form.startDate, form.startTime)) : undefined;
	let endDate = form.startDate && form.endTime ? dateTimeToISO(getDateTime(form.startDate, form.endTime)) : undefined;

	if (form.isMultiday) {
		endDate = form.endDate && form.endTime ? dateTimeToISO(getDateTime(form.endDate, form.endTime)) : undefined;
	}

	return {
		roomId: form.room?.value ?? undefined,
		code: emptyToUndefined(form.code),
		startDate: startDate,
		endDate: endDate,
		status: form.status,
		isPublished: form.isPublished,
		publicationDate: form.publicationDate ? dateTimeToISO(form.publicationDate) : undefined,
		title: form.title,
		isPrivate: form.isPrivate,
		isAbstract: shouldMapIsAbstract ? form.isAbstract : undefined,
		description: emptyToUndefined(form.description),
		organizedBy: emptyToUndefined(form.organizedBy),
		expectedAudience: form.expectedAudience !== "" ? Number.parseInt(form.expectedAudience) : undefined,
		primaryTagId: emptyToUndefined(form.primaryTagId),
		duration: form.duration !== "" ? stringToIsoDuration(form.duration) : undefined,
		tagIds: secondariesTagsId,
		customFieldValues: customFieldsValuesFormToCustomFieldValuesRead(form.customFields),
		shouldApplySessionTemplate: form.shouldApplySessionTemplate,
		order: emptyToUndefined(form.order ?? undefined),
	};
};

export const sessionReadToSessionForm = (
	session: SessionRead,
	customFields: CustomFieldsValuesForm,
	tagTypes: SessionModalLiteTagTypes,
): CustomFieldForm<SessionModalForm> => {
	const secondariesTags: Record<string, Array<ActionItem<TagProps>>> = {};

	for (const tagType of tagTypes.secondaries) {
		secondariesTags[tagType.id] = tagType.tags.map((tag) => {
			return {
				value: tag.id,
				name: tag.label,
				component: {
					backgroundColor: tag.backgroundColor,
					borderColor: tag.borderColor,
					fontColor: tag.fontColor,
					children: tag.label,
				},
			};
		});
	}

	let isMultiday = false;
	if (session.startDate && session.endDate) {
		isMultiday =
			stringISOToDateTime(session.startDate).set({
				hour: 0,
				minute: 0,
			}) < stringISOToDateTime(session.endDate).set({ hour: 0, minute: 0 });
	}

	return {
		code: session.code ?? "",
		primaryTagId: session.primaryTag?.id ?? "",
		organizedBy: session.organizedBy ?? "",
		title: session.title ?? "",
		description: session.description ?? "",
		order: session.order?.toString() ?? "",
		isPrivate: session.isPrivate,
		isAbstract: session.isAbstract ?? false,
		expectedAudience: session.expectedAudience?.toString() ?? "",
		room: session.room ? roomToAutocompleteItem(session.room) : null,
		isMultiday: isMultiday,
		startDate: stringISOToDateTimeNullable(session.startDate),
		startTime: stringISOToDateTimeNullable(session.startDate),
		endDate: stringISOToDateTimeNullable(session.endDate),
		endTime: stringISOToDateTimeNullable(session.endDate),
		duration: isoDurationToString(session.duration),
		status: session.status,
		isPublished: session.isPublished,
		publicationDate: stringISOToDateTimeNullable(session.publicationDate),
		tagsByTagTypes: secondariesTags,
		shouldApplySessionTemplate: false,
		customFields: customFieldValuesReadToCustomFieldsValuesForm(customFields, session.customFieldValues),
	};
};

export const overrideWithSessionTemplate = (form: UseFormReturn<CustomFieldForm<SessionModalForm>, undefined>, sessionTemplate: SessionTemplateRead) => {
	form.setValue("startTime", fromTimeWithSecondToDateTime(sessionTemplate.startHour));
	form.setValue("endTime", fromTimeWithSecondToDateTime(sessionTemplate.endHour));
	form.setValue("status", sessionTemplate.status);
	form.setValue("isPublished", sessionTemplate.isPublished);
	form.setValue("publicationDate", fromIsoToDateTime(sessionTemplate.publicationDate));
	form.setValue("duration", isoDurationToString(sessionTemplate.duration ?? undefined) ?? "");
	form.setValue("isPrivate", sessionTemplate.isPrivate);
};

export const mapTagTypesAndTags = (tagTypes: TagTypeRead[], tags: TagRead[]): SessionModalTagTypes => {
	const tagGroupByType: Record<string, TagRead[]> = groupBy(tags ?? [], "tagTypeId");
	const sessionModalTagTypes = tagTypes.map((tagType) => {
		return {
			...tagType,
			tags: tagGroupByType[tagType.id] ?? [],
		} as SessionModalTagType;
	});
	return {
		primary: sessionModalTagTypes?.find((x) => x.order === 1),
		secondaries: sessionModalTagTypes?.filter((x) => x.order === undefined || x.order > 1),
	};
};

export const mapTagTypesAndLiteTags = (tagTypes: TagTypeRead[], tags: LiteTagRead[]): SessionModalLiteTagTypes => {
	const tagGroupByType: Record<string, LiteTagRead[]> = groupBy(tags ?? [], "tagTypeId");
	const sessionModalTagTypes = tagTypes.map((tagType) => {
		return {
			...tagType,
			tags: tagGroupByType[tagType.id] ?? [],
		} as SessionModalTagType;
	});
	return {
		primary: sessionModalTagTypes?.find((x) => x.order === 1),
		secondaries: sessionModalTagTypes?.filter((x) => x.order === undefined || x.order > 1),
	};
};
