import { getPresentations } from "@api";
import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import CalendarController from "@application/Controllers/CalendarController";
import SessionController from "@application/Controllers/SessionController";
import DateHelper from "@application/helpers/date.helper";
import { type InjectSessionData, SessionModal } from "@components";
import { ESchedulerView } from "@domain/interfaces/calendar.interface";
import type { ICalendarSession, ICalendarSettings, TFilterUnplannedSessionsForm, TRoomView } from "@domain/interfaces/calendar.interface";
import type { TTagTypeFilterForm } from "@domain/interfaces/session.interface";
import type { TSessionCalendar } from "@domain/model/calendar.model";
import type { DateSelectArg } from "@fullcalendar/core";
import K4Calendar from "@infrastructure/components/calendar/K4Calendar";
import UnplannedSessionsCard from "@infrastructure/components/calendar/UnplannedSessionsCard";
import FilterModal from "@infrastructure/components/interface/modals/FilterModal";
import DateService from "@infrastructure/services/dates/date.service";
import { BackgroundColors, type ButtonTitleProps, Locales, PeriodContainer, TitleOld, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import { Card, Grid, Stack } from "@mui/material";
import type { SxProps } from "@mui/material";
import { API_VERSION_QUERY_PARAM, Cultures, Loader, QUERY_KEYS_PROGRAMME, stringToIsoDuration, useDialogs, useUser } from "@mykey4/core";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { DateTime, Duration } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EditPresentationsSchedule } from "../../../Components/Dialogs/EditPresentationsSchedule/EditPresentationsSchedule";
import FilterUnplannedSessionsForm from "./FilterUnplannedSessionsForm";

const preSelectedRoomRange = {
	start: 0,
	end: 5,
};

const defaultFilterUnplannedSessionsForm = {
	rooms: [],
	sessionStatus: [],
	dates: [],
	tags: [],
	isPublished: null,
};

export const Scheduler = () => {
	const { client, event } = useContextModule();
	const dialog = useDialogs();
	const [calendarSettings, setCalendarSettings] = useState<ICalendarSettings | null>(null);
	const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
	const [roomId, setRoomId] = useState<string | null>(null);
	const [roomIds, setRoomIds] = useState<Array<string> | null>(null);
	const [roomsResource, setRoomsResource] = useState<Array<TRoomView> | null>(null);
	const [roomSessions, setRoomSessions] = useState<Array<ICalendarSession> | null>(null);
	const [unplannedSessions, setUnplannedSessions] = useState<Array<ICalendarSession> | null>(null);
	const [currentView, setCurrentView] = useState<ESchedulerView>(ESchedulerView.MULTIROOM);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const { user } = useUser();
	const queryClient = useQueryClient();

	const [isFilterUnplannedBracketModal, setIsFilterUnplannedBracketModal] = useState<boolean>(false);
	const [tagsTypeListFilterForm, setTagsTypeListFilterForm] = useState<Array<TTagTypeFilterForm>>();

	const [searchUnplannedBracket, setSearchUnplannedBracket] = useState<string>("");

	const [filterUnplannedSessionsQuery, setFilterUnplannedSessionsQuery] = useState<TFilterUnplannedSessionsForm>({
		...defaultFilterUnplannedSessionsForm,
	});

	const [isBadge, setIsBadge] = useState<boolean>(false);
	const { sendError, sendSuccess } = useSnackBarHook();

	const formMethods = useForm<TFilterUnplannedSessionsForm>({
		mode: "onChange",
		defaultValues: { ...defaultFilterUnplannedSessionsForm },
	});
	const { handleSubmit, reset } = formMethods;

	const fullScreenStyle: SxProps = {
		height: "100%",
		position: "fixed",
		top: "0",
		left: "0",
		transform: ["translate(0, 0)", "scale(1)"],
		zIndex: "1100",
		overflowY: "auto",
		backgroundColor: BackgroundColors.light.default,
	};

	const handleOpenFilterModalClick = () => {
		setIsFilterUnplannedBracketModal(true);
	};

	const handleSaveClick = async (form: TFilterUnplannedSessionsForm) => {
		setFilterUnplannedSessionsQuery(form);

		// Set up badge on filter
		let isTags = false;
		if (form.tags) {
			for (const tags of form.tags) {
				if (tags?.length) {
					isTags = true;
				}
			}
		}

		const isNotBadge =
			form.dates.length === 0 && form.rooms.length === 0 && form.sessionStatus.length === 0 && form.dates.length === 0 && !isTags && form.isPublished === null;

		setIsBadge(!isNotBadge);
		sendSuccess(t("old.common.scheduler.unplannedSessionBracket.advancedFilter.saveSuccess"));
		setIsFilterUnplannedBracketModal(false);
	};

	const handleSearchChange = (search: string) => {
		setSearchUnplannedBracket(search.trim());
	};

	const buttons: Array<ButtonTitleProps> = [
		{
			label: t("old.form.buttons.add"),
			icon: { iconName: "plus" },
			color: "primary" as const,
			handleClick: async () => {
				handleOpenSessionModal();
				await refreshUnplannedSessions();
			},
		},
	];

	const fetchUnplannedSessions = useCallback(
		async (clientId: string, eventId: string) => {
			try {
				const unplannedSessionsData = await CalendarController.getUnplannedSessions(clientId, eventId, searchUnplannedBracket, filterUnplannedSessionsQuery);
				setUnplannedSessions(unplannedSessionsData);
			} catch {
				setUnplannedSessions([]);
			}
		},
		[filterUnplannedSessionsQuery, searchUnplannedBracket],
	);

	const initComponent = useCallback(async () => {
		try {
			const calendarSettingsData = await CalendarController.getCalendarSettings(client.id, event.id);
			setCalendarSettings(calendarSettingsData);
			const haveRooms = calendarSettingsData.rooms !== undefined && calendarSettingsData.rooms.length > 0;

			if (!haveRooms) {
				setRoomIds([]);
				setRoomId(null);
				setRoomSessions(null);
				setRoomsResource(null);
				return;
			}
			const preselectedRoomIds = calendarSettingsData.rooms?.slice(preSelectedRoomRange.start, preSelectedRoomRange.end).map((room) => room.id) ?? [];
			setRoomIds(preselectedRoomIds);
			setRoomId(calendarSettingsData.rooms?.[0].id ?? null);
			setRoomsResource(calendarSettingsData.rooms?.slice(preSelectedRoomRange.start, preSelectedRoomRange.end) ?? []);
			try {
				const sessions = await CalendarController.getSessionsByRoom(client.id, event.id, preselectedRoomIds);
				setRoomSessions(sessions);
			} catch {
				setRoomSessions([]);
			}
		} catch {
			setCalendarSettings(null);
		} finally {
			setIsApiLoading(false);
		}
	}, [event]);

	const refreshUnplannedSessions = async () => {
		await fetchUnplannedSessions(client.id, event.id);
	};

	const refreshPlannedSessions = async (newRoomId: string | Array<string> | null, view: ESchedulerView | null = null) => {
		if (view) {
			switch (view) {
				case ESchedulerView.ROOM:
					newRoomId = roomId;
					break;
				case ESchedulerView.MULTIROOM:
					newRoomId = roomIds;
					break;
				default:
					// TODO error display
					return;
			}
		}

		// TODO error display
		if (!newRoomId || !calendarSettings) {
			return;
		}

		const currentRoomIds = typeof newRoomId === "string" ? [newRoomId] : newRoomId;

		await CalendarController.getSessionsByRoom(client.id, event.id, currentRoomIds)
			.then((sessions) => setRoomSessions(sessions))
			.catch(() => setRoomSessions([]));
	};

	const refreshAllSessions = async (newRoomId: string | Array<string> | null, view: ESchedulerView | null = null) => {
		const promise1 = refreshPlannedSessions(newRoomId, view);
		const promise2 = refreshUnplannedSessions();
		await Promise.all([promise1, promise2]);
	};

	const updateSession = async (sessionId: string, session: TSessionCalendar) => {
		await CalendarController.putSession(client.id, event.id, sessionId, session);
	};

	const initTagTypesListFilterForm = useCallback(async (clientId: string, eventId: string) => {
		await SessionController.getListTagsType(clientId, eventId)
			.then((result) => setTagsTypeListFilterForm(result ?? []))
			.catch(() => setTagsTypeListFilterForm([]));
	}, []);

	useEffect(() => {
		initComponent();
	}, [event, initComponent]);

	useEffect(() => {
		fetchUnplannedSessions(client.id, event.id);
	}, [event, fetchUnplannedSessions]);

	useEffect(() => {
		if (!tagsTypeListFilterForm) {
			initTagTypesListFilterForm(client.id, event.id);
		}
	}, [event, initTagTypesListFilterForm, tagsTypeListFilterForm]);

	const handleRoomChange = (newRoomId: string) => {
		setRoomSessions(null);
		setRoomId(newRoomId);
		refreshPlannedSessions(newRoomId);
	};

	const handleRoomsChange = (newRooms: Array<TRoomView>) => {
		let newRoomIds: Array<string> | null = [];
		if (!newRooms || newRooms.length === 0) {
			newRoomIds = null;
		} else {
			for (const room of newRooms) {
				if (!newRoomIds) {
					continue;
				}
				newRoomIds.push(room.id);
			}
		}
		setRoomIds(newRoomIds);
		setRoomsResource(newRooms);
		refreshPlannedSessions(newRoomIds);
	};

	const expandScheduler = () => {
		setIsExpanded(!isExpanded);
	};

	const refreshSessionCardScheduler = (id: string) => {
		queryClient.invalidateQueries({
			queryKey: [...QUERY_KEYS_PROGRAMME.sessions, "Get", "V1", client.id, event.id, id],
		});
		queryClient.invalidateQueries({
			queryKey: [...QUERY_KEYS_PROGRAMME.presentations, "Get", client.id, event.id, id],
		});
	};

	const handleResizeSession = async (session: any) => {
		const newRoomId = session?.event?.extendedProps ? session.event.extendedProps.roomId : null;
		if (!newRoomId) {
			return;
		}
		if (
			!DateHelper.isDurationEligible(
				DateTime.fromISO(session.event.startStr),
				DateTime.fromISO(session.event.endStr),
				session?.event?.extendedProps?.minDuration ?? 0,
			)
		) {
			sendError(Locales.Parsers.TranslateParserError.schedulerMinDuration(Duration.fromISO(session.event.extendedProps.minDuration).toFormat("mm")));
			refreshPlannedSessions(newRoomId, currentView);
			return;
		}

		const { dateStart, dateEnd, duration } = DateHelper.setDurationAndDatesSession(
			DateTime.fromISO(session.event.startStr),
			DateTime.fromISO(session.event.endStr),
			session.event.extendedProps.minDuration,
		);

		await updateSession(session.event.id, {
			roomId: newRoomId,
			startDate: dateStart.toFormat("yyyy-MM-dd"),
			startHour: dateStart.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
			endDate: dateEnd.toFormat("yyyy-MM-dd"),
			endHour: dateEnd.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
			timeZone: "UTC",
			duration: stringToIsoDuration(duration),
			tagIds: session.event.extendedProps.tagsId,
		});

		refreshPlannedSessions(newRoomId, currentView);
		refreshSessionCardScheduler(session.event.id);
		await handleEditPresentationSchedule(session, dateStart, dateEnd);
	};

	async function handleEditPresentationSchedule(session: any, newSessionStart: DateTime, newSessionEnd: DateTime) {
		const { dateStart: oldDateStart, dateEnd: oldDateEnd } = DateHelper.setDurationAndDatesSession(
			DateTime.fromISO(session.oldEvent?.startStr ?? "0001-01-01T00:00:00Z"),
			DateTime.fromISO(session.oldEvent?.endStr ?? "0001-01-01T00:00:00Z"),
			null,
		);

		const isSameDate = (!oldDateStart.isValid || !oldDateEnd.isValid) && oldDateStart.equals(newSessionStart) && oldDateEnd.equals(newSessionEnd);

		if (!event.id || !session.event.id || isSameDate) {
			return;
		}

		const presentations = await getPresentations({
			clientId: client.id,
			eventId: event.id,
			sessionId: session.event.id,
			queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
		});

		if (presentations?.data.length === 0) {
			return;
		}

		const duration = oldDateEnd.diff(oldDateStart, "minutes");

		const sessionEditSchedule = {
			id: session.event.id,
			oldDateTimeStart: oldDateStart,
			oldDateTimeEnd: oldDateEnd,
			newDateTimeStart: newSessionStart,
			newDateTimeEnd: newSessionEnd,
			oldDuration: duration.isValid ? duration.toFormat("mm") : "00",
			newDuration: duration.isValid ? duration.toFormat("mm") : "00",
		};

		await dialog.open(EditPresentationsSchedule, { sessionEditSchedule });
	}

	/**
	 * Handle 2 Drag & drop actions:
	 * - action on dragging session inside the calendar
	 * - action on dragging unplanned session into the calendar
	 */
	const handleSessionDrop = async (session: any) => {
		// internal D&D roomId
		let dropRoomId = session.newResource ? session.newResource.id : session.event.extendedProps.roomId;

		let isInternal = true;
		if (!dropRoomId) {
			isInternal = false;
			// external D&D roomId
			switch (currentView) {
				case ESchedulerView.ROOM:
					dropRoomId = roomId;
					break;
				case ESchedulerView.MULTIROOM:
					if (session.event.getResources() && session.event.getResources().length > 0) {
						dropRoomId = session.event.getResources()[0].id;
					}
			}
		}

		// TODO error display
		if (!dropRoomId) {
			return;
		}
		if (!roomId) {
			return;
		}

		let endStr = session.event.endStr;

		const minDuration = session.event.extendedProps.minDuration ?? SchedulerConfiguration.defaultMinDuration;

		if (!session.event.endStr) {
			endStr = session.event.startStr;
		}

		const { dateStart, dateEnd, duration } = DateHelper.setDurationAndDatesSession(
			DateTime.fromISO(session.event.startStr),
			DateTime.fromISO(endStr),
			minDuration,
		);

		await updateSession(session.event.id, {
			roomId: dropRoomId,
			startDate: dateStart.toFormat("yyyy-MM-dd"),
			startHour: dateStart.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
			endDate: dateEnd.toFormat("yyyy-MM-dd"),
			endHour: dateEnd.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
			timeZone: "UTC",
			duration: stringToIsoDuration(duration),
			tagIds: session.event.extendedProps.tagsId,
		});

		await refreshAllSessions(null, currentView);
		refreshSessionCardScheduler(session.event.id);
		if (!isInternal) {
			session.event.remove();
		}
		await handleEditPresentationSchedule(session, dateStart, dateEnd);
	};

	/**
	 * action on dragging calendar session into the unplanned section
	 */
	const handleDragStop = async (session: any) => {
		const unplannedSessionCard = document.getElementById("unplanned-session-card"); // as HTMLElement;

		if (!unplannedSessionCard) {
			return;
		}

		const x1 = unplannedSessionCard.offsetLeft;
		const x2 = unplannedSessionCard.offsetLeft + unplannedSessionCard.offsetWidth;
		const y1 = unplannedSessionCard.offsetTop;
		const y2 = unplannedSessionCard.offsetTop + unplannedSessionCard.offsetHeight;

		const minDuration = session.event.extendedProps.minDuration ?? SchedulerConfiguration.defaultMinDuration;

		const { duration } = DateHelper.setDurationAndDatesSession(
			DateTime.fromISO(session.event.startStr),
			DateTime.fromISO(session.event.endStr ?? session.event.startStr),
			minDuration,
		);

		// Drag event to the unplanned section
		if (session.jsEvent.pageX >= x1 && session.jsEvent.pageX <= x2 && session.jsEvent.pageY >= y1 && session.jsEvent.pageY <= y2) {
			await updateSession(session.event.id, {
				roomId: null,
				startDate: null,
				startHour: null,
				endDate: null,
				endHour: null,
				timeZone: "UTC",
				duration: stringToIsoDuration(duration),
				tagIds: session.event.extendedProps.tagsId,
			});
			await refreshAllSessions(null, currentView);
			session.event.remove();
		}
	};

	const handleChangeViewClick = (view: ESchedulerView) => {
		setCurrentView(view);
		refreshPlannedSessions(null, view);
	};

	const handleEventClick = async (sessionId: string) => {
		handleOpenSessionModal(sessionId);
	};

	const handleSelectClick = (arg: DateSelectArg) => {
		let selectRoomId = null;

		if (currentView === ESchedulerView.ROOM || currentView === ESchedulerView.MULTIROOM) {
			selectRoomId = roomId;
		}

		handleOpenSessionModal(undefined, {
			roomId: selectRoomId,
			startDate: DateTime.fromISO(arg.startStr),
			endDate: DateTime.fromISO(arg.endStr),
		});
	};

	const handleOpenSessionModal = async (sessionId?: string, injectSessionData?: InjectSessionData) => {
		await dialog.open(SessionModal, { sessionId, injectSessionData });
		void queryClient.invalidateQueries({
			queryKey: [...QUERY_KEYS_PROGRAMME.sessions, "Get", "V1", client.id, event.id, sessionId],
		});
		refreshUnplannedSessions();
		switch (currentView) {
			case ESchedulerView.ROOM:
				refreshPlannedSessions(roomId);
				break;
			case ESchedulerView.MULTIROOM:
				refreshPlannedSessions(roomIds);
				break;
		}
	};

	if (isApiLoading || !calendarSettings) {
		return <Loader />;
	}
	return (
		<>
			<>
				{!isExpanded && (
					<Grid container alignItems="center" data-testid="title">
						<Grid item xs>
							<TitleOld
								title={event.name ?? ""}
								reference={
									<PeriodContainer
										dateStartIso={DateService.Format.IsoStringDate({
											date: calendarSettings.date.start,
										})}
										dateEndIso={DateService.Format.IsoStringDate({
											date: calendarSettings.date.end,
										})}
									/>
								}
								buttons={buttons}
							/>
						</Grid>
					</Grid>
				)}

				<Grid container spacing={1}>
					{!roomsResource && (
						<Grid item xs={12}>
							{t("old.common.scheduler.noRoomMessage")}
						</Grid>
					)}
					<Grid item xs={12} md={isExpanded ? 12 : 9} sx={isExpanded ? fullScreenStyle : undefined}>
						<Card sx={{ padding: 0 }}>
							<K4Calendar
								rooms={roomsResource}
								changeView={handleChangeViewClick}
								handleResizeSession={handleResizeSession}
								handleDragStop={handleDragStop}
								handleSessionDrop={handleSessionDrop}
								locale={Cultures[user.locale].locale}
								events={roomSessions ?? []}
								calendarSettings={calendarSettings}
								view={currentView}
								changeRoom={handleRoomChange}
								changeRooms={handleRoomsChange}
								roomId={roomId}
								views={{
									room: SchedulerConfiguration.roomScheduler,
									multiroom: SchedulerConfiguration.multiRoomScheduler,
								}}
								handleEventClick={handleEventClick}
								select={handleSelectClick}
								expandScheduler={expandScheduler}
								isExpanded={isExpanded}
							/>
						</Card>
					</Grid>

					{!isExpanded && (
						<Grid item xs={12} md={3} display={{ xs: "none", sm: "block" }}>
							<Stack height={"100%"} sx={{ contain: "size" }}>
								<UnplannedSessionsCard
									handleSessionClick={handleEventClick}
									sessions={unplannedSessions}
									onOpenFilterModalClick={handleOpenFilterModalClick}
									onSearchChange={handleSearchChange}
									isBadge={isBadge}
								/>
							</Stack>
						</Grid>
					)}
				</Grid>
			</>
			{tagsTypeListFilterForm && (
				<FormProvider {...formMethods}>
					<FilterModal
						open={isFilterUnplannedBracketModal}
						maxWidth={"md"}
						onClearAllClick={() => {
							const tags = [];
							for (const {} of tagsTypeListFilterForm) {
								tags.push([]);
							}

							const resetForm = {
								...defaultFilterUnplannedSessionsForm,
								tags,
							};
							reset(resetForm);
							setFilterUnplannedSessionsQuery(resetForm);
							setIsBadge(false);
						}}
						onSaveClick={handleSubmit(handleSaveClick)}
					>
						<FilterUnplannedSessionsForm calendarSettings={calendarSettings} event={event} rooms={calendarSettings.rooms ?? []} tags={tagsTypeListFilterForm} />
					</FilterModal>
				</FormProvider>
			)}
		</>
	);
};
