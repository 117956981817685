import { IconButton, useTheme } from "@mui/material";
import { Icon, type IconName } from "@mykey4/core";
import { motion } from "framer-motion";
import { memo } from "react";

const SelectIconButton = memo(() => <Icon name={"arrow-up-arrow-down"} />);

export interface AnimatedIconButtonProps {
	iconName: IconName;
	selected?: boolean;
	onClick?: () => void;
}

export const AbstractSelectButton = memo((props: AnimatedIconButtonProps) => {
	const theme = useTheme();
	const { selected = false, onClick } = props;

	const defaultMainColor = theme.palette.warning.main;
	const defaultBackgroundColor = theme.palette.background.paper;

	const computedBackgroundColor = selected ? defaultMainColor : defaultBackgroundColor;
	const computedTextColor = selected ? defaultBackgroundColor : theme.palette.text.primary;
	const computedBorderColor = selected ? defaultMainColor : defaultBackgroundColor;

	const MotionIconButton = motion.create(IconButton);

	return (
		<MotionIconButton
			onClick={onClick}
			whileHover={{
				scale: 1.1,
				backgroundColor: defaultBackgroundColor,
				borderColor: defaultMainColor,
				color: defaultMainColor,
			}}
			whileTap={{ scale: 0.9 }}
			initial={false}
			transition={{ type: "spring", stiffness: 300, damping: 20 }}
			animate={{ backgroundColor: computedBackgroundColor, color: computedTextColor, borderColor: computedBorderColor }}
			sx={{
				width: "2rem",
				height: "2rem",
				borderRadius: "50%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				border: "1px solid",
			}}
		>
			<motion.div animate={{ rotate: selected ? 180 : 0 }} transition={{ duration: 0.4 }}>
				<SelectIconButton />
			</motion.div>
		</MotionIconButton>
	);
});
