import { Wizard } from "@components";
import { PermissionContext, useUserPermissionPage } from "@mykey4/core";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { AbstractWizardConfigs } from "./common";
import { AbstractsBulkActionStoreKey } from "./hooks";
import { AbstractsChooseActionStep, AbstractsSelectionStep } from "./steps";
import { AbstractsActionStep } from "./steps";
import { AbstractsConfirmationStep } from "./steps";

export const AbstractsBulkAction = () => {
	const permissions = useContext(PermissionContext);

	useUserPermissionPage([
		"abstract_Abstract_Keyword_ReadAll",
		"abstract_Abstract_ReadAll",
		"abstract_CoAuthor_ReadAll",
		"abstract_Keyword_ReadAll",
		"abstract_Category_ReadAll",
		"abstract_Depositary_ReadAll",
		"abstract_Theme_ReadAll",
		"abstract_Type_ReadAll",
		"programme_Presentation_PatchAll",
	]);

	const canAssociateDisassociate = permissions.includes("programme_Presentation_PatchAll");

	const { t } = useTranslation();

	const configs = useMemo<AbstractWizardConfigs>(
		() => ({
			abstractSelection: {
				id: "abstractSelection",
				type: "abstractSelection",
				title: t("abstract.bulkActions.steps.selection.stepper.title"),
				component: (props) => <AbstractsSelectionStep {...props} />,
			},
			chooseAction: {
				id: "chooseAction",
				type: "chooseAction",
				title: t("abstract.bulkActions.steps.chooseAction.stepper.title"),
				component: (props) => <AbstractsChooseActionStep {...props} isAuthorized={canAssociateDisassociate} />,
			},
			action: {
				id: "action",
				type: "action",
				title: "Action",
				component: (props) => <AbstractsActionStep {...props} />,
			},
			completed: {
				id: "completed",
				type: "completed",
				title: t("abstract.bulkActions.steps.completed.stepper.title"),
				component: (props) => <AbstractsConfirmationStep {...props} />,
			},
		}),
		[canAssociateDisassociate, t],
	);

	return <Wizard configs={configs} storeKey={AbstractsBulkActionStoreKey} />;
};
