import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";

import { MenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import RoomController from "@application/Controllers/RoomController";
import DraggableCard from "@application/components/_common/DraggableCard/DraggableCard";
import DraggableList from "@application/components/_common/DraggableCard/DraggableList";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import SimpleTextSearchFilter from "@infrastructure/components/interface/search/SimpleTextSearchFilter";
import RoomCard from "../../../Components/Molecules/RoomCard/RoomCard";

import { type RoomRead, useClashes, useRoomCreate, useRoomUpdate, useRooms, useRoomsOrder } from "@api";
import { RoomFormModal, roomFormToRoomWrite } from "@components";
import type { RoomForm } from "@domain/model/room.model";
import type { IUiMenu } from "@infrastructure/model/interfaces/components/UiMenu.interface";
import { ConfirmationModal, TitleOld, useContextModule } from "@key4-front-library/core";
import { Stack } from "@mui/material";
import { FilteringOperator, filtersOrInToValueString, useDialogs, useUserHasPermissions } from "@mykey4/core";
import update from "immutability-helper";
import { clashReadsToClashReadsByRoomId, roomReadsToRoomsOrder } from "./Rooms.mapper";

export const Rooms = () => {
	const canDeleteRoom = useUserHasPermissions(["programme_Room_Delete"]);

	const component = "rooms";

	const detailsConfig = MenuActionsConfigurations[MenuActionsReference.DETAILS];
	const addConfig = MenuActionsConfigurations[MenuActionsReference.ADD];
	const editConfig = MenuActionsConfigurations[MenuActionsReference.EDIT];
	const deleteConfig = MenuActionsConfigurations[MenuActionsReference.DELETE];
	const moveUpConfig = MenuActionsConfigurations[MenuActionsReference.UP];
	const moveDownConfig = MenuActionsConfigurations[MenuActionsReference.DOWN];

	const { client, event } = useContextModule();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [isApiLoading, setIsApiLoading] = useState<boolean>(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [search, setSearch] = useState<string>("");
	const [stateRooms, setStateRooms] = useState<Array<RoomRead>>([]); // Used for drag and drop. To delete as soon as DraggableCard is deleted

	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});

	const dialogs = useDialogs();

	const roomsQuery = useRooms(client.id, event.id, {
		queryStrings: search ? [{ key: "filters", value: `name${FilteringOperator.CaseInsensitiveContain}${search}` }] : [],
		select: (data) => ({
			ids: data?.data.map((room) => room.id),
			rooms: data?.data,
		}),
	});

	useEffect(() => {
		setStateRooms(roomsQuery.data?.rooms ?? []);
	}, [roomsQuery.data?.rooms]);

	const roomsClashesQuery = useClashes(client.id, event.id, {
		enabled: roomsQuery.isSuccess && (roomsQuery.data?.ids.length ?? 0) > 0,
		queryStrings: [
			{
				key: "filters",
				value:
					filtersOrInToValueString("session1.RoomId", FilteringOperator.Equal, roomsQuery.data?.ids ?? []) +
					FilteringOperator.Or +
					filtersOrInToValueString("session2.RoomId", FilteringOperator.Equal, roomsQuery.data?.ids ?? []),
			},
		],
		select: (data) => clashReadsToClashReadsByRoomId(data?.data),
	});

	const createRoomMutation = useRoomCreate();
	const updateRoomMutation = useRoomUpdate();
	const roomsOrder = useRoomsOrder({ onSuccess: () => refresh() });

	const refresh = useCallback(async () => {
		void roomsQuery.refetch();
		void roomsClashesQuery.refetch();
	}, [roomsQuery.refetch, roomsClashesQuery.refetch]);

	const moveCardWithoutMutation = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const rooms = stateRooms ?? [];
			const orderedRooms = update(rooms, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, rooms[dragIndex]],
				],
			});
			setStateRooms(orderedRooms);
			return orderedRooms;
		},
		[stateRooms],
	);

	const moveCard = useCallback(
		async (dragIndex: number, hoverIndex: number) => {
			const newOrderedRooms = moveCardWithoutMutation(dragIndex, hoverIndex);
			await roomsOrder.mutateAsync({ clientId: client.id, eventId: event.id, body: roomReadsToRoomsOrder(newOrderedRooms) });
		},
		[client.id, event.id, moveCardWithoutMutation, roomsOrder],
	);

	const handleEditModaleOpen = useCallback(
		(roomId?: string) => {
			dialogs.open(RoomFormModal, { roomId: roomId, handleSaveRoom, handleModaleClose });
		},
		[dialogs.open],
	);

	const renderCard = useCallback(
		(card: RoomRead, index: number) => {
			const handleSaveRoomsOrderOnDrop = async (rooms: Array<RoomRead> | undefined) => {
				if (rooms) {
					await roomsOrder.mutateAsync({ clientId: client.id, eventId: event.id, body: roomReadsToRoomsOrder(rooms) });
				}
			};

			const handleDeleteRoomClick = async (_id: string) => {
				if (_id) {
					try {
						await RoomController.deleteRoom(client.id, event.id, _id);
						enqueueSnackbar(`${component}.edition.snackbar.success_delete`, { variant: "success" });
						setIsApiLoading(true);
						refresh();
					} catch (error: unknown) {
						if (error instanceof Error) {
							enqueueSnackbar(error.message, { variant: "error" });
						}
					} finally {
						handleModaleClose();
					}
				}
			};
			const handleRoomMoveUp = () => {
				moveCard(index, index - 1);
			};

			const handleRoomMoveDown = () => {
				moveCard(index, index + 1);
			};

			const handleNavigateRoomClick = (roomId: string) => {
				navigate(`./${roomId}`);
			};

			const getMenu = (): IUiMenu => {
				const menu: IUiMenu = {
					items: [
						{
							label: t(`${component}.buttons.details`),
							reference: detailsConfig.reference,
							icon: detailsConfig.icon,
							onClick: (room: RoomForm) => {
								handleNavigateRoomClick(room.id);
							},
						},
						{
							label: t(`${component}.buttons.edit`),
							reference: editConfig.reference,
							icon: editConfig.icon,
							onClick: (room: RoomForm) => {
								handleEditModaleOpen(room.id);
							},
						},
					],
				};

				if (index > 0) {
					menu.items.push({
						label: t(`${component}.buttons.moveUp`),
						reference: moveUpConfig.reference,
						icon: moveUpConfig.icon,
						onClick: () => {
							handleRoomMoveUp();
						},
					});
				}
				if (roomsQuery.data?.rooms && index < roomsQuery.data?.rooms.length - 1) {
					menu.items.push({
						label: t(`${component}.buttons.moveDown`),
						reference: moveDownConfig.reference,
						icon: moveDownConfig.icon,
						onClick: () => {
							handleRoomMoveDown();
						},
					});
				}

				menu.items.push("divider");
				menu.items.push({
					label: t(`${component}.buttons.delete`),
					reference: deleteConfig.reference,
					icon: deleteConfig.icon,
					onClick: (_event: RoomRead) => {
						confirmationModalProps.current = {
							action: EConfirmationModalAction.DELETE,
							handleAction: () => {
								handleDeleteRoomClick(_event.id);
							},
						};
						setOpenConfirmationModal(true);
					},
					isDisabled: !canDeleteRoom,
				});

				return menu;
			};

			return (
				<DraggableCard
					key={card.id}
					id={card.id}
					index={index}
					card={card}
					moveCard={moveCardWithoutMutation}
					handleDropAction={() => {
						handleSaveRoomsOrderOnDrop(stateRooms);
					}}
				>
					<RoomCard
						room={card}
						hasClashes={(roomsClashesQuery.data?.[card.id]?.length ?? 0) > 0}
						menu={getMenu()}
						handleCardSingleClick={() => {
							handleNavigateRoomClick(card.id);
						}}
						handleCardDoubleClick={() => {
							handleEditModaleOpen(card.id);
						}}
					/>
				</DraggableCard>
			);
		},
		[
			canDeleteRoom,
			client.id,
			moveCard,
			moveCardWithoutMutation,
			event,
			enqueueSnackbar,
			navigate,
			detailsConfig.reference,
			detailsConfig.icon,
			editConfig.reference,
			editConfig.icon,
			roomsClashesQuery.data,
			roomsQuery.data?.rooms,
			roomsOrder.mutateAsync,
			deleteConfig.reference,
			deleteConfig.icon,
			handleEditModaleOpen,
			stateRooms,
			moveUpConfig.reference,
			moveUpConfig.icon,
			moveDownConfig.reference,
			moveDownConfig.icon,
			refresh,
		],
	);

	const handleModaleClose = () => {
		setOpenConfirmationModal(false);
		setIsApiLoading(false);
	};

	const handleSaveRoom = async (_room: RoomForm, isCreateAnotherRoom: boolean) => {
		if (_room.id) {
			try {
				await updateRoomMutation.mutateAsync({ clientId: client.id, eventId: event.id, id: _room.id, body: roomFormToRoomWrite(_room) });
				enqueueSnackbar(t(`${component}.edition.snackbar.success_edit`), { variant: "success" });
				setIsApiLoading(true);
				refresh();
			} catch (error: unknown) {
				if (error instanceof Error) {
					enqueueSnackbar(error.message, { variant: "error" });
				}
			} finally {
				handleModaleClose();
			}
		} else {
			try {
				await createRoomMutation.mutateAsync({ clientId: client.id, eventId: event.id, body: roomFormToRoomWrite(_room) }, {});
				enqueueSnackbar(t(`${component}.edition.snackbar.success_create`), { variant: "success" });
				setIsApiLoading(true);
				refresh();
			} catch (error: unknown) {
				if (error instanceof Error) {
					enqueueSnackbar(error.message, { variant: "error" });
				}
			} finally {
				!isCreateAnotherRoom && handleModaleClose();
			}
		}
	};

	return (
		<>
			<TitleOld
				title={t(`${component}.title`)}
				buttons={[
					{
						label: t("button.add"),
						icon: { iconName: addConfig.icon },
						handleClick: () => {
							handleEditModaleOpen();
						},
					},
				]}
			/>
			<AppBox>
				<Stack spacing={2}>
					<SimpleTextSearchFilter
						handleChange={(value: string) => {
							setSearch(value);
						}}
					/>
					<DndProvider backend={HTML5Backend}>
						<DraggableList arrayOfCards={stateRooms} isLoading={isApiLoading || roomsQuery.isFetching} renderCard={renderCard} />
					</DndProvider>
				</Stack>
			</AppBox>

			<ConfirmationModal
				open={openConfirmationModal}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleModaleClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
		</>
	);
};
