import type { SessionRead, SessionTemplateRead } from "@api";
import type { PresentationModalForm } from "@components";
import { durationZod } from "@helpers";
import { type CustomFieldForm, customFieldsZod } from "@key4-front-library/core";
import { dateTimeNullable, dateTimeRangeRefine, integerZod } from "@mykey4/core";
import { isoDurationToString, stringISOToDateTime } from "@mykey4/core";
import type { UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const presentationDefaultValues: CustomFieldForm<PresentationModalForm> = {
	code: "",
	title: "",
	description: "",
	startDate: null,
	startHour: null,
	endDate: null,
	endHour: null,
	duration: "",
	order: "",
	participantOrContact: null,
	qualityId: "",
	customFields: {},
};

const baseSchema = z.object({
	code: z.string(),
	title: z.string(),
	description: z.string(),
	startDate: dateTimeNullable(),
	startHour: dateTimeNullable(),
	endDate: dateTimeNullable(),
	endHour: dateTimeNullable(),
	duration: durationZod(),
	order: integerZod().or(z.literal("")),
	customFields: customFieldsZod,
	participantOrContact: z.any().optional(),
	qualityId: z.string().optional(),
});

export const presentationSchema = baseSchema.superRefine(dateTimeRangeRefine);

/**
 * Two cases: a single day session or session without timeslot
 *
 * @param session
 */
export const isPresentationDateDisabled = (session: SessionRead): boolean => {
	if (!session.startDate || !session.endDate) {
		return true;
	}
	const start = stringISOToDateTime(session.startDate);
	const end = stringISOToDateTime(session.endDate);

	return start.hasSame(end, "year") && start.hasSame(end, "month") && start.hasSame(end, "day");
};

export const overridePresentationWithSessionTemplate = (
	form: UseFormReturn<CustomFieldForm<PresentationModalForm>, undefined, undefined>,
	sessionTemplate?: SessionTemplateRead,
) => {
	if (sessionTemplate?.presentationDuration) {
		form.setValue("duration", isoDurationToString(sessionTemplate.presentationDuration));
	}
};
