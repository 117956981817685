import { type PresentationRead, type SessionRead, getEventPresentations } from "@api";
import { AbstractsAssociation, type AbstractsAssociationResults } from "@components";
import { useOpenWindow } from "@hooks/useOpenWindow";
import { useContextModule } from "@key4-front-library/core";
import { Stack, useTheme } from "@mui/material";
import { Button, FilteringOperator, Loader, Typography, filtersOrInToValueString, useDialogs } from "@mykey4/core";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import type { AbstractWizardActionResult, AbstractWizardProps } from "../common";
import { AbstractsSessionSelectorDialog } from "../components";
import { AbstractsDissociationDialog } from "../components";
import { AbstractsSelectedWizardHeader } from "../components";
import { AbstractStepLayout } from "../components";
import { useAbstractAssociationBulkAction } from "../hooks";

const component = "abstract.bulkActions.steps.action.associate";

interface SessionSelectionSubStepProps {
	clientId: string;
	eventId: string;
	onSessionSelected: (session: SessionRead) => void;
}

const SessionSelectionSubStep = (props: SessionSelectionSubStepProps) => {
	const { clientId, eventId, onSessionSelected } = props;
	const { t } = useTranslation();
	const theme = useTheme();
	const dialog = useDialogs();
	const openDialog = useCallback(async () => {
		const session = await dialog.open(AbstractsSessionSelectorDialog, { clientId, eventId });
		if (session) {
			onSessionSelected(session);
		}
	}, [clientId, eventId, onSessionSelected]);
	return (
		<Stack direction="column">
			<Stack
				direction={"column"}
				p={3}
				spacing={3}
				alignItems={"center"}
				justifyContent={"center"}
				borderRadius={2}
				sx={{ backgroundColor: theme.palette.background.tone }}
			>
				<Typography>{t(`${component}.sessionSelection.text`)}</Typography>
				<Button onClick={openDialog} startIcon={{ name: "plus" }}>
					{t(`${component}.sessionSelection.button`)}
				</Button>
			</Stack>
		</Stack>
	);
};

interface AssociationStepProps {
	session: SessionRead;
	selectedAbstractIds: string[];
	onAssociationsChange: (associations: AbstractsAssociationResults) => void;
}

const AssociationStep = (props: AssociationStepProps) => {
	const { session, selectedAbstractIds, onAssociationsChange } = props;
	const { t } = useTranslation();
	const { openSession, openSessionPresentations, openAbstract } = useOpenWindow();

	const { isLoading, abstracts, presentations } = useAbstractAssociationBulkAction({
		session: session ?? undefined,
		selectedAbstractIds,
	});

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Stack direction="column" spacing={2}>
			<Typography variant="body2">{t(`${component}.text`)}</Typography>
			<AbstractsAssociation
				session={session}
				presentations={presentations.data?.data ?? []}
				abstracts={abstracts.data?.data ?? []}
				selectedAbstractIds={selectedAbstractIds}
				onAssociationsChange={onAssociationsChange}
				containerSx={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden", width: "100%" }}
				onSessionKeyClick={() => openSession(session.id)}
				onPresentationKeyClick={(presentation: PresentationRead) => openSessionPresentations(presentation.sessionId)}
				onAbstractKeyClick={(abstract) => openAbstract(abstract)}
			/>
		</Stack>
	);
};

export const AbstractsAssociationStep = (props: AbstractWizardProps) => {
	const { useWizardResult } = props;
	const { client, event } = useContextModule();
	const dialog = useDialogs();

	const [associations, setAssociations] = useState<AbstractsAssociationResults | undefined>(undefined);

	const [selectedAbstractIds] = useWizardResult("abstractSelection");
	const [current, setCurrent] = useWizardResult("action");
	const session = current?.sessionSelected;

	const handleAssociationsChange = useCallback((associations: AbstractsAssociationResults) => {
		setAssociations(associations);
	}, []);

	const handleSessionSelected = useCallback(
		(session: SessionRead) => {
			const newState = { ...current, sessionSelected: session };
			setCurrent(newState);
		},
		[current, setCurrent],
	);

	const handleNextClick = useCallback(async () => {
		if (!session) {
			return;
		}

		const paginated = await getEventPresentations({
			clientId: client.id,
			eventId: event.id,
			body: {
				page: 1,
				pageSize: 1,
				filters: `${filtersOrInToValueString("abstractId", FilteringOperator.Equal, selectedAbstractIds ?? [])}${FilteringOperator.And}sessionId${FilteringOperator.NotEqual}${session.id}`,
			},
		});

		const result: AbstractWizardActionResult = {
			sessionSelected: session,
			associate: associations,
			dissociate: [],
		};

		if (paginated?.pagination?.totalCount && paginated.pagination.totalCount > 0) {
			result.dissociate = await dialog.open(AbstractsDissociationDialog, { session, selectedAbstractIds: selectedAbstractIds ?? [] });
		}

		setCurrent(result);
	}, [session, selectedAbstractIds, associations, setCurrent, client.id, event.id]);

	return (
		<AbstractStepLayout
			slotProps={{
				buttons: {
					nextButton: {
						onBeforeClick: handleNextClick,
						disabled: !session || !associations || associations.length <= 0,
					},
				},
			}}
		>
			<AbstractsSelectedWizardHeader i18nKey={`${component}.title`} {...props} />
			{!session && <SessionSelectionSubStep clientId={client.id} eventId={event.id} onSessionSelected={handleSessionSelected} />}
			{session && selectedAbstractIds && (
				<AssociationStep session={session} selectedAbstractIds={selectedAbstractIds} onAssociationsChange={handleAssociationsChange} />
			)}
		</AbstractStepLayout>
	);
};
