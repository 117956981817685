import type { SessionTemplateRead } from "@api";
import { zodResolver } from "@hookform/resolvers/zod";
import { fromIso8601DurationToDateTime, fromIsoToDateTime, fromTimeWithSecondToDateTime } from "@mykey4/core";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { type SessionTemplateFormType, sessionTemplateSchema } from "./SessionTemplateForm.zod";
import type { SessionTemplateFormContextType } from "./SessionTemplateFormProvider";

export interface UseSessionTemplateFormArgs {
	primaryTagId: string;
	existing?: SessionTemplateRead;
}

export const useSessionTemplateForm = (args: UseSessionTemplateFormArgs): SessionTemplateFormContextType => {
	const { primaryTagId, existing } = args;
	const defaultValues = useMemo((): SessionTemplateFormType => {
		return {
			primaryTagId,
			startHour: fromTimeWithSecondToDateTime(existing?.startHour),
			endHour: fromTimeWithSecondToDateTime(existing?.endHour),
			duration: fromIso8601DurationToDateTime(existing?.duration),
			presentationCount: existing?.presentationCount,
			presentationDuration: fromIso8601DurationToDateTime(existing?.presentationDuration),
			status: existing?.status ?? "draft",
			isPublished: existing?.isPublished ?? false,
			publicationDate: fromIsoToDateTime(existing?.publicationDate),
			chairInvitationStatus: existing?.chairInvitationStatus ?? "unsent",
			speakerInvitationStatus: existing?.speakerInvitationStatus ?? "unsent",
			isPrivate: existing?.isPrivate ?? false,
		};
	}, [primaryTagId, existing]);

	const form = useForm<SessionTemplateFormType>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: defaultValues,
		resolver: zodResolver(sessionTemplateSchema),
	});

	useEffect(() => {
		form.reset(defaultValues);
	}, [form.reset, defaultValues]);

	return useMemo(() => {
		return {
			form,
			existing,
		};
	}, [form, existing]);
};
