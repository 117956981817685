import { type AbstractKeywordRead, postAbstractKeywordsSearchPaginated } from "@api";
import { API_VERSION_QUERY_PARAM, type BodyPostGet, type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { getAllData } from "../../common";

export const useAbstractKeywordsSearch = <TData = Paginated<AbstractKeywordRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Paginated<AbstractKeywordRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const override = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.abstractKeyword, ...queryKeyPart, "GetPost", override, body],
		queryFn: ({ signal }) =>
			postAbstractKeywordsSearchPaginated({
				clientId,
				eventId,
				body,
				queryStrings: override,
				signal,
			}),
		...others,
	});
};

export const useAbstractKeywordsSearchAll = <TData = Array<AbstractKeywordRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Array<AbstractKeywordRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const override = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	const postQueryFn = async (signal: AbortSignal | undefined): Promise<AbstractKeywordRead[]> => {
		return getAllData(
			(b) =>
				postAbstractKeywordsSearchPaginated({
					clientId,
					eventId,
					body: b,
					queryStrings: override,
					signal,
				}),
			body,
		);
	};

	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.abstractKeyword, ...queryKeyPart, "GetPost", "All", override, body],
		queryFn: ({ signal }) => postQueryFn(signal),
		...others,
	});
};
