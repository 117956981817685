import { YesNoSelect, type YesNoSelectProps } from "@components";
import { SessionPublicationStatusBulletText } from "../../Atoms";

export type SessionPublicationStatusSelectProps = Omit<YesNoSelectProps, "slots">;

export const SessionPublicationStatusSelect = (props: SessionPublicationStatusSelectProps) => {
	return (
		<YesNoSelect
			{...props}
			slots={{
				item: SessionPublicationStatusBulletText,
			}}
		/>
	);
};
