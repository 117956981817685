import { MenuItem, useTheme } from "@mui/material";
import type { UncapitalizeObjectKeys } from "@mui/x-date-pickers/internals/utils/slots-migration";
import { BulletText, Select, type SelectProps } from "@mykey4/core";
import { useCallback } from "react";

interface YesNoBulletTextProps {
	value: boolean;
}

const YesNoBulletText = (props: YesNoBulletTextProps) => {
	const { value } = props;
	const theme = useTheme();
	return (
		<BulletText
			text={`yesNoSelect.${value ? "yes" : "no"}`}
			slotProps={{
				icon: {
					color: value ? theme.palette.success.main : theme.palette.error.main,
				},
			}}
		/>
	);
};

export interface YesNoSelectComponentsProps {
	value: boolean;
}

export interface YesNoSelectComponents {
	Item?: React.JSXElementConstructor<YesNoSelectComponentsProps>;
}

export type YesNoSelectProps = Omit<SelectProps<boolean>, "defaultValue" | "value" | "onChange" | "renderValue" | "multiple" | "native"> & {
	defaultValue?: boolean;
	value: boolean | undefined | null;
	onChange: (value: boolean | null) => void;
	slots?: UncapitalizeObjectKeys<YesNoSelectComponents>;
};

export const YesNoSelect = (props: YesNoSelectProps) => {
	const { defaultValue, value, onChange, isRequired = true, slots, ...others } = props;

	const renderItem = useCallback(
		(value: number) => {
			if (!slots?.item) {
				return <YesNoBulletText value={value === 1} />;
			}
			const ItemComponent = slots.item;
			return <ItemComponent value={value === 1} />;
		},
		[slots?.item],
	);

	return (
		<Select<number>
			{...others}
			isRequired={isRequired}
			multiple={false}
			defaultValue={defaultValue === undefined ? undefined : +defaultValue}
			renderValue={renderItem}
			value={value === undefined || value === null ? "" : +value}
			onChange={(event) => {
				const changeValue = event.target.value;
				if (changeValue === "" || changeValue === undefined) {
					onChange(null);
					return;
				}
				onChange(event.target.value === 1);
			}}
		>
			<MenuItem value={1}>{renderItem(1)}</MenuItem>
			<MenuItem value={0}>{renderItem(0)}</MenuItem>
		</Select>
	);
};
