import SearchFilterController from "@application/Controllers/SearchFilterController";
import type { DtoSearchFilterGet } from "@key4-front-library/core/Dto";
import type { EnumSearchFilterScope } from "@key4-front-library/core/Enums";
import { getErrorMessage } from "@mykey4/core";
import { t } from "i18next";
import { useCallback } from "react";

const usePageList = (
	setNewQueryFilterId: any,
	setSearchFilterQueriesList: any,
	enqueueSnackbar: any,
	client: any,
	event: any,
	setOpenSaveNewQueryModale: any,
	scope: EnumSearchFilterScope,
) => {
	const getSearchFilterQueriesList = useCallback(
		async (clientId: string, eventId: string) => {
			const searchFilterQueriesList = await SearchFilterController.getList(clientId, eventId, scope);

			setNewQueryFilterId(undefined);
			setSearchFilterQueriesList([
				...[
					{
						id: null,
						scope,
						name: t("old.searchList.searchFilter.autoComplete.newQueryOption"),
						value: "",
					},
				],
				...searchFilterQueriesList,
			]);
		},
		[scope, setNewQueryFilterId, setSearchFilterQueriesList],
	);

	const handleCreateQuerySearchFilterClick = async (titleQuery: string, query: string) => {
		if (!query || query === "") {
			enqueueSnackbar(t("old.searchList.searchFilter.snackbar.createEmpty"), {
				variant: "error",
			});
			return;
		}
		try {
			const createResponse = await SearchFilterController.create(client.id, event.id, titleQuery, query, scope);
			enqueueSnackbar(t("old.searchList.searchFilter.snackbar.createSuccess"), {
				variant: "success",
			});
			setOpenSaveNewQueryModale(false);
			await getSearchFilterQueriesList(client.id, event.id);
			setNewQueryFilterId(createResponse.id);
		} catch (e) {
			const errorMessage = getErrorMessage(e);
			enqueueSnackbar(errorMessage, {
				variant: "error",
			});
		}
	};

	const handleUpdateQueryFilterClick = async (querySearchFilter: DtoSearchFilterGet) => {
		if (!event?.id || !querySearchFilter.id) {
			return;
		}

		try {
			await SearchFilterController.update(client.id, event.id, querySearchFilter.id, querySearchFilter);
			enqueueSnackbar(t("old.searchList.searchFilter.snackbar.modifySuccess"), {
				variant: "success",
			});
			await getSearchFilterQueriesList(client.id, event.id);
		} catch (e) {
			const errorMessage = getErrorMessage(e);
			enqueueSnackbar(errorMessage, {
				variant: "error",
			});
		}
	};

	const handleDeleteQueryFilterClick = async (filterQueryId: string) => {
		if (!event?.id) {
			return;
		}

		try {
			await SearchFilterController.remove(client.id, event.id, filterQueryId);
			enqueueSnackbar(t("old.searchList.searchFilter.snackbar.deleteSuccess"), {
				variant: "success",
			});
			await getSearchFilterQueriesList(client.id, event.id);
		} catch (e) {
			const errorMessage = getErrorMessage(e);
			enqueueSnackbar(errorMessage, {
				variant: "error",
			});
		}
	};

	return {
		getSearchFilterQueriesList,
		handleCreateQuerySearchFilterClick,
		handleUpdateQueryFilterClick,
		handleDeleteQueryFilterClick,
	};
};

export default usePageList;
