import { type AbstractRead, type PresentationRead, type SessionRead, useAbstractsAll, useEventPresentationsAll, useSessionsAll } from "@api";
import { filtersOrInToQueryString, filtersOrInToValueString, useContextModule } from "@key4-front-library/core";
import { FilteringOperator, filtersOrInToBodyPostGet } from "@mykey4/core";
import { type UseQueryResult, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

const buildPresentationQuery = (sessionId?: string, abstractIds?: string[]) => {
	let queryFilter = `${filtersOrInToValueString("abstractId", FilteringOperator.Equal, abstractIds ?? [])}`;
	if (sessionId) {
		queryFilter += `${FilteringOperator.And}sessionId${FilteringOperator.NotEqual}${sessionId}`;
	}
	return {
		filters: queryFilter,
	};
};

export interface UseAbstractDissociationBulkActionArgs {
	sessionId?: string;
	abstractIds?: string[];
}

export interface UseAbstractDissociationBulkActionArgsReturn {
	isFetching: boolean;
	isLoading: boolean;
	abstracts: UseQueryResult<AbstractRead[]>;
	presentations: UseQueryResult<PresentationRead[]>;
	sessions: UseQueryResult<SessionRead[]>;
	refresh: () => Promise<void>;
}

export const useAbstractDissociationBulkAction = (args: UseAbstractDissociationBulkActionArgs): UseAbstractDissociationBulkActionArgsReturn => {
	const key = "useAbstractDissociationBulkAction";
	const { sessionId, abstractIds } = args;
	const {
		client: { id: clientId },
		event: { id: eventId },
	} = useContextModule();
	const query = useQueryClient();

	const abstracts = useAbstractsAll(clientId, eventId, filtersOrInToBodyPostGet("id", FilteringOperator.Equal, abstractIds ?? []), {
		queryKeyPart: [key],
		enabled: !!abstractIds && abstractIds.length > 0,
	});

	const presentations = useEventPresentationsAll(clientId, eventId, {
		queryKeyPart: [key],
		enabled: !!abstractIds && abstractIds.length > 0,
		body: buildPresentationQuery(sessionId, abstractIds),
	});

	const sessionIds = useMemo(() => presentations.data?.map((p) => p.sessionId).filter(Boolean) || [], [presentations.data]);

	const isSessionsEnabled = sessionIds.length > 0;

	const sessions = useSessionsAll(clientId, eventId, {
		queryKeyPart: [key],
		enabled: isSessionsEnabled,
		queryStrings: [...filtersOrInToQueryString("id", FilteringOperator.Equal, sessionIds)],
	});

	const refresh = useCallback(async () => {
		await query.invalidateQueries({
			predicate: (query) => query.queryKey.find((t) => typeof t === "string" && t === key) !== undefined,
		});
	}, [query]);

	const isFetching = presentations.isFetching || abstracts.isFetching || sessions.isFetching;
	const isLoading = presentations.isLoading || abstracts.isLoading || sessions.isLoading;

	return useMemo(() => {
		return {
			isFetching,
			isLoading,
			abstracts,
			presentations,
			sessions,
			refresh,
		};
	}, [isFetching, isLoading, presentations, abstracts, sessions, refresh]);
};
