import { type RoomRead, type SessionRead, useRooms, useSession, useSessionTags, useSessionsTags, useTagTypes } from "@api";
import { type SessionModalLiteTagTypes, type SessionModalTagTypes, mapTagTypesAndLiteTags, mapTagTypesAndTags } from "@components";
import { FilteringOperator, type Paginated, filtersOrInToBodyPostGet, sortToQueryString, useIsModuleActivatedOnOperation } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export interface UseSessionModalArgs {
	clientId: string;
	eventId: string;
	sessionId: string | undefined;
}

export interface UseSessionModalReturn {
	isLoading: boolean;
	isFetching: boolean;
	session: UseQueryResult<SessionRead>;
	rooms: UseQueryResult<Paginated<RoomRead>>;
	isAbstractModuleActivated: UseQueryResult<boolean>;
	tagTypes: UseQueryResult<SessionModalTagTypes>;
	sessionTagTypes: UseQueryResult<SessionModalLiteTagTypes>;
}

export const useSessionModal = (args: UseSessionModalArgs) => {
	const { clientId, eventId, sessionId } = args;

	const sessionQuery = useSession(clientId, eventId, sessionId ?? "", {
		queryKeyPart: ["useSessionModal"],
		enabled: !!sessionId,
	});

	const tagTypes = useTagTypes(clientId, eventId, {
		queryKeyPart: ["useSessionModal"],
		queryStrings: sortToQueryString([{ field: "order", sort: "asc" }]),
		select: (response) => {
			return {
				allTagTypes: response.data,
				allTagTypeIds: response.data.map((tagType) => tagType.id),
			};
		},
	});

	const allTagTypeIds = tagTypes.data?.allTagTypeIds ?? [];
	const allTagTypes = tagTypes.data?.allTagTypes ?? [];

	const tags = useSessionsTags(clientId, eventId, filtersOrInToBodyPostGet("tagTypeId", FilteringOperator.Equal, allTagTypeIds), {
		queryKeyPart: ["useSessionModal"],
		enabled: allTagTypeIds.length > 0,
	});

	const sessionTags = useSessionTags(clientId, eventId, sessionId ?? "", {
		queryKeyPart: ["useSessionModal"],
		enabled: !!sessionId,
	});

	const sessionsTagTypes = useQuery<SessionModalTagTypes>({
		queryKey: ["useSessionModal", allTagTypes, tags.data],
		enabled: allTagTypes.length > 0,
		queryFn: () => mapTagTypesAndTags(allTagTypes, tags.data ?? []),
	});

	const sessionTagTypes = useQuery<SessionModalLiteTagTypes>({
		queryKey: ["useSessionModal", allTagTypes, sessionTags.data],
		enabled: allTagTypes.length > 0,
		queryFn: () => mapTagTypesAndLiteTags(allTagTypes, sessionTags.data?.data ?? []),
	});

	const rooms = useRooms(clientId, eventId, { queryKeyPart: ["useSessionModal"] });
	const isAbstractModuleActivated = useIsModuleActivatedOnOperation(clientId, eventId, "Abstracts");

	const isLoading =
		sessionQuery.isLoading ||
		tagTypes.isLoading ||
		tags.isLoading ||
		sessionsTagTypes.isLoading ||
		rooms.isLoading ||
		isAbstractModuleActivated.isLoading ||
		sessionTags.isLoading ||
		sessionTagTypes.isLoading;

	const isFetching =
		sessionQuery.isFetching ||
		tagTypes.isFetching ||
		tags.isFetching ||
		sessionsTagTypes.isFetching ||
		rooms.isFetching ||
		isAbstractModuleActivated.isFetching ||
		sessionTags.isFetching ||
		sessionTagTypes.isFetching;

	return useMemo<UseSessionModalReturn>(() => {
		return {
			isLoading,
			isFetching,
			session: sessionQuery,
			rooms,
			isAbstractModuleActivated,
			tagTypes: sessionsTagTypes,
			sessionTagTypes: sessionTagTypes,
		};
	}, [isLoading, isFetching, sessionQuery, rooms, isAbstractModuleActivated, sessionsTagTypes, sessionTagTypes]);
};
