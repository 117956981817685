import type { SessionRead } from "@api";
import { Button, Checkbox, Grid, GridLabel, Link, PaperTitle, Stack, Typography, getButton, useIsModuleActivatedOnOperation } from "@mykey4/core";
import { t } from "i18next";
import { useCallback } from "react";
import type { SessionExternalLink } from "../../../Pages/Sessions/SessionDetails/Hooks";

export interface GeneralSectionSessionProps {
	clientId: string;
	eventId: string;
	session: SessionRead;
	sessionExternalLink?: SessionExternalLink | null;
	onExternalLinkDeleteClick: () => Promise<void>;
}

export const GeneralSectionSession = (props: GeneralSectionSessionProps): React.ReactNode => {
	const component = "generalSectionSession";
	const { clientId, eventId, session, sessionExternalLink, onExternalLinkDeleteClick } = props;

	const handleExternalLinkDelete = useCallback(async () => {
		await onExternalLinkDeleteClick();
	}, [onExternalLinkDeleteClick]);

	const isAbstractModuleActivated = useIsModuleActivatedOnOperation(clientId, eventId, "Abstracts").data;

	return (
		<Stack>
			<PaperTitle
				title={{
					icon: { variant: "h5", prefix: "fal", name: "clipboard-list", color: "primary.light" },
					children: <Typography variant="h5">{t(`${component}.title`)}</Typography>,
				}}
			>
				<Grid container spacing={2}>
					{isAbstractModuleActivated && (
						<GridLabel label={t(`${component}.displayer.isAbstract`)} gridSize={{ xs: 12 }}>
							<Checkbox checked={session.isAbstract === true} isDisabled />
						</GridLabel>
					)}
					<GridLabel label={t(`${component}.displayer.code`)}>
						<Typography>{session.code ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.organizedBy`)}>
						<Typography>{session.organizedBy ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.title`)}>
						<Typography>{session.title ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.description`)}>
						<Typography>{session.description ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.isPrivate`)}>
						<Checkbox checked={session.isPrivate} isDisabled />
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.order`)}>
						<Typography>{session.order ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.externalLink`)} gridSize={{ xs: 12 }}>
						<Stack direction="row" alignItems={"center"}>
							{sessionExternalLink?.externalLink ? <Link to={sessionExternalLink.externalLink}>{sessionExternalLink.externalLink}</Link> : <br />}
							{sessionExternalLink?.externalLink && <Button onClick={handleExternalLinkDelete} {...getButton({ type: "delete" })} isIcon variant="text" />}
						</Stack>
					</GridLabel>
				</Grid>
			</PaperTitle>
		</Stack>
	);
};
