import DateHelper from "@application/helpers/date.helper";
import { ESchedulerView } from "@domain/interfaces/calendar.interface";
import type { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { Button, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { type DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import type { IconName, IconPrefix } from "@mykey4/core";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

type Props = {
	calendar: any;
	view: ESchedulerView;
	changeView: (view: ESchedulerView) => void;
	locale: string;
};

const HeaderCalendarRoom = ({ calendar, view, changeView = (_view: ESchedulerView) => {}, locale }: Props) => {
	const theme = useTheme();
	const [title, setTitle] = useState<any>();
	const [currentRange, setCurrentRange] = useState<DateRange<DateTime>>([null, null]);
	const [currentView, setCurrentView] = useState<ESchedulerView>(ESchedulerView.ROOM);
	const [datePickerLimit, setDatePickerLimit] = useState<any>();
	const [isOpen, setIsOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const viewModes = [
		{
			value: ESchedulerView.ROOM,
			icon: "calendar-days",
			prefix: "far",
			size: "lg",
			disabled: true,
		},
		{
			value: ESchedulerView.MULTIROOM,
			icon: "calendar-day",
			prefix: "far",
			size: "lg",
			disabled: false,
		},
	];

	useEffect(() => {
		if (calendar.current) {
			const calendarApi = calendar.current.getApi();
			setTitle(calendarApi.view.title);
			const minDate = DateTime.fromISO(new Date(calendarApi.view.currentStart).toISOString());
			// Warning in view calendar currentEnd is + 1 day (so minus one day to have the real end date)
			const maxDate = DateTime.fromISO(new Date(calendarApi.view.currentEnd).toISOString()).minus({ days: 1 });
			setDatePickerLimit({
				min: minDate,
				max: maxDate,
			});
			setCurrentRange([minDate, maxDate]);
		}
	}, [calendar]);

	useEffect(() => {
		setCurrentView(view);
	}, [view]);

	useEffect(() => {
		if (calendar.current) {
			const calendarApi = calendar.current.getApi();
			setTitle(calendarApi.view.title);
		}
	}, [calendar, locale]);

	const handlePreviousClick = () => {
		if (!currentRange[0] || !currentRange[1]) return;
		const dateStart = currentRange[0].minus({ day: 1 });
		const dateEnd = currentRange[1].minus({ day: 1 });
		setCurrentRange([dateStart, dateEnd]);
		const calendarApi = calendar.current.getApi();

		calendarApi.gotoDate(dateStart.toFormat("yyyy-MM-dd"));

		calendarApi.dispatch({
			type: "SET_OPTION",
			optionName: "duration",
			rawOptionValue: {
				days: DateHelper.getDurationDays(dateStart, dateEnd) + 1,
			},
		});
		setTitle(calendarApi.view.title);
	};

	const handleNextClick = () => {
		if (!currentRange[0] || !currentRange[1]) return;
		const dateEnd = currentRange[1].plus({ day: 1 });
		const dateStart = currentRange[0].plus({ day: 1 });
		setCurrentRange([dateStart, dateEnd]);
		const calendarApi = calendar.current.getApi();
		calendarApi.gotoDate(dateStart.toFormat("yyyy-MM-dd"));
		calendarApi.dispatch({
			type: "SET_OPTION",
			optionName: "duration",
			rawOptionValue: {
				days: DateHelper.getDurationDays(dateStart, dateEnd) + 1,
			},
		});
		setTitle(calendarApi.view.title);
	};

	const handlechangeViewClick = (_event: React.MouseEvent<HTMLElement>, view: ESchedulerView) => {
		changeView(view);
		const calendarApi = calendar.current.getApi();
		calendarApi.changeView(view);
		setTitle(calendarApi.view.title);
	};

	const handleDateClick = (e: any) => {
		setIsOpen(!isOpen);
		setAnchorEl(e.currentTarget);
	};

	const handleDateRangeChange = (newRange: DateRange<DateTime>) => {
		if (calendar.current && newRange[1] && newRange[0] && newRange[1] >= newRange[0]) {
			const calendarApi = calendar.current.getApi();
			calendarApi.gotoDate(newRange[0].toFormat("yyyy-MM-dd"));
			calendarApi.dispatch({
				type: "SET_OPTION",
				optionName: "duration",
				rawOptionValue: {
					days: DateHelper.getDurationDays(newRange[0], newRange[1]) + 1,
				},
			});
			setCurrentRange(newRange);
			setTitle(calendarApi.view.title);
		}
	};

	return (
		<Stack>
			<Stack direction="row" alignItems={"center"} justifyContent={"space-between"} my=".8em">
				<Stack sx={{ width: "80px", marginLeft: "1em" }}></Stack>
				{currentRange[0] && currentRange[1] && (
					<Stack direction="row" alignItems={"center"} sx={{ fontWeight: "light" }}>
						<IconButton
							aria-label="left"
							sx={{ width: "40px", height: "40px" }}
							onClick={handlePreviousClick}
							disabled={currentRange[0].toFormat("yyyy-MM-dd") === datePickerLimit?.min.toFormat("yyyy-MM-dd")}
						>
							<Typography component={"div"} fontSize=".7em" fontWeight={"inherit"}>
								<Icon prefix="fal" iconName={"chevron-left"} color={"inherit"} />
							</Typography>
						</IconButton>
						<Button size="medium" onClick={handleDateClick}>
							<Typography fontSize="1.8rem" fontWeight={"Light"} sx={{ textTransform: "capitalize" }}>
								{title}
							</Typography>
						</Button>
						<IconButton
							aria-label="left"
							sx={{ width: "40px", height: "40px" }}
							disabled={currentRange[1].toFormat("yyyy-MM-dd") === datePickerLimit?.max.toFormat("yyyy-MM-dd")}
							onClick={handleNextClick}
						>
							<Typography component={"div"} fontSize=".7em" fontWeight={"inherit"}>
								<Icon prefix="fal" iconName={"chevron-right"} color={"inherit"} />
							</Typography>
						</IconButton>
					</Stack>
				)}
				<ToggleButtonGroup
					sx={{ width: "80px", marginRight: "1em" }}
					value={currentView}
					exclusive
					onChange={handlechangeViewClick}
					aria-label="text alignment"
				>
					{viewModes.map((item: any, key: any) => {
						return (
							<ToggleButton
								disabled={item.disabled}
								key={key}
								sx={{
									padding: ".5em 1em",
									backgroundColor: currentView === item.value ? `${theme.palette.primary.main} !important` : "",
								}}
								value={item.value}
							>
								<Icon
									size={item.size as SizeProp}
									color={currentView === item.value ? `${theme.palette.common.white} !important` : ""}
									prefix={item.prefix as IconPrefix}
									iconName={item.icon as IconName}
								/>
							</ToggleButton>
						);
					})}
				</ToggleButtonGroup>
			</Stack>
			<Stack>
				{datePickerLimit && (
					<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
						<DateRangePicker
							minDate={datePickerLimit.min}
							maxDate={datePickerLimit.max}
							open={isOpen}
							sx={{
								display: "none",
							}}
							onClose={() => {
								setIsOpen(false);
							}}
							value={currentRange}
							onChange={handleDateRangeChange}
							slotProps={{
								textField: { sx: { display: "none" } }, // Cache l'input
								popper: {
									placement: "bottom",
									anchorEl,
								},
							}}
						/>
					</LocalizationProvider>
				)}
			</Stack>
		</Stack>
	);
};

export default HeaderCalendarRoom;
