import ConfigurationApplication from "@application/Configurations/configuration";
import CustomFieldController from "@application/Controllers/CustomFieldController";
import SessionController from "@application/Controllers/SessionController";
import { useSessionStatusConfigurations } from "@configurations";
import { EBulkActionAction } from "@infrastructure/helpers/bulkAction.helper";
import { BulkAction, type EBulkActionEntity, type TPickForm, useContextModule, useCustomFieldDefinitions } from "@key4-front-library/core";
import { type TUseBulkActionProps, useBulkAction } from "@key4-front-library/core/Bo/Hooks/BulkAction/useBulkAction";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";
import { PermissionContext, SessionStatusTypes } from "@mykey4/core";
import { useCallback, useContext, useEffect } from "react";
import { getSessionCustomFieldPickForms, patchSessionEditBulkAction } from "./BulActionSession.configuration";

const BulkActionSession = () => {
	const permissions = useContext(PermissionContext);
	const sessionStatusConfigurations = useSessionStatusConfigurations();

	const cbDataGridRows = async (clientId: string, eventId: string): Promise<number | Array<GridValidRowModel>> => {
		return await SessionController.getSessionsListBulkActionStepSelect(
			clientId,
			eventId,
			filters ? filters.search : "",
			[],
			filters ? filters.queryBuilderString : "",
			sessionStatusConfigurations,
		);
	};

	const { client, event } = useContextModule();
	const customFieldDefinitionsQuery = useCustomFieldDefinitions("programme", "session", client.id, event.id, undefined, { queryKeyPart: ["BulkAction"] });
	const cbStepActionSetupEditGetForm = useCallback(
		async (clientId: string, eventId: string, activeEntity: EBulkActionEntity): Promise<Array<TPickForm>> => {
			return [
				...(await CustomFieldController.getForms(clientId, eventId, activeEntity)),
				...(customFieldDefinitionsQuery.data ? getSessionCustomFieldPickForms(customFieldDefinitionsQuery.data) : []),
			];
		},
		[customFieldDefinitionsQuery.data],
	);

	const hookBulkAction: TUseBulkActionProps = {
		cbDataGridRows,
		cbStepActionSetupEditGetForm,
	};
	const {
		handleActiveStepChange,
		activeEntity,
		filters,
		action,
		activeStep,
		isNoStepSelect,
		stepSelect,
		selectedItems,
		stepActionSetup,
		setStepConfirmationIsAPIProcessing,
		getStepSelectData,
		setupStepChooseAction,
		setupStepActionSetupEdit,
		setupStepActionSetupTransition,
		handleConfirmationClick,
		setupClearStepConfirmation,
	} = useBulkAction(hookBulkAction);

	// #region 'STEP 1'
	useEffect(() => {
		getStepSelectData(client.id, event.id, ConfigurationApplication.Session.datagridHeaders);
	}, [event]);
	// #endregion 'STEP 1'

	// #region 'STEP 2'
	useEffect(() => {
		setupStepChooseAction(
			ConfigurationApplication.Session.bulkActionActions.map((action) => ({
				action: action.action,
				isAuthorized: action.permissions ? action.permissions.every((permission) => permissions.includes(permission)) : true,
			})),
		);
	}, [activeEntity]);
	// #endregion 'STEP 2'

	// #region 'STEP 3'
	// #region 'STEP 3 EDIT'
	useEffect(() => {
		setupStepActionSetupEdit(client.id, event.id);
	}, [activeStep, event]);
	// #endregion 'STEP 3 EDIT'

	// #region 'STEP 3 TRANSITION'
	useEffect(() => {
		setupStepActionSetupTransition(
			SessionStatusTypes.map((s) => {
				const config = sessionStatusConfigurations[s];
				return {
					value: s,
					label: config.translationKey,
					bgColor: config.backgroundColor,
					fontColor: config.color,
				};
			}),
		);
	}, [activeEntity, action, activeStep]);
	// #endregion 'STEP 3 TRANSITION'
	// #endregion 'STEP 3'

	const cbConfirm = async (clientId: string, eventId: string) => {
		let response: any = null;
		switch (action) {
			case EBulkActionAction.PUBLISH:
				response = await SessionController.patchSessionPublishBulkAction(
					clientId,
					eventId,
					filters,
					isNoStepSelect,
					stepSelect.rows ?? [],
					selectedItems,
					stepActionSetup.publish,
				);
				break;
			case EBulkActionAction.TRANSITION:
				if (!stepActionSetup.transition.value) {
					return;
				}
				response = await SessionController.patchSessionTransitionBulkAction(
					clientId,
					eventId,
					filters,
					isNoStepSelect,
					stepSelect.rows ?? [],
					selectedItems,
					stepActionSetup.transition.value,
				);
				break;
			case EBulkActionAction.NUMBERING:
				response = await SessionController.putNumberingSessionBulkAction(
					clientId,
					eventId,
					filters,
					isNoStepSelect,
					stepSelect.rows ?? [],
					selectedItems,
					stepActionSetup.numbering.type,
				);
				break;
			case EBulkActionAction.DELETE:
				response = await SessionController.deleteSessionBulkAction(clientId, eventId, filters, isNoStepSelect, stepSelect.rows ?? [], selectedItems);
				break;
			case EBulkActionAction.EDIT:
				if (!stepActionSetup.edit.useFormValues) {
					return;
				}
				if (!stepActionSetup.edit.useFormValues) return;
				response = await patchSessionEditBulkAction(
					clientId,
					eventId,
					filters,
					isNoStepSelect,
					stepSelect.rows ?? [],
					selectedItems,
					stepActionSetup.edit.useFormValues,
				);
		}

		return response;
	};
	// #region 'STEP 4'
	const handleCallAPIClick = async () => {
		await handleConfirmationClick(cbConfirm);
	};

	// Clear stepConfirmation data from store when we leave step 4 page
	useEffect(() => {
		setupClearStepConfirmation();
	}, [activeStep]);
	// #endregion 'STEP 4'

	return (
		<BulkAction
			changeActiveStep={handleActiveStepChange}
			onCallAPIClick={async () => {
				setStepConfirmationIsAPIProcessing(true);
				await handleCallAPIClick();
			}}
		/>
	);
};

export default BulkActionSession;
