import { motion } from "framer-motion";
import { t } from "i18next";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import { MenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import IconCardWithButtonAndMenu from "@infrastructure/components/interface/card/IconCardWithButtonAndMenu";
import type { IUiMenu } from "@infrastructure/model/interfaces/components/UiMenu.interface";
import { ConfirmationModal, type DtoQuality } from "@key4-front-library/core";
import { Box } from "@mui/material";

import type { Identifier } from "dnd-core";

interface DragItem {
	index: number;
}

type Props = {
	index: number;
	chairQuality: DtoQuality;
	chairQualitiesCount: number;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
	handleDeleteChairQuality: (id: string) => void;
	handleOpenModalChairQualityEdit: (chairQuality: DtoQuality) => void;
};

const AdvancedSettingsChairQualityRender = (props: Props) => {
	const { index, chairQuality, chairQualitiesCount, moveCard, handleDeleteChairQuality, handleOpenModalChairQualityEdit } = props;

	const [confirmationModalDeleteOpenState, setConfirmationModalDeleteOpenState] = useState<boolean>(false);

	const editConfig = MenuActionsConfigurations[MenuActionsReference.EDIT];
	const deleteConfig = MenuActionsConfigurations[MenuActionsReference.DELETE];
	const dragAndDropConfig = MenuActionsConfigurations[MenuActionsReference.DRAG_AND_DROP];
	const moveUpConfig = MenuActionsConfigurations[MenuActionsReference.UP];
	const moveDownConfig = MenuActionsConfigurations[MenuActionsReference.DOWN];

	const handleConfimationModalDeleteOpen = () => {
		setConfirmationModalDeleteOpenState(true);
	};
	const handleConfimationModalDeleteClose = () => {
		setConfirmationModalDeleteOpenState(false);
	};

	const handleChairQualityDelete = () => {
		handleDeleteChairQuality(chairQuality.id);
	};

	const handleChairQualityMoveUp = () => {
		moveCard(index, index - 1);
	};

	const handleChairQualityMoveDown = () => {
		moveCard(index, index + 1);
	};

	const getMenu = (): IUiMenu => {
		const menu: IUiMenu = {
			items: [
				{
					label: t("old.programme.advancedSettings.chairs.buttons.edit"),
					reference: editConfig?.reference!,
					icon: editConfig?.icon!,
					onClick: () => {
						handleOpenModalChairQualityEdit(chairQuality);
					},
				},
			],
		};

		menu.items.push("divider");

		if (index > 0) {
			menu.items.push({
				label: t("old.programme.advancedSettings.chairs.buttons.moveUp"),
				reference: moveUpConfig?.reference!,
				icon: moveUpConfig?.icon!,
				onClick: handleChairQualityMoveUp,
			});
		}
		if (index < chairQualitiesCount - 1) {
			menu.items.push({
				label: t("old.programme.advancedSettings.chairs.buttons.moveDown"),
				reference: moveDownConfig?.reference!,
				icon: moveDownConfig?.icon!,
				onClick: handleChairQualityMoveDown,
			});
		}
		menu.items.push("divider");
		menu.items.push({
			label: t("old.programme.advancedSettings.chairs.buttons.delete"),
			reference: deleteConfig?.reference!,
			icon: deleteConfig?.icon!,
			onClick: handleConfimationModalDeleteOpen,
		});
		return menu;
	};

	const ref = useRef<HTMLDivElement>(null);

	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: "CHAIR_QUALITY",
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current.getBoundingClientRect();

			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			const clientOffset = monitor.getClientOffset();
			if (!clientOffset) {
				return;
			}
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveCard(dragIndex, hoverIndex);

			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: "CHAIR_QUALITY",
		item: () => {
			return { index };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(drop(ref));

	const opacity = isDragging ? 0.5 : 1;

	return (
		<>
			<Box ref={ref} style={{ opacity }} data-handler-id={handlerId}>
				<motion.div whileHover={{ scale: 1.005 }}>
					<IconCardWithButtonAndMenu title={chairQuality.label} icon={dragAndDropConfig?.icon} menu={getMenu()} />
				</motion.div>
			</Box>
			<ConfirmationModal
				maxWidth={"sm"}
				open={confirmationModalDeleteOpenState}
				action={EConfirmationModalAction.DELETE}
				handleAction={handleChairQualityDelete}
				handleModaleClose={handleConfimationModalDeleteClose}
			/>
		</>
	);
};

export default AdvancedSettingsChairQualityRender;
