import { useDashboardOverview } from "@api";
import Card from "@infrastructure/components/interface/card/Card";
import { ApplicationColors, useContextModule } from "@key4-front-library/core";
import { Box, Grid, Skeleton, alpha } from "@mui/material";
import type { RegularBreakpoints } from "@mui/material/Grid/Grid";
import { t } from "i18next";
import Chart from "react-apexcharts";
import { buildOverviewChartOptions } from "./DashboardOverview.helper";

export type DashboardOverviewProps = RegularBreakpoints & {};

export const DashboardOverview = (props: DashboardOverviewProps) => {
	const { ...restProps } = props;
	const {
		client: { id: clientId },
		event: { id: eventId },
	} = useContextModule();

	const overview = useDashboardOverview({
		clientId,
		eventId,
		select: (data) => buildOverviewChartOptions(data),
	});

	if (overview.isFetching) {
		return (
			<Grid {...restProps} item display={"flex"}>
				<Skeleton
					variant="rounded"
					width="100%"
					height={"100%"}
					sx={{
						borderRadius: "16px",
						bgcolor: alpha(ApplicationColors.blue.main, 0.5),
						minHeight: 300,
					}}
				/>
			</Grid>
		);
	}

	return (
		<Grid {...restProps} item display={"flex"}>
			<Card title={t("old.programme.dashboard.charts.bar.title")} backgroundColor={ApplicationColors.blue.main}>
				<Box style={{ width: "95%", maxHeight: "250px", overflow: "hidden" }}>
					{overview.data && <Chart type="bar" options={overview.data.options} series={overview.data.series} width={"95%"} height={"250px"} />}
				</Box>
			</Card>
		</Grid>
	);
};

export default DashboardOverview;
