import { Typography } from "@mui/material";
import { Tooltip, type TypographyProps } from "@mykey4/core";
import { memo, useState } from "react";

export type TypographyWithTooltip = TypographyProps;

export const TypographyWithTooltip = memo((props: TypographyWithTooltip) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	let hoverTimer: ReturnType<typeof setTimeout>;

	const handleMouseEnter = () => {
		hoverTimer = setTimeout(() => {
			setTooltipOpen(true);
		}, 500);
	};

	const handleMouseLeave = () => {
		clearTimeout(hoverTimer);
		setTooltipOpen(false);
	};

	return (
		<Tooltip title={props.children || ""} open={tooltipOpen} disableFocusListener disableHoverListener disableTouchListener>
			<Typography {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
		</Tooltip>
	);
});
