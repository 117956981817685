import { type AbstractRead, type PresentationRead, type SessionRead, useAbstracts, usePresentations } from "@api";
import { useContextModule } from "@key4-front-library/core";
import { FilteringOperator, type Paginated, filtersOrInToBodyPostGet } from "@mykey4/core";
import { type UseQueryResult, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

interface UseAbstractAssociationBulkActionArgs {
	session?: SessionRead;
	selectedAbstractIds?: string[];
}

interface UseAbstractAssociationBulkActionReturn {
	isFetching: boolean;
	isLoading: boolean;
	abstracts: UseQueryResult<Paginated<AbstractRead>>;
	presentations: UseQueryResult<Paginated<PresentationRead>>;
	refresh: () => Promise<void>;
}

export const useAbstractAssociationBulkAction = (props: UseAbstractAssociationBulkActionArgs): UseAbstractAssociationBulkActionReturn => {
	const key = "useAbstractAssociationBulkAction";
	const {
		client: { id: clientId },
		event: { id: eventId },
	} = useContextModule();
	const { session, selectedAbstractIds } = props;
	const query = useQueryClient();

	const presentations = usePresentations(clientId, eventId, session?.id ?? "", {
		queryKeyPart: [key],
		enabled: !!session,
	});

	const abstractIds = useMemo(() => {
		let ids = selectedAbstractIds ?? [];
		if (presentations.data) {
			const abstractIds = presentations.data.data.filter((item) => !!item.abstractId).map((item) => item.abstractId as string);
			ids = [...ids, ...abstractIds];
		}
		return new Set(ids);
	}, [presentations.data, selectedAbstractIds]);

	const abstracts = useAbstracts(clientId, eventId, filtersOrInToBodyPostGet("id", FilteringOperator.Equal, abstractIds.values().toArray()), {
		queryKeyPart: [key],
		enabled: abstractIds.size > 0,
	});

	const refresh = useCallback(async () => {
		await query.invalidateQueries({
			predicate: (query) => query.queryKey.find((t) => typeof t === "string" && t === key) !== undefined,
		});
	}, [query]);

	const isFetching = presentations.isFetching || abstracts.isFetching;
	const isLoading = presentations.isLoading || abstracts.isLoading;

	return useMemo(() => {
		return {
			isFetching,
			isLoading,
			abstracts,
			presentations,
			refresh,
		};
	}, [isFetching, isLoading, presentations, abstracts, refresh]);
};
