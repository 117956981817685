import DataGridHelper from "@infrastructure/helpers/dataGrid.helper";
import type { GridColDef } from "@mui/x-data-grid-pro";

const dataGridNumbering: GridColDef[] = [
	{
		field: "primaryTag",
		renderCell: DataGridHelper.getCellChipColor,
		minWidth: 280,
		sortComparator: (a, b) => {
			const labelA: string = a?.chip?.label ?? "";
			const labelB: string = b?.chip?.label ?? "";
			return labelA.localeCompare(labelB);
		},
		flex: 2,
	},
	{ field: "sessionPrefix", editable: true, flex: 2 },
	{ field: "presentationPrefix", editable: true, flex: 2 },
];

export const getNumberingHeaderDataGrid = (hydratedFields: string[]): GridColDef[] => {
	return dataGridNumbering.map((header: GridColDef, index) => {
		return {
			...header,
			renderHeader: (params) => DataGridHelper.getRenderHeader(params),
			headerName: hydratedFields[index],
		};
	});
};
