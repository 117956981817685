import { type TagTypeRead, getTagTypes } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideVersion2 } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useTagTypes = <TQueryData = Paginated<TagTypeRead>, TData = TQueryData>(
	clientId: string,
	eventId: string,
	args: UseQueryArgs<Paginated<TagTypeRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.tagTypes, ...queryKeyPart, "Get", "List", clientId, eventId, queryStringOverride],
		queryFn: ({ signal }) => getTagTypes({ clientId, eventId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};
