import type { PresentationRead } from "@api";
import type { GridColDef } from "@mui/x-data-grid";
import { Chip, type ChipProps, ElementGroup } from "@mykey4/core";
import { t } from "i18next";
import { AbstractStatusBulletText } from "../../Atoms";
import type { AbstractRow } from "./AbstractDataGrid.type";
import { AbstractNumberCell } from "./components";
import { AbstractPresentationCell } from "./components";
import { AbstractTypographyCell } from "./components";
import { AbstractActionCell } from "./components";
import { AbstractPresenterCell } from "./components";

export interface BuildAbstractColDefArgs {
	clientKey: string;
	eventKey: string;
	translation: string;
}

export const getColumnsAbstracts = (args: BuildAbstractColDefArgs): Array<GridColDef<AbstractRow>> => {
	const { clientKey, eventKey, translation } = args;

	const Typo = (text?: string, tooltip?: boolean) => <AbstractTypographyCell text={text} tooltip={tooltip} variant={"body2"} />;

	return [
		{
			headerName: t(`${translation}.column.number`),
			field: "number",
			flex: 0.2,
			renderCell: (params) => <AbstractNumberCell number={params.row.number} url={params.row.url} title={params.row.title} />,
		},
		{
			headerName: t(`${translation}.column.newAttributedNumber`),
			field: "newAttributedNumber",
			flex: 0.2,
			renderCell: (params) => Typo(params.row.newAttributedNumber),
		},
		{
			headerName: t(`${translation}.column.status`),
			field: "status",
			flex: 0.5,
			renderCell: (params) => <AbstractStatusBulletText value={params.row.status} />,
		},
		{
			headerName: t(`${translation}.column.title`),
			field: "title",
			flex: 2,
			renderCell: (params) => Typo(params.row.title, true),
		},
		{
			headerName: t(`${translation}.column.category`),
			field: "category.name",
			flex: 0.5,
			renderCell: (params) => Typo(params.row.category?.name),
		},
		{
			headerName: t(`${translation}.column.redefinitionType`),
			field: "redefinitionType.name",
			flex: 1,
			renderCell: (params) => Typo(params.row.redefinitionType?.name),
		},
		{
			headerName: t(`${translation}.column.theme`),
			field: "theme.name",
			flex: 1,
			renderCell: (params) => Typo(params.row.theme?.name, true),
		},
		{
			headerName: t(`${translation}.column.subTheme`),
			field: "subTheme",
			flex: 1,
			renderCell: (params) => (
				<ElementGroup Component={Chip} elements={params.row.keywords.map((keyword) => ({ size: "small", label: keyword.name }) as ChipProps)} />
			),
			sortable: false,
		},
		{
			headerName: t(`${translation}.column.presentations`),
			field: "presentations",
			flex: 0.7,
			renderCell: (params) => <AbstractPresentationCell clientKey={clientKey} eventKey={eventKey} presentations={params.row.presentations} />,
			sortable: false,
		},
		{
			headerName: t(`${translation}.column.presenter`),
			field: "presenter",
			flex: 1,
			renderCell: (params) => <AbstractPresenterCell firstName={params.row.coAuthors?.firstname} lastName={params.row.coAuthors?.lastname} />,
			sortable: false,
		},
		{
			field: "actions",
			type: "actions",
			width: 50,
			maxWidth: 50,
			sortable: false,
			editable: false,
			flex: 1,
			disableColumnMenu: true,
			getActions: (params) => [<AbstractActionCell key={"details"} abstractUrl={params.row.url} abstractTitle={params.row.title} />],
		},
	];
};

export const slicePresentationsToDisplay = (presentationsAbstract: Array<PresentationRead>, maxPresentationsDisplayed = 2): Array<PresentationRead> =>
	presentationsAbstract.slice(0, presentationsAbstract.length > maxPresentationsDisplayed + 1 ? maxPresentationsDisplayed : maxPresentationsDisplayed + 1);
