import { useDashboardIssues } from "@api";
import Card from "@infrastructure/components/interface/card/Card";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import { NoData, useContextModule } from "@key4-front-library/core";
import { Avatar, Box, Grid, Skeleton, Stack, Typography, alpha } from "@mui/material";
import type { RegularBreakpoints } from "@mui/material/Grid/Grid";
import type { IconName } from "@mykey4/core";
import { t } from "i18next";
import { Fragment, useMemo } from "react";
import { type DashboardIssueType, type DashboardIssueValues, mapToIndicators } from "./DashboardIssues.mapper";

const DashboardIssueValuesRender = (props: DashboardIssueValues) => {
	const { key, name, value } = props;
	return (
		<Box key={key}>
			<Typography component="span" variant="body1" sx={{ fontWeight: 500 }}>
				<NumberThousandSeparator value={value} />
			</Typography>
			&nbsp;
			<Typography component="span" color={"text_special.main"} variant="body2" sx={{ fontWeight: 300 }}>
				{name}
			</Typography>
		</Box>
	);
};

const DashboardAlertsRender = (props: DashboardIssueType) => {
	const { name, values, icon, themeColor, key } = props;

	if (props.count < 1) {
		return <Fragment key={name} />;
	}

	return (
		<Grid
			container
			key={key}
			display={"flex"}
			flexDirection={"row"}
			alignItems={"center"}
			flexWrap={"nowrap"}
			sx={{
				backgroundColor: alpha(themeColor.main, 0.12),
				my: 1,
				pb: 1,
				borderRadius: 2,
			}}
			spacing={1}
		>
			<Grid item alignItems={"baseline"}>
				<Avatar sx={{ backgroundColor: themeColor.main }}>
					<Box sx={{ mb: "5px" }} alignItems={"baseline"}>
						<Icon prefix="far" iconName={icon as IconName} size="1x" />
					</Box>
				</Avatar>
			</Grid>
			<Grid item>
				<Stack alignItems={"baseline"}>
					<Typography component="span" color={themeColor.main} variant="body1" sx={{ fontWeight: 500 }}>
						{name}
					</Typography>
					<Stack divider={<>,&nbsp;</>} direction={"row"} alignItems={"baseline"} flexWrap={"wrap"}>
						{values.map((value) => {
							return DashboardIssueValuesRender({
								...value,
							});
						})}
					</Stack>
				</Stack>
			</Grid>
		</Grid>
	);
};

type Props = RegularBreakpoints & {};

const DashboardAlerts = (props: Props) => {
	const { client, event } = useContextModule();

	const indicators = useDashboardIssues({
		clientId: client.id,
		eventId: event.id,
		select: mapToIndicators,
	});

	const countIssues = useMemo(() => {
		if (!indicators.data) {
			return 0;
		}
		return indicators.data.reduce((totalCount, indicator) => totalCount + indicator.count, 0);
	}, [indicators.data]);

	if (!indicators || indicators.isFetching) {
		return (
			<Grid item {...props} display={"flex"}>
				<Skeleton
					variant="rounded"
					width="100%"
					height={"100%"}
					sx={{
						borderRadius: "16px",
						minHeight: 300,
					}}
				/>
			</Grid>
		);
	}

	return (
		<Grid item {...props} display={"flex"}>
			<Card title={t("old.programme.dashboard.alerts.title")}>
				{!(countIssues > 0) ? (
					<NoData />
				) : (
					<Stack>
						{indicators.data?.map((indicator) =>
							DashboardAlertsRender({
								...indicator,
							}),
						)}
					</Stack>
				)}
			</Card>
		</Grid>
	);
};

export default DashboardAlerts;
