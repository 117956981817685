import { SessionStatusBulletText } from "@components";
import { MenuItem } from "@mui/material";
import { Select, type SessionStatus, SessionStatusTypes } from "@mykey4/core";
import type { SelectProps } from "@mykey4/core/Components/Atoms/Select/Select";

export type SessionStatusSelectProps = Omit<SelectProps<SessionStatus>, "children" | "renderValue">;

export const SessionStatusSelect = (props: SessionStatusSelectProps) => {
	const { isRequired = true } = props;
	return (
		<Select {...props} isRequired={isRequired} renderValue={(value) => <SessionStatusBulletText value={value} />}>
			{SessionStatusTypes.map((status) => (
				<MenuItem key={status} value={status}>
					<SessionStatusBulletText value={status} />
				</MenuItem>
			))}
		</Select>
	);
};
