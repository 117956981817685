import { ApplicationColors } from "@key4-front-library/core";
import type { ApexOptions } from "apexcharts";

export const defaultOverviewChartOptions = (): ApexOptions => {
	return {
		colors: [ApplicationColors.greyBlueVeryLight.main, ApplicationColors.greyViolet.main, ApplicationColors.darkGrey.main],
		grid: {
			row: {
				colors: [`${ApplicationColors.greyBlueLight.light}`, "transparent"],
				opacity: 0.5,
			},
		},
		chart: {
			id: "bar-chart",
			type: "bar",
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
		},
		tooltip: {
			enabled: true,
			theme: "dark",
		},
		xaxis: {
			labels: {
				style: {
					colors: ApplicationColors.white.main,
					fontSize: "12px",
					fontWeight: 700,
				},
			},
		},
		yaxis: {
			labels: {
				style: {
					colors: [ApplicationColors.white.main],
					fontSize: "12px",
					fontWeight: 700,
				},
			},
		},
		stroke: {
			show: true,
			curve: "smooth",
			width: 3,
			dashArray: 0,
		},
		legend: {
			show: true,
			position: "top",
			horizontalAlign: "right",
		},
	};
};
