import { type SessionTemplateRead, type SessionTemplateWrite, useSessionTemplateCreate, useSessionTemplateUpdate } from "@api";
import { SessionTemplateForm } from "@components";
import { useSnackBarHook } from "@key4-front-library/core";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import {
	DialogAdvanced,
	type DialogComponentProps,
	Stack,
	Typography,
	fromDateTimeToIso,
	fromDateTimeToIso8601Duration,
	fromDateTimeToTimeWithSecond,
	getDialogAdvanced,
} from "@mykey4/core";
import { t } from "i18next";
import type { SessionTemplateFormType } from "../SessionTemplateForm/SessionTemplateForm.zod";
import { SessionTemplateFormProvider } from "../SessionTemplateForm/SessionTemplateFormProvider";
import { useSessionTemplateForm } from "../SessionTemplateForm/useSessionTemplateForm";

export interface SessionTemplateModalProps {
	clientId: string;
	eventId: string;
	primaryTagId: string;
	existing?: SessionTemplateRead;
}

export const SessionTemplateModal = (props: DialogComponentProps<SessionTemplateModalProps>) => {
	const component = "sessionTemplateModal";
	const { open, onClose, payload } = props;
	const { existing, clientId, eventId } = payload;
	const { sendSuccess, sendError } = useSnackBarHook();

	const context = useSessionTemplateForm(payload);

	const sessionTemplateCreate = useSessionTemplateCreate({});
	const sessionTemplateUpdate = useSessionTemplateUpdate({});

	const onFormSubmitted = (data: SessionTemplateFormType) => {
		const write: SessionTemplateWrite = {
			primaryTagId: data.primaryTagId,
			startHour: fromDateTimeToTimeWithSecond(data.startHour),
			endHour: fromDateTimeToTimeWithSecond(data.endHour),
			duration: fromDateTimeToIso8601Duration(data.duration),
			presentationCount: data.presentationCount,
			presentationDuration: fromDateTimeToIso8601Duration(data.presentationDuration),
			status: data.status,
			isPublished: data.isPublished,
			publicationDate: fromDateTimeToIso(data.publicationDate),
			chairInvitationStatus: data.chairInvitationStatus,
			speakerInvitationStatus: data.speakerInvitationStatus,
			isPrivate: data.isPrivate,
		};

		const task = existing
			? sessionTemplateUpdate.mutateAsync({ clientId, eventId, sessionTemplateId: existing.id, body: write })
			: sessionTemplateCreate.mutateAsync({ clientId, eventId, body: write });

		task
			.then(() => sendSuccess(t(`old.programme.settings.sessionTemplates.modal.${existing ? "editTemplate" : "addTemplate"}.success`)))
			.catch(sendError)
			.finally(() => onClose());
	};

	return (
		<DialogAdvanced
			open={open}
			{...getDialogAdvanced({
				type: "save",
				translation: existing ? `${component}.edit` : `${component}.create`,
				options: {
					isLoading: context.form.formState.isSubmitting || sessionTemplateCreate.isPending || sessionTemplateUpdate.isPending,
					onCancelClick: onClose,
					onActionClick: () => context.form.handleSubmit(onFormSubmitted)(),
				},
			})}
			title={{
				component: "div",
				children: (
					<Stack direction="row" spacing={1} alignItems={"center"} justifyContent={"space-between"}>
						<Typography variant="h6">
							{existing ? t(`${component}.edit.advancedDialog.save.title`) : t(`${component}.create.advancedDialog.save.title`)}
						</Typography>
						<IconButton onClick={() => onClose()}>
							<CloseIcon />
						</IconButton>
					</Stack>
				),
			}}
			fullWidth
		>
			<SessionTemplateFormProvider value={context}>
				<SessionTemplateForm />
			</SessionTemplateFormProvider>
		</DialogAdvanced>
	);
};
