import { type SxProps, useTheme } from "@mui/material";
import type { Theme } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import { memo, useCallback, useMemo } from "react";
import { type EnrichedAbstractAssociation, determineAbstractAssociationState } from "../common";
import { AbstractContainer } from "./AbstractContainer";
import { PresentationHeader } from "./PresentationHeader";

const AnimatePresenceMemo = memo(AnimatePresence);

interface MotionContainerProps {
	layoutId?: string;
	sx?: SxProps<Theme>;
	children?: React.ReactElement;
}

const MotionContainer = memo((props: MotionContainerProps) => {
	const { layoutId, children } = props;
	const theme = useTheme();
	return (
		<AnimatePresenceMemo propagate>
			<motion.div
				layout
				layoutId={layoutId}
				initial={{ opacity: 0, x: 0, scale: 0.8, transition: { duration: 0.2 } }}
				animate={{ opacity: 1, x: 0, scale: 1 }}
				exit={{ opacity: 0, x: 0, scale: 0.8, transition: { duration: 0.2 } }}
				transition={{ type: "spring", stiffness: 150, damping: 20, mass: 0.2 }}
				style={{
					padding: theme.spacing(1),
					borderRadius: theme.spacing(2),
					backgroundColor: theme.palette.primary.background,
					display: "flex",
					flexDirection: "column",
					gap: theme.spacing(1),
				}}
			>
				{children}
			</motion.div>
		</AnimatePresenceMemo>
	);
});

export interface PresentationAbstractItem {
	item: EnrichedAbstractAssociation;
	selected?: boolean;
	onSelectClick?: (itemId: string) => void;
	onOverridePresentationNameClick?: (itemId: string, value: boolean) => void;
	onAbstractKeyClick?: (abstractId: string) => void;
	onPresentationKeyClick?: (presentationId: string) => void;
}

export const AbstractAssociationContainer = memo((props: PresentationAbstractItem) => {
	const { item, selected = false, onSelectClick = () => {}, onOverridePresentationNameClick = () => {}, onAbstractKeyClick, onPresentationKeyClick } = props;

	const containerKey = `${item.id}-presentation`;
	const childrenKey = item.abstract?.id ?? `${item.id}-empty-abstract`;

	const handleSelectClick = useCallback(() => {
		onSelectClick(item.id);
	}, [item.id, onSelectClick]);

	const handleOverridePresentationNameClick = useCallback(
		(value: boolean) => onOverridePresentationNameClick(item.id, value),
		[item.id, onOverridePresentationNameClick],
	);

	const handlePresentationKeyClick = useCallback(() => {
		if (item.presentation?.id) {
			onPresentationKeyClick?.(item.presentation.id);
		}
	}, [item.presentation?.id, onPresentationKeyClick]);

	const handleAbstractKeyClick = useCallback(() => {
		if (item.abstract?.id) {
			onAbstractKeyClick?.(item.abstract.id);
		}
	}, [item.abstract?.id, onAbstractKeyClick]);

	const content = useMemo(() => {
		return (
			<>
				<PresentationHeader
					presentationId={item.presentation?.id}
					presentationKey={item.presentation?.key}
					presentationTitle={item.presentation?.title}
					abstractTitle={item.abstract?.title}
					overridePresentationTitle={item.overridePresentationTitle}
					status={determineAbstractAssociationState(item)}
					onKeyClick={handlePresentationKeyClick}
				/>
				<AbstractContainer
					key={childrenKey}
					rowId={item}
					abstract={item.abstract}
					layoutId={childrenKey}
					selected={selected}
					overridePresentationName={item.overridePresentationTitle}
					onSelectClick={handleSelectClick}
					onOverridePresentationNameClick={handleOverridePresentationNameClick}
					onKeyClick={handleAbstractKeyClick}
				/>
			</>
		);
	}, [item, handlePresentationKeyClick, childrenKey, selected, handleSelectClick, handleOverridePresentationNameClick, handleAbstractKeyClick]);

	return <MotionContainer layoutId={containerKey}>{content}</MotionContainer>;
});
