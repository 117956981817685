import { type LiteTagRead, type SessionStatusUpdateRequest, type TagTypeRead, useSessionStatusUpdate, useSessionTags, useTagTypes } from "@api";
import SessionController from "@application/Controllers/SessionController";
import { type UseMutationResult, type UseQueryResult, useMutation } from "@tanstack/react-query";
import { groupBy } from "lodash";
import { useMemo } from "react";
import { type SessionDetailsContextType, useSessionDetailsContext } from "../Providers";
import { type SessionExternalLink, useSessionExternalLink } from "./useSessionExternalLink";

export interface useSessionDetailsTagsArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

const useSessionDetailsTags = (props: useSessionDetailsTagsArgs) => {
	const { clientId, eventId, sessionId } = props;
	const sessionTags = useSessionTags(clientId, eventId, sessionId);
	const eventTagType = useTagTypes(clientId, eventId);

	// todo make tag type by session endpoint
	const combinedData: (TagTypeRead & { tags: LiteTagRead[] })[] = useMemo(() => {
		if (!sessionTags.data || !eventTagType.data) {
			return [];
		}
		const tagGroupByType: Record<string, LiteTagRead[]> = groupBy(sessionTags.data.data, "tagTypeId");
		return eventTagType.data.data
			.filter((tagType) => tagType.id in tagGroupByType)
			.map((tagType) => {
				return {
					...tagType,
					tags: tagGroupByType[tagType.id],
				};
			});
	}, [sessionTags.data, eventTagType.data]);

	return combinedData;
};

export interface UseSessionDetailsReturn {
	context: SessionDetailsContextType;
	sessionExternalLink: UseQueryResult<SessionExternalLink | null>;
	sessionTagTypes: (TagTypeRead & { tags: LiteTagRead[] })[];
	deleteSession: UseMutationResult<void, Error, void>;
	cloneSession: UseMutationResult<void, Error, void>;
	updateSessionStatus: UseMutationResult<boolean, Error, SessionStatusUpdateRequest>;
}

export const useSessionDetails = () => {
	const sessionContext = useSessionDetailsContext();
	const { clientId, eventId, sessionId } = sessionContext;

	const sessionExternalLink = useSessionExternalLink(sessionContext);
	const sessionTagTypes = useSessionDetailsTags(sessionContext);

	const deleteSession = useMutation<void, Error, void>({
		mutationFn: async () => {
			await SessionController.deleteSession(clientId, eventId, sessionId);
		},
	});

	const cloneSession = useMutation<void, Error, void>({
		mutationFn: async () => {
			await SessionController.cloneSession({ clientId, eventId, sessionId });
		},
	});

	const updateSessionStatus = useSessionStatusUpdate({
		onSettled: async () => {
			await sessionContext.invalidateSession();
		},
	});

	return useMemo<UseSessionDetailsReturn>(() => {
		return {
			context: sessionContext,
			sessionTagTypes,
			sessionExternalLink,
			deleteSession,
			cloneSession,
			updateSessionStatus,
		};
	}, [sessionContext, sessionTagTypes, sessionExternalLink, deleteSession, cloneSession, updateSessionStatus]);
};
