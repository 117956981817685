import { Autocomplete } from "@mykey4/core";
import { DateTime } from "luxon";
import { useMemo } from "react";

export type DurationAutoCompleteProps = {
	label?: string;
	maxHours: number;
	stepMinutes: number;
	value: DateTime<true> | null;
	onChange: (value: DateTime<true> | null) => void;
	disabled?: boolean;
};

export const DurationAutoComplete = (props: DurationAutoCompleteProps) => {
	const { value, maxHours, stepMinutes, onChange, disabled, ...others } = props;

	const options = useMemo(() => {
		return [...Array((60 / stepMinutes) * maxHours)].map((_, index) => {
			const date = DateTime.fromSeconds((index + 1) * stepMinutes * 60, { zone: "UTC" });
			return {
				id: index,
				value: date,
				label: date.toFormat("HH:mm"),
			};
		});
	}, [maxHours, stepMinutes]);

	const currentOption = useMemo(() => {
		const index = options.findIndex((option) => (value ? option.value.equals(value) : false));
		return index >= 0 ? options[index] : null;
	}, [options, value]);

	return (
		<Autocomplete
			{...others}
			value={currentOption}
			isOptionEqualToValue={(option, value) => (option && value ? option.value.equals(value.value) : false)}
			options={options}
			multiple={false}
			isDisabled={disabled}
			onChange={(_, options) => {
				const date = Array.isArray(options)
					? options.length > 0
						? options[0].value
						: null
					: options
						? (options as { value: DateTime<true> | null }).value
						: null;
				onChange(date);
			}}
		/>
	);
};
