import type { SessionRead } from "@api";
import { useSessionStatusConfiguration } from "@configurations";
import { Stack } from "@mui/material";
import { Icon, Typography } from "@mykey4/core";

export interface SessionDetailsTitleProps {
	session: SessionRead;
}

export const SessionDetailsTitle = (props: SessionDetailsTitleProps) => {
	const { session } = props;
	const configuration = useSessionStatusConfiguration({ status: session.status });
	return (
		<Stack direction={"row"} alignItems={"center"} gap={1}>
			<Icon variant="h5" prefix="fas" name={"circle"} color={configuration.color} />
			<Stack direction={"row"} gap={1} alignItems={"baseline"}>
				<Typography variant="h4">{session.title}</Typography>
				<Typography variant={"h5"} fontWeight={"light"} color={"text.disabled"}>
					{session.title ? `(${session.code})` : session.code}
				</Typography>
			</Stack>
		</Stack>
	);
};
