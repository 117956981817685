import { Link } from "@mykey4/core";
import { memo, useCallback } from "react";

export interface AbstractNumberCellProps {
	url?: string;
	title: string;
	number: string;
}

export const AbstractNumberCell = memo((props: AbstractNumberCellProps) => {
	const { url, title, number } = props;
	const open = useCallback(() => {
		if (url) {
			window.open(url, title, "width=900,height=800,resizable=yes,scrollbars=yes");
		}
	}, [url, title]);
	return (
		<Link sx={{ cursor: "pointer" }} target="_blank" rel="noopener noreferrer" onClick={open}>
			{number}
		</Link>
	);
});
