import { IconText } from "@mykey4/core";
import { memo } from "react";

export interface AbstractPresenterCellProps {
	firstName?: string;
	lastName?: string;
}

export const AbstractPresenterCell = memo((props: AbstractPresenterCellProps) => {
	const { firstName, lastName } = props;
	if (!firstName && !lastName) {
		return undefined;
	}
	return <IconText icon={{ name: "user" }} typography={{ children: `${firstName} ${lastName}`, variant: "body2" }} />;
});
