export const AbstractStatusTypes = ["draft", "submitted", "accepted", "refused", "updateRequested", "deleted"] as const;
export type AbstractStatus = (typeof AbstractStatusTypes)[number];

export interface CategoryAbstractLiteRead {
	id: string;
	name: string;
}

export interface TypeAbstractLiteRead {
	id: string;
	name: string;
	order?: number;
}

export interface ThemeAbstractLiteRead {
	id: string;
	name: string;
}

export interface DepositaryAbstractLiteRead {
	id: string;
	firstname?: string;
	lastname?: string;
	email?: string;
}

/**
 * GET: /programme/clients/{clientId}/events/{eventId}/abstracts
 * POST: /programme/clients/{clientId}/events/{eventId}/abstracts/search
 */
export interface AbstractRead {
	id: string;
	eventId: string;
	externalReference?: string;
	title: string;
	number: string;
	newAttributedNumber?: string;
	status: AbstractStatus;
	category?: CategoryAbstractLiteRead;
	type?: TypeAbstractLiteRead;
	redefinitionType?: TypeAbstractLiteRead;
	theme?: ThemeAbstractLiteRead;
	depositary?: DepositaryAbstractLiteRead;
	url?: string;
	embargoDate?: string;
	createdBy: string;
	createdAt: string;
	lastModifiedBy: string;
	lastModifiedAt: string;
}
