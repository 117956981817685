import { activityMatchDtoToUserInformationList } from "@application/Mappers";
import type { UserInformationWithMetadata } from "@application/Types";
import {
	type ActivityMatchDto,
	type DtoParticipantGet,
	Services,
	type TypeApiResponsePagined,
	UserInformationRadio,
	escapeSieveString,
	queryFilters,
	queryStringPagination,
	useContextModule,
} from "@key4-front-library/core";
import { Stack, Typography } from "@mui/material";
import { FilteringOperator, metaEnv } from "@mykey4/core";
import { getExternalPath } from "@routes";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";

export interface MatchingParticipantSectionProps {
	matchingId?: string;
	userSelected?: UserInformationWithMetadata;
	setParticipantActivityCount: (count: number) => void;
	onUserSelectedChange: (user: UserInformationWithMetadata) => void;
	search: string;
}

export const MatchingParticipantSection = (props: MatchingParticipantSectionProps): React.ReactElement => {
	const { matchingId, userSelected, setParticipantActivityCount, onUserSelectedChange, search } = props;
	const component = "old.common.matchingParticipantSection";
	const { client, event } = useContextModule();
	const [participantsProposals, setParticipantsProposals] = useState<Array<UserInformationWithMetadata>>();

	const { data: participants } = useQuery({
		queryKey: ["client", client.id, "event", event.id, "participants", search, "page=1", "pageSize10"],
		queryFn: (): Promise<TypeApiResponsePagined<Array<DtoParticipantGet>>> =>
			Services.Events.Registration.ParticipantsService.getListPagined(client.id, event.id, [
				...queryFilters(
					[["lastname", "firstname", "email"].join(FilteringOperator.OrIn), FilteringOperator.CaseInsensitiveContain, escapeSieveString(search)].join(""),
				),
				...queryStringPagination({ page: 0, pageSize: 10 }),
			]),
		enabled: !!search,
		placeholderData: (previousData) => previousData,
	});

	const { data: participantActivity } = useQuery({
		queryKey: ["matching", "participants", "proposal", matchingId, client.id, event.id],
		queryFn: (): Promise<Array<ActivityMatchDto>> => Services.Registration.Clients.ParticipantsService.postMatching(client.id, event.id, [matchingId ?? ""]),
		enabled: !!matchingId,
	});

	useEffect(() => {
		if (search) {
			setParticipantsProposals(
				participants?.data.map((participant: DtoParticipantGet) => {
					return {
						id: participant.id,
						type: "participant",
						user: {
							email: participant.email ?? undefined,
							lastname: participant.lastname ?? undefined,
							firstname: participant.firstname ?? undefined,
							link: { children: participant.key },
						},
					};
				}) ?? [],
			);
		} else if (participantActivity?.[0]) {
			setParticipantsProposals(
				activityMatchDtoToUserInformationList(
					participantActivity[0],
					"participant",
					getExternalPath(metaEnv.myKey4.endPoint.registration, "participants", [client.key, event.key]),
				),
			);
			setParticipantActivityCount(participantActivity[0].matches.length);
		}
	}, [participantActivity, search, participants, client.key, event.key, setParticipantActivityCount]);

	return (
		<Stack spacing={1}>
			<Typography variant="h6" align={"center"}>
				{t(`${component}.title`)}
			</Typography>
			{(!participantsProposals || participantsProposals.length === 0) && (
				<Stack pt={2}>
					<Typography fontStyle={"italic"} align="center">
						{t(`${component}.notFound`)}
					</Typography>
				</Stack>
			)}
			{participantsProposals?.map((participantProposal) => (
				<UserInformationRadio
					key={uniqueId()}
					checked={participantProposal.id === userSelected?.id}
					onClick={() => {
						onUserSelectedChange(participantProposal);
					}}
					userInformation={participantProposal.user}
				/>
			))}
		</Stack>
	);
};
