import type { DateTime } from "luxon";

import type { EnumSessionStatus } from "@key4-front-library/core";
import type { TAutocompleteMultiBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiBulletColor";
import type { TAutocompleteMultiChipItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiChip";

export enum ESchedulerView {
	CALENDAR = "calendar",
	ROOM = "room",
	MULTIROOM = "multiroom",
}

export interface ICalendarSettings {
	date: TdateCalendar;
	hour: TdateCalendar;
	dateDurationDays: number;
	rooms?: Array<TRoomView>;
}

type TdateCalendar = {
	start: DateTime;
	end: DateTime;
};

export type TRoomView = {
	id: string;
	title: string;
};

export interface ICalendarSession {
	id: string;
	code: string | null;
	title: string | undefined;
	start: string | undefined;
	end: string | undefined;
	hourStart: string | null;
	hourEnd: string | null;
	status: EnumSessionStatus;
	hasClashes: boolean;
	hasAnomalies: boolean;
	minDuration: number | null;
	duration?: string;
	backgroundColor?: string;
	borderColor?: string;
	textColor?: string;
	tagsId: Array<string> | null;
	resourceId: string | undefined;
	roomId: string | null;
	timezone: string | null;
	invitationStatusAccepted?: boolean;
	isReadOnly?: boolean;
}

export class CalendarSettings implements ICalendarSettings {
	date: TdateCalendar;
	hour: TdateCalendar;
	dateDurationDays: number;
	rooms?: Array<TRoomView>;

	constructor(date: TdateCalendar, hour: TdateCalendar, dateDurationDays: number, rooms?: Array<TRoomView>) {
		this.date = date;
		this.hour = hour;
		this.dateDurationDays = dateDurationDays;
		this.rooms = rooms;
	}
}

export enum EFilterUnplannedSessions {
	ROOM = "rooms",
	SESSIONSTATUS = "sessionStatus",
	DATE = "dates",
	TAGS = "tags",
	IsPublished = "isPublished",
}

export type TFilterUnplannedSessionsForm = {
	[EFilterUnplannedSessions.ROOM]: Array<TAutocompleteMultiChipItem> | Array<string>;
	[EFilterUnplannedSessions.SESSIONSTATUS]: Array<TAutocompleteMultiBulletColorItem> | Array<string>;
	[EFilterUnplannedSessions.DATE]: Array<TAutocompleteMultiChipItem> | Array<string>;
	[EFilterUnplannedSessions.TAGS]?: Array<Array<TAutocompleteMultiChipItem> | undefined>;
	[EFilterUnplannedSessions.IsPublished]?: boolean | null;
};

export const filtersUnplannedSessionsObject = {
	[EFilterUnplannedSessions.ROOM]: {
		sieveKey: "roomId",
	},
	[EFilterUnplannedSessions.SESSIONSTATUS]: {
		sieveKey: "status",
	},
	[EFilterUnplannedSessions.DATE]: {
		sieveKey: "startDate",
	},
	[EFilterUnplannedSessions.TAGS]: {
		sieveKey: "tags.id",
	},
	[EFilterUnplannedSessions.IsPublished]: {
		sieveKey: "isPublished",
	},
};
