import PeriodContainer from "@infrastructure/components/interface/period/PeriodContainer";
import { useContextModule } from "@key4-front-library/core";
import { Grid, Stack } from "@mui/material";
import { Title, Typography } from "@mykey4/core";
import { DashboardFaculties } from "./DashboardFaculties/DashboardFaculties";
import DashboardInvitationStatus from "./DashboardInvitationStatus/DashboardInvitationStatus";
import DashboardAlerts from "./DashboardIssues/DashboardIssues";
import { DashboardOverview } from "./DashboardOverview";
import DashboardProgramme from "./DashboardProgramme/DashboardProgramme";
import DashboardPublicationsDonutChart from "./DashboardSessionPublicationStatus/DashboardSessionPublicationStatus";
import { DashboardSessionsStatus } from "./DashboardSessionStatus/DashboardSessionsStatus";

export const Dashboard = () => {
	const { event } = useContextModule();

	return (
		<Stack>
			<Stack mt={3} mb={3}>
				<Title>
					<Stack>
						<Typography variant="h4">{event.name ?? ""}</Typography>
						<PeriodContainer dateStartIso={event.startDate} dateEndIso={event.endDate} />
					</Stack>
				</Title>
			</Stack>
			<Grid container spacing={3}>
				<DashboardOverview xs={12} lg={6} />
				<DashboardProgramme xs={12} md={6} lg={3} />
				<DashboardFaculties xs={12} md={6} lg={3} />
				<DashboardSessionsStatus xs={12} md={6} lg={3} />
				<DashboardInvitationStatus xs={12} md={6} lg={3} />
				<DashboardPublicationsDonutChart xs={12} md={6} lg={3} />
				<DashboardAlerts xs={12} md={6} lg={3} />
			</Grid>
		</Stack>
	);
};
