import { useNotificationContext } from "@key4-front-library/core";
import { Badge, Icon, Link, Stack, Tooltip } from "@mykey4/core";
import { getPath } from "@routes";
import { t } from "i18next";
import { useParams } from "react-router-dom";

export const HeaderNotifications = (): React.ReactNode => {
	const component = "headerNotification";

	const { client = "", event = "" } = useParams();
	const { totalCountClashes, totalCountAnomalies } = useNotificationContext();
	return (
		<Stack direction="row" spacing={5} alignContent={"center"}>
			<Tooltip title={t(`${component}.clashes.tooltip`, { count: totalCountClashes ?? 0 }) ?? ""}>
				<Link color="common.white" to={getPath("alertsClashes", [client, event])}>
					<Badge badgeContent={totalCountClashes} color="error">
						<Icon name="calendar-circle-exclamation" variant="size18" />
					</Badge>
				</Link>
			</Tooltip>
			<Tooltip title={t(`${component}.anomalies.tooltip`, { count: totalCountAnomalies ?? 0 }) ?? ""}>
				<Link color="common.white" to={getPath("alertsAnomalies", [client, event])}>
					<Badge badgeContent={totalCountAnomalies} color="warning">
						<Icon name="triangle-exclamation" variant="size18" />
					</Badge>
				</Link>
			</Tooltip>
		</Stack>
	);
};
