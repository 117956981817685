import { ActivityInvitationStatusBulletText, GridActionButton } from "@components";
import { type DtoFaculty, PersonHeader, sortPersonComparator } from "@key4-front-library/core";
import type { Theme } from "@mui/material";
import type { GridColDef } from "@mui/x-data-grid-pro";
import { Icon, Typography } from "@mykey4/core";
import { t } from "i18next";

export interface BuildChairColDefArgs {
	theme: Theme;
	editAction: (participant: DtoFaculty) => void;
	detailsAction: (participant: DtoFaculty) => void;
	deleteAction: (participant: DtoFaculty) => void;
}

export const buildChairColDef = (args: BuildChairColDefArgs): GridColDef<DtoFaculty>[] => {
	const { theme, editAction, detailsAction, deleteAction } = args;
	return [
		{
			field: "chair",
			headerName: t("old.programme.sessionDetails.chairs.datagrid.headerLabels.chair"),
			minWidth: 300,
			flex: 3,
			disableColumnMenu: true,
			renderCell: (params) => <PersonHeader data={params.row} />,
			sortComparator: sortPersonComparator,
		},
		{
			field: "invitationType",
			headerName: t("old.programme.sessionDetails.chairs.datagrid.headerLabels.invitationType"),
			renderCell: (params) => <Typography>{params.row.tagTypes?.[0]?.tags?.[0]?.label ?? t("old.common.chair")}</Typography>,
			width: 150,
			flex: 2,
		},
		{
			field: "status",
			headerName: t("old.programme.sessionDetails.chairs.datagrid.headerLabels.status"),
			minWidth: 150,
			flex: 2,
			renderCell: (params) => <ActivityInvitationStatusBulletText value={params.row.invitationStatus} />,
			sortComparator: (a, b) => {
				const labelA = a.label;
				const labelB = b.label;

				return labelA.localeCompare(labelB);
			},
		},
		{
			field: "actions",
			type: "actions",
			headerName: t("old.programme.sessionDetails.chairs.datagrid.headerLabels.actions"),
			minWidth: 150,
			sortable: false,
			editable: false,
			flex: 2,
			disableColumnMenu: true,
			getActions: (params) => {
				return [
					<GridActionButton
						key={"details"}
						icon={<Icon name={"file-magnifying-glass"} />}
						label="details"
						iconColor={theme.palette.success.main}
						onClick={() => detailsAction(params.row)}
					/>,
					<GridActionButton
						key={"edit"}
						label="edit"
						icon={<Icon name={"pen-to-square"} />}
						iconColor={theme.palette.success.main}
						onClick={() => editAction(params.row)}
					/>,
					<GridActionButton
						key={"delete"}
						label="delete"
						icon={<Icon name={"trash-can"} />}
						iconColor={theme.palette.error.main}
						onClick={() => deleteAction(params.row)}
					/>,
				];
			},
		},
	];
};
