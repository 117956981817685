import type { PresentationRead, SessionRead } from "@api";
import type { AbstractDissociationResults } from "@components";
import { getPresentationKey, getSessionKey } from "@helpers";
import type { AbstractDissociationItem, PresentationDissociationItem } from "./common";

export const mapPresentationToItem = (item: PresentationRead, sessions: SessionRead[]): PresentationDissociationItem => {
	const session = sessions.find((session) => session.id === item.sessionId);
	return {
		id: item.id,
		key: getPresentationKey(item),
		sessionId: item.sessionId,
		sessionKey: getSessionKey(session),
		dissociate: true,
		abstractId: item.abstractId ?? "",
	};
};

export const mapDissociationToResult = (items: AbstractDissociationItem[]): AbstractDissociationResults => {
	return items.flatMap((items) =>
		items.presentations
			.values()
			.toArray()
			.map((presentation) => ({
				presentationId: presentation.id,
				sessionId: presentation.sessionId,
				dissociate: presentation.dissociate,
			})),
	);
};
