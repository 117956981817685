import { invitationAnomaliesObject, presentationAnomaliesObject, sessionAnomaliesObject } from "@application/Configurations/anomalies.configuration";
import { type EAnomalies, EInvitationAnomalies, EPresentationAnomalies, ESessionAnomalies, ETypeAnomalies } from "@application/enum/Anomalies.enum";
import { IndicatorDefinitions } from "@configurations";
import type { IAnomaliesByType } from "@domain/interfaces/anomalies.interface";
import type { ISettingsAnomaliesItems } from "@domain/interfaces/settings.anomalies.interface";
import type { ISessionAnomaly } from "@domain/model/anomalies.model";
import { EMenuPath } from "@key4-front-library/core";
import { Box } from "@mui/material";
import type { NavigateFunction } from "react-router-dom";

const TransformSessionOrPresentationOrInvitationAnomaly = (anomalyType: EAnomalies, translations: any): { title: string; text: string; icon: string } => {
	// Backend returns enum like "sessionDuplicateCode", "presentationWithoutSpeaker", "invitationNotInAcceptedStatus"
	// The purposes of the if, elseif, elseif conditions is to know if it's about a session, presentation or invitation
	// and to translate the anomalie (e.g : sessionDuplicateCode => "Duplicate code")
	switch (true) {
		case Object.values(ESessionAnomalies).includes(anomalyType as ESessionAnomalies):
			return {
				title: translations.anomalies.session.toUpperCase(),
				text: translations.anomalies[sessionAnomaliesObject[anomalyType as ESessionAnomalies].label],
				icon: IndicatorDefinitions.sessions.icon,
			};
		case Object.values(EPresentationAnomalies).includes(anomalyType as EPresentationAnomalies):
			return {
				title: translations.anomalies.presentation.toUpperCase(),
				text: translations.anomalies[presentationAnomaliesObject[anomalyType as EPresentationAnomalies].label],
				icon: IndicatorDefinitions.presentations.icon,
			};
		default:
			return {
				title: translations.anomalies.invitation.toUpperCase(),
				text: translations.anomalies[invitationAnomaliesObject[anomalyType as EInvitationAnomalies].label],
				icon: IndicatorDefinitions.invitations.icon,
			};
	}
};

type TransformSessionAnomalyDataForReadingProps = {
	anomaly: ISessionAnomaly;
	translationsAnomalies: any;
	clientKey: string;
	eventCode: string;
	navigate: NavigateFunction;
};
const TransformSessionAnomalyDataForReading = (props: TransformSessionAnomalyDataForReadingProps): ISettingsAnomaliesItems => {
	const { anomaly, translationsAnomalies, clientKey, eventCode, navigate } = props;
	const formatedAnomaly = TransformSessionOrPresentationOrInvitationAnomaly(anomaly.type, translationsAnomalies);

	const addAnomalyCode = () => {
		const baseUrl = ["", clientKey, eventCode, EMenuPath.SESSIONS].join("/");
		if (anomaly.type === EPresentationAnomalies.PRESENTATION_DUPLICATE_TITLE || anomaly.type === EPresentationAnomalies.PRESENTATION_DUPLICATE_CODE) {
			return (
				<Box
					onClick={(_event) => {
						_event.stopPropagation();
						navigate(`${baseUrl}/${anomaly.otherSession.id}`);
					}}
					sx={{
						"&:hover": {
							cursor: "pointer",
						},
					}}
				>
					{anomaly.otherPresentation?.code ?? ""}
				</Box>
			);
		}
		if (anomaly.type === ESessionAnomalies.SESSION_DUPLICATE_CODE || anomaly.type === ESessionAnomalies.SESSION_DUPLICATE_TITLE) {
			return (
				<Box
					onClick={(event) => {
						event.stopPropagation();
						navigate(`${baseUrl}/${anomaly.otherSession.id}`);
					}}
					sx={{
						"&:hover": {
							cursor: "pointer",
						},
					}}
				>
					{anomaly.otherSession?.code ?? ""}
				</Box>
			);
		}
	};

	return {
		id: anomaly.id,
		checked: false,
		...(addAnomalyCode() && {
			code: addAnomalyCode(),
		}),
		...formatedAnomaly,
	};
};

type TransformAnomaliesByTypeProps = {
	translations: any;
};
const TransformAnomaliesByType = (props: TransformAnomaliesByTypeProps): IAnomaliesByType[] => {
	const { translations } = props;
	const anomaliesByTypes = [
		{
			type: ETypeAnomalies.SESSION,
			anomalyEnum: ESessionAnomalies,
		},
		{
			type: ETypeAnomalies.PRESENTATION,
			anomalyEnum: EPresentationAnomalies,
		},
		{
			type: ETypeAnomalies.INVITATION,
			anomalyEnum: EInvitationAnomalies,
		},
	];
	const allTypes = anomaliesByTypes.map((el) => {
		return (Object.keys(el.anomalyEnum) as Array<keyof typeof el.anomalyEnum>).map((key) => {
			const anomalyTranslation = AnomaliesFactory.TransformSessionOrPresentationOrInvitationAnomaly(el.anomalyEnum[key], translations);
			return {
				anomaly: el.anomalyEnum[key],
				anomalyTranslation: anomalyTranslation.text,
				checked: true,
				type: el.type,
			};
		});
	});
	return [...allTypes[0], ...allTypes[1], ...allTypes[2]];
};

type SearchAnomaliesCheckboxesProps = {
	anomaliesCheckboxes: IAnomaliesByType[];
	searchValue: string;
};
const SearchAnomaliesCheckboxes = (props: SearchAnomaliesCheckboxesProps): IAnomaliesByType[] => {
	const { anomaliesCheckboxes, searchValue } = props;
	return anomaliesCheckboxes.filter((anomalyItem) => {
		// toUpperCase comparaison to be case insensitive
		return anomalyItem.anomalyTranslation.toUpperCase().includes(searchValue.toUpperCase());
	});
};

const AnomaliesFactory = {
	TransformSessionOrPresentationOrInvitationAnomaly,
	TransformSessionAnomalyDataForReading,
	TransformAnomaliesByType,
	SearchAnomaliesCheckboxes,
};
export default AnomaliesFactory;
