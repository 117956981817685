import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import HelpersApp from "@application/helpers";
import DateHelper from "@application/helpers/date.helper";
import { SessionPublicationStatusSelect } from "@components";
import { useSessionStatusConfigurations } from "@configurations";
import type { ICalendarSettings, TRoomView } from "@domain/interfaces/calendar.interface";
import type { TTagTypeFilterForm } from "@domain/interfaces/session.interface";
import { AutocompleteMultiChipColor } from "@key4-front-library/core";
import type { TAutoCompleteBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteBulletColor";
import FormControlAutocompleteMultiBulletColor from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiBulletColor";
import FormControlAutocompleteMultiChip, {
	type TAutocompleteMultiChipItem,
} from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiChip";
import type { DtoEvent } from "@key4-front-library/core/Dto";
import { Stack } from "@mui/material";
import { FormControl } from "@mykey4/core";

interface FilterUnplannedSessionsFormProps {
	calendarSettings: ICalendarSettings;
	event: DtoEvent;
	rooms: Array<TRoomView>;
	tags: Array<TTagTypeFilterForm> | undefined;
}

const FilterUnplannedSessionsForm = (props: FilterUnplannedSessionsFormProps) => {
	const { calendarSettings, event, rooms, tags } = props;
	const formContext = useFormContext();
	const sessionStatusConfigurations = useSessionStatusConfigurations();
	const [datesListRange, setDatesListRange] = useState<Array<TAutocompleteMultiChipItem>>([]);

	const [roomsList, setRoomsList] = useState<Array<TAutocompleteMultiChipItem>>([]);
	const statusSessionList = useRef<Array<TAutoCompleteBulletColorItem>>(
		HelpersApp.SessionHelper.getStatusSessionListItems(sessionStatusConfigurations, ["cancelled"]),
	);

	const { control } = formContext;

	useEffect(() => {
		if (event) {
			setDatesListRange(DateHelper.getDatesListRange(calendarSettings.date.start.toISO()!, calendarSettings.date.end.toISO()!));
		}
	}, [event]);

	useEffect(() => {
		setRoomsList(
			rooms.map((val) => {
				return { key: val.id, label: val.title };
			}),
		);
	}, [rooms]);

	return (
		<form>
			<Stack spacing={1.5}>
				{statusSessionList.current && statusSessionList.current.length > 0 && (
					<Controller
						render={({ field: { onChange, value } }) => (
							<FormControlAutocompleteMultiBulletColor
								value={value}
								items={statusSessionList.current}
								onChange={(value: Array<string>) => {
									onChange(value ?? []);
								}}
								placeholder={""}
								label={t("old.common.scheduler.unplannedSessionBracket.advancedFilter.sessionStatusLabel")}
								selectAllLabel={t("old.common.field.autocomplete.multiple.selectAllLabel")}
							/>
						)}
						control={control}
						name={"sessionStatus"}
					/>
				)}
				<FormControl
					name={"isPublished"}
					render={(args) => (
						<SessionPublicationStatusSelect {...args} label={t("old.common.scheduler.unplannedSessionBracket.advancedFilter.isPublished")} isRequired={false} />
					)}
				/>
				{tags?.map((tag, key) => {
					return (
						<Controller
							key={`tags-${tag.id}`}
							render={({ field: { onChange, value } }) => (
								<AutocompleteMultiChipColor
									value={value ?? []}
									items={tag.tags}
									onChange={(value) => {
										onChange(value ?? []);
									}}
									placeholder={""}
									label={tag.label}
									selectAllLabel={t("old.common.field.autocomplete.multiple.selectAllLabel")}
								/>
							)}
							control={control}
							name={`tags.${key}`}
						/>
					);
				})}
				{roomsList && roomsList.length > 0 && (
					<Controller
						render={({ field: { onChange, value } }) => (
							<FormControlAutocompleteMultiChip
								value={value}
								items={roomsList}
								onChange={(value) => {
									onChange(value ?? []);
								}}
								placeholder={""}
								label={t("old.common.scheduler.unplannedSessionBracket.advancedFilter.roomsLabel")}
								selectAllLabel={t("old.common.field.autocomplete.multiple.selectAllLabel")}
							/>
						)}
						control={control}
						name={"rooms"}
					/>
				)}
				{datesListRange && datesListRange.length > 0 && (
					<Controller
						render={({ field: { onChange, value } }) => (
							<FormControlAutocompleteMultiChip
								value={value}
								items={datesListRange}
								onChange={(value) => {
									onChange(value ?? []);
								}}
								placeholder={""}
								label={t("old.common.scheduler.unplannedSessionBracket.advancedFilter.datesStartLabel")}
								selectAllLabel={t("old.common.field.autocomplete.multiple.selectAllLabel")}
							/>
						)}
						control={control}
						name={"dates"}
					/>
				)}
			</Stack>
		</form>
	);
};

export default FilterUnplannedSessionsForm;
