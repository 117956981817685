import type { StepId } from "@components";
import { createContext, useContext } from "react";

export interface WizardContextType {
	previousStepId?: StepId;
	nextStepId?: StepId;
	goToNext: () => void;
	goToPrevious: () => void;
}

export const WizardContext = createContext<WizardContextType | undefined>(undefined);

export const useWizard = () => {
	const context = useContext(WizardContext);
	if (!context) {
		throw new Error("useWizard must be used within a WizardProvider");
	}
	return context;
};
