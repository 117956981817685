import { GridActionButton } from "@components";
import { Icon } from "@mykey4/core";
import { memo } from "react";

const EyeIcon = memo(() => <Icon name={"eye"} />);

export interface AbstractActionCell {
	abstractUrl?: string;
	abstractTitle?: string;
}

export const AbstractActionCell = memo((props: AbstractActionCell) => {
	const { abstractUrl, abstractTitle } = props;
	return (
		<GridActionButton
			key={"details"}
			icon={<EyeIcon />}
			label="details"
			onClick={() => window.open(abstractUrl, abstractTitle, "width=900,height=800,resizable=yes,scrollbars=yes")}
		/>
	);
});
