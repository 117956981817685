export function groupBy<T, K extends keyof T>(items: T[], key: K): Map<string, T[]> {
	return items.reduce((acc, item) => {
		const groupKey = String(item[key]);
		if (!acc.has(groupKey)) {
			acc.set(groupKey, []);
		}
		const value = acc.get(groupKey);
		if (value) {
			value.push(item);
		}
		return acc;
	}, new Map<string, T[]>());
}

export function groupByFirst<T, K extends keyof T>(items: T[], key: K): Map<string, T> {
	return items.reduce((acc, item) => {
		const groupKey = String(item[key]);
		if (!acc.has(groupKey)) {
			acc.set(groupKey, item);
		}
		return acc;
	}, new Map<string, T>());
}
