import {
	AbstractStatusTypes,
	useAbstractCategoriesSearch,
	useAbstractKeywordsSearch,
	useAbstractThemesSearch,
	useAbstractTypesSearch,
	useCoAuthorsSearch,
	usePresentationsSearch,
	useSessions,
} from "@api";
import {
	type AbstractFiltersState,
	AbstractStatusBulletText,
	type CategoryFilterOption,
	type FilterOption,
	KeyTitle,
	type PresenterFilterOption,
	buildSearchQuery,
	isFilterActive,
} from "@components";
import { useAbstractStatusConfigurations } from "@configurations";
import { useContextModule } from "@key4-front-library/core";
import { Box, ToggleButton, useTheme } from "@mui/material";
import {
	Autocomplete,
	FilteringOperator,
	Grid,
	Icon,
	Paper,
	Stack,
	Switch,
	TextField,
	Typography,
	getTextField,
	toArray,
	useDebounce,
	useToggle,
} from "@mykey4/core";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface AbstractFilterBarProps {
	onSearch: (searchQuery: string) => void;
}

const filterToFilterQueryString = (filterProperties: string, filterValue: string): string =>
	`${filterProperties}${FilteringOperator.CaseInsensitiveContain}${filterValue}`;

export const AbstractFilterBar = (props: AbstractFilterBarProps): React.ReactElement => {
	const { onSearch } = props;

	const component = "abstractFilterBar";
	const { t } = useTranslation();
	const abstractStatusConfig = useAbstractStatusConfigurations();
	const { client, event } = useContextModule();
	const { isToggle: isFilterExpanded, toggle } = useToggle(true);

	const [filters, setFilters] = useState<AbstractFiltersState>({
		searchTerm: "",
		isIntegrated: false,
		presenter: [],
		category: [],
		status: [],
		finalType: [],
		theme: [],
		subTheme: [],
		number: "",
		newAttributedNumber: "",
		session: [],
		presentation: [],
	});

	const theme = useTheme();

	const [presenterQuery, setPresenterQuery] = useState<string>("");
	const debouncedPresenterQuery = useDebounce(presenterQuery, 300);

	const [sessionQuery, setSessionQuery] = useState<string>("");
	const debouncedSessionQuery = useDebounce(sessionQuery, 300);

	const [presentationQuery, setPresentationQuery] = useState<string>("");
	const debouncedPresentationQuery = useDebounce(presentationQuery, 300);

	const presenterOptions = useCoAuthorsSearch(
		client.id,
		event.id,
		{
			filters: [
				`IsPresenter${FilteringOperator.Equal}true`,
				...(debouncedPresenterQuery
					? [filterToFilterQueryString(["Firstname", "Lastname", "email"].join(FilteringOperator.OrIn), debouncedPresenterQuery)]
					: []),
			].join(FilteringOperator.And),
		},
		{ placeholderData: (previousData) => previousData },
	);

	const categoryOptions = useAbstractCategoriesSearch(client.id, event.id, {});
	const finalTypeOptions = useAbstractTypesSearch(client.id, event.id, {});
	const themeOptions = useAbstractThemesSearch(client.id, event.id, {});
	const subThemeOptions = useAbstractKeywordsSearch(client.id, event.id, {});

	const sessionOptions = useSessions(client.id, event.id, {
		queryStrings: [
			{
				key: "filters",
				value: [
					`Presentations.AbstractId${FilteringOperator.NotEqual}null`,
					...(debouncedSessionQuery ? [filterToFilterQueryString(["title", "key", "code"].join(FilteringOperator.OrIn), debouncedSessionQuery)] : []),
				].join(FilteringOperator.And),
			},
		],
		placeholderData: (previousData) => previousData,
	});

	const presentationsOptions = usePresentationsSearch(
		client.id,
		event.id,
		{
			filters: [
				`AbstractId${FilteringOperator.NotEqual}null`,
				...(debouncedPresentationQuery ? [filterToFilterQueryString(["title", "key", "code"].join(FilteringOperator.OrIn), debouncedPresentationQuery)] : []),
			].join(FilteringOperator.And),
		},
		{ placeholderData: (previousData) => previousData },
	);

	useEffect(() => {
		const query = buildSearchQuery(filters);
		onSearch(query);
	}, [filters, onSearch]);

	const handleChange = <K extends keyof AbstractFiltersState>(field: K, value: AbstractFiltersState[K] | null) => {
		setFilters((prev) => ({ ...prev, [field]: value ?? [] }));
	};

	return (
		<Stack>
			<Stack direction="row" spacing={1} alignItems={"center"} alignContent={"center"}>
				<Grid container columnSpacing={1}>
					<Grid item md={9} xs={12}>
						<TextField
							{...getTextField({ type: "search" })}
							label={t(`${component}.search.label`)}
							placeholder={t(`${component}.search.placeholder`)}
							value={filters.searchTerm}
							onChange={(e) => handleChange("searchTerm", e.target.value)}
						/>
					</Grid>
					<Grid item md={3} xs={12}>
						<Stack width={"100%"}>
							<Autocomplete
								onChange={(_, newValue) => {
									handleChange("presenter", toArray(newValue));
								}}
								label={t(`${component}.presenter.label`)}
								onInputChange={(_, newInputValue) => setPresenterQuery(newInputValue)}
								options={presenterOptions.data?.data ?? []}
								getOptionLabel={(option: PresenterFilterOption) => `${option.firstname} ${option.lastname}`}
								renderOption={(props, option) => (
									<Box component="li" {...props} key={option.id}>
										{`${option.firstname} ${option.lastname}`}
									</Box>
								)}
							/>
						</Stack>
					</Grid>
				</Grid>
				<Stack>
					<ToggleButton size="large" value={isFilterExpanded} selected={isFilterExpanded} onChange={toggle} color="primary">
						<Icon prefix={!isFilterActive(filters) ? "far" : "fas"} name={"filter"} fontSize={18} />
					</ToggleButton>
				</Stack>
			</Stack>
			<Grid container pt={1}>
				<Grid item xs={12} rowSpacing={2}>
					<AnimatePresence initial={false}>
						{isFilterExpanded && (
							<motion.div
								initial={{ height: 0, opacity: 0 }}
								animate={{ height: "auto", opacity: 1 }}
								exit={{ height: 0, opacity: 0 }}
								transition={{ duration: 0.3, ease: "easeInOut" }}
								style={{ overflow: "hidden" }}
							>
								<Paper elevation={0} square={false} sx={{ width: "100%", p: 2, backgroundColor: theme.palette.background.tone }}>
									<Typography variant="body1bold" gutterBottom>
										{t(`${component}.filters.title`)}
									</Typography>
									<Grid container direction={"row"} columnSpacing={1} mt={2} mb={2} alignItems={"center"}>
										<Grid item xs={12} md={3}>
											<Switch
												checked={filters.isIntegrated}
												labelPlacement={"start"}
												typography={{ children: t(`${component}.isIntegrated.label`) }}
												onChange={(e) => handleChange("isIntegrated", e.target.checked)}
											/>
										</Grid>
										{categoryOptions.data && categoryOptions.data.length > 0 && (
											<Grid item xs={12} md={3}>
												<Stack width={"100%"}>
													<Autocomplete
														multiple
														label={t(`${component}.category.label`)}
														options={categoryOptions.data}
														getOptionLabel={(option: CategoryFilterOption) => option.name}
														value={filters.category}
														onChange={(_, newValue) => handleChange("category", toArray(newValue))}
													/>
												</Stack>
											</Grid>
										)}
										<Grid item xs={12} md={3}>
											<Stack width={"100%"}>
												<Autocomplete
													multiple
													label={t(`${component}.status.label`)}
													options={AbstractStatusTypes}
													getOptionLabel={(option) => abstractStatusConfig[option].name}
													value={filters.status}
													onChange={(_, newValue) => handleChange("status", toArray(newValue))}
													renderOption={(props, option) => (
														<Box component="li" {...props} key={option}>
															<AbstractStatusBulletText value={option} />
														</Box>
													)}
												/>
											</Stack>
										</Grid>
										{finalTypeOptions.data && finalTypeOptions.data.length > 0 && (
											<Grid item xs={12} md={3}>
												<Stack width={"100%"}>
													<Autocomplete
														multiple
														label={t(`${component}.finalType.label`)}
														options={finalTypeOptions.data}
														getOptionLabel={(option: CategoryFilterOption) => option.name}
														value={filters.finalType}
														onChange={(_, newValue) => handleChange("finalType", toArray(newValue))}
													/>
												</Stack>
											</Grid>
										)}
										{themeOptions.data && themeOptions.data.length > 0 && (
											<Grid item xs={12} md={3}>
												<Stack width={"100%"}>
													<Autocomplete
														multiple
														label={t(`${component}.theme.label`)}
														options={themeOptions.data}
														getOptionLabel={(option: CategoryFilterOption) => option.name}
														value={filters.theme}
														onChange={(_, newValue) => handleChange("theme", toArray(newValue))}
													/>
												</Stack>
											</Grid>
										)}
										{subThemeOptions.data && subThemeOptions.data.data.length > 0 && (
											<Grid item xs={12} md={3}>
												<Stack width={"100%"}>
													<Autocomplete
														multiple
														label={t(`${component}.subTheme.label`)}
														options={subThemeOptions.data.data}
														getOptionLabel={(option: CategoryFilterOption) => option.name}
														value={filters.subTheme}
														onChange={(_, newValue) => handleChange("subTheme", toArray(newValue))}
													/>
												</Stack>
											</Grid>
										)}
										<Grid item xs={12} md={3}>
											<TextField label={t(`${component}.number.label`)} value={filters.number} onChange={(e) => handleChange("number", e.target.value)} />
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t(`${component}.newAttributedNumber.label`)}
												value={filters.newAttributedNumber}
												onChange={(e) => handleChange("newAttributedNumber", e.target.value)}
											/>
										</Grid>
									</Grid>
									<Typography variant="body1bold">{t(`${component}.programme.title`)}</Typography>
									<Grid container direction="row" mt={2} spacing={1}>
										<Grid item xs={12} md={6}>
											<Stack width={"100%"}>
												<Autocomplete
													multiple
													label={t(`${component}.session.label`)}
													options={sessionOptions.data?.data ?? []}
													getOptionLabel={(option: FilterOption) => option.code ?? option.key ?? option.title ?? ""}
													value={filters.session}
													onChange={(_, newValue) => handleChange("session", toArray(newValue))}
													onInputChange={(_, newInputValue) => setSessionQuery(newInputValue)}
													renderOption={(props, option) => (
														<Box component="li" {...props} key={option.id}>
															<KeyTitle keyText={option.code || option.key} title={option.title} />
														</Box>
													)}
												/>
											</Stack>
										</Grid>
										<Grid item xs={12} md={6}>
											<Stack width={"100%"}>
												<Autocomplete
													multiple
													label={t(`${component}.presentation.label`)}
													clearOnBlur
													options={presentationsOptions.data?.data ?? []}
													getOptionLabel={(option: FilterOption) => option.code ?? option.key ?? option.title ?? ""}
													value={filters.presentation}
													onChange={(_, newValue) => handleChange("presentation", toArray(newValue))}
													onInputChange={(_, newInputValue) => setPresentationQuery(newInputValue)}
													renderOption={(props, option) => (
														<Box component="li" {...props} key={option.id}>
															<KeyTitle keyText={option.code || option.key} title={option.title} />
														</Box>
													)}
												/>
											</Stack>
										</Grid>
									</Grid>
								</Paper>
							</motion.div>
						)}
					</AnimatePresence>
				</Grid>
			</Grid>
		</Stack>
	);
};
