import type { LiteTagRead, SessionRead, SessionStatusWrite, TagTypeRead } from "@api";
import K4Tags from "@infrastructure/components/interface/tags/K4Tags";
import FieldLabel from "@infrastructure/components/interface/titles/FieldLabel";
import { Stack, Typography } from "@mui/material";
import { PaperTitle } from "@mykey4/core";
import { t } from "i18next";
import { SessionStatusForm } from "../Components";

export interface SessionDetailsSidePanelProps {
	session: SessionRead;
	sessionTagTypes: (TagTypeRead & { tags: LiteTagRead[] })[];
	onSessionStatusChange: (sessionStatus: SessionStatusWrite) => void;
}

export const SessionDetailsSidePanel = (props: SessionDetailsSidePanelProps) => {
	const { session, sessionTagTypes, onSessionStatusChange } = props;
	return (
		<Stack spacing={2}>
			<PaperTitle>
				<SessionStatusForm existingValues={session} onSubmit={(data) => onSessionStatusChange(data)} />
			</PaperTitle>
			{sessionTagTypes && (
				<PaperTitle
					title={{
						children: <Typography variant="h5">{t("old.programme.sessionDetails.tags.label")}</Typography>,
					}}
				>
					<K4Tags tags={sessionTagTypes} />
				</PaperTitle>
			)}
			{session.organizedBy && (
				<PaperTitle
					title={{
						children: <Typography variant="h5">{t("old.programme.sessionDetails.organizedBy.label")}</Typography>,
					}}
				>
					<FieldLabel label={t("old.programme.sessionDetails.organizedBy.compagnyName")} />
					<Typography>{session.organizedBy}</Typography>
				</PaperTitle>
			)}
		</Stack>
	);
};
