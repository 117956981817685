import { type AbstractAbstractKeywordRead, postAbstractAbstractKeywordsSearchPaginated } from "@api";
import { API_VERSION_QUERY_PARAM, type BodyPostGet, type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { getAllData } from "../../common";

export const useAbstractAbstractKeywordsSearch = <TData = Paginated<AbstractAbstractKeywordRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Paginated<AbstractAbstractKeywordRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const override = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.abstractTheme, ...queryKeyPart, "AbstractKeywords", "GetPost", override, body],
		queryFn: ({ signal }) =>
			postAbstractAbstractKeywordsSearchPaginated({
				clientId,
				eventId,
				body,
				queryStrings: override,
				signal,
			}),
		...others,
	});
};

export const useAbstractAbstractKeywordsSearchAll = <TData = Array<AbstractAbstractKeywordRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Array<AbstractAbstractKeywordRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const override = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	const postQueryFn = async (signal: AbortSignal | undefined): Promise<AbstractAbstractKeywordRead[]> => {
		return getAllData((b) => postAbstractAbstractKeywordsSearchPaginated({ clientId, eventId, body: b, queryStrings: override, signal }), body);
	};

	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.abstractTheme, ...queryKeyPart, "AbstractKeywords", "GetPost", "All", override, body],
		queryFn: ({ signal }) => postQueryFn(signal),
		...others,
	});
};
