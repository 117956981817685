import { KeyTitle } from "@components";
import { Stack, Typography } from "@mykey4/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { type AbstractAssociationStatus, AbstractsAssociationTranslation } from "../common";
import { PresentationHeaderIcon } from "./PresentationHeaderIcon";

export type PresentationKeyTitleProps = {
	presentationId?: string;
	presentationKey?: string;
	presentationTitle?: string;
	abstractTitle?: string;
	overridePresentationTitle: boolean;
	status: AbstractAssociationStatus;
	onKeyClick?: () => void;
};

export const PresentationHeader = memo((props: PresentationKeyTitleProps) => {
	const { presentationId, presentationKey, presentationTitle, abstractTitle, overridePresentationTitle, status, onKeyClick } = props;
	const { t } = useTranslation();

	const presentationTitleOverridden = overridePresentationTitle ? abstractTitle : presentationTitle;
	const title = presentationTitleOverridden
		? `${t(`${AbstractsAssociationTranslation}.newPresentation`)} - ${presentationTitleOverridden}`
		: t(`${AbstractsAssociationTranslation}.newPresentation`);

	if (!presentationId) {
		return (
			<Stack direction={"row"} alignItems={"center"} p={1}>
				<PresentationHeaderIcon variant={status} />
				<Typography variant={"body1italic"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} px={1}>
					{title}
				</Typography>
			</Stack>
		);
	}

	return (
		<Stack direction={"row"} alignItems={"center"} p={1}>
			<PresentationHeaderIcon variant={status} />
			<KeyTitle keyText={presentationKey} title={presentationTitleOverridden} sx={{ p: 1 }} onClick={onKeyClick} />
		</Stack>
	);
});
