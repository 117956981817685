import { useTheme } from "@mui/material";
import { Icon, type IconName } from "@mykey4/core";
import { AnimatePresence, motion } from "framer-motion";
import { memo } from "react";
import type { AbstractAssociationStatus } from "../common";

const AnimatePresenceMemo = memo(AnimatePresence);

type Configuration = {
	[key in AbstractAssociationStatus]: { name: IconName; color: string };
};

export interface PresentationAssociationIconProps {
	variant: AbstractAssociationStatus;
}

export const PresentationHeaderIcon = memo((props: PresentationAssociationIconProps) => {
	const { variant } = props;
	const theme = useTheme();

	const iconConfig: Configuration = {
		notExistNotAssociate: {
			name: "circle-plus",
			color: theme.palette.info.main,
		},
		notExistAssociate: {
			name: "check",
			color: theme.palette.info.main,
		},
		existAndAssociateUpdate: {
			name: "rotate",
			color: theme.palette.warning.main,
		},
		existAndAssociate: {
			name: "check",
			color: theme.palette.success.main,
		},
		existAndNotAssociate: {
			name: "exclamation-circle",
			color: theme.palette.error.main,
		},
	};

	return (
		<AnimatePresenceMemo mode="wait">
			<motion.div
				key={variant}
				initial={{ opacity: 0, scale: 0.8 }}
				animate={{ opacity: 1, scale: 1 }}
				exit={{ opacity: 0, scale: 0.8 }}
				transition={{ duration: 0.4, ease: "easeInOut" }}
			>
				<Icon {...iconConfig[variant]} />
			</motion.div>
		</AnimatePresenceMemo>
	);
});
