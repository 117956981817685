import { useCallback, useEffect, useMemo } from "react";
import { WizardContext, type WizardContextType } from "./WizardContext";
import { iterateConfigs } from "./common";
import type { StepConfigs, StepId, StepValueTypes } from "./common";
import type { WizardStepperItem } from "./components";
import { WizardLayout } from "./components/WizardLayout";
import { WizardStepWrapper } from "./components/WizardStepWrapper";
import { useWizardDataStore, useWizardStepStore } from "./stores";

interface WizardProps<StepType extends StepId, ValueTypes extends StepValueTypes<StepType>, TInitialData = undefined> {
	storeKey: string;
	configs: StepConfigs<StepType, ValueTypes, TInitialData>;
}

export const Wizard = <StepType extends StepId, ValueTypes extends StepValueTypes<StepType>, TInitialData = undefined>(
	props: WizardProps<StepType, ValueTypes, TInitialData>,
) => {
	const { storeKey, configs } = props;

	const dataStore = useWizardDataStore<StepType, ValueTypes, TInitialData>(storeKey);
	const stepStore = useWizardStepStore<StepType>(storeKey, Object.keys(configs) as StepType[]);

	const { resetSteps } = dataStore((state) => ({ resetSteps: state.resetSteps }));

	const { steps, completedSteps, currentStepIndex, setCurrentStepIndex, goToPrevious, goToNext, completeStep } = stepStore((state) => ({
		steps: state.steps,
		completedSteps: state.completedSteps,
		currentStepIndex: state.currentStepIndex,
		setCurrentStepIndex: state.setCurrentStepIndex,
		goToNext: state.goToNext,
		goToPrevious: state.goToPrevious,
		completeStep: state.completeStep,
	}));

	const currentStepId = steps[currentStepIndex];
	const previousStepId = currentStepIndex > 0 ? steps[currentStepIndex - 1] : null;
	const nextStepId = currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1] : null;
	const currentConfig = configs[currentStepId];

	useEffect(() => {
		const unsubscribe = stepStore.subscribe(
			(state) => state.currentStepIndex,
			(currentStepIndex, previousStepIndex) => {
				if (currentStepIndex < previousStepIndex) {
					const filteredSteps = steps.filter((_, index) => index > currentStepIndex);
					resetSteps(filteredSteps);
				}
			},
		);
		return () => unsubscribe();
	}, [stepStore, steps, resetSteps]);

	const handleStepClick = useCallback(
		(index: number) => {
			const stepId = steps[index];
			if (completedSteps.includes(stepId)) {
				setCurrentStepIndex(index);
			}
		},
		[steps, completedSteps, setCurrentStepIndex],
	);

	const steppers = useMemo<WizardStepperItem[]>(() => {
		const result: WizardStepperItem[] = [];
		iterateConfigs(configs, (_, config, index) =>
			result.push({
				label: config.title,
				optional: config.stepperTitle,
				disabled: index > currentStepIndex,
				completed: index < currentStepIndex,
				onClick: () => handleStepClick(index),
			}),
		);
		return result;
	}, [handleStepClick, configs, currentStepIndex]);

	const handlePrevious = useCallback(() => {
		if (previousStepId) {
			goToPrevious();
		}
	}, [previousStepId, goToPrevious]);

	const handleNext = useCallback(() => {
		completeStep(currentStepId);
		if (nextStepId) {
			goToNext();
		}
	}, [completeStep, currentStepId, nextStepId, goToNext]);

	const context = useMemo<WizardContextType>(
		() => ({
			previousStepId: previousStepId as StepId,
			nextStepId: nextStepId as StepId,
			goToPrevious: handlePrevious,
			goToNext: handleNext,
		}),
		[previousStepId, nextStepId, handlePrevious, handleNext],
	);

	if (!currentConfig) {
		return <div>Step configuration not found</div>;
	}

	return (
		<WizardContext.Provider value={context}>
			<WizardLayout title={currentConfig.title} steppers={steppers} currentStepIndex={currentStepIndex}>
				<WizardStepWrapper config={currentConfig} storeKey={storeKey} />
			</WizardLayout>
		</WizardContext.Provider>
	);
};
