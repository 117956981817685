import type { SessionTemplateRead, TagRead } from "@api";
import { ActionReference, getCellChipColor } from "@key4-front-library/core";
import DataGridCellButtons, {} from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import { EnumActionsReference } from "@key4-front-library/core/Enums";
import type { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import { getColumnAction } from "@mykey4/core";

export const dataGridHeaders: GridColDef[] = [
	{ field: "id" },
	{
		field: "primaryTag",
		renderCell: getCellChipColor,
		sortComparator: (a, b) => {
			const labelA: string = a?.chip?.label ?? "";
			const labelB: string = b?.chip?.label ?? "";
			return labelA.localeCompare(labelB);
		},
		width: 1000,
		flex: 8,
		sortable: false,
		minWidth: 200,
		disableColumnMenu: true,
	},
	getColumnAction(),
];

type RowAction = (id: string) => Promise<void> | void;
type RowActions = {
	edit: RowAction;
	delete: RowAction;
	create: RowAction;
};

export const buildTagRows = (tags: TagRead[], sessionTemplates: SessionTemplateRead[], actions: RowActions): GridValidRowModel[] => {
	return tags.map((tag) => {
		const isPrimaryTagAssociatedToASessionTemplate = !!Object.values(sessionTemplates).filter((sessionTemplate) => sessionTemplate.primaryTagId === tag.id)
			.length;

		const actionButtons = isPrimaryTagAssociatedToASessionTemplate
			? [
					{
						...ActionReference[EnumActionsReference.EDIT],
						onClick: () => actions.edit(tag.id),
					},
					{
						...ActionReference[EnumActionsReference.DELETE],
						onClick: () => actions.delete(tag.id),
					},
				]
			: [
					{
						...ActionReference[EnumActionsReference.ADD],
						onClick: () => actions.create(tag.id),
					},
				];

		return {
			id: tag.id,
			primaryTag: {
				chip: {
					label: tag.label,
					backgroundColor: tag.backgroundColor,
					fontColor: tag.fontColor,
					borderColor: tag.borderColor,
				},
			},
			actions: DataGridCellButtons({ listButtons: actionButtons }),
		};
	});
};
