import type { IItemK4AutocompleteMultiSelect } from "@infrastructure/model/interfaces/components/K4AutocompleteMultiSelect.interface";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField, createFilterOptions } from "@mui/material";

type Props = {
	items: Array<IItemK4AutocompleteMultiSelect>;
	selectedValues: Array<IItemK4AutocompleteMultiSelect>;
	label: string;
	placeholder?: string;
	selectAllLabel: string;
	// text to display when there are no options
	noOptionsText?: string;
	onToggleOption: (selectedValues: Array<IItemK4AutocompleteMultiSelect>) => void;
	onClearOptions: () => void;
	onSelectAll: (isSelected: boolean) => void;
	getOptionLabel: (option: any) => string;
};
const K4AutocompleteMultiSelect = (props: Props) => {
	const { items, selectedValues, label, placeholder, selectAllLabel, noOptionsText, onToggleOption, onClearOptions, onSelectAll, getOptionLabel } = props;
	const allSelected = items.length === selectedValues.length;
	const handleToggleSelectAll = () => {
		onSelectAll && onSelectAll(!allSelected);
	};

	const handleChange = (_event: any, selectedOptions: any, reason: any) => {
		if (reason === "selectOption" || reason === "removeOption") {
			if (selectedOptions.find((option: any) => option.value === "select-all")) {
				handleToggleSelectAll();
			} else {
				onToggleOption && onToggleOption(selectedOptions);
			}
		} else if (reason === "clear") {
			onClearOptions && onClearOptions();
		}
	};

	const filter = createFilterOptions();

	return (
		<Autocomplete
			fullWidth
			multiple
			size="medium"
			options={items}
			value={selectedValues}
			disableCloseOnSelect
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={(option: any, value: any) => {
				// ! value of item should be unique
				return option.value === value.value;
			}}
			noOptionsText={noOptionsText}
			filterOptions={(options: Array<unknown>, params: any) => {
				const filtered = filter(options, params);
				return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
			}}
			onChange={handleChange}
			renderOption={(props, option: any, { selected }: any) => {
				const selectAllProps =
					option.value === "select-all" // To control the state of 'select-all' checkbox
						? { checked: allSelected }
						: {};
				return (
					<li {...props}>
						<Checkbox
							color="primary"
							icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
							checkedIcon={<CheckBoxIcon fontSize="small" />}
							style={{ marginRight: 8 }}
							checked={selected}
							{...selectAllProps}
						/>
						{getOptionLabel(option)}
					</li>
				);
			}}
			renderInput={(params) => {
				return <TextField {...params} label={label} placeholder={placeholder} />;
			}}
		/>
	);
};

export default K4AutocompleteMultiSelect;
