import { PrefixedTimePicker, getPrefixedTimePicker } from "@application/components/Molecules";
import { getMinutesDiffIgnoringDates } from "@application/helpers/date.helper";
import { Box, Grid, useTheme } from "@mui/material";
import { DatePicker, FormControl, Typography } from "@mykey4/core";
import { t } from "i18next";
import type { DateTime } from "luxon";
import { type ReactNode, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export interface DateBlockProps {
	displayDate?: string;
	startDateField: string;
	endDateField: string;
	isDisabled?: boolean;
	defaultCalendarDate?: DateTime | null;
}

export const DateBlock = (props: DateBlockProps): ReactNode => {
	const { displayDate, startDateField, endDateField, isDisabled = false, defaultCalendarDate } = props;
	const theme = useTheme();

	const { control } = useFormContext();

	const dateStart: DateTime = useWatch({ control, name: startDateField });
	const dateEnd: DateTime = useWatch({ control, name: endDateField });
	const [duration, setDuration] = useState("0");

	useEffect(() => {
		if (dateStart && dateEnd && dateStart.isValid && dateEnd.isValid) {
			setDuration(getMinutesDiffIgnoringDates(dateStart, dateEnd).toString());
		} else {
			setDuration("0");
		}
	}, [dateStart, dateEnd]);

	return (
		<Grid item xs={5.5}>
			{!!displayDate && (
				<FormControl name={displayDate} render={(args) => <DatePicker {...args} disabled={isDisabled} format={"DDDD"} referenceDate={defaultCalendarDate} />} />
			)}
			<Grid container spacing={2}>
				<PrefixedTimePicker isDisabled={isDisabled} {...getPrefixedTimePicker({ type: "from", options: { fieldName: startDateField } })} />
				<PrefixedTimePicker isDisabled={isDisabled} {...getPrefixedTimePicker({ type: "to", options: { fieldName: endDateField } })} />
			</Grid>
			<Box justifyItems="end">
				<Typography
					color={isDisabled ? theme.palette.text.disabled : "textPrimary"}
					variant={"body1"}
				>{`${t("duration")} ${duration}${t("units.minutes")}`}</Typography>
			</Box>
		</Grid>
	);
};
