import {
	type DtoFacultySession,
	type DtoFacultySessionPresentation,
	EBulkActionAction,
	FacultyHelper,
	getCellBulletColorWithLabel,
} from "@key4-front-library/core";
import type { DataGridCellBulletColorProps } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellBulletColor";
import type { GridColDef } from "@mui/x-data-grid-pro";
import { getColumnAction } from "@mykey4/core";

export enum EFacultyType {
	CHAIR = "chair",
	SPEAKER = "speaker",
}

// TODO TM : Not sure it should be in the configuration
export interface InvitationRow {
	id: string;
	session: DtoFacultySession;
	presentation: DtoFacultySessionPresentation | null;
	invitationType: string | null | undefined;
	status: DataGridCellBulletColorProps;
	actions: React.ReactElement;
}

const datagridHeaders: Array<GridColDef> = [
	{ field: "id" },
	{ field: "key", minWidth: 180, maxWidth: 180, flex: 1 },
	{ field: "firstname", minWidth: 400 },
	{ field: "lastname", minWidth: 400 },
	{ field: "email", minWidth: 600, flex: 2 },
];

const invitationsDatagridHeaders: Array<GridColDef> = [
	{
		field: "session",
		minWidth: 450,
		flex: 3,
		sortable: false,
		disableColumnMenu: true,
		renderCell: FacultyHelper.renderSessionCell,
	},
	{
		field: "presentation",
		flex: 3,
		minWidth: 300,
		sortable: false,
		disableColumnMenu: true,
		renderCell: FacultyHelper.renderPresentationCell,
	},
	{ field: "invitationType", width: 150, flex: 1 },
	{
		field: "status",
		renderCell: getCellBulletColorWithLabel,
		minWidth: 150,
		flex: 1,
		sortComparator: (a, b) => {
			const labelA = a.label;
			const labelB = b.label;

			return labelA.localeCompare(labelB);
		},
	},
	getColumnAction(),
];

const bulkActionActions: Array<EBulkActionAction> = [EBulkActionAction.MAILING, EBulkActionAction.INVITATION];

const sieveKeys = ["key", "firstname", "lastname", "email"];

const Faculty = {
	datagridHeaders,
	invitationsDatagridHeaders,
	bulkActionActions,
	sieveKeys,
};

export default Faculty;
