import { dateTimeNotNullable, dateTimeNullable } from "@mykey4/core";
import { t } from "i18next";
import { type DateTime, Duration } from "luxon";
import { z } from "zod";
import { isPeriodOverlap } from "./EditPresentationsSchedule.helper";

export interface SessionEditSchedule {
	id: string;
	hasBeenUpdated?: boolean;
	oldDateTimeStart?: DateTime | null;
	oldDateTimeEnd?: DateTime | null;
	newDateTimeStart: DateTime | null;
	newDateTimeEnd: DateTime | null;
}

export interface PresentationEditSchedule {
	id: string;
	title?: string;
	code?: string;
	oldDateTimeStart?: DateTime;
	oldDateTimeEnd?: DateTime;
	newDateTimeStart: DateTime | null;
	newDateTimeEnd: DateTime | null;
}

export interface FormPresentationsEdit {
	session: SessionEditSchedule;
	presentations: Record<string, PresentationEditSchedule>;
}

const presentationMinDuration = 1;

const sessionDateRangeSchema = z
	.object({
		newDateTimeStart: dateTimeNotNullable(),
		newDateTimeEnd: dateTimeNotNullable(),
	})
	.superRefine((data, ctx) => {
		const currentStartTime = Duration.fromISOTime(data.newDateTimeStart.toFormat("HH:mm"));
		const currentEndTime = Duration.fromISOTime(data.newDateTimeEnd.toFormat("HH:mm"));
		if (currentEndTime <= currentStartTime) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: t("form.validation.date.endDateMustBeSuperiorToStartDate"),
				path: ["newDateTimeEnd"],
			});
		}
	});

const presentationDateRangeSchema = z
	.object({
		id: z.string(),
		newDateTimeStart: dateTimeNullable(),
		newDateTimeEnd: dateTimeNullable(),
	})
	.superRefine((data, ctx) => {
		// Ensure both fields are either null or valid DateTime objects
		if ((data.newDateTimeStart === null && data.newDateTimeEnd !== null) || (data.newDateTimeStart !== null && data.newDateTimeEnd === null)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: t("form.validation.required"),
				path: data.newDateTimeStart === null ? ["newDateTimeStart"] : ["newDateTimeEnd"],
			});
			return z.NEVER;
		}

		// Check if both are non-null, then validate their relationship
		if (data.newDateTimeStart !== null && data.newDateTimeEnd !== null) {
			if (data.newDateTimeEnd < data.newDateTimeStart) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t("form.validation.date.endDateMustBeSuperiorToStartDate"),
					path: ["newDateTimeEnd"],
				});
				return z.NEVER;
			}

			const durationInMinutes = data.newDateTimeEnd.diff(data.newDateTimeStart, "minutes").minutes;
			if (durationInMinutes < presentationMinDuration) {
				const message = t("form.validation.minimumDuration", { count: presentationMinDuration });
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: message,
					path: ["newDateTimeEnd"],
				});
				return z.NEVER;
			}
		}

		return data;
	});

const presentations = (translation: string) =>
	z.record(presentationDateRangeSchema).superRefine((data, ctx) => {
		const schedules: Array<PresentationEditSchedule> = Object.values(data);

		const schedulesWithAllDates = schedules
			.filter((schedule) => schedule.newDateTimeStart && schedule.newDateTimeEnd)
			.map((schedule) => ({
				id: schedule.id,
				newDateTimeStart: schedule.newDateTimeStart as DateTime<true>,
				newDateTimeEnd: schedule.newDateTimeEnd as DateTime<true>,
			}));

		for (let i = 0; i < schedulesWithAllDates.length; i++) {
			const current = schedulesWithAllDates[i];

			const currentStartTime = current.newDateTimeStart.toFormat("HH:mm");
			const currentEndTime = current.newDateTimeEnd.toFormat("HH:mm");

			for (let j = i + 1; j < schedulesWithAllDates.length; j++) {
				const other = schedulesWithAllDates[j];
				const otherStartTime = other.newDateTimeStart.toFormat("HH:mm");
				const otherEndTime = other.newDateTimeEnd.toFormat("HH:mm");

				if (isPeriodOverlap(currentStartTime, currentEndTime, otherStartTime, otherEndTime)) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t(`${translation}.form.validation.presentationOverlaps`, {
							currentTitle: current.id,
							overlappingTitle: other.id,
						}),
						path: [`${current.id}.newDateTimeEnd`],
					});
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t(`${translation}.form.validation.presentationOverlaps`, {
							currentTitle: other.id,
							overlappingTitle: current.id,
						}),
						path: [`${other.id}.newDateTimeStart`],
					});
				}
			}
		}
	});

export const formPresentationEditValidationSchema = (translation: string) =>
	z
		.object({
			session: sessionDateRangeSchema,
			presentations: presentations(translation),
		})
		.superRefine((data, ctx) => {
			// Get session boundaries
			const sessionStart = data.session.newDateTimeStart;
			const sessionEnd = data.session.newDateTimeEnd;

			if (!sessionStart || !sessionEnd) {
				return; // Skip validation if session times aren't set
			}

			const sessionStartTime = sessionStart.toFormat("HH:mm");
			const sessionEndTime = sessionEnd.toFormat("HH:mm");

			const schedulesWithAllDates = Object.values(data.presentations)
				.filter((schedule) => schedule.newDateTimeStart && schedule.newDateTimeEnd)
				.map((schedule) => ({
					id: schedule.id,
					newDateTimeStart: schedule.newDateTimeStart as DateTime<true>,
					newDateTimeEnd: schedule.newDateTimeEnd as DateTime<true>,
				}));

			for (const presentation of schedulesWithAllDates) {
				const id = presentation.id;
				const startTime = presentation.newDateTimeStart.toFormat("HH:mm");
				const endTime = presentation.newDateTimeEnd.toFormat("HH:mm");

				// Check start time is within session
				if (startTime < sessionStartTime || startTime > sessionEndTime) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t(`${translation}.form.validation.withinSessionBounds`),
						path: [`presentations.${id}.newDateTimeStart`],
					});
				}

				// Check end time is within session
				if (endTime < sessionStartTime || endTime > sessionEndTime) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t(`${translation}.form.validation.withinSessionBounds`),
						path: [`presentations.${id}.newDateTimeEnd`],
					});
				}
			}
		});
