import type { SpeakerRead, SpeakerWrite } from "@api";
import type { SpeakerModalForm } from "@components";
import {
	type CustomFieldForm,
	type CustomFieldsValuesForm,
	type UserInformationProps,
	customFieldValuesReadToCustomFieldsValuesForm,
	customFieldsValuesFormToCustomFieldValuesRead,
} from "@key4-front-library/core";
import { emptyToUndefined } from "@mykey4/core";

export const speakerFormToSpeakerWrite = (form: CustomFieldForm<SpeakerModalForm>): SpeakerWrite => {
	return {
		qualityId: emptyToUndefined(form.qualityId),
		order: emptyToUndefined(form.order ?? undefined),
		invitationStatus: form.invitationStatus,
		customFieldValues: customFieldsValuesFormToCustomFieldValuesRead(form.customFields),
	};
};

export const speakerReadToSpeakerForm = (customFields: CustomFieldsValuesForm, speaker?: SpeakerRead): CustomFieldForm<SpeakerModalForm> => ({
	participantOrContact: null,
	qualityId: speaker?.quality?.id ?? "",
	order: speaker?.order?.toString() ?? "",
	invitationStatus: speaker?.invitationStatus ?? "unsent",
	replacementEmail: speaker?.replacement?.email ?? "",
	replacementFirstname: speaker?.replacement?.firstname ?? "",
	replacementLastname: speaker?.replacement?.lastname ?? "",
	replacementComment: speaker?.replacement?.comment ?? "",
	customFields: customFieldValuesReadToCustomFieldsValuesForm(customFields, speaker?.customFieldValues),
});

export const speakerReadToUserInformationProps = (speaker: SpeakerRead): UserInformationProps => ({
	email: speaker.email,
	firstname: speaker.firstname,
	lastname: speaker.lastname,
});
