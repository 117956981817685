import {
	type LiteTagRead,
	type SessionRead,
	type SessionReadV1,
	type SessionStatusWrite,
	type SessionWrite,
	type TagRead,
	type TokenRead,
	getSession,
	getSessionTags,
	getSessionTokens,
	getSessionV1,
	getSessions,
	getSessionsAll,
	postSession,
	postSessionsTags,
	putSession,
	putSessionStatus,
} from "@api";
import {
	API_VERSION_QUERY_PARAM,
	type BodyPostGet,
	type Paginated,
	QUERY_KEYS_PROGRAMME,
	type UseMutationArgs,
	type UseQueryArgs,
	overrideQueryParams,
	overrideVersion2,
} from "@mykey4/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const UseSessionQueryKeys = {
	sessions: (clientId: string, eventId: string) => [...QUERY_KEYS_PROGRAMME.sessions, clientId, eventId],
	session: (clientId: string, eventId: string, sessionId: string) => [...UseSessionQueryKeys.sessions(clientId, eventId), sessionId],
	sessionV1: (clientId: string, eventId: string, sessionId: string) => [...UseSessionQueryKeys.session(clientId, eventId, sessionId), "V1"],
	sessionsTags: (clientId: string, eventId: string) => [...UseSessionQueryKeys.sessions(clientId, eventId), "Tags"],
	sessionTags: (clientId: string, eventId: string, sessionId: string) => [...UseSessionQueryKeys.session(clientId, eventId, sessionId), "Tags"],
	sessionTokens: (clientId: string, eventId: string, sessionId: string) => [...UseSessionQueryKeys.session(clientId, eventId, sessionId), "Tokens"],
};

export const useSession = (clientId: string, eventId: string, sessionId: string, args: UseQueryArgs<SessionRead> = {}): UseQueryResult<SessionRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryWithVersion = overrideQueryParams(queryStrings, [
		{ key: API_VERSION_QUERY_PARAM, value: "2.0" },
		{ key: "includeCustomFields", value: "true" },
	]);
	return useQuery({
		queryKey: [...UseSessionQueryKeys.session(clientId, eventId, sessionId), ...queryKeyPart, queryWithVersion],
		queryFn: ({ signal }) =>
			getSession({
				clientId,
				eventId,
				sessionId,
				queryStrings: queryWithVersion,
				signal,
			}),
		...others,
	});
};

export const useSessions = <TData = Paginated<SessionRead>>(
	clientId: string,
	eventId: string,
	args: UseQueryArgs<Paginated<SessionRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const override = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", "List", clientId, eventId, override],
		queryFn: ({ signal }) =>
			getSessions({
				clientId,
				eventId,
				queryStrings: override,
				signal,
			}),
		...others,
	});
};

export const useSessionsAll = <TData = SessionRead[]>(
	clientId: string,
	eventId: string,
	args: UseQueryArgs<SessionRead[], TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const override = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", "List", "All", clientId, eventId, override],
		queryFn: ({ signal }) =>
			getSessionsAll({
				clientId,
				eventId,
				queryStrings: override,
				signal: signal,
			}),
		...others,
	});
};

export const useSessionV1 = (clientId: string, eventId: string, sessionId: string, args: UseQueryArgs<SessionReadV1> = {}): UseQueryResult<SessionReadV1> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...UseSessionQueryKeys.sessionV1(clientId, eventId, sessionId), ...queryKeyPart, queryStrings],
		queryFn: ({ signal }) =>
			getSessionV1({
				clientId,
				eventId,
				sessionId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "1.0" }]),
				signal,
			}),
		...others,
	});
};

export const useSessionsTags = <TData = Array<TagRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Array<TagRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery({
		queryKey: [...UseSessionQueryKeys.sessionsTags(clientId, eventId), ...queryKeyPart, body, queryStringOverride],
		queryFn: ({ signal }) => postSessionsTags({ clientId, eventId, body, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export const useSessionTags = (
	clientId: string,
	eventId: string,
	sessionId: string,
	args: UseQueryArgs<Paginated<LiteTagRead>> = {},
): UseQueryResult<Paginated<LiteTagRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery({
		queryKey: [...UseSessionQueryKeys.sessionTags(clientId, eventId, sessionId), ...queryKeyPart, queryStringOverride],
		queryFn: ({ signal }) => getSessionTags({ clientId, eventId, sessionId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export interface SessionCreateRequest {
	clientId: string;
	eventId: string;
	body: SessionWrite;
	signal?: AbortSignal;
}

export const useSessionCreate = (args: UseMutationArgs<SessionCreateRequest, string>): UseMutationResult<string, Error, SessionCreateRequest> => {
	const queryStringOverride = overrideVersion2([]);
	return useMutation<string, Error, SessionCreateRequest>({
		mutationFn: (request: SessionCreateRequest) =>
			postSession({
				...request,
				queryStrings: queryStringOverride,
			}),
		...args,
	});
};

export interface SessionUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: SessionWrite;
	signal?: AbortSignal;
}

export const useSessionUpdate = (args: UseMutationArgs<SessionUpdateRequest>): UseMutationResult<boolean, Error, SessionUpdateRequest> => {
	const queryStringOverride = overrideVersion2([]);
	return useMutation({
		mutationFn: (request: SessionUpdateRequest) =>
			putSession({
				...request,
				queryStrings: queryStringOverride,
			}),
		...args,
	});
};

export const useSessionTokens = <TData = Paginated<TokenRead>>(
	clientId: string,
	eventId: string,
	sessionId: string,
	args: UseQueryArgs<Paginated<TokenRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery({
		queryKey: [...UseSessionQueryKeys.sessionTokens(clientId, eventId, sessionId), ...queryKeyPart, queryStringOverride],
		queryFn: ({ signal }) => getSessionTokens({ clientId, eventId, sessionId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export interface SessionStatusUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: SessionStatusWrite;
	signal?: AbortSignal;
}

export const useSessionStatusUpdate = (args: UseMutationArgs<SessionStatusUpdateRequest>): UseMutationResult<boolean, Error, SessionStatusUpdateRequest> => {
	const queryStringOverride = overrideVersion2([]);
	return useMutation({
		mutationFn: (request: SessionStatusUpdateRequest) =>
			putSessionStatus({
				...request,
				queryStrings: queryStringOverride,
			}),
		...args,
	});
};
