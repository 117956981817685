import { useRooms } from "@api";
import ClashesSkeleton from "@application/components/_common/Clashes/ClashesSkeleton";
import QuickSearch from "@application/components/_common/QuickSearch/QuickSearch";
import type { ISelectableListItemsDefinition } from "@application/components/_common/QuickSearch/components/SelectableListItems/interface/ISelectableListItemsDefinition.interface";
import AnomaliesBySession from "@application/components/notifications/AnomaliesBySession";
import HelpersApp from "@application/helpers";
import DateHelper from "@application/helpers/date.helper";
import useTagTypes from "@application/hooks/useTagTypes";
import { useSessionPublicationStatusConfigurations, useSessionStatusConfigurations } from "@configurations";
import type { IAnomaliesByType } from "@domain/interfaces/anomalies.interface";
import type { ISessionAnomaliesModel } from "@domain/model/anomalies.model";
import { defaultRowsPerPageOptions } from "@infrastructure/constants/default-pagination.constant";
import {
	DebugConfig,
	EMenuPath,
	NoData,
	Services,
	queryFilters,
	queryStringPagination,
	useContextModule,
	usePaginationOld,
	useSnackBarHook,
} from "@key4-front-library/core";
import DebugQueryFilter from "@key4-front-library/core/Bo/Components/DebugQueryFilter";
import type { TAutocompleteMultiBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiBulletColor";
import { Box, TablePagination } from "@mui/material";
import { FilteringOperator } from "@mykey4/core";
import i18n, { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const debugQueryFilter = DebugConfig.QueryFilter;
const localStoragePrefixKey = "notifications";

type Props = {
	anomaliesCheckboxes: Array<IAnomaliesByType>;
	isOnlyActive: boolean;
};

const AnomaliesBySessionContainer = (props: Props) => {
	const { anomaliesCheckboxes, isOnlyActive } = props;

	const navigate = useNavigate();
	const { sendError } = useSnackBarHook();

	const { client, event } = useContextModule();
	const sessionStatusConfigurations = useSessionStatusConfigurations();
	const sessionPublicationStatus = useSessionPublicationStatusConfigurations();

	const [searchString, setSearchString] = useState<string>("");
	const [quickSearchString, setQuickSearchString] = useState<string>("");

	const [anomaliesBySession, setAnomaliesBySession] = useState<Array<ISessionAnomaliesModel>>();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { page, pageSize, totalRowCount, handleChangePage, handleChangePageSize, setTotalRowCount } = usePaginationOld();
	const rowsPerPageOptions = defaultRowsPerPageOptions;

	const [quickSearchFieldsDefinition, setQuickSearchFieldsDefinition] = useState<Array<ISelectableListItemsDefinition>>([]);

	const { data: rooms, isFetching: roomsLoading, error: roomsError } = useRooms(client.id, event.id);

	useEffect(() => {
		const key = "rooms";
		const setRoomsQuickSearchFieldsDefinition = async () => {
			const definition: ISelectableListItemsDefinition = {
				key,
				name: t("old.common.quickSearch.rooms"),
				collection:
					rooms?.data.map((room) => {
						return {
							key: room.key,
							label: room.name,
						};
					}) ?? [],
				searchKey: "room.id",
				keyPropertyName: "id",
				labelPropertyName: "name",
				addEmptyRow: true,
				order: 2,
			};

			setQuickSearchFieldsDefinition([...quickSearchFieldsDefinition.filter((def) => def.key !== key), definition]);
		};
		if (roomsLoading !== null && !roomsLoading && quickSearchFieldsDefinition.find((def) => def.key === key) === undefined) {
			setRoomsQuickSearchFieldsDefinition();
		}
		if (roomsError) {
			throw roomsError;
		}
	}, [rooms, roomsLoading, roomsError, quickSearchFieldsDefinition, setQuickSearchFieldsDefinition]);

	useEffect(() => {
		const key = "dates";
		const setDateQuickSearchFieldsDefinition = async () => {
			const definition: ISelectableListItemsDefinition = {
				key,
				name: t("old.common.quickSearch.dates"),
				collection: DateHelper.getDatesListRange(event.startDate, event.endDate),
				searchKey: "startDate",
				keyPropertyName: "key",
				labelPropertyName: "label",
				addEmptyRow: true,
				order: 1,
			};

			setQuickSearchFieldsDefinition([...quickSearchFieldsDefinition.filter((def) => def.key !== key), definition]);
		};
		if (quickSearchFieldsDefinition.find((def) => def.key === key) === undefined) {
			setDateQuickSearchFieldsDefinition();
		}
	}, [event, quickSearchFieldsDefinition, setQuickSearchFieldsDefinition]);

	useEffect(() => {
		const key = "status";
		const setStatusQuickSearchFieldsDefinition = async () => {
			const statusList: Array<TAutocompleteMultiBulletColorItem> = HelpersApp.SessionHelper.getStatusSessionListItems(sessionStatusConfigurations);

			const definition: ISelectableListItemsDefinition = {
				key,
				name: t("old.common.quickSearch.status"),
				collection: statusList.map((status) => {
					return {
						key: status.key,
						label: status.label,
						color: status.color,
					};
				}),
				searchKey: "status",
				keyPropertyName: "key",
				labelPropertyName: "label",
				addEmptyRow: false,
				order: 0,
			};

			const isPublished = {
				key: "isPublished",
				name: t("old.common.quickSearch.isPublished"),
				collection: [
					{
						key: "true",
						label: sessionPublicationStatus.published.name,
						color: sessionPublicationStatus.published.color,
					},
					{
						key: "false",
						label: sessionPublicationStatus.unpublished.name,
						color: sessionPublicationStatus.unpublished.color,
					},
				],
				searchKey: "isPublished",
				keyPropertyName: "key",
				labelPropertyName: "label",
				addEmptyRow: false,
				order: 1,
			} as ISelectableListItemsDefinition;

			setQuickSearchFieldsDefinition([...quickSearchFieldsDefinition.filter((def) => def.key !== key), definition, isPublished]);
		};
		if (quickSearchFieldsDefinition.find((def) => def.key === key) === undefined) {
			setStatusQuickSearchFieldsDefinition();
		}
	}, [event, quickSearchFieldsDefinition, setQuickSearchFieldsDefinition, t]);

	const { data: tagTypes, loading: tagTypesLoading, error: tagTypesError } = useTagTypes();

	useEffect(() => {
		const setTagTypesQuickSearchFieldsDefinition = async () => {
			const definitions: Array<ISelectableListItemsDefinition> = [];
			tagTypes.forEach((tagType, index) => {
				const tagTypeLabelFormated = tagType.label[0].toLowerCase() + tagType.label.slice(1).replace(/\s/g, "");
				const name = i18n.exists(`old.common.quickSearch.${tagTypeLabelFormated}`) ? t(`old.common.quickSearch.${tagTypeLabelFormated}`) : tagType.label;

				const definition: ISelectableListItemsDefinition = {
					key: tagType.id,
					name,
					collection:
						tagType.tags?.map((tag) => {
							return {
								key: tag.id,
								label: tag.label,
							};
						}) ?? [],
					searchKey: "tags.id",
					keyPropertyName: "key",
					labelPropertyName: "label",
					addEmptyRow: false,
					order: 3 + index,
				};
				definitions.push(definition);
			});
			setQuickSearchFieldsDefinition([...quickSearchFieldsDefinition.filter((def) => tagTypes.map((i) => i.id).includes(def.key)), ...definitions]);
		};
		if (tagTypesLoading !== null && !tagTypesLoading && !quickSearchFieldsDefinition.some((def) => tagTypes.map((n) => n.id).includes(def.key))) {
			setTagTypesQuickSearchFieldsDefinition();
		}
		if (tagTypesError) {
			throw tagTypesError;
		}
	}, [tagTypes, tagTypesLoading, tagTypesError, quickSearchFieldsDefinition, setQuickSearchFieldsDefinition]);

	const fetchAnomalies = useCallback(
		(clientId: string, eventId: string, searchString: string) => {
			setIsLoading(true);
			Services.Events.Programme.AnomaliesService.getListPagined(clientId, eventId, [
				...queryStringPagination({
					page,
					pageSize,
				}),
				...queryFilters(searchString),
			])
				.then((_response: any) => {
					const { data, pagination } = _response;
					if (pagination.totalCount) setTotalRowCount(pagination.totalCount);
					setAnomaliesBySession(data);
				})
				.catch(sendError)
				.finally(() => setIsLoading(false));
		},
		[page, pageSize, setTotalRowCount],
	);

	const getFilterWithAnomaliesChecked = useCallback(() => {
		if (anomaliesCheckboxes && anomaliesCheckboxes.filter((anomalieCB) => anomalieCB.checked).length > 0) {
			if (anomaliesCheckboxes.filter((anomalieCB) => anomalieCB.checked).length === anomaliesCheckboxes.length) {
				return ""; // Tous les type d'annomalie sont selectionnés, inutile de filtrer dessus
			}
			return `type==${anomaliesCheckboxes
				.filter((anomalieCB) => anomalieCB.checked)
				.map((anomalie) => anomalie.anomaly)
				.join(FilteringOperator.OrIn)}`;
		}
		return ""; // Aucun type d'annomalie définie ou selectionné, inutile de filtrer dessus
	}, [anomaliesCheckboxes]);

	useEffect(() => {
		const typeFilters = getFilterWithAnomaliesChecked();
		const activeFilter = `onlyActive==${isOnlyActive}`;
		const quickSearchFilter = quickSearchString.length > 0 ? quickSearchString : "";
		const _searchString = [typeFilters, activeFilter, quickSearchFilter].filter((filter) => filter.length > 0).join(FilteringOperator.And);
		setSearchString(_searchString);
		fetchAnomalies(client.id, event.id, _searchString);
	}, [event, getFilterWithAnomaliesChecked, isOnlyActive, quickSearchString, fetchAnomalies]);

	const handleSessionClick = (session: ISessionAnomaliesModel) => {
		navigate(["", client.key, event.key, EMenuPath.SESSIONS, session.id].join("/"));
	};

	const handleQueryBuilderChange = (query: string) => {
		setSearchString(query);
	};

	useEffect(() => {
		handleChangePage(0);
	}, [searchString]);

	return (
		<>
			{debugQueryFilter && <DebugQueryFilter queryBuilderString={searchString} onChangeQueryBuilder={handleQueryBuilderChange} />}
			<Box m={2}>
				<QuickSearch
					localStoragePrefixKey={localStoragePrefixKey}
					quickSearchFieldsDefinition={quickSearchFieldsDefinition}
					handleQueryBuilder={(_query: string) => {
						setQuickSearchString(_query);
					}}
				/>
			</Box>
			{isLoading && <ClashesSkeleton quantity={5} />}
			{anomaliesBySession && anomaliesBySession.length > 0 ? (
				anomaliesBySession.map((session) => {
					return <AnomaliesBySession key={session.id} session={session} handleCardSingleClick={handleSessionClick} anomaliesCheckboxes={anomaliesCheckboxes} />;
				})
			) : (
				<NoData />
			)}
			<TablePagination
				component={"div"}
				count={totalRowCount}
				onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, _newPage: number) => {
					handleChangePage(_newPage);
				}}
				page={page}
				rowsPerPage={pageSize}
				rowsPerPageOptions={rowsPerPageOptions}
				onRowsPerPageChange={(_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					handleChangePageSize(Number.parseInt(_event.target.value, 10));
				}}
				showFirstButton
				showLastButton
			/>
		</>
	);
};

export default AnomaliesBySessionContainer;
