import type { SessionTemplateRead, SessionTemplateWrite } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated, postCreate, put } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "session-templates", id }];

interface GetSessionTemplatesArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getSessionTemplates = async (args: GetSessionTemplatesArgs): Promise<Paginated<SessionTemplateRead>> => {
	return await getPaginated({
		module,
		entities: rootEntities(),
		...args,
	});
};

interface PostSessionTemplateArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: SessionTemplateWrite;
}

export const postSessionTemplate = async (args: PostSessionTemplateArgs): Promise<string> => {
	const { body, ...others } = args;
	return await postCreate<SessionTemplateWrite>(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};

interface PutSessionTemplateArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionTemplateId: string;
	body: SessionTemplateWrite;
}

export const putSessionTemplate = async (args: PutSessionTemplateArgs): Promise<boolean> => {
	const { sessionTemplateId, body, ...others } = args;
	return await put<SessionTemplateWrite>(
		{
			module,
			entities: rootEntities(sessionTemplateId),
			...others,
		},
		body,
	);
};
