import { Step, StepButton, Stepper } from "@mui/material";

export type WizardStepperItem = {
	label: string;
	disabled?: boolean;
	optional?: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	completed?: boolean;
};

export type WizardStepperProps = {
	stepperItems: WizardStepperItem[];
	activeStep: number;
};

export const WizardStepper = (props: WizardStepperProps) => {
	const { stepperItems, activeStep } = props;
	return (
		<Stepper activeStep={activeStep - 1} alternativeLabel>
			{stepperItems.map((item) => {
				const stepPropsCompleted =
					item.completed === undefined
						? {}
						: {
								completed: item.completed,
							};
				return (
					<Step expanded={true} key={item.label} {...stepPropsCompleted} disabled={item.disabled ?? true}>
						<StepButton optional={item.optional} onClick={item.onClick}>
							{item.label}
						</StepButton>
					</Step>
				);
			})}
		</Stepper>
	);
};
