import type { SessionRead } from "@api";
import { type AbstractDissociationResults, AbstractsDissociation } from "@components";
import { getSessionKey } from "@helpers";
import { Stack, Typography } from "@mui/material";
import { DialogAdvanced, type DialogComponentProps, getButton, useAutoLoaderOverlay } from "@mykey4/core";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAbstractDissociationBulkAction } from "../hooks";

export interface AbstractsDissociationDialogProps {
	session: SessionRead;
	selectedAbstractIds: string[];
}

export const AbstractsDissociationDialog = (props: DialogComponentProps<AbstractsDissociationDialogProps, AbstractDissociationResults>) => {
	const component = "abstract.bulkActions.dialog.dissociation";
	const {
		open,
		onClose,
		payload: { session, selectedAbstractIds },
	} = props;
	const { t } = useTranslation();

	const [dissociation, setDissociation] = useState<AbstractDissociationResults>([]);

	const { isLoading, abstracts, presentations, sessions } = useAbstractDissociationBulkAction({ sessionId: session.id, abstractIds: selectedAbstractIds });

	const handleClose = useCallback((source: "confirm" | "cancel") => onClose(source === "confirm" ? dissociation : []), [dissociation, onClose]);
	const handleDissociationChange = useCallback((value: AbstractDissociationResults) => setDissociation(value), []);

	const { isLoading: isLoaderLoading } = useAutoLoaderOverlay(isLoading);

	const canOpen = !isLoaderLoading && !!presentations.data && presentations.data.length > 0;

	return (
		<DialogAdvanced
			open={open && canOpen}
			onClose={handleClose}
			fullWidth
			maxWidth="lg"
			title={{ variant: "h6", children: t(`${component}.title`) }}
			buttonGroupEnd={{
				buttons: [
					{ onClick: () => handleClose("cancel"), ...getButton({ type: "cancel" }) },
					{ onClick: () => handleClose("confirm"), ...getButton({ type: "confirm" }), variant: "contained" },
				],
			}}
		>
			<Stack direction={"column"} spacing={2}>
				<Typography variant="body2">
					<Trans
						i18nKey={`${component}.text1`}
						components={{
							primary: <Typography component="span" variant={"body2"} color="primary" sx={{ textDecoration: "underline" }} />,
						}}
						values={{ session: getSessionKey(session) }}
					/>
				</Typography>
				<Typography variant="body2">{t(`${component}.text2`)}</Typography>
				<AbstractsDissociation
					abstracts={abstracts?.data}
					presentations={presentations?.data}
					sessions={sessions?.data}
					onDissociationChange={handleDissociationChange}
				/>
			</Stack>
		</DialogAdvanced>
	);
};
