import type { AbstractRead, PresentationRead, SessionRead } from "@api";
import { getAbstractKey, groupBy, groupByFirst } from "@helpers";
import { mapPresentationToItem } from "./AbstarctsDissociation.mapper";
import type { AbstractDissociationItem } from "./common";

export const buildAbstractDissociationItems = (
	abstracts: AbstractRead[],
	presentations: PresentationRead[],
	sessions: SessionRead[],
): AbstractDissociationItem[] => {
	const newItems: AbstractDissociationItem[] = [];

	if (abstracts.length <= 0) {
		return newItems;
	}

	const presentationItems = presentations?.filter((item) => !!item.abstractId).map((item) => mapPresentationToItem(item, sessions)) ?? [];

	const grouping = groupBy(presentationItems, "abstractId");

	for (const abstract of abstracts) {
		const abstractPresentations = grouping.get(abstract.id) ?? [];
		newItems.push({
			id: abstract.id,
			key: getAbstractKey(abstract),
			title: abstract.title,
			presentations: groupByFirst(abstractPresentations, "id"),
		});
	}

	return newItems;
};
