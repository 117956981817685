import { KeyTitle } from "@components";
import type { SxProps, Theme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { type IconProps, Stack } from "@mykey4/core";
import type { TextVariant } from "@mykey4/core/Theme/Theme.types";
import type { JSXElementConstructor } from "react";

export interface KeyTitleHeaderComponentProps {
	iconBefore?: IconProps;
}

export interface KeyTitleHeaderComponents {
	iconBefore?: JSXElementConstructor<IconProps>;
	topRight?: React.ReactElement;
}

interface KeyTitleHeaderProps {
	keyText?: string;
	title?: string;
	variant?: TextVariant;
	onKeyClick?: () => void;
	sx?: SxProps<Theme>;
	slots?: KeyTitleHeaderComponents;
	slotProps?: KeyTitleHeaderComponentProps;
	children?: React.ReactElement;
}

export const KeyTitleHeader = (props: KeyTitleHeaderProps) => {
	const { keyText, title, variant, onKeyClick, sx, slots, slotProps, children } = props;
	const IconComponent = slots?.iconBefore;
	return (
		<Stack direction={"column"} overflow={"hidden"} sx={deepmerge({}, sx)} spacing={1}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1}>
				<Stack direction={"row"} alignItems={"center"} spacing={1} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
					{IconComponent && slotProps?.iconBefore && (
						<Stack flexShrink={0} alignItems={"center"} justifyContent={"center"}>
							<IconComponent {...slotProps.iconBefore} />
						</Stack>
					)}
					<KeyTitle keyText={keyText} title={title} variant={variant} onClick={onKeyClick} />
				</Stack>
				<Stack flexShrink={0}>{slots?.topRight}</Stack>
			</Stack>
			{children}
		</Stack>
	);
};
