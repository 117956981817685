import type { IAnomaliesByType } from "@domain/interfaces/anomalies.interface";
import { FormControlLabel, Stack, Switch } from "@mui/material";

type AnomaliesByTypesListProps = {
	anomaliesByType: Array<IAnomaliesByType>;
	onAnomalyCheckboxClick: (anomalyItem: IAnomaliesByType) => void;
};
const AnomaliesByTypesList = (props: AnomaliesByTypesListProps) => {
	const { anomaliesByType, onAnomalyCheckboxClick } = props;
	return (
		<Stack>
			{anomaliesByType.map((anomaly, index) => {
				return (
					<FormControlLabel
						key={index}
						control={
							<Switch
								checked={anomaly.checked}
								onChange={() => {
									onAnomalyCheckboxClick(anomaly);
								}}
								inputProps={{
									"aria-label": anomaly.anomalyTranslation,
								}}
							/>
						}
						label={anomaly.anomalyTranslation}
					/>
				);
			})}
		</Stack>
	);
};

export default AnomaliesByTypesList;
