import { useSessionTemplates } from "@api";
import type { SessionModalForm } from "@components";
import { ConfirmationDialog, SessionFormComponent, overrideWithSessionTemplate } from "@components";
import type { CustomFieldForm } from "@key4-front-library/core";
import { useDialogs } from "@mykey4/core";
import { t } from "i18next";
import { useEffect } from "react";
import { type UseFormReturn, useWatch } from "react-hook-form";

export interface UseSessionModalWatchPrimaryTagArgs {
	clientId: string;
	eventId: string;
	sessionId?: string;
	form: UseFormReturn<CustomFieldForm<SessionModalForm>>;
}

export interface UseSessionModalWatchPrimaryTagReturn {
	isFetching: boolean;
}

export const useSessionModalWatchPrimaryTag = (args: UseSessionModalWatchPrimaryTagArgs): UseSessionModalWatchPrimaryTagReturn => {
	const { clientId, eventId, sessionId, form } = args;
	const dialog = useDialogs();

	const watchPrimaryTag = useWatch({ control: form.control, name: "primaryTagId" });

	const sessionTemplatesQuery = useSessionTemplates(clientId, eventId, {
		queryKeyPart: ["useSessionModalWatchPrimaryTag"],
	});

	useEffect(() => {
		const sessionTemplate = sessionTemplatesQuery.data?.data.find((sessionTemplates) => sessionTemplates.primaryTagId === watchPrimaryTag);
		if (!watchPrimaryTag || !sessionTemplatesQuery?.data?.data || !sessionTemplate || sessionId) {
			return;
		}
		dialog.open(ConfirmationDialog, { content: t(`${SessionFormComponent}.sessionTemplate.advancedDialog.confirm.content`) }).then((confirmed) => {
			form.setValue("shouldApplySessionTemplate", confirmed);
			if (confirmed) {
				overrideWithSessionTemplate(form, sessionTemplate);
			}
		});
	}, [watchPrimaryTag, sessionTemplatesQuery?.data?.data, sessionId, form, form.setValue, dialog.open]);

	return {
		isFetching: sessionTemplatesQuery.isFetching,
	};
};
