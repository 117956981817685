import type { RoomRead } from "@api";
import {
	SessionFormComponent,
	type SessionModalForm,
	SessionStatusSelect,
	roomsToAutocompleteItem,
	tagsReadToItems,
	tagsToSecondariesTagsSelect,
} from "@components";
import { SessionPublicationStatusSelect } from "@components";
import {
	type ActionItem,
	Autocomplete,
	Checkbox,
	DatePicker,
	DateTimePicker,
	FormControl,
	Grid,
	Select,
	Stack,
	type TagProps,
	TextField,
	TimePicker,
	TitleDivider,
	getControlledAutoComplete,
	getRhfCheckbox,
	getSelectTag,
	getTextField,
} from "@mykey4/core";
import { t } from "i18next";
import { uniqueId } from "lodash";
import type { DateTime } from "luxon";
import { useFormContext, useWatch } from "react-hook-form";
import type { SessionFormTagType } from "./SessionForm.type";

export interface SessionFormProps {
	eventStartDate: DateTime<boolean>;
	rooms: RoomRead[];
	primaryTagType: SessionFormTagType | undefined;
	secondaryTagType: SessionFormTagType[];
	isAbstractModuleActivated: boolean | undefined;
}

export const SessionForm = (props: SessionFormProps): React.ReactNode => {
	const form = useFormContext<SessionModalForm, undefined>();

	const { eventStartDate, rooms: roomsProps, primaryTagType, secondaryTagType, isAbstractModuleActivated } = props;

	const rooms = roomsToAutocompleteItem(roomsProps);
	const primaryTag = tagsReadToItems(primaryTagType ? primaryTagType.tags : []);
	const secondaryTags = tagsToSecondariesTagsSelect(secondaryTagType, form);
	const watchIsMultiDay = useWatch({ control: form.control, name: "isMultiday" });

	return (
		<Stack spacing={2}>
			<TitleDivider title={{ variant: "h5", children: t(`${SessionFormComponent}.section.general`) }} />
			<Grid container spacing={2} px={2} alignItems={"center"}>
				{isAbstractModuleActivated && (
					<FormControl
						name={"isAbstract"}
						gridSize={{ xs: 12 }}
						render={(args) => (
							<Checkbox
								checked={args.value ?? false}
								{...getRhfCheckbox(args, {
									type: "default",
									translation: t(`${SessionFormComponent}.isAbstract`),
								})}
							/>
						)}
					/>
				)}
				<FormControl name={"code"} gridSize={{ xs: 4 }} render={(args) => <TextField {...args} label={t(`${SessionFormComponent}.code`)} />} />
				<FormControl
					name={"primaryTagId"}
					gridSize={{ xs: 4 }}
					render={(args) => (
						<Select
							{...args}
							{...getSelectTag(primaryTag, args.value)}
							label={primaryTagType?.label}
							SelectDisplayProps={{
								style: args.value ? { padding: "12px 14px", marginRight: "24px" } : {},
							}}
						/>
					)}
				/>
				<FormControl name={"organizedBy"} gridSize={{ xs: 4 }} render={(args) => <TextField {...args} label={t(`${SessionFormComponent}.organizedBy`)} />} />
				<FormControl name={"title"} render={(args) => <TextField {...args} label={t(`${SessionFormComponent}.title`)} />} />
				<FormControl
					name={"description"}
					render={(args) => <TextField {...args} {...getTextField({ type: "textarea" })} label={t(`${SessionFormComponent}.description`)} minRows={5} />}
				/>
				<FormControl
					name={"order"}
					gridSize={{ xs: 4 }}
					render={(args) => <TextField {...args} {...getTextField({ type: "number" })} label={t(`${SessionFormComponent}.order`)} />}
				/>
				<FormControl
					name={"isPrivate"}
					gridSize={{ xs: 2 }}
					render={(args) => (
						<Checkbox
							{...getRhfCheckbox(args, {
								type: "default",
								translation: t(`${SessionFormComponent}.isPrivate`),
							})}
						/>
					)}
				/>
			</Grid>
			<TitleDivider title={{ variant: "h5", children: t(`${SessionFormComponent}.section.room`) }} />
			<Grid container spacing={2} pt={2} px={2}>
				<FormControl
					name={"expectedAudience"}
					gridSize={{ xs: 4 }}
					render={(args) => (
						<TextField
							{...args}
							{...getTextField({ type: "number" })}
							label={t(`${SessionFormComponent}.expectedAudience`)}
							inputProps={{
								min: 0,
							}}
						/>
					)}
				/>
				<FormControl
					name={"room"}
					gridSize={{ xs: 8 }}
					render={(args) => (
						<Autocomplete
							id="room"
							{...getControlledAutoComplete({ type: "labelWithCaption", options: rooms ?? [] }, args)}
							label={t(`${SessionFormComponent}.room`)}
						/>
					)}
				/>
			</Grid>
			<TitleDivider title={{ variant: "h5", children: t(`${SessionFormComponent}.section.schedule`) }} />
			<Grid container spacing={2} pt={2} px={2}>
				<FormControl
					name={"isMultiday"}
					gridSize={{ xs: 12 }}
					render={(args) => (
						<Checkbox
							{...getRhfCheckbox(args, {
								type: "default",
								translation: t(`${SessionFormComponent}.isMultiday`),
							})}
						/>
					)}
				/>
				{!watchIsMultiDay && [
					<FormControl
						key={uniqueId()}
						name={"startDate"}
						gridSize={{ xs: 3 }}
						render={(args) => <DatePicker {...args} label={t(`${SessionFormComponent}.startDate`)} referenceDate={eventStartDate} />}
					/>,
					<FormControl
						key={uniqueId()}
						name={"startTime"}
						gridSize={{ xs: 3 }}
						render={(args) => <TimePicker {...args} label={t(`${SessionFormComponent}.startTime`)} />}
					/>,
					<FormControl
						key={uniqueId()}
						name={"endTime"}
						gridSize={{ xs: 3 }}
						render={(args) => <TimePicker {...args} label={t(`${SessionFormComponent}.endTime`)} />}
					/>,
				]}
				{watchIsMultiDay && [
					<FormControl
						key={uniqueId()}
						name={"startDate"}
						gridSize={{ xs: 4 }}
						render={(args) => <DatePicker {...args} label={t(`${SessionFormComponent}.startDate`)} referenceDate={eventStartDate} />}
					/>,
					<FormControl
						key={uniqueId()}
						name={"startTime"}
						gridSize={{ xs: 4 }}
						render={(args) => <TimePicker {...args} label={t(`${SessionFormComponent}.startTime`)} />}
					/>,
					<Grid key={uniqueId()} item xs={4} />,
					<FormControl
						key={uniqueId()}
						name={"endDate"}
						gridSize={{ xs: 4 }}
						render={(args) => <DatePicker {...args} label={t(`${SessionFormComponent}.endDate`)} referenceDate={eventStartDate} />}
					/>,
					<FormControl
						key={uniqueId()}
						name={"endTime"}
						gridSize={{ xs: 4 }}
						render={(args) => <TimePicker {...args} label={t(`${SessionFormComponent}.endTime`)} />}
					/>,
				]}
				<FormControl
					name={"duration"}
					gridSize={{ xs: !watchIsMultiDay ? 3 : 4 }}
					render={(args) => <TextField {...args} label={t(`${SessionFormComponent}.duration`)} />}
				/>
				<FormControl name={"status"} gridSize={{ xs: 4 }} render={(args) => <SessionStatusSelect {...args} label={t(`${SessionFormComponent}.status`)} />} />
				<FormControl
					name={"isPublished"}
					gridSize={{ xs: 4 }}
					render={(args) => <SessionPublicationStatusSelect {...args} label={t(`${SessionFormComponent}.isPublished`)} />}
				/>
				<FormControl
					name={"publicationDate"}
					gridSize={{ xs: 4 }}
					render={(args) => <DateTimePicker {...args} label={t(`${SessionFormComponent}.publicationDate`)} />}
				/>
				{secondaryTags.map((secondaryTag) => (
					<FormControl
						key={secondaryTag.key}
						name={`tagsByTagTypes.${secondaryTag.key}`}
						gridSize={{ xs: 4 }}
						render={(args) => (
							<Autocomplete
								{...args}
								{...getControlledAutoComplete({ type: "multiTag", options: secondaryTag.options }, args)}
								{...secondaryTag.autocomplete}
								getOptionDisabled={(option) => {
									const values = args.value as Array<ActionItem<TagProps>>;
									return (
										secondaryTag.max !== undefined && values.length >= (secondaryTag.max ?? 0) && !values.filter((val) => val.value === option.value).length
									);
								}}
							/>
						)}
					/>
				))}
			</Grid>
		</Stack>
	);
};
