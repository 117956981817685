import { SessionStatusBulletText } from "@components";
import { useSessionStatusConfiguration } from "@configurations";
import { type SessionStatus, Tag, type TagProps } from "@mykey4/core";

export type SessionStatusTagProps = Omit<TagProps, "backgroundColor" | "borderColor" | "fontColor" | "children"> & {
	value: SessionStatus;
};

export const SessionStatusTag = (props: SessionStatusTagProps) => {
	const { value, ...others } = props;
	const configuration = useSessionStatusConfiguration({ status: value });
	return (
		<Tag {...others} backgroundColor={configuration.backgroundColor} borderColor={configuration.borderColor} fontColor={configuration.color}>
			<SessionStatusBulletText value={value} />
		</Tag>
	);
};
