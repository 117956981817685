import { getPresentations } from "@api";
import { API_VERSION_QUERY_PARAM, useDialogs } from "@mykey4/core";
import { useCallback } from "react";
import { EditPresentationsSchedule } from "./EditPresentationsSchedule";
import { type SessionToEditScheduleArgs, sessionsToEditSchedule } from "./EditPresentationsSchedule.mapper";

export interface UseEditPresentationScheduleDialogArgs {
	clientId: string;
	eventId: string;
}

export interface UseEditPresentationScheduleDialogReturn {
	open: (args: SessionToEditScheduleArgs) => Promise<void>;
}

export const useEditPresentationScheduleDialog = (args: UseEditPresentationScheduleDialogArgs): UseEditPresentationScheduleDialogReturn => {
	const { clientId, eventId } = args;
	const dialog = useDialogs();

	const open = useCallback(
		async (args: SessionToEditScheduleArgs) => {
			const { sessionId, oldSession, newSession } = args;

			const sessionEditSchedule = sessionsToEditSchedule({
				sessionId: sessionId,
				oldSession: oldSession,
				newSession: newSession,
			});

			if (!sessionEditSchedule.hasBeenUpdated) {
				return;
			}

			const presentations = await getPresentations({
				clientId: clientId,
				eventId: eventId,
				sessionId: sessionId,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
			});

			if (presentations?.data.length === 0) {
				return;
			}

			await dialog.open(EditPresentationsSchedule, { sessionEditSchedule });
		},
		[clientId, eventId, dialog.open],
	);

	return { open };
};
