import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { IMenuItems } from "@infrastructure/model/interfaces/navigation/menuItems.interface";
import { Box, Tab, Tabs } from "@mui/material";
import type { IconName } from "@mykey4/core";

type Props = {
	menuItems: IMenuItems;
	baseUrl: string;
	defaultTabsValue: string;
	routeParentLastPartPath: string;
};

const NavigationTabsWithIcons = (props: Props) => {
	const { menuItems, baseUrl, defaultTabsValue, routeParentLastPartPath } = props;

	const location = useLocation();
	const navigate = useNavigate();

	const [tabValue, setTabValue] = useState<string>();

	useEffect(() => {
		const routeComponents = location.pathname.split("/");
		const routeLastPartPath = routeComponents[routeComponents.length - 1];

		if (routeLastPartPath === "" || routeLastPartPath === routeParentLastPartPath) {
			navigate(`${baseUrl}/${defaultTabsValue}`, { replace: true });
			setTabValue(defaultTabsValue);
		} else {
			setTabValue(routeLastPartPath);
		}
	}, [baseUrl, location, defaultTabsValue, routeParentLastPartPath, navigate, setTabValue]);

	const handleChange = (_: React.SyntheticEvent, _newTabValue: string) => {
		if (_newTabValue !== tabValue) {
			setTabValue(_newTabValue);
			navigate(`${baseUrl}/${_newTabValue}`);
		}
	};

	return (
		<Box sx={{ borderBottom: 1, borderColor: "divider", mb: 5 }}>
			{tabValue && (
				<Tabs value={tabValue} onChange={handleChange} centered={false} variant="scrollable" scrollButtons="auto">
					{menuItems.map((item) => {
						return (
							<Tab
								key={item.reference}
								value={item.reference}
								label={<Box component="span">{item.translationKey ? t(item.translationKey) : item.name?.toLocaleUpperCase()}</Box>}
								icon={
									<Box mr={1}>
										<Icon iconName={item.icon as IconName} />
									</Box>
								}
								iconPosition="start"
							/>
						);
					})}
				</Tabs>
			)}
		</Box>
	);
};

export default NavigationTabsWithIcons;
