import { type AbstractCategoryRead, postAbstractCategoriesSearch } from "@api";
import { API_VERSION_QUERY_PARAM, type BodyPostGet, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useAbstractCategoriesSearch = (
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Array<AbstractCategoryRead>> = {},
): UseQueryResult<Array<AbstractCategoryRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const override = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.abstractCategory, ...queryKeyPart, "GetPost", override, body],
		queryFn: ({ signal }) =>
			postAbstractCategoriesSearch({
				clientId,
				eventId,
				body,
				queryStrings: override,
				signal,
			}),
		...others,
	});
};
