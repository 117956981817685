import type { ISettingsClashesObject } from "@domain/interfaces/settings.clashes.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import DateService from "@infrastructure/services/dates/date.service";
import { FormYupService } from "@infrastructure/services/form/form.yup.service";
import { Locales } from "@key4-front-library/core";
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, Popper, TextField, autocompleteClasses, styled } from "@mui/material";
import { TIME_FORMAT } from "@mykey4/core";
import { t } from "i18next";
import { DateTime } from "luxon";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

type Props = {
	settings: ISettingsClashesObject;
	culture: string;
	onSave: (data: ISettingsClashesObject) => void;
	handleModaleClose: () => void;
};

const SettingsClashesEdit = (props: Props) => {
	const { settings, onSave, handleModaleClose } = props;
	const component = "settingsClashesEdit";

	const [facultyClash, setFacultyClash] = React.useState<DateTime | null>(settings.facultyClashDetectionInterval);

	const [roomClash, setRoomClash] = React.useState<DateTime | null>(settings.roomClashDetectionInterval);

	const [isSpeakerBusy, setIsSpeakerBusy] = React.useState<boolean | null>(settings.isSpeakerBusySessionTimeSlot);

	const localFacultyClash = DateService.Format.TimeSimple({
		date: facultyClash!,
	});
	const localRoomClash = DateService.Format.TimeSimple({ date: roomClash! });

	const formLabels = {
		facultyClash: t(`${component}.facultyClashDetectionInterval`),
		roomClash: t(`${component}.roomClashDetectionInterval`),
		isSpeakerBusy: t(`${component}.isSpeakerBusySessionTimeSlot`),
	};

	const today = DateTime.now().toUTC().set({ hour: 0, minute: 0 });
	const timeSuggestionOptions: Array<string> = [...Array(12)].map((_, index) =>
		DateService.Format.TimeSimple({
			date: today.plus({ minutes: index * 5 }),
		}),
	);

	const Schema: any = yup.object({
		facultyClash: yup
			.date()
			.transform((value: any, input: any) => FormYupService.ParseDate(value, input, TIME_FORMAT))
			.label(formLabels.facultyClash)
			.typeError(Locales.Parsers.FormFieldRule.Date(formLabels.facultyClash))
			.required(Locales.Parsers.FormFieldRule.Required(formLabels.facultyClash)),
		roomClash: yup
			.date()
			.transform((value: any, input: any) => FormYupService.ParseDate(value, input, TIME_FORMAT))
			.label(formLabels.roomClash)
			.typeError(Locales.Parsers.FormFieldRule.Date(formLabels.roomClash))
			.required(Locales.Parsers.FormFieldRule.Required(formLabels.roomClash)),
	});

	const {
		register,
		handleSubmit: onSubmit,
		trigger,
		setValue,
		formState: { isSubmitting, isValid, errors },
	} = useForm<any>({
		mode: "all",
		defaultValues: {
			facultyClash: settings.facultyClashDetectionInterval!,
			roomClash: settings.roomClashDetectionInterval!,
			isSpeakerBusy: settings.isSpeakerBusySessionTimeSlot!,
		},
		resolver: yupResolver(Schema),
	});

	const handleFacultyClashChange = async (_event: React.SyntheticEvent, value: string) => {
		if (localFacultyClash !== value && value.length === 5) {
			const dateValue = DateTime.fromFormat(value, TIME_FORMAT, {
				zone: "utc",
			});
			setValue("facultyClash", dateValue);
			setFacultyClash(dateValue);
			await trigger("facultyClash");
		}
	};

	const handleRoomClashChange = async (_event: React.SyntheticEvent, value: string) => {
		if (localRoomClash !== value && value.length === 5) {
			const dateValue = DateTime.fromFormat(value, TIME_FORMAT, {
				zone: "utc",
			});
			setValue("roomClash", dateValue);
			setRoomClash(dateValue);
			await trigger("roomClash");
		}
	};

	const handleIsSpeakerBusyChange = async (_event: React.SyntheticEvent, checked: boolean) => {
		setValue("isSpeakerBusy", checked);
		setIsSpeakerBusy(checked);
		await trigger("isSpeakerBusy");
	};

	const handleSubmit = (_values: any) => {
		const values = {
			facultyClashDetectionInterval: facultyClash,
			roomClashDetectionInterval: roomClash,
			isSpeakerBusySessionTimeSlot: isSpeakerBusy,
		} as ISettingsClashesObject;
		onSave(values);
		handleModaleClose();
	};

	const StyledPopper = styled(Popper)({
		[`& .${autocompleteClasses.listbox}`]: {
			boxSizing: "border-box",
			"& ul": {
				padding: 0,
				margin: 0,
			},
		},
	});

	return (
		<Box component="form">
			<Grid container spacing={1}>
				<Grid item xs={12} sm={12} lg={6}>
					<Autocomplete
						fullWidth
						PopperComponent={StyledPopper}
						options={timeSuggestionOptions}
						value={DateService.Format.TimeSimple({
							date: facultyClash!,
						})}
						onInputChange={handleFacultyClashChange}
						{...register("facultyClash")}
						renderInput={(params) => (
							<TextField
								{...params}
								label={formLabels.facultyClash}
								margin="dense"
								error={!!errors.facultyClash}
								placeholder={TIME_FORMAT}
								helperText={errors.facultyClash?.message?.toString()}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={12} lg={6}>
					<Autocomplete
						fullWidth
						PopperComponent={StyledPopper}
						options={timeSuggestionOptions}
						value={DateService.Format.TimeSimple({
							date: roomClash!,
						})}
						onInputChange={handleRoomClashChange}
						{...register("roomClash")}
						renderInput={(params) => (
							<TextField
								{...params}
								label={formLabels.roomClash}
								margin="dense"
								error={!!errors.roomClash}
								placeholder={TIME_FORMAT}
								helperText={errors.roomClash?.message?.toString()}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={12} lg={6}>
					<FormControlLabel
						label={formLabels.isSpeakerBusy}
						labelPlacement="end"
						control={
							<Checkbox {...register("isSpeakerBusy")} onChange={handleIsSpeakerBusyChange} value={isSpeakerBusy} checked={isSpeakerBusy!} size="medium" />
						}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<ValidationFormButtons cancelButtonClick={handleModaleClose} saveButtonClick={onSubmit(handleSubmit)} disabledSaveButton={isSubmitting || !isValid} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default SettingsClashesEdit;
