import { Button, type ButtonProps, Stack } from "@mui/material";
import { Icon } from "@mykey4/core";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWizard } from "../WizardContext";

export interface WizardButtonsComponentsProps {
	previousButton?: Omit<ButtonProps, "children" | "onClick">;
	nextButton?: Omit<ButtonProps, "children" | "onClick"> & { onBeforeClick?: () => Promise<void> | void };
}

export interface WizardButtonsProps {
	slotProps?: WizardButtonsComponentsProps;
}

export const WizardButtons = (props: WizardButtonsProps) => {
	const component = "wizard";
	const { slotProps } = props;
	const { t } = useTranslation();
	const { nextStepId, previousStepId, goToPrevious, goToNext } = useWizard();
	const { disabled: previousDisabled = false, ...otherPrevious } = slotProps?.previousButton ?? {};
	const { disabled: nextDisabled = false, onBeforeClick, ...otherNext } = slotProps?.nextButton ?? {};

	const handleNextClick = useCallback(async () => {
		if (onBeforeClick) {
			await onBeforeClick();
		}
		goToNext();
	}, [onBeforeClick, goToNext]);

	return (
		<Stack direction="row" justifyContent={"space-between"} spacing={2} mt={3}>
			<Button
				startIcon={<Icon name={"angle-left"} />}
				color="primary"
				variant="outlined"
				disabled={!previousStepId || previousDisabled}
				{...otherPrevious}
				onClick={goToPrevious}
			>
				{t(`${component}.button.previous`)}
			</Button>
			<Stack ml="auto">
				<Button
					disabled={nextDisabled}
					startIcon={!nextStepId && <Icon name={"check"} />}
					endIcon={nextStepId && <Icon name={"angle-right"} />}
					variant="contained"
					color="primary"
					onClick={handleNextClick}
					{...otherNext}
				>
					{nextStepId ? t(`${component}.button.next`) : t(`${component}.button.finish`)}
				</Button>
			</Stack>
		</Stack>
	);
};
