import { type AbstractRead, getAbstracts, getAbstractsAll } from "@api";
import { type BodyPostGet, type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideVersion2 } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useAbstracts = <TData = Paginated<AbstractRead>>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<Paginated<AbstractRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.abstract, ...queryKeyPart, "Get", "List", clientId, eventId, queryStringOverride, body],
		queryFn: ({ signal }) => getAbstracts({ clientId, eventId, body, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export const useAbstractsAll = <TData = AbstractRead[]>(
	clientId: string,
	eventId: string,
	body: BodyPostGet,
	args: UseQueryArgs<AbstractRead[], TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.abstract, ...queryKeyPart, "Get", "List", "All", clientId, eventId, queryStringOverride, body],
		queryFn: ({ signal }) => getAbstractsAll({ clientId, eventId, body, queryStrings: queryStringOverride, signal }),
		...others,
	});
};
