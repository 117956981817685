import { type ChairRead, type ChairWrite, getSessionChair, getSessionChairs, postChair, putChair } from "@api";
import { API_VERSION_QUERY_PARAM, type Paginated, QUERY_KEYS_PROGRAMME, type UseMutationArgs, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const useSessionChairs = (clientId: string, eventId: string, args: UseQueryArgs<Paginated<ChairRead>> = {}): UseQueryResult<Paginated<ChairRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.chairs, ...queryKeyPart, "Get", "List", clientId, eventId, queryStrings],
		queryFn: ({ signal }) =>
			getSessionChairs({
				clientId,
				eventId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export const useSessionChair = (
	clientId: string,
	eventId: string,
	sessionId: string,
	participantId: string,
	args: UseQueryArgs<ChairRead> = {},
): UseQueryResult<ChairRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.chairs, ...queryKeyPart, "Get", clientId, eventId, sessionId, participantId, queryStrings],
		queryFn: ({ signal }) =>
			getSessionChair({
				clientId,
				eventId,
				sessionId,
				participantId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export interface ChairCreateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	participantId: string;
	body: ChairWrite;
	signal?: AbortSignal;
}

export const useChairCreate = (args: UseMutationArgs<ChairCreateRequest, string>): UseMutationResult<string, Error, ChairCreateRequest> => {
	return useMutation({
		mutationFn: (request: ChairCreateRequest) => postChair(request),
		...args,
	});
};

export interface ChairUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	participantId: string;
	body: ChairWrite;
	signal?: AbortSignal;
}

export const useChairUpdate = (args: UseMutationArgs<ChairUpdateRequest>): UseMutationResult<boolean, Error, ChairUpdateRequest> => {
	return useMutation({
		mutationFn: (request: ChairUpdateRequest) => putChair(request),
		...args,
	});
};
