import type { TagRead } from "@api";
import type { DtoTagWrite } from "@key4-front-library/core";
import { emptyToUndefined } from "@mykey4/core";
import type { SessionTagForm } from "./SessionTagModal.configuration";

export const tagReadToSessionTagForm = (tag: TagRead): SessionTagForm => ({
	id: tag.id,
	label: tag.label,
	backgroundColor: tag.backgroundColor,
	borderColor: tag.borderColor,
	fontColor: tag.fontColor,
	order: tag.order?.toString(),
	createAnother: false,
});

export const sessionTagFormToDtoTagWrite = (tag: SessionTagForm): DtoTagWrite => ({
	label: tag.label,
	backgroundColor: tag.backgroundColor,
	borderColor: tag.borderColor,
	fontColor: tag.fontColor,
	order: emptyToUndefined(tag.order),
});
