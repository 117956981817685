import { getCellBulletColorWithLabel } from "@key4-front-library/core";
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { getColumnAction } from "@mykey4/core";

export const speakerColDef: Array<GridColDef> = [
	{
		field: "faculty",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "type",
		minWidth: 200,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "status",
		renderCell: (params: GridRenderCellParams) => getCellBulletColorWithLabel(params),
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
		sortComparator: (a, b) => {
			const labelA = a.label;
			const labelB = b.label;
			return labelA.localeCompare(labelB);
		},
	},
	{ ...getColumnAction(), minWidth: 220 },
];
