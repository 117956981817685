import { getColumnsAbstracts, slicePresentationsToDisplay } from "@components";
import { useContextModule } from "@key4-front-library/core";
import { TablePagination, type TablePaginationProps } from "@mui/material";
import type { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import type { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import type { GridInputRowSelectionModel, GridRowSelectionModel } from "@mui/x-data-grid/models/gridRowSelectionModel";
import { ROWS_PER_PAGE, Stack, usePagination, useUserPermissionPage } from "@mykey4/core";
import { memo, useEffect, useMemo, useState } from "react";
import type { AbstractRow } from "./AbstractDataGrid.type";
import { useAbstractDataGrid } from "./useAbstractDataGrid";

const TablePaginationMemo = memo(TablePagination);

interface DataGridAbstractProps {
	search: string;
	checkboxSelection?: boolean;
	rowSelectionModel?: GridInputRowSelectionModel;
	onRowCountChange?: (count: number) => void;
	onRowSelectionModelChange?: (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => void;
}

export const AbstractDataGrid = (props: DataGridAbstractProps): React.ReactElement => {
	const { search, onRowCountChange, checkboxSelection = false, onRowSelectionModelChange, rowSelectionModel } = props;
	const component = "dataGridAbstract";

	useUserPermissionPage(["abstract_Abstract_Keyword_ReadAll", "abstract_Abstract_ReadAll", "abstract_CoAuthor_ReadAll", "programme_Presentation_ReadAll"]);

	const [sort, setSort] = useState<GridSortModel>([]);
	const { page, pageSize, totalRowCount, handleChangePage, handleChangePageSize, setTotalRowCount } = usePagination();
	const { client, event } = useContextModule();

	const { isFetching, abstracts, presentationsByAbstract, coAuthorByAbstract, abstractKeywordsByAbstract } = useAbstractDataGrid({
		clientId: client.id,
		eventId: event.id,
		sortAbstract: sort,
		searchAbstract: search,
		page,
		pageSize,
		componentId: component,
	});

	const rows = useMemo(() => {
		return (
			abstracts.data?.data.map((abstract) => ({
				...abstract,
				presentations: slicePresentationsToDisplay(presentationsByAbstract.data?.get(abstract.id) ?? []),
				keywords: abstractKeywordsByAbstract.data?.get(abstract.id) ?? [],
				coAuthors: coAuthorByAbstract.data?.get(abstract.id),
			})) ?? []
		);
	}, [abstracts.data?.data, presentationsByAbstract.data, coAuthorByAbstract.data, abstractKeywordsByAbstract.data]);

	const columns: Array<GridColDef<AbstractRow>> = useMemo(
		() =>
			getColumnsAbstracts({
				clientKey: client.key,
				eventKey: event.key,
				translation: component,
			}),
		[client.key, event.key],
	);

	useEffect(() => onRowCountChange?.(rows.length), [rows.length, onRowCountChange]);
	useEffect(() => setTotalRowCount(abstracts.data?.pagination?.totalCount ?? 0), [abstracts.data?.pagination, setTotalRowCount]);
	// Reset to the first page whenever the search term changes.
	// biome-ignore lint/correctness/useExhaustiveDependencies: Including 'search' in the dependency array ensures the effect is triggered, even though handleChangePage doesn't directly use it.
	useEffect(() => handleChangePage(0), [handleChangePage, search]);

	const paginationProps = useMemo<TablePaginationProps>(
		() => ({
			component: "div",
			count: totalRowCount,
			page,
			rowsPerPage: pageSize,
			rowsPerPageOptions: ROWS_PER_PAGE,
			onPageChange: (_, newPage) => handleChangePage(newPage),
			onRowsPerPageChange: (event) => handleChangePageSize(Number(event.target.value)),
		}),
		[page, totalRowCount, pageSize, handleChangePage, handleChangePageSize],
	);

	return (
		<Stack spacing={1} sx={{ maxHeight: "70vh", width: "100%" }}>
			<DataGridPro
				getRowId={(row) => row.id}
				density={"compact"}
				checkboxSelection={checkboxSelection}
				rowSelectionModel={rowSelectionModel}
				onRowSelectionModelChange={onRowSelectionModelChange}
				loading={isFetching}
				columns={columns}
				rows={rows}
				pagination
				paginationModel={{ page, pageSize }}
				rowCount={totalRowCount}
				slots={{ pagination: TablePaginationMemo }}
				slotProps={{ pagination: paginationProps }}
				onSortModelChange={setSort}
				disableColumnMenu={false}
				disableColumnFilter
				disableRowSelectionOnClick
				filterMode={"server"}
				sortingMode={"server"}
				paginationMode={"server"}
				initialState={{
					columns: { columnVisibilityModel: { id: false } },
					sorting: { sortModel: sort },
					pinnedColumns: { right: ["actions"] },
				}}
				sx={{
					"& .MuiDataGrid-row:hover": {
						cursor: "default",
					},
					"& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
						outline: "none",
					},
				}}
			/>
		</Stack>
	);
};
