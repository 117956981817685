import type { LiteRoomRead, LiteTagRead, TagRead } from "@api";
import type { CustomFieldValuesRead, SessionStatus } from "@mykey4/core";

export const SessionPublicationStatusTypes = ["notPublished", "planned", "published"] as const;
export type SessionPublicationStatus = (typeof SessionPublicationStatusTypes)[number];

export interface LiteSessionRead {
	id: string;
	key: string;
	eventId: string;
	code?: string;
	startDate?: string;
	endDate?: string;
	title?: string;
	order?: number;
	room?: LiteRoomRead;
	primaryTag?: LiteTagRead;
}

/**
 * Session Read
 * Route: GET /programme/clients/{clientId}/events/{eventId}/sessions
 * Route: GET /programme/clients/{clientId}/events/{eventId}/sessions/{sessionId}
 */
export interface SessionRead {
	id: string;
	key: string;
	eventId: string;
	sessionTemplateId?: string;
	room?: LiteRoomRead;
	externalReference?: string;
	code?: string;
	startDate?: string;
	endDate?: string;
	status: SessionStatus;
	isPublished: boolean;
	publicationDate?: string;
	title?: string;
	order?: number;
	isPrivate: boolean;
	description?: string;
	organizedBy?: string;
	expectedAudience?: number;
	isAbstract?: boolean;
	duration?: string;
	primaryTag?: LiteTagRead;
	customFieldValues?: CustomFieldValuesRead;
	publicationStatus: SessionPublicationStatus;
	createdBy: string;
	createdAt: string;
	lastModifiedBy: string;
	lastModifiedAt: string;
}

export type TagTypeWithTagRead = {
	id: string;
	label: string;
	min?: number;
	max?: number;
	tags?: Array<TagRead>;
};

/**
 * Session Read v1
 * Route: GET /programme/clients/{clientId}/events/{eventId}/sessions
 * Route: GET /programme/clients/{clientId}/events/{eventId}/sessions/{sessionId}
 */
export interface SessionReadV1 {
	id: string;
	code?: string;
	startDate?: string; // In "yyyy-MM-dd" format.
	startHour?: string; // In "hh:mm:ss" format.
	endDate?: string; // In "yyyy-MM-dd" format.
	endHour?: string; // In "hh:mm:ss" format.
	timeZone?: string;
	status: SessionStatus;
	isPublished: boolean;
	publicationDate?: string;
	title?: string;
	order?: number;
	isPrivate: boolean;
	description?: string;
	organizedBy?: string;
	expectedAudience?: number;
	duration?: string;
	anomaliesCount: number;
	clashesCount: number;
	room?: LiteRoomRead;
	tagTypes?: Array<TagTypeWithTagRead>;
	customFieldValues?: CustomFieldValuesRead;
	key: string;
}

/**
 * Session Write
 * Route: POST /programme/clients/{clientId}/events/{eventId}/sessions
 * Route: PUT /programme/clients/{clientId}/events/{eventId}/sessions/{sessionId}
 */
export interface SessionWrite {
	roomId?: string;
	code?: string;
	startDate?: string;
	endDate?: string;
	status: SessionStatus;
	isPublished: boolean;
	publicationDate?: string;
	title?: string;
	isPrivate: boolean;
	isAbstract?: boolean;
	description?: string;
	organizedBy?: string;
	expectedAudience?: number;
	primaryTagId?: string;
	duration?: string;
	order?: string;
	tagIds: Array<string>;
	customFieldValues?: CustomFieldValuesRead;
	shouldApplySessionTemplate?: boolean;
}

export type TokenType = "session" | "participant";

export interface TokenRead {
	id: string;
	entityType: TokenType;
	entityId: string;
	expireAt: string;
	createdAt: string;
	updatedAt: string;
}

export interface BulkSessionStatusWrite {
	filters?: string;
	excludedIds?: Array<string>;
	status: SessionStatus;
}

export interface BulkSessionPublishWrite {
	filters?: string;
	excludedIds?: Array<string>;
	isPublished: boolean;
	publicationDate?: string; // ISO date
}

export interface SessionStatusWrite {
	status: SessionStatus;
	isPublished: boolean;
	publicationDate?: string;
}
