import { getPresentations } from "@api";
import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import BadTabs from "@application/Configurations/tabs/badTabs.config";
import CalendarController from "@application/Controllers/CalendarController";
import RoomController from "@application/Controllers/RoomController";
import NavigationTabsWithBadges from "@application/components/_common/Navigation/NavigationTabsWithBadges";
import { EnumSessionFacultyClashesEntityType } from "@application/enum/Clashes.enum";
import { MenuItemReference } from "@application/enum/MenuItemReference.enum";
import { ERoomMenuItemReference } from "@application/enum/RoomMenuItemReference.enum";
import NavigationFactory from "@application/factory/navigation/NavigationFactory";
import DateHelper from "@application/helpers/date.helper";
import GetTranslationsForRooms from "@application/helpers/translations/GetTranslationsForRooms";
import GetTranslationsForCreateEditSession from "@application/helpers/translations/sessions/GetTranslationsForCreateEditSession";
import { EditPresentationsSchedule } from "@components";
import { RoomFormModal, SessionModal } from "@components";
import { ESchedulerView, type ICalendarSession, type ICalendarSettings } from "@domain/interfaces/calendar.interface";
import type { TRoomDetails } from "@domain/interfaces/room.interface";
import type { TSessionCalendar } from "@domain/model/calendar.model";
import type { RoomForm } from "@domain/model/room.model";
import K4Calendar from "@infrastructure/components/calendar/K4Calendar";
import { Locales, Services, TitleOld, queryFilters, queryStringPagination, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import { Card } from "@mui/material";
import { API_VERSION_QUERY_PARAM, FilteringOperator, useDialogs } from "@mykey4/core";
import { t } from "i18next";
import { DateTime, Duration } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

const RoomDetails = () => {
	const { event, client } = useContextModule();
	const { roomId } = useParams();
	const dialog = useDialogs();
	const { sendSuccess, sendError } = useSnackBarHook();
	const [room, setRoom] = useState<TRoomDetails>();
	const [sessions, setSessions] = useState<Array<ICalendarSession> | null>(null);
	const [calendarSettings, setCalendarSettings] = useState<ICalendarSettings>();
	const dialogs = useDialogs();
	const translations = GetTranslationsForRooms(true);

	const [activeClashesCounter, setActiveClashesCounter] = useState<number | undefined>(0);
	const [activeAnomaliesCounter] = useState<number | undefined>(0);
	const [unactiveClashesCounter, setUnactiveClashesCounter] = useState<number | undefined>(0);
	const [unactiveAnomaliesCounter] = useState<number | undefined>(0);

	let menuItems = roomId ? BadTabs.GetRoomMenuConfiguration(client.key, event.key, roomId) : undefined;
	if (unactiveClashesCounter === 0 && activeClashesCounter === 0) {
		menuItems = menuItems?.filter((menuItem) => menuItem.reference !== ERoomMenuItemReference.CLASHES);
	}
	if (unactiveAnomaliesCounter === 0 && activeAnomaliesCounter === 0) {
		menuItems = menuItems?.filter((menuItem) => menuItem.reference !== ERoomMenuItemReference.ANOMALIES);
	}

	const translationSessions = GetTranslationsForCreateEditSession(t);

	const handleCreateSessionClick = async () => {
		await dialog.open(SessionModal, { sessionId: undefined, injectSessionData: { roomId } });
		await refreshSessions();
	};

	const handleEditSessionClick = async (sessionId: string) => {
		await dialog.open(SessionModal, { sessionId });
		await refreshSessions();
	};

	const buttons = [
		{
			label: translationSessions.buttons.edit,
			link: "",
			icon: { iconName: "pencil" },
			color: "primary" as const,
			handleClick: () => {
				handleEditModaleOpen(roomId);
			},
		},
		{
			label: translationSessions.create_edit_modale.title.create,
			link: "",
			icon: { iconName: "plus" },
			color: "primary" as const,
			handleClick: () => handleCreateSessionClick(),
		},
	];

	const initComponent = useCallback(async () => {
		if (!roomId) {
			return;
		}

		RoomController.getRoomDetails(client.id, event.id, roomId)
			.then((room) => setRoom(room))
			.catch(() => setRoom(undefined));

		try {
			const dataCalendarSettings = await CalendarController.getCalendarSettings(client.id, event.id);
			const dataSessions = await CalendarController.getSessionsByRoom(client.id, event.id, [roomId]);
			setSessions(dataSessions);
			setCalendarSettings(dataCalendarSettings);
		} catch (_) {
			setSessions([]);
			setCalendarSettings(undefined);
		}
	}, [event.id, roomId]);

	const initBadgesCountersTabs = useCallback(() => {
		if (!roomId) {
			return;
		}

		const fetchData = async () => {
			return {
				_activeClashes: await Services.Events.Programme.RoomsService.getListPaginedClashes(client.id, event.id, roomId, [
					...queryFilters(
						`ignoredDate${FilteringOperator.Equal}null${FilteringOperator.And}entityType${FilteringOperator.Equal + EnumSessionFacultyClashesEntityType.ALL}`,
					),
					...queryStringPagination({
						page: 0,
						pageSize: 100,
					}),
				]),
				_unactiveClashes: await Services.Events.Programme.RoomsService.getListPaginedClashes(client.id, event.id, roomId, [
					...queryFilters(
						`ignoredDate${FilteringOperator.NotEqual}null${FilteringOperator.And}entityType${FilteringOperator.Equal + EnumSessionFacultyClashesEntityType.ALL}`,
					),
					...queryStringPagination({
						page: 0,
						pageSize: 100,
					}),
				]),
			};
		};

		fetchData().then((results) => {
			const { _activeClashes, _unactiveClashes } = results;
			setActiveClashesCounter(_activeClashes.pagination.totalCount);
			setUnactiveClashesCounter(_unactiveClashes.pagination.totalCount);
		});
	}, [roomId, event]);

	const updateSession = async (sessionId: string, session: TSessionCalendar) => {
		await CalendarController.putSession(client.id, event.id, sessionId, session);
	};

	const refreshSessions = async () => {
		if (!roomId || !calendarSettings) {
			return;
		}

		CalendarController.getSessionsByRoom(client.id, event.id, [roomId])
			.then((sessions) => setSessions(sessions))
			.catch(() => {
				sendError(t("old.common.errors.generic"));
				setSessions([]);
			});
	};

	useEffect(() => {
		initComponent();
		initBadgesCountersTabs();
	}, [event, initBadgesCountersTabs, initComponent]);

	const handleEditModaleOpen = useCallback(
		(roomId?: string) => {
			dialogs.open(RoomFormModal, { roomId: roomId, handleSaveRoom, handleModaleClose });
		},
		[dialogs.open],
	);
	const handleModaleClose = () => {};

	const handleSaveRoom = async (_room: RoomForm) => {
		if (roomId) {
			try {
				await RoomController.updateRoom(client.id, event.id, _room);
				sendSuccess(translations.editionModal.returnMessages.success_modification);
				initComponent();
			} catch (error: any) {
				sendError(error.message);
			} finally {
				handleModaleClose();
			}
		}
	};
	/**
	 * Handle 2 Drag & drop actions:
	 * - action on dragging session inside the calendar
	 */
	const handleSessionDrop = async (session: any) => {
		if (!roomId) {
			return;
		}

		let endStr = session.event.endStr;
		let minDuration = session.event.extendedProps.minDuration ?? SchedulerConfiguration.defaultMinDuration;
		if (!session.event.endStr) {
			endStr = session.event.startStr;
			minDuration = SchedulerConfiguration.defaultMinDuration;
		}

		const { dateStart, dateEnd } = DateHelper.setDurationAndDatesSession(DateTime.fromISO(session.event.startStr), DateTime.fromISO(endStr), minDuration);

		try {
			await updateSession(session.event.id, {
				roomId,
				startDate: dateStart.toFormat("yyyy-MM-dd"),
				startHour: dateStart.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
				// ! Do not change to endDate because we want same startDate/endDate on fullDay
				endDate: dateEnd.toFormat("yyyy-MM-dd"),
				endHour: dateEnd.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
				timeZone: "UTC",
				duration: undefined,
				tagIds: session.event.extendedProps.tagsId,
			});

			const { dateStart: oldDateStart, dateEnd: oldDateEnd } = DateHelper.setDurationAndDatesSession(
				DateTime.fromISO(session.oldEvent?.startStr ?? "0001-01-01T00:00:00Z"),
				DateTime.fromISO(session.oldEvent?.endStr ?? "0001-01-01T00:00:00Z"),
				null,
			);

			const isSameDate = (!oldDateStart.isValid || !oldDateEnd.isValid) && oldDateStart.equals(dateStart) && oldDateEnd.equals(dateEnd);
			if (!event.id || !session.event.id || isSameDate) {
				return;
			}

			const presentations = await getPresentations({
				clientId: client.id,
				eventId: event.id,
				sessionId: session.event.id,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
			});

			if (presentations?.data.length === 0) {
				return;
			}

			const duration = oldDateEnd.diff(oldDateStart, "minutes");

			const sessionEditSchedule = {
				id: session.event.id,
				oldDateTimeStart: oldDateStart,
				oldDateTimeEnd: oldDateEnd,
				newDateTimeStart: dateStart,
				newDateTimeEnd: dateEnd,
				oldDuration: duration.isValid ? duration.toFormat("mm") : "00",
				newDuration: duration.isValid ? duration.toFormat("mm") : "00",
			};

			await dialog.open(EditPresentationsSchedule, { sessionEditSchedule });
		} catch {
			sendError(t("old.common.errors.generic"));
		}

		await refreshSessions();
		session.event.remove();
	};

	const handleResizeSession = async (session: any) => {
		const newRoomId = session?.event?.extendedProps ? session.event.extendedProps.roomId : null;
		const durationToNumberFormat = session?.event?.extendedProps?.minDuration
			? Number.parseInt(Duration.fromISO(session.event.extendedProps.minDuration).toFormat("mm"))
			: 0;

		if (!DateHelper.isDurationEligible(DateTime.fromISO(session.event.startStr), DateTime.fromISO(session.event.endStr), durationToNumberFormat)) {
			sendError(Locales.Parsers.TranslateParserError.schedulerMinDuration(Duration.fromISO(session.event.extendedProps.minDuration).toFormat("mm")));
			refreshSessions();
			return;
		}

		const { dateStart, dateEnd } = DateHelper.setDurationAndDatesSession(
			DateTime.fromISO(session.event.startStr),
			DateTime.fromISO(session.event.endStr),
			session.event.extendedProps.minDuration,
		);

		try {
			await updateSession(session.event.id, {
				roomId: newRoomId,
				startDate: dateStart.toFormat("yyyy-MM-dd"),
				startHour: dateStart.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
				endDate: dateEnd.toFormat("yyyy-MM-dd"),
				endHour: dateEnd.toLocaleString(DateTime.TIME_24_WITH_SECONDS),
				timeZone: "UTC",
				duration: undefined,
				tagIds: session.event.extendedProps.tagsId,
			});

			const { dateStart: oldDateStart, dateEnd: oldDateEnd } = DateHelper.setDurationAndDatesSession(
				DateTime.fromISO(session.oldEvent?.startStr ?? "0001-01-01T00:00:00Z"),
				DateTime.fromISO(session.oldEvent?.endStr ?? "0001-01-01T00:00:00Z"),
				null,
			);

			const isSameDate = (!oldDateStart.isValid || !oldDateEnd.isValid) && oldDateStart.equals(dateStart) && oldDateEnd.equals(dateEnd);
			if (!event.id || !session.event.id || isSameDate) {
				return;
			}

			const presentations = await getPresentations({
				clientId: client.id,
				eventId: event.id,
				sessionId: session.event.id,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
			});

			if (presentations?.data.length === 0) {
				return;
			}

			const duration = oldDateEnd.diff(oldDateStart, "minutes");

			const sessionEditSchedule = {
				id: session.event.id,
				oldDateTimeStart: oldDateStart,
				oldDateTimeEnd: oldDateEnd,
				newDateTimeStart: dateStart,
				newDateTimeEnd: dateEnd,
				oldDuration: duration.isValid ? duration.toFormat("mm") : "00",
				newDuration: duration.isValid ? duration.toFormat("mm") : "00",
			};

			await dialog.open(EditPresentationsSchedule, { sessionEditSchedule });
		} catch {
			sendError(t("old.common.errors.generic"));
		}

		refreshSessions();
	};

	return (
		<>
			{room && <TitleOld title={room.name} reference={translations.details.capacity + room.capacity} buttons={buttons} />}
			{calendarSettings && (
				<Card>
					<K4Calendar
						calendarSettings={calendarSettings}
						views={{
							calendar: SchedulerConfiguration.roomScheduler,
						}}
						handleSessionDrop={handleSessionDrop}
						handleResizeSession={handleResizeSession}
						view={ESchedulerView.CALENDAR}
						events={sessions ?? []}
						locale={translations.details.locale}
						handleEventClick={handleEditSessionClick}
					/>
				</Card>
			)}

			{roomId && menuItems && (
				<>
					<NavigationTabsWithBadges
						menuItems={NavigationFactory.GetMenuItemsTranslations({
							menuItems,
						})}
						badges={[
							{
								reference: ERoomMenuItemReference.CLASHES,
								number: activeClashesCounter ?? 0,
							},
							{
								reference: ERoomMenuItemReference.ANOMALIES,
								number: activeAnomaliesCounter ?? 0,
							},
						]}
						baseUrl={`/${client.key}/${event.key}/${MenuItemReference.ROOMS}/${roomId}`}
						defaultTabsValue={ERoomMenuItemReference.CLASHES}
						routeParentLastPartPath={roomId}
					/>
					<Outlet context={{ initBadgesCountersTabs }} />
				</>
			)}
		</>
	);
};

export default RoomDetails;
