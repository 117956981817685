import { useChairQualities } from "@api";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { type IEntity, NoData, Services, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import DefaultModal from "@key4-front-library/core/Bo/Components/Modal/DefaultModal";
import type { DtoQuality } from "@key4-front-library/core/Dto";
import { Skeleton, Stack } from "@mui/material";
import { emptyToUndefined } from "@mykey4/core";
import type { ActivityForm } from "@pages";
import SettingsChairQualityRender from "@presentation/pages/advancedSettings/chairsTypes/AdvancedSettingsChairQualityRender";
import ChairQualityEdit from "@presentation/pages/advancedSettings/chairsTypes/ChairQualityEdit";
import { t } from "i18next";
import update from "immutability-helper";
import { uniqueId } from "lodash";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { dtoQualityToActivityForm } from "./Activity.mapper";

export const ChairsTypes = () => {
	const { client, event } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();

	const [currentChairQuality, setCurrentChairQuality] = useState<ActivityForm | null>(null);
	const [chairQualities, setChairQualities] = useState<Array<DtoQuality> | undefined>(undefined);

	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const handleTopButtonOpenModalChairQualityAdd = () => {
		setCurrentChairQuality(null);
		setModaleOpenState(true);
	};

	const handleEditModaleClose = (): void => {
		setModaleOpenState(false);
	};

	const qualitiesQueries = useChairQualities(client.id, event.id);

	useEffect(() => {
		setChairQualities(qualitiesQueries.data?.data ?? []);
	}, [qualitiesQueries.data?.data]);

	const refresh = useCallback(async () => {
		void qualitiesQueries.refetch();
	}, [qualitiesQueries.refetch]);

	const handleUpdateChairQuality = useCallback(
		async (_data: ActivityForm) => {
			if (!_data.id) {
				await Services.Events.Programme.ChairsService.postQuality(client.id, event.id, {
					label: _data.label,
					order: emptyToUndefined(_data.order ?? undefined),
				})
					.then((_response: IEntity) => {
						sendSuccess(t("old.programme.advancedSettings.chairs.returnMessages.success_creation"));
						refresh();
					})
					.catch(() => {
						sendError(t("old.common.errors.generic"));
					});
			} else {
				await Services.Events.Programme.TagsService.put(client.id, event.id, currentChairQuality?.id ?? "", currentChairQuality?.id ?? "", {
					label: _data.label,
					order: emptyToUndefined(_data.order ?? undefined),
				})
					.then((_response: boolean) => {
						refresh();
						sendSuccess(t("old.programme.advancedSettings.chairs.returnMessages.success_modification"));
					})
					.catch(() => {
						sendError(t("old.common.errors.generic"));
					});
			}
		},
		[client.id, event.id, currentChairQuality?.id, refresh, sendError, sendSuccess],
	);

	const handleDeleteChairQuality = useCallback(
		async (id: string) => {
			await Services.Events.Programme.TagsService.deleteEntity(client.id, event.id, id, id)
				.then(() => {
					setChairQualities(chairQualities?.filter((x) => x.id !== id));
					sendSuccess(t("old.programme.advancedSettings.chairs.returnMessages.success_deletion"));
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				});
		},
		[client.id, event.id, chairQualities, sendError, sendSuccess],
	);

	const renderIconCardChildren = useCallback((): React.ReactElement => {
		if (!chairQualities?.length) {
			return <NoData />;
		}
		return (
			<Stack spacing={2} key={uniqueId()}>
				{chairQualities.map((chairQuality, index) => renderChairQuality(chairQuality, index))}
			</Stack>
		);
	}, [chairQualities]);

	const moveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const qualities = chairQualities ?? [];
			const orderedQualities = update(qualities, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, qualities[dragIndex]],
				],
			});

			Services.Events.Programme.ChairsService.putQualitiesReOrder(client.id, event.id, { ids: orderedQualities.map((x) => x.id) })
				.then(() => refresh())
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				});

			setChairQualities(orderedQualities);

			return orderedQualities;
		},
		[chairQualities, client.id, event.id, refresh, sendError],
	);

	const renderChairQuality = useCallback(
		(_chairQuality: DtoQuality, index: number) => {
			const handleOpenModalChairQualityEdit = (_entity: DtoQuality) => {
				setCurrentChairQuality(dtoQualityToActivityForm(_entity));
				setModaleOpenState(true);
			};

			return (
				<DndProvider backend={HTML5Backend} key={uniqueId()}>
					<Stack key={_chairQuality.id}>
						<SettingsChairQualityRender
							index={index}
							chairQuality={_chairQuality}
							chairQualitiesCount={chairQualities?.length ?? 0}
							handleOpenModalChairQualityEdit={handleOpenModalChairQualityEdit}
							handleDeleteChairQuality={handleDeleteChairQuality}
							moveCard={moveCard}
						/>
					</Stack>
				</DndProvider>
			);
		},
		[chairQualities?.length, handleDeleteChairQuality, moveCard],
	);

	return (
		<>
			<IconCard
				title={t("old.programme.advancedSettings.chairs.title")}
				icon={"user-crown"}
				button={{
					label: t("old.form.buttons.add"),
					icon: "plus",
					onClick: handleTopButtonOpenModalChairQualityAdd,
				}}
			>
				{qualitiesQueries.isFetching ? <Skeleton height={200} /> : renderIconCardChildren()}
			</IconCard>

			<DefaultModal
				open={modaleOpenState}
				maxWidth={"sm"}
				title={currentChairQuality ? t("old.programme.advancedSettings.chairs.modale.edition") : t("old.programme.advancedSettings.chairs.modale.creation")}
			>
				<ChairQualityEdit chairQuality={currentChairQuality} updateChairQuality={handleUpdateChairQuality} editModaleClose={handleEditModaleClose} />
			</DefaultModal>
		</>
	);
};
