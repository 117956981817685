import {
	type AbstractKeywordRead,
	type AbstractRead,
	type CoAuthorRead,
	type PresentationRead,
	useAbstractAbstractKeywordsSearchAll,
	useAbstractKeywordsSearchAll,
	useAbstracts,
	useCoAuthorsSearchAll,
	usePresentationsSearchAll,
} from "@api";
import { groupBy, groupByFirst } from "@helpers";
import type { GridSortModel } from "@mui/x-data-grid-pro";
import { FilteringOperator, type Paginated, filtersOrInToBodyPostGet, sortToString } from "@mykey4/core";
import type { UseQueryResult } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

export interface UseDataGridAbstractArgs {
	clientId: string;
	eventId: string;
	componentId: string;
	sortAbstract: GridSortModel;
	searchAbstract: string;
	page: number;
	pageSize: number;
}

export interface UseAbstractDataGridReturn {
	isFetching: boolean;
	abstracts: UseQueryResult<Paginated<AbstractRead>>;
	presentationsByAbstract: UseQueryResult<Map<string, PresentationRead[]>>;
	coAuthorByAbstract: UseQueryResult<Map<string, CoAuthorRead>>;
	abstractKeywordsByAbstract: UseQueryResult<Map<string, AbstractKeywordRead[]>>;
}

export const useAbstractDataGrid = (args: UseDataGridAbstractArgs): UseAbstractDataGridReturn => {
	const { clientId, eventId, searchAbstract, sortAbstract, componentId, page, pageSize } = args;

	const abstractsQuery = useAbstracts(
		clientId,
		eventId,
		{
			filters: searchAbstract ?? undefined,
			sorts: sortToString(sortAbstract),
			page: page + 1,
			pageSize,
		},
		{
			queryKeyPart: [componentId],
			placeholderData: (previousData) => previousData,
		},
	);

	const abstractIds = abstractsQuery.data?.data.map((abstract) => abstract.id) ?? [];

	const presentationsSearchQuery = usePresentationsSearchAll(
		clientId,
		eventId,
		{
			...filtersOrInToBodyPostGet("abstractId", FilteringOperator.Equal, abstractIds),
			pageSize: pageSize,
		},
		{
			queryKeyPart: [componentId],
			enabled: abstractIds.length > 0,
			select: (data) => groupBy(data, "abstractId"),
		},
	);

	const coAuthorSearchQuery = useCoAuthorsSearchAll(
		clientId,
		eventId,
		{
			...filtersOrInToBodyPostGet("abstractId", FilteringOperator.Equal, abstractIds),
			pageSize: pageSize,
			sorts: "abstractId,-isPresenter,-isPrincipal,order",
		},
		{
			queryKeyPart: [componentId],
			enabled: abstractIds.length > 0,
			select: (data) => groupByFirst(data, "abstractId"),
		},
	);

	const abstractAbstractKeywordsSearchQuery = useAbstractAbstractKeywordsSearchAll(
		clientId,
		eventId,
		{
			...filtersOrInToBodyPostGet("abstractId", FilteringOperator.Equal, abstractIds),
			pageSize: pageSize,
		},
		{
			queryKeyPart: [componentId],
			enabled: abstractIds.length > 0,
		},
	);

	const abstractAbstractKeywordsIds = abstractAbstractKeywordsSearchQuery.data?.map((themeAbstractKeyword) => themeAbstractKeyword.keywordId) ?? [];

	const abstractKeywordsByAbstract = useCallback(
		(abstractKeywords: AbstractKeywordRead[]) => {
			const keywordsGrouped = groupBy(abstractKeywords, "id");
			const abstractKeywordsTuple =
				abstractAbstractKeywordsSearchQuery.data?.map((abstractKeyword) => ({
					abstractId: abstractKeyword.abstractId,
					keywords: keywordsGrouped.get(abstractKeyword.keywordId) ?? [],
				})) ?? [];
			return abstractKeywordsTuple.reduce((acc, item) => {
				const array = acc.get(item.abstractId);
				if (!array) {
					acc.set(item.abstractId, [...item.keywords]);
				} else {
					acc.set(item.abstractId, [...array, ...item.keywords]);
				}
				return acc;
			}, new Map<string, AbstractKeywordRead[]>());
		},
		[abstractAbstractKeywordsSearchQuery.data],
	);

	const abstractKeywordsSearchQuery = useAbstractKeywordsSearchAll(
		clientId,
		eventId,
		{
			...filtersOrInToBodyPostGet("id", FilteringOperator.Equal, abstractAbstractKeywordsIds),
			pageSize: pageSize,
		},
		{
			queryKeyPart: [componentId],
			enabled: abstractAbstractKeywordsIds.length > 0,
			select: abstractKeywordsByAbstract,
		},
	);

	const isFetching =
		abstractsQuery.isFetching ||
		presentationsSearchQuery.isFetching ||
		coAuthorSearchQuery.isFetching ||
		abstractAbstractKeywordsSearchQuery.isFetching ||
		abstractKeywordsSearchQuery.isFetching;

	return useMemo(() => {
		return {
			isFetching,
			abstracts: abstractsQuery,
			presentationsByAbstract: presentationsSearchQuery,
			coAuthorByAbstract: coAuthorSearchQuery,
			abstractKeywordsByAbstract: abstractKeywordsSearchQuery,
		};
	}, [isFetching, abstractsQuery, presentationsSearchQuery, coAuthorSearchQuery, abstractKeywordsSearchQuery]);
};
