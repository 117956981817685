import { scan } from "react-scan"; // must be imported before React and React DOM

import "@components/Atoms/Icon/Icon.configuration";
import Router from "@key4-front-library/core/Bo/Layout/Router";
import DevToolsHelpers from "@key4-front-library/core/DevToolsHelpers";
import { DEFAULT_TIMEZONE } from "@mykey4/core";
import { routes } from "@routes/Routes";
import { Settings } from "luxon";
import { version } from "../package.json";

const App = () => {
	// TODO: Remove when using the events' timezones
	Settings.defaultZone = DEFAULT_TIMEZONE;

	scan({
		enabled: process.env.NODE_ENV === "development",
	});

	return (
		<>
			<DevToolsHelpers.VersionDevTools version={version} />
			<Router routes={routes} />
		</>
	);
};

export default App;
