import { type SessionRead, useSessions } from "@api";
import { KeyTitleAutocomplete } from "@components";
import { Stack, Typography } from "@mui/material";
import {
	DialogAdvanced,
	type DialogComponentProps,
	FilteringOperator,
	type QueryString,
	escapeFilterString,
	filtersToQueryString,
	getButton,
	useDebounce,
} from "@mykey4/core";
import { t } from "i18next";
import { useCallback, useMemo, useState } from "react";

export interface AbstractsSessionSelectorDialog {
	clientId: string;
	eventId: string;
}

export const AbstractsSessionSelectorDialog = (props: DialogComponentProps<AbstractsSessionSelectorDialog, SessionRead | null>) => {
	const component = "abstract.bulkActions.dialog.sessionSelector";
	const {
		open: dialogOpen,
		onClose,
		payload: { clientId, eventId },
	} = props;

	const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const debouncedSearch = useDebounce(inputValue);
	const [session, setSession] = useState<SessionRead | null>(null);

	const queryStrings: Array<QueryString> | undefined = useMemo(() => {
		let filterQuery = `isAbstract${FilteringOperator.Equal}true`;
		if (debouncedSearch) {
			const keyTitleSearch = [
				...["key", "title"].map((item) => [item, FilteringOperator.CaseInsensitiveContain, escapeFilterString(debouncedSearch)].join("")),
			].join(FilteringOperator.Or);
			filterQuery += `${FilteringOperator.And}(${keyTitleSearch})`;
		}
		return [...filtersToQueryString(filterQuery)];
	}, [debouncedSearch]);

	const { data: sessions, isFetching } = useSessions(clientId, eventId, { queryStrings, enabled: autoCompleteOpen, queryKeyPart: ["SessionSelector"] });

	const handleAutoCompleteOpen = useCallback((value: boolean) => setAutoCompleteOpen(value), []);
	const handleInput = useCallback((value: string) => setInputValue(value), []);
	const handleClose = useCallback(() => onClose(session), [session, onClose]);
	const handleSelected = useCallback((value: SessionRead | null) => setSession(value), []);

	return (
		<DialogAdvanced
			open={dialogOpen}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			title={{ variant: "h6", children: t(`${component}.title`) }}
			buttonGroupEnd={{
				buttons: [
					{ onClick: handleClose, ...getButton({ type: "cancel" }) },
					{ onClick: handleClose, ...getButton({ type: "confirm" }), variant: "contained" },
				],
			}}
		>
			<Stack direction={"column"} spacing={2}>
				<Typography variant="body2">{t(`${component}.text`)}</Typography>
				<KeyTitleAutocomplete
					items={sessions?.data}
					isFetching={isFetching}
					onInputValueChange={handleInput}
					onOptionSelected={handleSelected}
					onOpenChange={handleAutoCompleteOpen}
					slotProps={{ textField: { label: t(`${component}.label`), placeholder: t(`${component}.placeholder`) } }}
				/>
			</Stack>
		</DialogAdvanced>
	);
};
