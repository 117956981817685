import { type TokenRead, useSessionTokens } from "@api";
import type { DtoEvent } from "@key4-front-library/core";
import { tokenReadToExternalLink } from "@mappers";
import { type Paginated, sortToQueryString } from "@mykey4/core";
import { useCallback } from "react";

export interface SessionExternalLink {
	tokenId: string;
	externalLink: string | null;
}

export interface UseSessionExternalLinkArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	event: DtoEvent;
}

export const useSessionExternalLink = (args: UseSessionExternalLinkArgs) => {
	const { clientId, eventId, sessionId, event } = args;

	const mapper = useCallback(
		(paginated: Paginated<TokenRead>): SessionExternalLink | null => {
			if (paginated.data.length <= 0) {
				return null;
			}
			const firstToken = paginated.data[0];
			return {
				tokenId: firstToken.id,
				externalLink: tokenReadToExternalLink(paginated.data[0], event.domain),
			};
		},
		[event.domain],
	);

	return useSessionTokens(clientId, eventId, sessionId, {
		queryStrings: sortToQueryString([
			{
				field: "expireAt",
				sort: "desc",
			},
		]),
		select: mapper,
	});
};
