import type { RoomRead } from "@api";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { IUiMenuItem } from "@infrastructure/model/interfaces/components/UiMenu.interface";
import { Box, Divider, ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { Tooltip } from "@mykey4/core";
import { t } from "i18next";
import { uniqueId } from "lodash";

export interface RoomModalProps {
	anchorElementMenu: HTMLElement | null;
	isOpen: boolean;
	room: RoomRead;
	items: Array<IUiMenuItem | "divider">;
	handleCloseMenu: () => void;
}

export const RoomCardMenu = (props: RoomModalProps) => {
	const { anchorElementMenu, room, items, isOpen, handleCloseMenu } = props;

	const theme = useTheme();

	return (
		<Menu
			id="settings-menu"
			open={isOpen}
			onClose={handleCloseMenu}
			anchorEl={anchorElementMenu}
			MenuListProps={{
				"aria-labelledby": "button",
			}}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			{items.map((item) => (
				<Box key={uniqueId("menu-item-")}>
					{item !== "divider" ? (
						<Tooltip title={item.isDisabled && t("unauthorized")}>
							<MenuItem
								disabled={item.isDisabled}
								key={item.reference}
								onClick={() => {
									item.onClick(room);
									handleCloseMenu();
								}}
							>
								<ListItemIcon>
									<Icon iconName={item.icon} color={theme.palette.secondary.main} />
								</ListItemIcon>
								{item.label}
							</MenuItem>
						</Tooltip>
					) : (
						<Divider />
					)}
				</Box>
			))}
		</Menu>
	);
};
