import { t } from "i18next";
import { Outlet } from "react-router";

import BadTabs from "@application/Configurations/tabs/badTabs.config";
import NavigationTabsWithIcons from "@application/components/_common/Navigation/NavigationTabsWithIcons";
import { MenuItemReference } from "@application/enum/MenuItemReference.enum";
import { EAlertsMenuItemReference } from "@application/enum/NotificationsMenuItemReference.enum";
import { TitleOld, useContextModule } from "@key4-front-library/core";

export const Alerts = () => {
	const { client, event } = useContextModule();

	return (
		<>
			<TitleOld title={t("navigation.alerts")} />
			<>
				<NavigationTabsWithIcons
					menuItems={BadTabs.GetAlertsMenuConfiguration(client.key, event.key)}
					baseUrl={`/${client.key}/${event.key}/${MenuItemReference.ALERTS}`}
					defaultTabsValue={EAlertsMenuItemReference.CLASHES}
					routeParentLastPartPath={MenuItemReference.ALERTS}
				/>
				<Outlet />
			</>
		</>
	);
};
