import { Fragment, useState } from "react";

import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { IMenuItems } from "@infrastructure/model/interfaces/menuItems.interface";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, ListItemIcon, ListItemText, Menu as MUIMenu, MenuItem, useTheme } from "@mui/material";
import type { IconName } from "@mykey4/core";

type Props<T> = {
	menuItems: IMenuItems;
	data: T;
};
const MenuItems = <T,>(props: Props<T>) => {
	const { menuItems, data } = props;

	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenuItems = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<Fragment>
			<IconButton aria-label="settings-button" aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleSettingsClick}>
				<MoreVertIcon />
			</IconButton>
			<MUIMenu
				id="settings-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenuItems}
				MenuListProps={{
					"aria-labelledby": "button",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{menuItems.map((item, index) => {
					return (
						<MenuItem
							key={index}
							onClick={(event) => {
								event.stopPropagation();
								item.handleClick(data);
								setAnchorEl(null);
							}}
						>
							<ListItemIcon>
								<Icon prefix="far" iconName={item.icon as IconName} size="1x" color={theme.palette.secondary.main} />
							</ListItemIcon>
							<ListItemText>{item.label}</ListItemText>
						</MenuItem>
					);
				})}
			</MUIMenu>
		</Fragment>
	);
};

export default MenuItems;
