import { useDashboardFaculties } from "@api";
import { useContextModule } from "@key4-front-library/core";
import type { RegularBreakpoints } from "@mui/material/Grid/Grid";
import { t } from "i18next";
import { getDashboardIndicatorDefinitions } from "../Common";
import DashboardIndicator from "../Common/DashboardIndicator";

type Props = RegularBreakpoints & {};

export const DashboardFaculties = (props: Props) => {
	const { client, event } = useContextModule();
	const definitions = getDashboardIndicatorDefinitions();

	const indicator = useDashboardFaculties({
		clientId: client.id,
		eventId: event.id,
		select: (data) => {
			return [
				{ ...definitions.faculties, value: data.faculties },
				{ ...definitions.chairs, value: data.chairs },
				{ ...definitions.speakers, value: data.speakers },
			];
		},
	});

	return (
		<DashboardIndicator
			{...props}
			title={t("old.programme.dashboard.indicators.faculties.title")}
			indicators={indicator.data ?? []}
			isLoading={indicator.isLoading}
		/>
	);
};
