import type { StepConfig, StepConfigs, StepId, StepValueTypes } from "./type";

export const iterateConfigs = <StepType extends StepId, ValueTypes extends StepValueTypes<StepType>, TInitialData>(
	configs: StepConfigs<StepType, ValueTypes, TInitialData>,
	callback: <K extends StepType>(key: K, config: StepConfig<K, StepType, ValueTypes, TInitialData>, index: number) => void,
) => {
	Object.keys(configs).forEach((key, index) => {
		const typedKey = key as StepType;
		callback(typedKey, configs[typedKey], index);
	});
};
