import { type DtoTagType, Services, queryStringSorts, useContextModule } from "@key4-front-library/core";
import { useEffect, useState } from "react";

const useTagTypes = () => {
	const { client, event } = useContextModule();
	const [data, setData] = useState<DtoTagType[]>([]);
	const [error, setError] = useState<unknown>("");
	const [loading, setLoading] = useState<boolean | null>(null);

	useEffect(() => {
		setLoading(true);
		Services.Events.Programme.SessionsService.getListTags(client.id, event.id, queryStringSorts(["order"]))
			.then((response: DtoTagType[]) => setData(response))
			.catch((_error) => setError(_error))
			.finally(() => setLoading(false));
	}, [client.id, event.id]);

	return { data, error, loading };
};

export default useTagTypes;
