import ConfigurationsApp from "@application/Configurations";
import type { TypeExportProgrammeShort, TypeProgrammeExport, TypeProgrammeExportTabsDefinition } from "@application/Types/ProgrammeExportType";
import HelpersApp from "@application/helpers";
import type { TypeCustomFieldForm, TypeUseFormListForms } from "@key4-front-library/core";
import DataGridCellButtons from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import { type ProgrammeExportWrite, useProgrammeExport, useProgrammeExports } from "@mykey4/core";

/**
 * Hook Entity
 * @returns Methods for fetching data
 */
const useEntity = () => {
	const read = (clientId: string, operationId: string, id: string | null) => {
		return useProgrammeExport(clientId, operationId, id, {
			select: (
				documentRead,
			): {
				raw: ProgrammeExportWrite;
				componentData: TypeProgrammeExportTabsDefinition;
				useFormData: TypeUseFormListForms;
				sectionsCount: number;
			} => {
				const document = documentRead ? documentRead : ConfigurationsApp.ProgrammeExportConfiguration.defaultProgrammeExportWrite();

				const sectionsCount = document.configuration.sections.length;

				// Get Custom Fields
				// * There's no CustomField in ProgrammeExport yet, but it uses the CustomField mechanism for faster delivery.
				const formsData: Array<TypeCustomFieldForm> = HelpersApp.ProgrammeExportHelper.getProgrammeExportCustomFieldForm(document);

				// Build structure of Statics and Dynamics form's sections fields
				let useFormData: TypeUseFormListForms | null = null;
				useFormData = HelpersApp.ProgrammeExportHelper.mapUseFormStaticsFields(formsData);
				useFormData = HelpersApp.ProgrammeExportHelper.mapUseFormDynamicsFields(formsData, useFormData);

				// Map fields into Modal's Tabs controls components
				let componentData: TypeProgrammeExportTabsDefinition | null = null;
				componentData = HelpersApp.ProgrammeExportHelper.mapTabsStaticFields(document, formsData);
				componentData = HelpersApp.ProgrammeExportHelper.mapTabsDynamicsFields(formsData, componentData);

				// Bind default values of form
				HelpersApp.ProgrammeExportHelper.mapFormTabsEditUseFormStaticsDefaultValue(useFormData, document);
				HelpersApp.ProgrammeExportHelper.mapFormTabsEditUseFormDynamicsDefaultValue(useFormData, document);

				return { raw: document, componentData, useFormData, sectionsCount };
			},
			placeholderData: (previousData) => previousData,
		});
	};

	const readListDataGrid = (
		clientId: string,
		operationId: string,
		actions: {
			clone: (id: string) => void;
			edit: (id: string) => void;
			delete: (id: string) => void;
		},
	) => {
		return useProgrammeExports(clientId, operationId, {
			select: (paginated): Array<TypeProgrammeExport> =>
				paginated.data.map((document) => {
					const { id, name, configuration } = document;
					const sectionsCount = configuration.sections.length;
					return {
						id,
						name,
						sectionsCount,
						actions: DataGridCellButtons(HelpersApp.ProgrammeExportHelper.getGridActionButtons(id, actions)),
					};
				}),
			placeholderData: (previousData) => previousData,
		});
	};

	/**
	 * Get Simple List of Document Export Programme
	 * @returns Pagined data
	 */
	const readListExportProgrammeShort = (clientId: string, operationId: string) => {
		return useProgrammeExports(clientId, operationId, {
			select: (paginated): Array<TypeExportProgrammeShort> =>
				paginated.data.map((document) => {
					const { id, name } = document;
					return {
						id,
						name,
					};
				}),
			placeholderData: (previousData) => previousData,
		});
	};

	return { read, readListDataGrid, readListExportProgrammeShort };
};

const ProgrammeExportHook = {
	useEntity,
};

export default ProgrammeExportHook;
