import type { StepConfig, StepId, StepValueTypes } from "@components";
import { useCallback } from "react";
import { useWizardDataStore } from "../stores/useWizardDataStore";

export const WizardStepWrapper = <K extends StepType, StepType extends StepId, ValueTypes extends StepValueTypes<StepType>, TInitialData = undefined>(props: {
	config: StepConfig<K, StepType, ValueTypes, TInitialData>;
	storeKey: string;
}) => {
	const { config, storeKey } = props;

	const StepComponent = config.component;

	const dataStore = useWizardDataStore<StepType, ValueTypes, TInitialData>(storeKey);
	const { initialData } = dataStore((state) => ({ initialData: state.initialData }));

	const hook = useCallback(
		<L extends StepType>(type: L): UseWizardResultReturn<L, StepType, ValueTypes> => {
			const { result, setValue } = dataStore((state) => ({
				result: state.results[type],
				setValue: state.setValue,
			}));
			const handle = useCallback((result: ValueTypes[L]) => setValue(type, result), [setValue, type]);
			return [result, handle];
		},
		[dataStore],
	);

	return <StepComponent useWizardResult={hook} initialData={initialData} />;
};

type UseWizardResultReturn<K extends StepType, StepType extends StepId, ValueTypes extends StepValueTypes<StepType>> = [
	Partial<ValueTypes>[K],
	(result: ValueTypes[K]) => void,
];
