import { getLibraryCoreTranslation } from "@key4-front-library/core";
import type { ModuleTranslation } from "@mykey4/core";
import enProgramme from "./Languages/en.json";
import frProgramme from "./Languages/fr.json";

export const getProgrammeTranslation = (): ModuleTranslation => {
	const core = getLibraryCoreTranslation();
	return {
		en: {
			...core.en,
			...enProgramme,
		},
		fr: {
			...core.fr,
			...frProgramme,
		},
	};
};
