import { useChairQualities } from "@api";
import { ActivityForm } from "@components";
import { type ActionItem, type UserInformationProps, useContextModule } from "@key4-front-library/core";
import { tagsReadToSelectItems } from "@mappers";
import type { TypographyProps } from "@mykey4/core";
import { useEffect, useState } from "react";

interface ChairFormProps {
	isEditMode: boolean;
	userInformation?: UserInformationProps;
}

export const ChairForm = (props: ChairFormProps): React.ReactNode => {
	const { isEditMode, userInformation } = props;

	const { client, event } = useContextModule();

	const [chairQualitiesOptions, setChairQualitiesOptions] = useState<Array<ActionItem<TypographyProps>>>([]);
	const chairQualitiesQuery = useChairQualities(client.id, event.id);

	useEffect(() => {
		if (chairQualitiesQuery?.data?.data) {
			setChairQualitiesOptions(tagsReadToSelectItems(chairQualitiesQuery?.data?.data));
		}
	}, [chairQualitiesQuery?.data?.data]);

	return <ActivityForm isEditMode={isEditMode} qualitiesOptions={chairQualitiesOptions} userInformation={userInformation} />;
};
