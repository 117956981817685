import { useRooms } from "@api";
import { MenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import ClashesRender from "@application/components/_common/Clashes/ClashesRender";
import ClashesSkeleton from "@application/components/_common/Clashes/ClashesSkeleton";
import QuickSearch from "@application/components/_common/QuickSearch/QuickSearch";
import type { ISelectableListItemsDefinition } from "@application/components/_common/QuickSearch/components/SelectableListItems/interface/ISelectableListItemsDefinition.interface";
import { EnumSessionFacultyClashesEntityType } from "@application/enum/Clashes.enum";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import ClashesFactory from "@application/factory/clashes/ClashesFactory";
import HelpersApp from "@application/helpers";
import DateHelper from "@application/helpers/date.helper";
import GetTranslationsForClashes from "@application/helpers/translations/clashes/GetTranslationsForClashes";
import useTagTypes from "@application/hooks/useTagTypes";
import { useSessionPublicationStatusConfigurations, useSessionStatusConfigurations } from "@configurations";
import type { ISessionFacultyClashesModel } from "@domain/model/clashes.model";
import { defaultRowsPerPageOptions } from "@infrastructure/constants/default-pagination.constant";
import type { PaginedResourceType } from "@infrastructure/model/@types/api/api-pagined-response.type";
import {
	DebugConfig,
	EMenuPath,
	type IK4Card,
	NoData,
	Services,
	queryFilters,
	queryStringPagination,
	queryStringSorts,
	useContextModule,
	useNotificationContext,
	usePaginationOld,
	useSnackBarHook,
} from "@key4-front-library/core";
import DebugQueryFilter from "@key4-front-library/core/Bo/Components/DebugQueryFilter";
import type { TAutocompleteMultiBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiBulletColor";
import { Box, Stack, TablePagination } from "@mui/material";
import { FilteringOperator } from "@mykey4/core";
import i18n, { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const debugQueryFilter = DebugConfig.QueryFilter;
const localStoragePrefixKey = "notifications";

const entityType = [EnumSessionFacultyClashesEntityType.ALL, EnumSessionFacultyClashesEntityType.SESSION, EnumSessionFacultyClashesEntityType.INVITATION];

type Props = {
	tabIndex: number;
	isOnlyActive: boolean;
};
const ClashesList = (props: Props) => {
	const { tabIndex, isOnlyActive } = props;

	const navigate = useNavigate();

	const { sendError, sendSuccess } = useSnackBarHook();
	const translations = GetTranslationsForClashes();

	const { client, event } = useContextModule();
	const { fetchCounterActiveClashes } = useNotificationContext();

	const [searchString, setSearchString] = useState<string>("");
	const [quickSearchString, setQuickSearchString] = useState<string>("");

	const [clashes, setClashes] = useState<Array<ISessionFacultyClashesModel>>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { page, pageSize, totalRowCount, handleChangePage, handleChangePageSize, setTotalRowCount } = usePaginationOld();
	const rowsPerPageOptions = defaultRowsPerPageOptions;

	const sessionStatusConfigurations = useSessionStatusConfigurations();
	const sessionPublicationStatus = useSessionPublicationStatusConfigurations();
	const detailsConfig = MenuActionsConfigurations[MenuActionsReference.DETAILS];

	const menuItems = [
		{
			label: translations.buttons.details,
			icon: detailsConfig.icon,
			handleClick: (data: IK4Card) => {
				handleSessionClick(data);
			},
		},
	];

	const [quickSearchFieldsDefinition, setQuickSearchFieldsDefinition] = useState<Array<ISelectableListItemsDefinition>>([]);

	const { data: rooms, isFetching: roomsLoading, error: roomsError } = useRooms(client.id, event.id);

	useEffect(() => {
		handleChangePage(0);
	}, [tabIndex, isOnlyActive, quickSearchString]);

	useEffect(() => {
		const key = "rooms";
		const setRoomsQuickSearchFieldsDefinition = async () => {
			const definition: ISelectableListItemsDefinition = {
				key,
				name: t("old.common.quickSearch.rooms"),
				collection:
					rooms?.data.map((room) => {
						return {
							key: room.key,
							label: room.name,
						};
					}) ?? [],
				searchKey: "roomId",
				keyPropertyName: "id",
				labelPropertyName: "name",
				addEmptyRow: true,
				order: 2,
			};

			setQuickSearchFieldsDefinition([...quickSearchFieldsDefinition.filter((def) => def.key !== key), definition]);
		};
		if (roomsLoading !== null && !roomsLoading && quickSearchFieldsDefinition.find((def) => def.key === key) === undefined) {
			setRoomsQuickSearchFieldsDefinition();
		}
		if (roomsError) {
			throw roomsError;
		}
	}, [rooms, roomsLoading, roomsError, quickSearchFieldsDefinition, setQuickSearchFieldsDefinition]);

	useEffect(() => {
		const key = "dates";
		const setDateQuickSearchFieldsDefinition = async () => {
			const definition: ISelectableListItemsDefinition = {
				key,
				name: t("old.common.quickSearch.dates"),
				collection: DateHelper.getDatesListRange(event.startDate, event.endDate),
				searchKey: "startDate",
				keyPropertyName: "key",
				labelPropertyName: "label",
				addEmptyRow: false,
				order: 1,
			};

			setQuickSearchFieldsDefinition([...quickSearchFieldsDefinition.filter((def) => def.key !== key), definition]);
		};
		if (quickSearchFieldsDefinition.find((def) => def.key === key) === undefined) {
			setDateQuickSearchFieldsDefinition();
		}
	}, [event, quickSearchFieldsDefinition, setQuickSearchFieldsDefinition]);

	useEffect(() => {
		const key = "status";
		const setStatusQuickSearchFieldsDefinition = async () => {
			const statusList: Array<TAutocompleteMultiBulletColorItem> = HelpersApp.SessionHelper.getStatusSessionListItems(sessionStatusConfigurations);

			const definition: ISelectableListItemsDefinition = {
				key,
				name: t("old.common.quickSearch.status"),
				collection: statusList.map((status) => {
					return {
						key: status.key,
						label: status.label,
						color: status.color,
					};
				}),
				searchKey: "status",
				keyPropertyName: "key",
				labelPropertyName: "label",
				addEmptyRow: false,
				order: 0,
			};

			const isPublished = {
				key: "isPublished",
				name: t("old.common.quickSearch.isPublished"),
				collection: [
					{
						key: "true",
						label: sessionPublicationStatus.published.name,
						color: sessionPublicationStatus.published.color,
					},
					{
						key: "false",
						label: sessionPublicationStatus.unpublished.name,
						color: sessionPublicationStatus.unpublished.color,
					},
				],
				searchKey: "isPublished",
				keyPropertyName: "key",
				labelPropertyName: "label",
				addEmptyRow: false,
				order: 1,
			} as ISelectableListItemsDefinition;

			setQuickSearchFieldsDefinition([...quickSearchFieldsDefinition.filter((def) => def.key !== key), definition, isPublished]);
		};
		if (quickSearchFieldsDefinition.find((def) => def.key === key) === undefined) {
			setStatusQuickSearchFieldsDefinition();
		}
	}, [event, quickSearchFieldsDefinition, setQuickSearchFieldsDefinition, t]);

	const { data: tagTypes, loading: tagTypesLoading, error: tagTypesError } = useTagTypes();

	useEffect(() => {
		const setTagTypesQuickSearchFieldsDefinition = async () => {
			const definitions: Array<ISelectableListItemsDefinition> = [];
			tagTypes.forEach((tagType, index) => {
				const tagTypeLabelFormated = tagType.label[0].toLowerCase() + tagType.label.slice(1).replace(/\s/g, "");
				const name = i18n.exists(`old.common.quickSearch.${tagTypeLabelFormated}`) ? t(`old.common.quickSearch.${tagTypeLabelFormated}`) : tagType.label;
				const definition: ISelectableListItemsDefinition = {
					key: tagType.id,
					name,
					collection:
						tagType.tags?.map((tag) => {
							return {
								key: tag.id,
								label: tag.label,
							};
						}) ?? [],
					searchKey: "tag",
					keyPropertyName: "key",
					labelPropertyName: "label",
					addEmptyRow: false,
					order: 3 + index,
				};
				definitions.push(definition);
			});
			setQuickSearchFieldsDefinition([...quickSearchFieldsDefinition.filter((def) => tagTypes.map((i) => i.id).includes(def.key)), ...definitions]);
		};
		if (tagTypesLoading !== null && !tagTypesLoading && !quickSearchFieldsDefinition.some((def) => tagTypes.map((n) => n.id).includes(def.key))) {
			setTagTypesQuickSearchFieldsDefinition();
		}
		if (tagTypesError) {
			throw tagTypesError;
		}
	}, [tagTypes, tagTypesLoading, tagTypesError, quickSearchFieldsDefinition, setQuickSearchFieldsDefinition]);

	const fetchClashes = useCallback(
		(clientId: string, eventId: string, searchString: string) => {
			setIsLoading(true);
			Services.Events.Programme.ClashesService.getListPagined(clientId, eventId, [
				...queryStringPagination({
					page,
					pageSize,
				}),
				...queryStringSorts(["id"]),
				...queryFilters(searchString),
			])
				.then((_response: PaginedResourceType<any>) => {
					const { data, pagination } = _response;
					if (pagination.totalCount) setTotalRowCount(pagination.totalCount);
					setClashes(data);
				})
				.catch(sendError)
				.finally(() => {
					setIsLoading(false);
				});
		},
		[page, pageSize, setTotalRowCount],
	);

	useEffect(() => {
		const entityTypeFilters = `entityType==${entityType[tabIndex]}`;
		const activeFilter = `ignoredDate${isOnlyActive ? "==null" : "!=null"}`;
		const quickSearchFilter = quickSearchString.length > 0 ? quickSearchString : "";
		const _searchString = [entityTypeFilters, activeFilter, quickSearchFilter].filter((filter) => filter.length > 0).join(FilteringOperator.And);
		setSearchString(_searchString);
		fetchClashes(client.id, event.id, _searchString);
	}, [event, tabIndex, isOnlyActive, quickSearchString, fetchClashes]);

	const handleSessionClick = (session: IK4Card) => {
		navigate(["", client.key, event.key, EMenuPath.SESSIONS, session.id].join("/"));
	};

	const handleClashesUpdate = (clientId: string, eventId: string, clashId: string, comments: string, isIgnored: boolean, snackbarMessage?: string) => {
		Services.Events.Programme.ClashesService.patch(clientId, eventId, clashId, {
			comments,
			isIgnored,
		})
			.then(() => {
				if (event) {
					// we don't have a mechanism of websocket to maintain the counter of active clashes
					// in navbar up to date when we activate/desactivate a clash.
					// So we manually set this counter on each activate/desactivate action.

					fetchCounterActiveClashes();
					fetchClashes(clientId, eventId, searchString);
					if (snackbarMessage) sendSuccess(snackbarMessage);
				}
			})
			.catch(sendError);
	};

	const handleQueryBuilderChange = (query: string) => {
		setSearchString(query);
	};

	return (
		<>
			<Stack spacing={2}>
				{debugQueryFilter && <DebugQueryFilter queryBuilderString={searchString} onChangeQueryBuilder={handleQueryBuilderChange} />}
				<Box m={2}>
					<QuickSearch
						localStoragePrefixKey={localStoragePrefixKey}
						quickSearchFieldsDefinition={quickSearchFieldsDefinition}
						handleQueryBuilder={(_query: string) => {
							setQuickSearchString(_query);
						}}
					/>
				</Box>

				{isLoading && <ClashesSkeleton quantity={1} />}

				{clashes && clashes.length > 0 ? (
					clashes.map((clash) => {
						return (
							<ClashesRender
								key={clash.id}
								clashes={ClashesFactory.TransformSessionClashesDataForReading({
									clash,
									client: client.key,
									event,
									translations,
									sessionStatusConfigurations,
								})}
								translations={translations}
								handleClashesUpdate={handleClashesUpdate}
								menuItems={menuItems}
								handleCardClick={handleSessionClick}
							/>
						);
					})
				) : (
					<NoData />
				)}
			</Stack>
			<TablePagination
				component={"div"}
				count={totalRowCount}
				onPageChange={(_, _newPage: number) => {
					handleChangePage(_newPage);
				}}
				page={page}
				rowsPerPage={pageSize}
				rowsPerPageOptions={rowsPerPageOptions}
				onRowsPerPageChange={(_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					handleChangePageSize(Number.parseInt(_event.target.value, 10));
				}}
				showFirstButton
				showLastButton
			/>
		</>
	);
};

export default ClashesList;
