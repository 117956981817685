import { GridActionsCellItem } from "@components";
import { type SxProps, alpha, useTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import type { GridActionsCellItemProps } from "@mui/x-data-grid-pro";

export type GridActionButtonProps = GridActionsCellItemProps & {
	iconColor?: string;
};

export const GridActionButton = (props: GridActionButtonProps) => {
	const theme = useTheme();
	const { iconColor = theme.palette.primary.main, sx, ...restProps } = props;

	const defaultStyles = {
		width: "2rem",
		height: "2rem",
		fontSize: "1rem",
		color: alpha(iconColor, 0.5),
		"&:hover": {
			color: alpha(iconColor, 1),
			backgroundColor: alpha(iconColor, 0.35),
		},
		backgroundColor: alpha(iconColor, 0.08),
	} as SxProps;

	const mergedSx = deepmerge(defaultStyles, sx);

	return <GridActionsCellItem {...restProps} sx={mergedSx} />;
};
