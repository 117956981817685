import { getCellChipColor } from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";
import { getColumnAction } from "@mykey4/core";

export const tagsColDef: GridColDef[] = [
	{ field: "id" },
	{
		field: "label",
		renderCell: getCellChipColor,
		minWidth: 400,
		flex: 10,
		disableColumnMenu: true,
		hideSortIcons: true,
	},
	{ ...getColumnAction(), minWidth: 180 },
];
