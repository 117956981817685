import type { PaletteMode } from "@mui/material";

type DragOverColorsType = {
	[key in PaletteMode]: string;
};

export const dragOverColors: DragOverColorsType = {
	light: "rgba(0, 123, 255, 0.2)",
	dark: "rgba(51, 157, 255, 0.2)",
};
