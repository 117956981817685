import { Box, useTheme } from "@mui/material";
import { Divider, Icon, Link, type LinkProps, Stack, Typography, type TypographyProps } from "@mykey4/core";

export interface TitleLinkProps {
	titleProps: TypographyProps;
	linkProps: LinkProps;
}

export const TitleLink = (props: TitleLinkProps): React.ReactElement => {
	const { titleProps, linkProps } = props;
	const theme = useTheme();
	return (
		<Stack direction={"row"} alignItems={"center"}>
			<Typography {...titleProps} variant="subtitle1" paddingRight={"0.2rem"} minWidth={"fit-content"} color={theme.palette.text.disabled} />
			<Link {...linkProps} underline={"always"} paddingRight={"0.2rem"} minWidth={"fit-content"} />
			<Icon sx={{ paddingRight: "0.2rem" }} variant={"subtitle1"} name={"link-simple"} />
			<Box width={"100%"} paddingLeft={"8px"}>
				<Divider />
			</Box>
		</Stack>
	);
};
