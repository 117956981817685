import type { PresentationRead } from "@api";
import { type AbstractDissociationResults, AbstractsDissociation } from "@components";
import { useOpenWindow } from "@hooks/useOpenWindow";
import { Box } from "@mui/material";
import { Loader } from "@mykey4/core";
import { useCallback, useState } from "react";
import type { AbstractWizardProps } from "../common";
import { AbstractStepLayout, AbstractsSelectedWizardHeader } from "../components";
import { useAbstractDissociationBulkAction } from "../hooks";

export const AbstractsDissociationStep = (props: AbstractWizardProps) => {
	const component = "abstract.bulkActions.steps.action.dissociate";
	const { useWizardResult } = props;

	const [dissociation, setDissociation] = useState<AbstractDissociationResults>([]);
	const { openSession, openSessionPresentations, openAbstract } = useOpenWindow();

	const [selectedAbstractIds] = useWizardResult("abstractSelection");
	const [_, setCurrent] = useWizardResult("action");

	const { isLoading, abstracts, presentations, sessions } = useAbstractDissociationBulkAction({ abstractIds: selectedAbstractIds });

	const handleDissociationChange = useCallback((value: AbstractDissociationResults) => setDissociation(value), []);
	const handleNextClick = useCallback(() => {
		setCurrent({ dissociate: dissociation });
	}, [dissociation, setCurrent]);

	if (isLoading) {
		return <Loader />;
	}

	return (
		<AbstractStepLayout
			slotProps={{
				buttons: {
					nextButton: {
						disabled: !dissociation.some((d) => d.dissociate),
						onBeforeClick: handleNextClick,
					},
				},
			}}
		>
			<AbstractsSelectedWizardHeader i18nKey={`${component}.title`} {...props} />
			<Box sx={{ maxHeight: "70vh", overflowY: "auto" }}>
				<AbstractsDissociation
					abstracts={abstracts?.data}
					presentations={presentations?.data}
					sessions={sessions?.data}
					onDissociationChange={handleDissociationChange}
					onSessionKeyClick={(session) => openSession(session.id)}
					onPresentationKeyClick={(presentation: PresentationRead) => openSessionPresentations(presentation.sessionId)}
					onAbstractKeyClick={(abstract) => openAbstract(abstract)}
				/>
			</Box>
		</AbstractStepLayout>
	);
};
