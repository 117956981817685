import { ActivityInvitationStatusSelect, type SpeakerModalForm } from "@components";
import { type ActionItem, AutoCompleteParticipantOrContact, UserInformation, type UserInformationProps } from "@key4-front-library/core";
import { FormControl, Grid, Select, Stack, TextField, TitleDivider, type TypographyProps, getSelect, getTextField } from "@mykey4/core";
import { t } from "i18next";
import { Controller, useFormContext } from "react-hook-form";

interface ActivityFormProps {
	isEditMode: boolean;
	userInformation?: UserInformationProps;
	qualitiesOptions: Array<ActionItem<TypographyProps>>;
}

export const ActivityForm = (props: ActivityFormProps): React.ReactNode => {
	const { isEditMode, userInformation, qualitiesOptions } = props;
	const component = "activityForm";
	const form = useFormContext<SpeakerModalForm, undefined>();

	return (
		<Stack spacing={2}>
			<Grid container spacing={2} pt={2} px={2}>
				<Grid item xs={12}>
					{!isEditMode ? (
						<Controller
							control={form.control}
							name={"participantOrContact"}
							render={({ field, field: { onChange }, fieldState: { error } }) => (
								<AutoCompleteParticipantOrContact
									updateOtherFieldsAfterParticipantOrContactSelection={false}
									required={false}
									onChange={(val) => {
										onChange(val);
									}}
									error={error?.message}
									field={field}
								/>
							)}
						/>
					) : (
						<UserInformation {...userInformation} />
					)}
				</Grid>
				<FormControl
					name={"qualityId"}
					gridSize={{ xs: 6 }}
					render={(args) => <Select {...args} {...getSelect({ type: "default" }, qualitiesOptions)} label={t(`${component}.qualityId`)} />}
				/>
				<FormControl
					name={"invitationStatus"}
					gridSize={{ xs: 6 }}
					render={(args) => <ActivityInvitationStatusSelect {...args} label={t(`${component}.invitationStatus`)} />}
				/>
				<FormControl
					name={"order"}
					gridSize={{ xs: 2 }}
					render={(args) => <TextField {...args} {...getTextField({ type: "number" })} label={t(`${component}.order`)} />}
				/>
			</Grid>

			{isEditMode && (
				<Stack spacing={2} pt={2}>
					<TitleDivider title={{ variant: "h5", children: t(`${component}.replacement`) }} />
					<Grid container spacing={2} pt={2} px={2}>
						<FormControl
							gridSize={{ xs: 12 }}
							name={"replacementEmail"}
							render={(args) => <TextField {...args} disabled={true} label={t(`${component}.replacementEmail`)} />}
						/>
						<FormControl
							gridSize={{ xs: 12 }}
							name={"replacementFirstname"}
							render={(args) => <TextField {...args} disabled={true} label={t(`${component}.replacementFirstname`)} />}
						/>
						<FormControl
							gridSize={{ xs: 12 }}
							name={"replacementLastname"}
							render={(args) => <TextField {...args} disabled={true} label={t(`${component}.replacementLastname`)} />}
						/>
						<FormControl
							gridSize={{ xs: 12 }}
							name={"replacementComment"}
							render={(args) => (
								<TextField {...args} disabled={true} {...getTextField({ type: "textarea" })} label={t(`${component}.replacementComment`)} minRows={5} />
							)}
						/>
					</Grid>
				</Stack>
			)}
		</Stack>
	);
};
