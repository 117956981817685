import { type DtoTagType, Services, queryStringSorts, useContextModule } from "@key4-front-library/core";
import { useQuery } from "@tanstack/react-query";

const useEntity = (): {
	readListTagsType: () => { data?: Array<DtoTagType> };
} => {
	const { client, event } = useContextModule();

	const useSessionTagsTypes = (): { data?: Array<DtoTagType> } => {
		const { data } = useQuery({
			queryKey: ["session", "list", "tagsType"],
			queryFn: async (): Promise<Array<DtoTagType>> =>
				await Services.Events.Programme.SessionsService.getListTags(client.id, event.id, queryStringSorts(["order"])),
		});

		return { data };
	};

	return { readListTagsType: useSessionTagsTypes };
};

const SessionHook = {
	useEntity,
};

export default SessionHook;
