import type { AbstractRead, PresentationRead } from "@api";
import { groupByFirst } from "@helpers";
import { uniqueId } from "lodash";
import { mapAbstractToItems, mapPresentationsToItems } from "./AbstractsAssociation.mapper";
import {
	type AbstractAssociation,
	type AbstractItem,
	type EnrichedAbstractAssociation,
	type PresentationItem,
	isAbstractOnly,
	isBoth,
	isWithAbstract,
} from "./common";

const associationComparator = (a: AbstractAssociation, b: AbstractAssociation): number => {
	// Helper function to get key from an item
	const getKey = (item: AbstractAssociation) => {
		return !isAbstractOnly(item) ? item.presentation?.key || item.abstract?.key || "" : item.abstract?.key || "";
	};

	// Helper function to get title from an item
	const getTitle = (item: AbstractAssociation) => {
		return !isAbstractOnly(item) ? item.presentation?.title || item.abstract?.title || "" : item.abstract?.title || "";
	};

	// Helper function to compare items by key and title
	const compareByKeyAndTitle = (itemA: AbstractAssociation, itemB: AbstractAssociation) => {
		const keyA = getKey(itemA);
		const keyB = getKey(itemB);

		if (keyA !== keyB) {
			return keyA.localeCompare(keyB);
		}

		const titleA = getTitle(itemA);
		const titleB = getTitle(itemB);

		return titleA.localeCompare(titleB);
	};

	// Case 1: Items with presentations come before abstract-only items
	if (isAbstractOnly(a) && !isAbstractOnly(b)) {
		return 1;
	}

	if (!isAbstractOnly(a) && isAbstractOnly(b)) {
		return -1;
	}

	// Case 2: Both items have presentations
	if (!isAbstractOnly(a) && !isAbstractOnly(b)) {
		const orderA = a.presentation?.order ?? Number.POSITIVE_INFINITY;
		const orderB = b.presentation?.order ?? Number.POSITIVE_INFINITY;

		// If orders are different, sort by order
		if (orderA !== orderB) {
			return orderA - orderB;
		}
	}

	// For all other cases, sort by key and title
	return compareByKeyAndTitle(a, b);
};

interface BuildAssociationResult {
	items: AbstractAssociation[];
	usedAbstractIds: Set<string>;
}

const buildAssociation = (presentations: PresentationItem[], abstracts: Map<string, AbstractItem>): BuildAssociationResult => {
	const usedAbstractIds = new Set<string>();
	const presentationMap = groupByFirst(presentations, "id");
	const items = presentationMap
		.values()
		.toArray()
		.map((presentation) => {
			const abstractId = presentation.abstractId;
			if (abstractId) {
				const abstract = abstracts.get(abstractId);
				if (abstract) {
					usedAbstractIds.add(abstractId);
					return { presentation, abstract };
				}
			}
			return { presentation };
		});
	return { items, usedAbstractIds };
};

export const buildPresentationAbstractItem = (item: AbstractAssociation): EnrichedAbstractAssociation => {
	return {
		...item,
		id: `presentation-abstract-item-${uniqueId()}`,
		isExisting: isBoth(item),
		overridePresentationTitle: isAbstractOnly(item),
		existingPresentationTitle: item.presentation?.title,
		existingAbstractId: item.presentation?.abstractId,
	};
};

export const buildPresentationAbstractItems = (
	presentations: PresentationRead[],
	abstracts: AbstractRead[],
	selectedAbstractIds: string[],
): Map<string, EnrichedAbstractAssociation> => {
	const presentationItems = mapPresentationsToItems(presentations);
	const abstractItems = mapAbstractToItems(abstracts, selectedAbstractIds);
	const abstractMap = groupByFirst(abstractItems, "id");

	const result = buildAssociation(presentationItems, abstractMap);

	abstractMap.forEach((abstract, id) => {
		if (!result.usedAbstractIds.has(id)) {
			result.items.push({ abstract });
		}
	});

	const sorted = result.items.sort(associationComparator);

	if (presentationItems.length > 0) {
		sorted.push({});
	}

	const enrichedAssociations = sorted.map(buildPresentationAbstractItem);

	const highlight = enrichedAssociations.some((item) => isBoth(item) && selectedAbstractIds.includes(item.abstract.id));

	for (const item of enrichedAssociations) {
		if (isWithAbstract(item) && selectedAbstractIds.includes(item.abstract.id)) {
			item.abstract.highlight = highlight;
		}
	}

	return new Map(enrichedAssociations.map((item) => [item.id, item]));
};
