import { t } from "i18next";

const GetTranslationsForAnomalies = () => {
	return {
		anomalies: {
			title: t(`old.programme.anomalies.title`),
			session: t(`old.programme.anomalies.type.session`),
			presentation: t(`old.programme.anomalies.type.presentation`),
			invitation: t(`old.programme.anomalies.type.invitation`),
			duplicateCode: t(`old.programme.anomalies.status.duplicateCode`),
			duplicateTitle: t(`old.programme.anomalies.status.duplicateTitle`),
			emptyTitle: t(`old.programme.anomalies.status.emptyTitle`),
			emptyPrimaryTag: t(`old.programme.anomalies.status.emptyPrimaryTag`),
			withoutChair: t(`old.programme.anomalies.status.withoutChair`),
			withoutPresentation: t(`old.programme.anomalies.status.withoutPresentation`),
			withoutSpeaker: t(`old.programme.anomalies.status.withoutSpeaker`),
			emptyCode: t(`old.programme.anomalies.status.emptyCode`),
			isDraftAndValidated: t(`old.programme.anomalies.status.isDraftAndValidated`),
			invalidLocation: t(`old.programme.anomalies.status.invalidLocation`),
			invalidSchedule: t(`old.programme.anomalies.status.invalidSchedule`),
			discontinuityInPresentationSchedule: t(`old.programme.anomalies.status.discontinuityInPresentationSchedule`),
			notInAcceptedStatus: t(`old.programme.anomalies.status.notInAcceptedStatus`),
		},
		filters: {
			isOnlyActive: t(`old.programme.sessionDetails.tabs.clashes.filters.isOnlyActive`),
			searchAnomaly: t(`old.programme.anomalies.searchAnomaly`),
		},
		buttons: {
			selectAll: t(`old.form.select.selectAll`),
			reset: t(`old.form.buttons.reset`),
			filter: t(`old.form.buttons.filter`),
		},
		comments: t(`old.form.placeholders.comments`),
		returnMessages: {
			success_comments: t(`old.programme.anomalies.returnMessages.success_comments`),
			activated_anomaly: t(`old.programme.anomalies.returnMessages.activated_clash`),
			desactivated_anomaly: t(`old.programme.anomalies.returnMessages.desactivated_anomaly`),
		},
	};
};

export default GetTranslationsForAnomalies;
