import { useDashboardProgramme } from "@api";
import { useContextModule } from "@key4-front-library/core";
import type { RegularBreakpoints } from "@mui/material/Grid/Grid";
import { t } from "i18next";
import { getDashboardIndicatorDefinitions } from "../Common";
import DashboardIndicator from "../Common/DashboardIndicator";

type Props = RegularBreakpoints & {};

const DashboardProgramme = (props: Props) => {
	const { client, event } = useContextModule();
	const definitions = getDashboardIndicatorDefinitions();

	const indicators = useDashboardProgramme({
		clientId: client.id,
		eventId: event.id,
		select: (data) => {
			return [
				{ ...definitions.sessions, value: data.sessions },
				{ ...definitions.rooms, value: data.rooms },
				{ ...definitions.presentations, value: data.presentations },
			];
		},
	});

	return (
		<DashboardIndicator
			{...props}
			title={t("old.programme.dashboard.indicators.programme.title")}
			indicators={indicators.data ?? []}
			isLoading={indicators.isFetching}
		/>
	);
};

export default DashboardProgramme;
