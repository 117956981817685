import { EnumPresentationFieldKey } from "@application/Enums/PresentationEnum";
import { DATETIME_PARSING_FORMAT, DATE_PARSING_FORMAT, GetEntireDate, SMALL_TIME_PARSING_FORMAT, padStartDigitWith0 } from "@key4-front-library/core";
import { t } from "i18next";
import { DateTime, Duration } from "luxon";
import type { FieldValues, UseFormGetValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";

export type TypePresentationSchedule = "FullDate" | "DateOnly" | "HourOnly" | "None";

export interface PresentationSchedule {
	startDate: DateTime | null;
	endDate: DateTime | null;
	dateType: TypePresentationSchedule;
}

const DATETIME_FIELD_KEYS = [
	EnumPresentationFieldKey.START_DATE,
	EnumPresentationFieldKey.END_DATE,
	EnumPresentationFieldKey.START_HOUR,
	EnumPresentationFieldKey.END_HOUR,
];

const callbackDisplay = (
	keys: Array<string>,
	name: string,
	value: any,
	setValues: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
	trigger: UseFormTrigger<FieldValues>,
) => {
	const nameSplit = name.split(".");
	const path = [nameSplit[0], nameSplit[1]].join(".");

	DATETIME_FIELD_KEYS.forEach((key: string) => {
		getValues([path, key].join(".")) === "Invalid DateTime" && setValues([path, key].join("."), null);
	});

	trigger();

	keys.forEach((key: string) => {
		if (value) {
			setValues([path, "metadata", key, "visibility"].join("."), false);
		} else {
			setValues([path, "metadata", key, "visibility"].join("."), true);
		}
	});
};

const setDurationValues = (path: string, setValues: UseFormSetValue<FieldValues>, getValues: UseFormGetValues<FieldValues>) => {
	// If duration doesn't exist compute duration
	if (
		getValues(`${path}.${EnumPresentationFieldKey.END_DATE}`) &&
		getValues(`${path}.${EnumPresentationFieldKey.START_DATE}`) &&
		getValues(`${path}.${EnumPresentationFieldKey.END_HOUR}`) &&
		getValues(`${path}.${EnumPresentationFieldKey.START_HOUR}`)
	) {
		const dateStart: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumPresentationFieldKey.START_DATE}`)} ${(getValues(`${path}.${EnumPresentationFieldKey.START_HOUR}`) as DateTime).toFormat(
				SMALL_TIME_PARSING_FORMAT,
			)}`,
			DATETIME_PARSING_FORMAT,
		);
		const dateEnd: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumPresentationFieldKey.END_DATE}`)} ${(getValues(`${path}.${EnumPresentationFieldKey.END_HOUR}`) as DateTime).toFormat(
				SMALL_TIME_PARSING_FORMAT,
			)}`,
			DATETIME_PARSING_FORMAT,
		);
		const duration: Duration = dateEnd.diff(dateStart, ["hours", "minutes"]);
		if (!Number.isNaN(duration.minutes)) {
			if (duration.minutes > 0 || duration.hours > 0) {
				setValues(
					`${path}.${EnumPresentationFieldKey.DURATION}`,
					`${padStartDigitWith0(duration.hours.toString())}:${padStartDigitWith0(duration.minutes.toString())}`,
				);
			} else {
				setValues(`${path}.${EnumPresentationFieldKey.DURATION}`, null);
			}
		}
	}
};

const changeDatesOnDuration = (path: string, setValues: UseFormSetValue<FieldValues>, getValues: UseFormGetValues<FieldValues>) => {
	const startDate: string | null = getValues(`${path}.${EnumPresentationFieldKey.START_DATE}`);
	const startHour: DateTime | null = getValues(`${path}.${EnumPresentationFieldKey.START_HOUR}`);

	const durationValue = getValues(`${path}.${EnumPresentationFieldKey.DURATION}`);

	if (startDate && startHour && durationValue && startDate !== "Invalid DateTime" && startHour.isValid) {
		const dateStart: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumPresentationFieldKey.START_DATE}`)} ${(getValues(`${path}.${EnumPresentationFieldKey.START_HOUR}`) as DateTime).toFormat(
				SMALL_TIME_PARSING_FORMAT,
			)}`,
			DATETIME_PARSING_FORMAT,
		);

		const durationValue = getValues(`${path}.${EnumPresentationFieldKey.DURATION}`);
		let duration;

		if (typeof durationValue === "string") {
			duration = Duration.fromISOTime(durationValue);
		} else {
			duration = Duration.fromISOTime(durationValue.key);
		}

		if (duration.isValid) {
			const dateEnd: DateTime = dateStart.plus(duration);
			setValues(`${path}.${EnumPresentationFieldKey.END_DATE}`, dateEnd.toFormat(DATE_PARSING_FORMAT));
			setValues(`${path}.${EnumPresentationFieldKey.END_HOUR}`, dateEnd);
		}
	}
};

const triggerDates = (path: string, trigger: UseFormTrigger<FieldValues>) => {
	DATETIME_FIELD_KEYS.forEach((key: string) => {
		trigger(`${path}.${key}`);
	});
};

const getPresentationDates = (startDate?: string | null, endDate?: string | null): string => {
	if (!startDate || !endDate) {
		return t("old.programme.presentations.notYetScheduled");
	}
	return GetEntireDate(startDate, endDate);
};

const PresentationHelper = {
	callbackDisplay,
	getPresentationDates,
	setValuesDuration: setDurationValues,
	changeDatesOnDuration,
	triggerDates,
};

export default PresentationHelper;
