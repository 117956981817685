import K4ButtonClearAll from "@infrastructure/components/interface/button/K4ButtonClearAll";
import { t } from "i18next";
import type { MouseEventHandler } from "react";

interface Props {
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonClearAllDecorated = (props: Props) => {
	const { isDisabled = false, onClick } = props;
	return <K4ButtonClearAll label={t("old.common.button.clearAll")} isDisabled={isDisabled} onClick={onClick} />;
};

export default K4ButtonClearAllDecorated;
