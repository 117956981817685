import type { AbstractThemeRead } from "@api";
import { type BodyPostGet, type PathEntity, type ServiceArgs, post } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "abstract-themes", id }];

interface PostAbstractThemeArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BodyPostGet;
}

export const postAbstractThemesSearch = async (args: PostAbstractThemeArgs): Promise<Array<AbstractThemeRead>> => {
	const { body, ...others } = args;
	return await post(
		{
			module,
			entities: [...rootEntities(), { entity: "search" }],
			...others,
		},
		body,
	);
};
