import { alpha, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Link } from "@mykey4/core";
import { memo } from "react";
import { AbstractDissociationSwitch } from "./AbstractDissociationSwitch";

interface BadgeSwitchProps {
	sessionKey: string;
	presentationKey: string;
	checked: boolean;
	onCheckedChange: (checked: boolean) => void;
	onSessionKeyClick?: () => void;
	onPresentationKeyClick?: () => void;
}

export const AbstractDissociationSwitchContainer = memo((props: BadgeSwitchProps) => {
	const { sessionKey, presentationKey, checked, onCheckedChange, onSessionKeyClick, onPresentationKeyClick } = props;
	const theme = useTheme();
	const typoColor = checked ? (theme.palette.mode === "light" ? "#5F2120" : "F4C7C7") : theme.palette.text.primary;
	const backGroundColor = checked ? (theme.palette.mode === "light" ? "rgba(255, 45, 38, 0.04)" : "rgba(255, 45, 38, 0.08)") : theme.palette.background.paper;
	const borderColor = checked ? alpha(theme.palette.error.main, 0.23) : "rgba(0, 0, 0, 0.23)";
	const borderColorHover = checked ? theme.palette.error.main : "rgba(0, 0, 0, 1)";
	return (
		<Stack
			direction="row"
			spacing={1}
			alignItems="center"
			paddingX={1}
			sx={{
				border: `2px solid ${borderColor}`,
				"&:hover": { borderColor: borderColorHover },
				borderRadius: theme.spacing(1),
				backgroundColor: backGroundColor,
			}}
		>
			<AbstractDissociationSwitch labelColor={typoColor} checked={checked} onCheckedChange={onCheckedChange}>
				<Stack direction={"row"} alignItems={"center"} overflow={"hidden"} textOverflow="ellipsis" whiteSpace={"nowrap"} spacing={1}>
					<Link onClick={onSessionKeyClick} color={typoColor} flexShrink={0} sx={{ textDecoration: "underline", cursor: "pointer" }}>
						{sessionKey}
					</Link>
					<Box>-</Box>
					<Link onClick={onPresentationKeyClick} color={typoColor} flexShrink={0} sx={{ textDecoration: "underline", cursor: "pointer" }}>
						{presentationKey}
					</Link>
				</Stack>
			</AbstractDissociationSwitch>
		</Stack>
	);
});
