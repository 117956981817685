import { t } from "i18next";
import { NumericFormat as NumberFormat } from "react-number-format";

/**
 * mustBePureRendering : N'imprime pas le <span> dans le dom, ce qui provoquait un problème dans le donuts des dashboard par exemple. Pas de data-cy donc.
 */
type NumberThousandSeparatorType = {
	value: number;
	mustBePureRendering?: boolean;
};

const NumberThousandSeparator = (props: NumberThousandSeparatorType) => {
	const { value, mustBePureRendering } = props;
	const thousandSeparator = t("old.common.format.number.thousand-separator");

	return (
		<NumberFormat
			value={value}
			displayType={"text"}
			thousandSeparator={thousandSeparator}
			renderText={(value) => (mustBePureRendering ? value : <span>{value}</span>)}
		/>
	);
};

export default NumberThousandSeparator;
