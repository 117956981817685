import type { AbstractAbstractKeywordRead } from "@api";
import { type BodyPostGet, type Paginated, type PathEntity, type ServiceArgs, post, postPaginated } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "abstracts-abstract-keywords", id }];

interface PostAbstractAbstractKeywordArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BodyPostGet;
}

export const postAbstractAbstractKeywordsSearch = async (args: PostAbstractAbstractKeywordArgs): Promise<Array<AbstractAbstractKeywordRead>> => {
	const { body, ...others } = args;
	return await post(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};

export const postAbstractAbstractKeywordsSearchPaginated = async (args: PostAbstractAbstractKeywordArgs): Promise<Paginated<AbstractAbstractKeywordRead>> => {
	const { body, ...others } = args;
	return await postPaginated(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};
