import { t } from "i18next";

import type { RoomForm } from "@domain/model/room.model";

const GetTranslationsForRooms = (room?: RoomForm | boolean) => {
	return {
		details: {
			locale: t("old.common.current_locale"),
			capacity: t("old.programme.rooms.details.capacity"),
		},
		title: t("old.programme.rooms.index.title"),
		capacity: t("old.programme.rooms.capacity"),
		buttons: {
			add: t("old.form.buttons.add"),
			details: t("old.form.buttons.details"),
			edit: t("old.form.buttons.edit"),
			delete: t("old.form.buttons.delete"),
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
			up: t("old.form.buttons.up"),
			down: t("old.form.buttons.down"),
		},
		editionModal: {
			title: t(`old.programme.rooms.edition.title.${room ? "modification" : "creation"}`),
			returnMessages: {
				success_creation: t("old.programme.rooms.edition.returnMessages.success_creation"),
				success_modification: t("old.programme.rooms.edition.returnMessages.success_modification"),
				success_deletion: t("old.programme.rooms.edition.returnMessages.success_deletion"),
			},
			name: t("old.programme.rooms.edition.name"),
			createAnother: t("old.programme.rooms.edition.createAnother"),
		},
	};
};

export default GetTranslationsForRooms;
