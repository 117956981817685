import type { SessionRead } from "@api";
import {
	DISPLAY_DATE_FORMAT,
	Grid,
	GridLabel,
	PaperTitle,
	Stack,
	TIME_FORMAT,
	Typography,
	isoDurationToString,
	stringISOToDateTimeNullable,
} from "@mykey4/core";
import { t } from "i18next";

export interface ScheduleSectionSessionProps {
	session: SessionRead;
}

export const ScheduleSectionSession = (props: ScheduleSectionSessionProps): React.ReactNode => {
	const component = "scheduleSectionSession";
	const { session } = props;

	const startDate = stringISOToDateTimeNullable(session.startDate);
	const endDate = stringISOToDateTimeNullable(session.endDate);

	return (
		<Stack>
			<PaperTitle
				title={{
					icon: { variant: "h5", prefix: "fal", name: "calendar-days", color: "primary.light" },
					children: <Typography variant="h5">{t(`${component}.title`)}</Typography>,
				}}
			>
				<Grid container spacing={2}>
					<GridLabel label={t(`${component}.displayer.startDate`)}>
						<Typography>{startDate ? startDate.toFormat(DISPLAY_DATE_FORMAT) : <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.startHour`)}>
						<Typography>{startDate ? startDate.toFormat(TIME_FORMAT) : <br />}</Typography>
					</GridLabel>
					<Grid item xs={0} md={4} lg={6} />
					<GridLabel label={t(`${component}.displayer.endDate`)}>
						<Typography>{endDate ? endDate.toFormat(DISPLAY_DATE_FORMAT) : <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.endHour`)}>
						<Typography>{endDate ? endDate.toFormat(TIME_FORMAT) : <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.duration`)}>
						<Typography>{session.duration ? isoDurationToString(session.duration) : <br />}</Typography>
					</GridLabel>
				</Grid>
			</PaperTitle>
		</Stack>
	);
};
