import ParticipantController from "@key4-front-library/core/Bo/Controllers/ParticipantController";
import CalendarController from "./CalendarController";
import ConvocationController from "./ConvocationController";
import CustomFieldController from "./CustomFieldController";
import FacultyController from "./FacultyController";
import RoomController from "./RoomController";
import SearchFilterController from "./SearchFilterController";
import SessionController from "./SessionController";
import SettingsController from "./SettingsController";

const ControllersApp = {
	CalendarController,
	ConvocationController,
	CustomFieldController,
	FacultyController,
	ParticipantController,
	RoomController,
	SearchFilterController,
	SessionController,
	SettingsController,
};

export default ControllersApp;
