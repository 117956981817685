import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button, Dialog, type DialogComponentProps, type DialogProps, Stack, getButton } from "@mykey4/core";

export type ConfirmationDialogProps = Omit<DialogProps, "open"> & {
	title?: string;
	content: string;
};

export const ConfirmationDialog = (props: DialogComponentProps<ConfirmationDialogProps, boolean>) => {
	const {
		open,
		onClose,
		payload: { title, content, ...otherPayload },
	} = props;
	return (
		<Dialog open={open} maxWidth={"sm"} fullWidth {...otherPayload}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				<Box>{content}</Box>
			</DialogContent>
			<DialogActions>
				<Stack direction={"row"} mr=".5rem" mb=".5rem" spacing=".8rem">
					<Button
						{...getButton({ type: "cancel" })}
						onClick={async () => {
							await onClose(false);
						}}
					/>
					<Button {...getButton({ type: "confirm" })} onClick={() => onClose(true)} />
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
