import type { Theme } from "@mui/material";
import { t } from "i18next";
import type { SessionStatusConfigurations } from "./type";

export const getSessionStatusConfiguration = (theme: Theme): SessionStatusConfigurations => ({
	draft: {
		backgroundColor: theme.palette.warning.background,
		borderColor: theme.palette.warning.background,
		color: theme.palette.warning.main,
		name: t("sessionStatus.draft"),
		translationKey: "sessionStatus.draft",
	},
	validated: {
		backgroundColor: theme.palette.success.background,
		borderColor: theme.palette.success.background,
		color: theme.palette.success.main,
		name: t("sessionStatus.validated"),
		translationKey: "sessionStatus.validated",
	},
	cancelled: {
		backgroundColor: theme.palette.grey[300],
		borderColor: theme.palette.grey[300],
		color: theme.palette.grey[700],
		name: t("sessionStatus.cancelled"),
		translationKey: "sessionStatus.cancelled",
	},
});
