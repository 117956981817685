import { SessionPublicationStatusTag, SessionStatusTag, TimeSlotSection, type TimeSlotSectionProps } from "@components";
import { getClashIcon } from "@configurations";
import { Typography } from "@mui/material";
import {
	Button,
	Chip,
	DatePaper,
	type DatePaperProps,
	Icon,
	Link,
	type SessionStatus,
	Stack,
	Tag,
	type TagProps,
	TextTooltip,
	TitleDivider,
	getTitleDivider,
} from "@mykey4/core";
import { t } from "i18next";
import { uniqueId } from "lodash";

export interface SchedulerCardPopoverProps {
	datePaper?: DatePaperProps;
	description?: string;
	hours?: string;
	hasClashes: boolean;
	shouldDisplayDatePaper: boolean;
	code?: string;
	tags?: Array<TagProps>;
	timeSlotSections: Array<TimeSlotSectionProps>;
	title?: string;
	to: string;
	sessionStatus?: SessionStatus;
	isPublished?: boolean;
}

export const SchedulerCardPopover = (props: SchedulerCardPopoverProps) => {
	const { datePaper, description, shouldDisplayDatePaper, hours, code, timeSlotSections, tags, title, to, hasClashes, sessionStatus, isPublished } = props;
	const component = "schedulerCardPopoverContent";

	return (
		<Stack sx={{ width: "513px;" }} p={2} spacing={3}>
			<Stack spacing={2}>
				<Stack direction={"row"} spacing={1}>
					{shouldDisplayDatePaper && <DatePaper {...datePaper} />}
					<Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
						<Stack flex={"1 1 auto"}>
							{hours ? (
								<Typography variant="subtitle2" color="textSecondary">
									{hours}
								</Typography>
							) : (
								<Typography variant="subtitle2" color="text.disabled">
									{t("noTime")}
								</Typography>
							)}
							<Link to={to} color="inherit">
								{code ? (
									<TextTooltip
										line={1}
										height="32px"
										tooltip={{ placement: "bottom-start" }}
										typography={{
											children: code,
											variant: "h6",
											color: "primary",
										}}
									/>
								) : (
									<Typography variant="subtitle1medium" color="text.disabled">
										{t("noCode")}
									</Typography>
								)}
							</Link>
							<Link to={to} color="inherit">
								{title ? (
									<TextTooltip
										line={2}
										height="48px"
										tooltip={{ placement: "bottom-start" }}
										typography={{
											children: title,
										}}
									/>
								) : (
									<Typography variant="body1italic" color="text.disabled">
										{t(`${component}.noTitle`)}
									</Typography>
								)}
							</Link>
						</Stack>
						<Stack alignItems={"flex-end"} justifyContent={"flex-start"} spacing={1} flex={"0 0 auto"}>
							<Stack direction={"row"} spacing={1}>
								{sessionStatus && <SessionStatusTag value={sessionStatus} />}
								{isPublished !== undefined && <SessionPublicationStatusTag value={isPublished} />}
							</Stack>
							{hasClashes && <Icon {...getClashIcon()} />}
						</Stack>
					</Stack>
				</Stack>
				{tags && tags.length > 0 && (
					<Stack direction={"row"} spacing={1} useFlexGap sx={{ flexWrap: "wrap" }}>
						{tags.map((tag, index) => (
							<Tag key={uniqueId(index.toString())} {...tag} />
						))}
						{tags.length === 5 && (
							<Link to={to}>
								<Chip color="primary" label={<Typography>...</Typography>} />
							</Link>
						)}
					</Stack>
				)}
			</Stack>
			<Stack spacing={2}>
				<Stack spacing={1}>
					<TitleDivider
						{...getTitleDivider({
							type: "default",
							translation: `${component}.title.description`,
						})}
					/>
					<Stack>
						{description ? (
							<TextTooltip
								line={3}
								height="72px"
								tooltip={{ placement: "bottom-start" }}
								typography={{
									children: description,
								}}
							/>
						) : (
							<Typography variant="body1italic" color="text.disabled">
								{t(`${component}.noDescription`)}
							</Typography>
						)}
					</Stack>
				</Stack>
				<Stack spacing={1}>
					<TitleDivider
						{...getTitleDivider({
							type: "default",
							translation: `${component}.title.presentations`,
						})}
					/>
					<Stack>
						{timeSlotSections.length > 0 ? (
							<Stack spacing={2}>
								{timeSlotSections.map((timeSlotSection, index) => (
									<Stack key={uniqueId(index.toString())}>
										<TimeSlotSection {...timeSlotSection} />
									</Stack>
								))}
							</Stack>
						) : (
							<Typography variant="body1italic" color="text.disabled">
								{t(`${component}.noPresentations`)}
							</Typography>
						)}
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
					<Button to={to} variant="text" endIcon={{ name: "arrow-up-right-from-square" }}>
						{t(`${component}.seeAll`)}
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};
