import { type AbstractRead, getAllData } from "@api";
import { type BodyPostGet, type Paginated, type PathEntity, type ServiceArgs, postPaginated } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "abstracts", id }];

interface GetAbstractsArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BodyPostGet;
}

export const getAbstracts = async (args: GetAbstractsArgs): Promise<Paginated<AbstractRead>> => {
	const { body, ...other } = args;
	return await postPaginated(
		{
			module,
			entities: [...rootEntities(), { entity: "search" }],
			...other,
		},
		body,
	);
};

export const getAbstractsAll = async (args: GetAbstractsArgs): Promise<AbstractRead[]> => {
	const { body, ...other } = args;
	return await getAllData((b) => getAbstracts({ ...other, body: b }), body);
};
