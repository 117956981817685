import type { AbstractRead, PresentationRead, SessionRead } from "@api";
import type { AbstractDissociationResults } from "@components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { mapDissociationToResult } from "./AbstarctsDissociation.mapper";
import { buildAbstractDissociationItems } from "./AbstractsDissociation.helper";
import type { AbstractDissociationItem } from "./common";

export interface UseAbstractDissociationArgs {
	sessions?: SessionRead[];
	presentations?: PresentationRead[];
	abstracts?: AbstractRead[];
	onDissociationChange?: (associations: AbstractDissociationResults) => void;
}

export interface UseAbstractDissociationReturn {
	items: AbstractDissociationItem[];
	dissociateAllChange: (value: boolean) => void;
	dissociateAbstractChange: (abstractId: string, value: boolean) => void;
	presentationDissociationChange: (abstractId: string, presentationId: string, value: boolean) => void;
}

export const useAbstractDissociation = (args: UseAbstractDissociationArgs): UseAbstractDissociationReturn => {
	const { sessions, presentations, abstracts, onDissociationChange } = args;

	const [items, setItems] = useState<AbstractDissociationItem[]>([]);

	const updateDissociation = useCallback((filterFn: (abstract: AbstractDissociationItem, presentationId: string) => boolean, value: boolean) => {
		setItems((current) => {
			return current.map((abstract) => {
				for (const presentationId of abstract.presentations.keys() ?? []) {
					if (filterFn(abstract, presentationId)) {
						const presentation = abstract.presentations.get(presentationId);
						if (presentation) {
							abstract.presentations.set(presentationId, { ...presentation, dissociate: value });
						}
					}
				}
				return { ...abstract };
			});
		});
	}, []);

	const handlePresentationDissociationChange = useCallback(
		(abstractId: string, presentationId: string, value: boolean) => {
			updateDissociation((abstract, id) => abstract.id === abstractId && id === presentationId, value);
		},
		[updateDissociation],
	);

	const handleDissociateAbstractChange = useCallback(
		(abstractId: string, value: boolean) => {
			updateDissociation((abstract, _) => abstract.id === abstractId, value);
		},
		[updateDissociation],
	);

	const handleDissociateAllChange = useCallback(
		(value: boolean) => {
			updateDissociation(() => true, value);
		},
		[updateDissociation],
	);

	useEffect(() => setItems(buildAbstractDissociationItems(abstracts ?? [], presentations ?? [], sessions ?? [])), [abstracts, presentations, sessions]);
	useEffect(() => onDissociationChange?.(mapDissociationToResult(items)), [items, onDissociationChange]);

	return useMemo(() => {
		return {
			items: items,
			dissociateAllChange: handleDissociateAllChange,
			dissociateAbstractChange: handleDissociateAbstractChange,
			presentationDissociationChange: handlePresentationDissociationChange,
		};
	}, [items, handleDissociateAllChange, handleDissociateAbstractChange, handlePresentationDissociationChange]);
};
