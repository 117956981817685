import type { AbstractRead, PresentationRead } from "@api";
import type { AbstractsAssociationResults } from "@components";
import { getAbstractKey, getPresentationKey } from "@helpers";
import { type AbstractItem, type EnrichedAbstractAssociation, type PresentationItem, isEmpty } from "./common";

export const mapPresentationsToItems = (presentations: PresentationRead[]): PresentationItem[] => {
	return presentations.map((item) => ({
		...item,
		key: getPresentationKey(item),
	}));
};

export const mapAbstractToItems = (abstracts: AbstractRead[], selectedAbstractIds: string[]): AbstractItem[] => {
	return abstracts.map((item) => ({
		...item,
		key: getAbstractKey(item),
		highlight: selectedAbstractIds.includes(item.id),
	}));
};

export const mapAssociationToResults = (items: Map<string, EnrichedAbstractAssociation>): AbstractsAssociationResults => {
	return items
		.values()
		.filter((item) => !isEmpty(item))
		.map((item) => ({
			presentationId: item.presentation?.id,
			abstractId: item.abstract?.id,
			overridePresentationTitle: item.overridePresentationTitle,
		}))
		.toArray();
};
