import type { SessionTemplateRead } from "@api";
import { useNonNullableContext } from "@mykey4/core";
import React, { type ReactNode } from "react";
import { FormProvider, type UseFormReturn } from "react-hook-form";
import type { SessionTemplateFormType } from "./SessionTemplateForm.zod";

export type SessionTemplateFormContextType = {
	existing?: SessionTemplateRead;
	form: UseFormReturn<SessionTemplateFormType>;
};

export const SessionTemplateFormContext = React.createContext<SessionTemplateFormContextType | null>(null);

export const useSessionTemplateFormContext = () => {
	return useNonNullableContext(SessionTemplateFormContext);
};

export const SessionTemplateFormProvider = (props: { value: SessionTemplateFormContextType; children?: ReactNode | undefined }) => {
	return (
		<SessionTemplateFormContext.Provider value={props.value}>
			<FormProvider {...props.value.form}>{props.children}</FormProvider>
		</SessionTemplateFormContext.Provider>
	);
};
