import { DateTime } from "luxon";

export const formatDateRange = (startDate?: string, endDate?: string): string | undefined => {
	const format = "dd/MM/yyyy HH'h'mm";
	const start = startDate ? DateTime.fromISO(startDate).setZone("UTC") : null;
	const end = endDate ? DateTime.fromISO(endDate).setZone("UTC") : null;
	switch (true) {
		case !!start && !!end:
			return `${start.toFormat(format)} to ${end.toFormat(format)}`;
		case !!start:
			return `From ${start.toFormat(format)}`;
		case !!end:
			return `Until ${end.toFormat(format)}`;
		default:
			return undefined;
	}
};
