import { Grid, Stack, useTheme } from "@mui/material";
import { FormControl, type FormControlRenderArgs, TimePicker, Typography, type TypographyProps } from "@mykey4/core";
import type { DateTime } from "luxon";
import { type ReactElement, useCallback } from "react";

export interface PrefixedTimePickerProps {
	prefixProps: Omit<TypographyProps, "variant" | "disabled">;
	fieldName: string;
	isDisabled?: boolean;
}

export const PrefixedTimePicker = (props: PrefixedTimePickerProps): ReactElement => {
	const { prefixProps, fieldName, isDisabled = false } = props;
	const theme = useTheme();
	return (
		<Grid item xs={6}>
			<Stack direction={"row"} alignItems={"center"}>
				<Typography {...prefixProps} color={isDisabled ? theme.palette.text.disabled : "textPrimary"} paddingRight={"16px"} variant="body1" />
				<FormControl
					name={fieldName}
					render={useCallback(
						(args: FormControlRenderArgs) => {
							const { value, ...others } = args;
							return <TimePicker value={value ? (value as DateTime) : null} disabled={isDisabled} {...others} />;
						},
						[isDisabled],
					)}
				/>
			</Stack>
		</Grid>
	);
};
