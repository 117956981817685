import NavigationTabsWithIcons from "@application/components/_common/Navigation/NavigationTabsWithIcons";
import { AdvancedSettingsMenuItemReference } from "@application/enum/AdvancedSettingsMenuItemReference.enum";
import { MenuItemReference } from "@application/enum/MenuItemReference.enum";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import PeriodContainer from "@infrastructure/components/interface/period/PeriodContainer";
import { useSettingsContext } from "@infrastructure/contexts/settingsContext";
import { TitleOld, useContextModule } from "@key4-front-library/core";
import type { DtoClient, DtoEvent } from "@key4-front-library/core/Dto";
import { Stack } from "@mui/material";
import { Loader, useUserRolePage } from "@mykey4/core";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const AdvancedSettings = () => {
	useUserRolePage(["moduleAdministrator"]);

	const { client, event } = useContextModule();
	const { settingsContext, setSettingsContext } = useSettingsContext();

	useEffect(() => {
		const updateData = (_client: DtoClient, _event: DtoEvent) => {
			setSettingsContext({
				settingsMenuItems: SettingsFactory.GetAdvancedSettingsMenuItems(_client, _event),
				generalMenuItems: SettingsFactory.GetGeneralMenuItems(_client, _event),
				icons: SettingsFactory.GetIconsItems(),
			});
		};

		updateData(client, event);
	}, [client, event, setSettingsContext]);

	if (!settingsContext) return <Loader />;

	return (
		<Stack>
			<TitleOld title={event.name ?? ""} reference={<PeriodContainer dateStartIso={event.startDate} dateEndIso={event.endDate} />} />
			<NavigationTabsWithIcons
				menuItems={settingsContext.settingsMenuItems}
				baseUrl={["", client.key, event.key, MenuItemReference.ADVANCED_SETTINGS].join("/")}
				defaultTabsValue={AdvancedSettingsMenuItemReference.TAGS}
				routeParentLastPartPath={MenuItemReference.ADVANCED_SETTINGS}
			/>
			<Outlet />
		</Stack>
	);
};
