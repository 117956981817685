import { MenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import SessionController from "@application/Controllers/SessionController";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import { ChairModal, ConfirmationDialog } from "@components";
import { useChairs } from "@hooks";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { type DtoFaculty, queryStringSorts, useSnackBarHook } from "@key4-front-library/core";
import { useTheme } from "@mui/material";
import { DataGrid, metaEnv, useDialogs, useLoaderOverlay } from "@mykey4/core";
import { getExternalPath } from "@routes";
import { t } from "i18next";
import { useMemo } from "react";
import { useSessionDetailsContext } from "../../Providers";
import { buildChairColDef } from "./SessionChairsTab.configuration";

const addConfig = MenuActionsConfigurations[MenuActionsReference.ADD];

const SessionChairsTab = () => {
	const {
		event,
		client,
		sessionId,
		sessionCounters: { invalidate: invalidateCounter },
	} = useSessionDetailsContext();
	const dialog = useDialogs();
	const theme = useTheme();
	const { showLoader } = useLoaderOverlay();
	const { sendSuccess, sendError } = useSnackBarHook();

	const chairsQuery = useChairs({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		queryStrings: queryStringSorts(["order"]),
	});

	const handleCreateOrEditAction = async (chair?: DtoFaculty) => {
		await dialog.open(ChairModal, { sessionId, chairParticipantId: chair?.participantId });
		await refresh();
	};

	const handleDetailsAction = (chair: DtoFaculty) => {
		window.location.href = getExternalPath(metaEnv.myKey4.endPoint.registration, "participantDetails", [client.key, event.key, chair.participantId]);
	};

	const handleDeleteAction = async (chair: DtoFaculty) => {
		const confirmed = await dialog.open(ConfirmationDialog, { content: t("old.form.confirmationModal.delete") });
		if (!confirmed) {
			return;
		}
		const { hideLoader } = showLoader();
		SessionController.deleteChairFromSession({
			clientId: client.id,
			eventId: event.id,
			sessionId,
			chairId: chair.participantId,
		})
			.then(async () => {
				sendSuccess(t("old.programme.sessionDetails.chairs.returnMessages.success_deletion"));
				await refresh();
			})
			.catch(sendError)
			.finally(hideLoader);
	};

	const refresh = async () => {
		await Promise.all([invalidateCounter(), chairsQuery.refetch()]);
	};

	const coldDef = useMemo(
		() =>
			buildChairColDef({
				theme,
				detailsAction: handleDetailsAction,
				editAction: handleCreateOrEditAction,
				deleteAction: handleDeleteAction,
			}),
		[theme],
	);

	return (
		<IconCard
			title={t("old.programme.sessionDetails.chairs.label")}
			icon={"user-crown"}
			button={{
				label: t("old.programme.sessionDetails.chairs.titleButton"),
				icon: addConfig.icon,
				onClick: () => handleCreateOrEditAction(),
			}}
		>
			<DataGrid
				translation={"sessionChairTab.dataGrid"}
				sx={{ marginTop: "1.5em" }}
				density={"standard"}
				loading={chairsQuery.isFetching}
				columns={coldDef}
				rows={chairsQuery.data?.data ?? []}
				getRowId={(params) => params.participantId}
			/>
		</IconCard>
	);
};

export default SessionChairsTab;
