import { type TagRead, getTag, getTags } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideVersion2 } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useTags = <TData = Paginated<TagRead>>(
	clientId: string,
	eventId: string,
	tagTypeId: string,
	args: UseQueryArgs<Paginated<TagRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.tags, ...queryKeyPart, "Get", "List", clientId, eventId, tagTypeId, queryStringOverride],
		queryFn: ({ signal }) => getTags({ clientId, eventId, tagTypeId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export const useTag = (clientId: string, eventId: string, tagTypeId: string, id: string, args: UseQueryArgs<TagRead> = {}): UseQueryResult<TagRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery<TagRead>({
		queryKey: [...QUERY_KEYS_PROGRAMME.tags, ...queryKeyPart, "Get", clientId, eventId, tagTypeId, id, queryStringOverride],
		queryFn: ({ signal }) => getTag({ clientId, eventId, tagTypeId, id, queryStrings: queryStringOverride, signal }),
		...others,
	});
};
