import { type IndicatorDefinitionType, IndicatorDefinitions } from "@configurations";
import { t } from "i18next";

export interface DashboardIndicatorDefinition {
	key: string;
	icon: string;
	name: string;
}

export type DashboardIndicatorsDefinitions = {
	[K in IndicatorDefinitionType]: DashboardIndicatorDefinition;
};

export const getDashboardIndicatorDefinitions = (): DashboardIndicatorsDefinitions => {
	return {
		sessions: {
			...IndicatorDefinitions.sessions,
			name: t("old.programme.dashboard.indicators.programme.indicators.sessions"),
		},
		rooms: {
			...IndicatorDefinitions.rooms,
			name: t("old.programme.dashboard.indicators.programme.indicators.rooms"),
		},
		presentations: {
			...IndicatorDefinitions.presentations,
			name: t("old.programme.dashboard.indicators.programme.indicators.presentations"),
		},
		invitations: {
			...IndicatorDefinitions.invitations,
			name: t("old.programme.dashboard.indicators.programme.indicators.invitations"),
		},
		faculties: {
			...IndicatorDefinitions.faculties,
			name: t("old.programme.dashboard.indicators.faculties.indicators.faculties"),
		},
		chairs: {
			...IndicatorDefinitions.chairs,
			name: t("old.programme.dashboard.indicators.faculties.indicators.chairs"),
		},
		speakers: {
			...IndicatorDefinitions.speakers,
			name: t("old.programme.dashboard.indicators.faculties.indicators.speakers"),
		},
		clashes: {
			...IndicatorDefinitions.clashes,
			name: t("old.programme.dashboard.indicators.programme.indicators.clashes"),
		},
		anomalies: {
			...IndicatorDefinitions.anomalies,
			name: t("old.programme.dashboard.indicators.programme.indicators.anomalies"),
		},
	};
};
