import type { SessionRead } from "@api";
import { KeyTitleHeader } from "@components";
import { type SxProps, type Theme, Typography } from "@mui/material";
import { Icon, Stack, Tag } from "@mykey4/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { formatDateRange } from "./SessionHeader.helper";

interface SessionHeaderDetailsProps {
	primaryTagLabel?: string;
	primaryTagFontColor?: string;
	primaryTagBorderColor?: string;
	primaryTagBackgroundColor?: string;
	roomName?: string;
	startDate?: string;
	endDate?: string;
}

const SessionHeaderDetails = memo((props: SessionHeaderDetailsProps) => {
	const { t } = useTranslation();
	const { primaryTagLabel, primaryTagFontColor, primaryTagBorderColor, primaryTagBackgroundColor, roomName, startDate, endDate } = props;
	return (
		<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
			{primaryTagLabel && (
				<Tag fontColor={primaryTagFontColor} borderColor={primaryTagBorderColor} backgroundColor={primaryTagBackgroundColor}>
					{primaryTagLabel}
				</Tag>
			)}
			<Icon name={"screen-users"} sx={{ verticalAlign: "middle" }} />
			<Typography variant="body1">{roomName ?? t("sessionHeader.noRoom")}</Typography>
			<Typography variant="body1">{"-"}</Typography>
			<Typography variant="body1" fontWeight={"light"} color={"text.disabled"}>
				{formatDateRange(startDate, endDate) ?? t("sessionHeader.noDate")}
			</Typography>
		</Stack>
	);
});

export interface SessionHeaderComponents {
	topRight?: React.ReactElement;
}

interface SessionHeaderProps {
	session: SessionRead;
	onSessionKeyClick?: () => void;
	sx?: SxProps<Theme>;
	slots?: SessionHeaderComponents;
}

export const SessionHeader = (props: SessionHeaderProps) => {
	const { session, sx, slots, onSessionKeyClick } = props;

	return (
		<KeyTitleHeader keyText={session.key} title={session.title} sx={sx} variant={"body1bold"} slots={slots} onKeyClick={onSessionKeyClick}>
			<SessionHeaderDetails
				primaryTagLabel={session.primaryTag?.label}
				primaryTagFontColor={session.primaryTag?.fontColor}
				primaryTagBorderColor={session.primaryTag?.borderColor}
				primaryTagBackgroundColor={session.primaryTag?.backgroundColor}
				roomName={session.room?.name}
				startDate={session.startDate}
				endDate={session.endDate}
			/>
		</KeyTitleHeader>
	);
};
