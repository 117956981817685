import { UseSessionQueryKeys } from "@api";
import { ConfirmationDialog, GeneralSectionSession, RoomSectionSession, ScheduleSectionSession } from "@components";
import { GridLabelsTabCustomField, Services } from "@key4-front-library/core";
import { Stack, useDialogs } from "@mykey4/core";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useCallback } from "react";
import { useSessionExternalLink } from "../../Hooks";
import { useSessionDetailsContext } from "../../Providers";

export const SessionGeneralTab = (): React.ReactNode => {
	const dialog = useDialogs();
	const queryClient = useQueryClient();
	const sessionContext = useSessionDetailsContext();
	const { clientId, eventId, sessionId } = sessionContext;
	const externalLink = useSessionExternalLink(sessionContext);

	const handleExternalLinkDeleteClick = useCallback(async () => {
		const confirmed = await dialog.open(ConfirmationDialog, { content: t("advancedDialog.delete.content") });
		if (confirmed && externalLink.data) {
			Services.Events.Programme.TokenService.revokeToken(clientId, eventId, externalLink.data.tokenId).finally(() =>
				queryClient.invalidateQueries({ queryKey: [...UseSessionQueryKeys.sessionTokens(clientId, eventId, sessionId)] }),
			);
		}
	}, [clientId, eventId, sessionId, externalLink.data?.tokenId]);

	return (
		<Stack spacing={2}>
			<GeneralSectionSession
				clientId={clientId}
				eventId={eventId}
				session={sessionContext.session}
				sessionExternalLink={externalLink.data}
				onExternalLinkDeleteClick={handleExternalLinkDeleteClick}
			/>
			<RoomSectionSession session={sessionContext.session} />
			<ScheduleSectionSession session={sessionContext.session} />
			<GridLabelsTabCustomField module="programme" scope="session" id={sessionContext.sessionId} customFieldValues={sessionContext.session.customFieldValues} />
		</Stack>
	);
};
