import { KeyTitle } from "@components";
import { useKeyTitles } from "@hooks/useKeyTitle";
import { Autocomplete, type AutocompleteRenderInputParams, CircularProgress, type TextFieldProps } from "@mui/material";
import { Box } from "@mui/system";
import { TextField, useDebounce } from "@mykey4/core";
import { Fragment, type HTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import type { KeyTitleAutoCompleteItem, KeyTitleAutoCompleteOption } from "./type";

export interface KeyTitleAutocompleteSlotProps {
	textField?: Pick<TextFieldProps, "label" | "placeholder">;
}

export interface KeyTitleAutocompleteProps<T> {
	items?: T[];
	isFetching?: boolean;
	onInputValueChange: (value: string) => void;
	onOptionSelected: (option: T | null) => void;
	onOpenChange: (open: boolean) => void;
	slotProps?: KeyTitleAutocompleteSlotProps;
}

export const KeyTitleAutocomplete = <T extends KeyTitleAutoCompleteItem>(props: KeyTitleAutocompleteProps<T>) => {
	const { items, isFetching, onInputValueChange, onOptionSelected, onOpenChange, slotProps } = props;
	const [inputValue, setInputValue] = useState("");
	const [selectedOption, setSelectedOption] = useState<KeyTitleAutoCompleteOption | null>(null);
	const [open, setOpen] = useState(false);
	const debouncedSearch = useDebounce(inputValue);
	const labels = useKeyTitles<T>(items ?? []);

	const options = useMemo(() => {
		return (
			items?.map((item) => {
				const label = labels[item.id];
				return { ...item, label: `${label.key} - ${label.title}` } as KeyTitleAutoCompleteOption;
			}) ?? []
		);
	}, [items, labels]);

	useEffect(() => onOpenChange(open), [onOpenChange, open]);
	useEffect(() => onInputValueChange(debouncedSearch), [onInputValueChange, debouncedSearch]);

	const handleOptionChange = useCallback(
		(_: React.SyntheticEvent, value: KeyTitleAutoCompleteOption | null) => {
			const selected = items?.find((item) => item.id === value?.id) ?? null;
			setSelectedOption(value);
			onOptionSelected(selected);
		},
		[items, onOptionSelected],
	);

	return (
		<Autocomplete
			value={selectedOption}
			onChange={handleOptionChange}
			id="sessionSelector"
			loading={isFetching}
			multiple={false}
			options={options}
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			filterOptions={(options) => options}
			renderOption={(props: HTMLAttributes<HTMLLIElement>, option) => (
				<Box component="li" {...props} key={option.id}>
					<KeyTitle keyText={option.code || option.key} title={option.title} />
				</Box>
			)}
			renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
				<TextField
					{...params}
					{...slotProps?.textField}
					variant="outlined"
					margin="dense"
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Fragment>
								{isFetching ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</Fragment>
						),
					}}
				/>
			)}
		/>
	);
};
