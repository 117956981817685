import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import type { ISettingsSessionTagType } from "@domain/interfaces/settings.session-tag-type.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import { type DtoTagType, Locales } from "@key4-front-library/core";
import { Box, Grid, TextField } from "@mui/material";

type Props = {
	tagType: DtoTagType | null;
	editModaleClose: () => void;
	updateTagType: (data: ISettingsSessionTagType) => void;
	isPrimaryTag?: boolean;
};

const AdvancedSettingsSessionTagTypeEditModal = (props: Props) => {
	const { tagType, updateTagType: handleUpdateTagType, editModaleClose: handleEditModaleClose, isPrimaryTag } = props;

	const formLabels = {
		label: t("old.programme.advancedSettings.tagTypes.modale.edit.fields.label"),
		max: t("old.programme.advancedSettings.tagTypes.modale.edit.fields.max"),
		min: t("old.programme.advancedSettings.tagTypes.modale.edit.fields.min"),
	};

	const Schema: any = yup.object({
		label: yup.string().label(formLabels.label).required(Locales.Parsers.FormFieldRule.Required(formLabels.label)),
		max: yup
			.number()
			.nullable()
			.min(1, Locales.Parsers.FormFieldRule.MinNumber(1, formLabels.max))
			.transform((_, value) => (isNaN(value) || value === "" ? null : Number(value)))
			.label(formLabels.max),
		min: yup
			.number()
			.label(formLabels.min)
			.typeError(Locales.Parsers.FormFieldRule.Number(formLabels.min))
			.required(Locales.Parsers.FormFieldRule.Required(formLabels.min)),
	});

	const {
		register,
		handleSubmit: onSubmit,
		formState: { isSubmitting, isValid, errors },
	} = useForm<any>({
		mode: "all",
		defaultValues: {
			label: tagType?.label,
			max: tagType?.max,
			min: 0,
		},
		resolver: yupResolver(Schema),
	});

	const handleSubmit = (_values: ISettingsSessionTagType) => {
		handleUpdateTagType(_values);
	};

	return (
		<Box component="form">
			<Grid container>
				<Grid item xs={12} sm={12}>
					<TextField
						id="label"
						aria-label="label"
						{...register("label")}
						label={formLabels.label}
						placeholder={formLabels.label}
						helperText={<>{errors.label?.message}</>}
						error={!!errors.label}
						margin="dense"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id="max"
						aria-label="max"
						{...register("max")}
						type={"number"}
						label={formLabels.max}
						placeholder={formLabels.max}
						helperText={<>{errors.max?.message}</>}
						error={!!errors.max}
						margin="dense"
						fullWidth
						disabled={isPrimaryTag}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<ValidationFormButtons
						cancelButtonClick={handleEditModaleClose}
						saveButtonClick={onSubmit(handleSubmit)}
						disabledSaveButton={isSubmitting || !isValid}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default AdvancedSettingsSessionTagTypeEditModal;
