import type { SessionStatusWrite } from "@api";
import ContainerExternalSessionLinkModal from "@application/Containers/ContainerExternalSessionLinkModal";
import { ConfirmationDialog, SessionModal } from "@components";
import { ButtonsTitle, useSnackBarHook } from "@key4-front-library/core";
import { Stack } from "@mui/material";
import { useDialogs, useLoaderOverlay } from "@mykey4/core";
import { TabsLayoutWithSidePanel, getButton } from "@mykey4/core";
import { t } from "i18next";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { SessionDetailsTitle } from "./Components/SessionDetailsTitle";
import { useSessionDetails } from "./Hooks";
import { useSessionDetailsTabs } from "./Hooks";
import { getSessionDetailsActionConfigs } from "./SessionDetails.configuration";
import { SessionDetailsSidePanel } from "./SidePanel";

const sessionDetailsActionConfigs = getSessionDetailsActionConfigs();

export const SessionDetails = (): React.ReactNode => {
	const navigate = useNavigate();
	const { sendSuccess, sendError } = useSnackBarHook();
	const dialog = useDialogs();
	const { showLoader } = useLoaderOverlay();
	const [isOpenExternalLinkModal, setIsOpenExternalLinkModal] = useState(false);

	const { context, sessionTagTypes, sessionExternalLink, deleteSession, cloneSession, updateSessionStatus } = useSessionDetails();

	const { clientId, eventId, sessionId, session } = context;

	const { activeTab, tabs } = useSessionDetailsTabs();

	const handleCloneSessionClick = async () => {
		const confirmed = await dialog.open(ConfirmationDialog, { content: t("advancedDialog.clone.content") });
		if (!confirmed) {
			return;
		}
		cloneSession
			.mutateAsync()
			.then(() => sendSuccess(t("sessionDetails.snackbar.cloned")))
			.catch(sendError);
	};

	const handleDeleteSessionClick = async () => {
		const confirmed = await dialog.open(ConfirmationDialog, { content: t("advancedDialog.delete.content") });
		if (!confirmed) {
			return;
		}
		deleteSession
			.mutateAsync()
			.then(() => {
				sendSuccess(t("sessionDetails.snackbar.deleted"));
				navigate("..");
			})
			.catch(sendError);
	};

	const handleEditSessionClick = () => {
		dialog.open(SessionModal, { sessionId }).then(() => context.invalidateSession());
	};

	const handleOnSessionStatusChanged = (data: SessionStatusWrite) => {
		const { hideLoader } = showLoader();
		updateSessionStatus
			.mutateAsync({
				clientId,
				eventId,
				sessionId,
				body: data,
			})
			.then(() => sendSuccess(t("sessionDetails.snackbar.updated")))
			.catch(sendError)
			.finally(() => hideLoader());
	};

	return (
		<Stack>
			<ContainerExternalSessionLinkModal
				isOpen={isOpenExternalLinkModal}
				changeIsOpen={setIsOpenExternalLinkModal}
				sessionId={sessionId}
				refreshSession={sessionExternalLink.refetch}
			/>
			<TabsLayoutWithSidePanel
				pt={5}
				spacing={3}
				title={{
					children: <SessionDetailsTitle session={session} />,
					buttonGroup: {
						buttons: [
							{
								onClick: handleEditSessionClick,
								...getButton({ type: "update" }),
								children: t("button.edit"),
							},
						],
					},
					dropDownMenu: (
						<ButtonsTitle
							buttons={[]}
							settingsButtons={[
								{
									label: t("button.clone"),
									icon: { iconName: sessionDetailsActionConfigs.clone?.icon },
									handleClick: handleCloneSessionClick,
								},
								{
									label: t("button.delete"),
									icon: { iconName: sessionDetailsActionConfigs.delete?.icon },
									handleClick: handleDeleteSessionClick,
								},
								{
									label: t("old.form.buttons.externalEditingLink"),
									icon: { iconName: "arrow-up-right-from-square" },
									handleClick: () => setIsOpenExternalLinkModal(true),
									disabled: !!sessionExternalLink.data?.externalLink,
								},
							]}
						/>
					),
				}}
				active={activeTab}
				tabs={tabs}
				sidePanel={<SessionDetailsSidePanel session={session} sessionTagTypes={sessionTagTypes} onSessionStatusChange={handleOnSessionStatusChanged} />}
			>
				<Outlet />
			</TabsLayoutWithSidePanel>
		</Stack>
	);
};
