import type { CoAuthorRead } from "@api";
import { type BodyPostGet, type Paginated, type PathEntity, type ServiceArgs, post, postPaginated } from "@mykey4/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "co-authors", id }];

interface PostCoAuthorsArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BodyPostGet;
}

export const postCoAuthorsSearch = async (args: PostCoAuthorsArgs): Promise<Array<CoAuthorRead>> => {
	const { body, ...others } = args;
	return await post(
		{
			module,
			entities: [...rootEntities(), { entity: "search" }],
			...others,
		},
		body,
	);
};

export const postCoAuthorsSearchPaginated = async (args: PostCoAuthorsArgs): Promise<Paginated<CoAuthorRead>> => {
	const { body, ...others } = args;
	return await postPaginated(
		{
			module,
			entities: [...rootEntities(), { entity: "search" }],
			...others,
		},
		body,
	);
};
