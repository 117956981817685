import { AbstractDataGrid, AbstractFilterBar } from "@components";
import { useContextModule } from "@key4-front-library/core";
import {
	Button,
	ErrorContext,
	ItemsLists,
	PermissionContext,
	Stack,
	Typography,
	useDebounce,
	useIsModuleActivatedOnOperation,
	useUserPermissionPage,
} from "@mykey4/core";
import { getPath } from "@routes";
import { t } from "i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAbstractsBulkActionDataStore } from "./BulkAction/hooks";

export const Abstracts = () => {
	const component = "abstracts";

	useUserPermissionPage([
		"abstract_Abstract_Keyword_ReadAll",
		"abstract_Abstract_ReadAll",
		"abstract_CoAuthor_ReadAll",
		"abstract_Keyword_ReadAll",
		"abstract_Category_ReadAll",
		"abstract_Depositary_ReadAll",
		"abstract_Theme_ReadAll",
		"abstract_Type_ReadAll",
	]);

	const [search, setSearch] = useState<string>("");
	const debouncedSearch = useDebounce(search);
	const navigate = useNavigate();
	const { client, event } = useContextModule();
	const permissions = useContext(PermissionContext);

	const { setErrorType } = useContext(ErrorContext);
	const isAbstractModuleActivated = useIsModuleActivatedOnOperation(client.id, event.id, "Abstracts");

	const canUseBulkAction = permissions.includes("programme_Presentation_PatchAll");

	const [entitiesCount, setEntitiesCount] = useState<number>(0);
	const { dataStore, stepStore } = useAbstractsBulkActionDataStore();

	const resetStepStore = stepStore((state) => state.reset);
	const { setInitialData, reset: resetDataSore } = dataStore((state) => ({
		setInitialData: state.setInitialData,
		reset: state.reset,
	}));

	const onSearchChange = useCallback((search: string) => setSearch(search), []);
	const onRowCountChange = useCallback((count: number) => setEntitiesCount(count), []);

	const handleBulkActionClick = useCallback(() => {
		resetDataSore();
		resetStepStore();
		setInitialData({ search: debouncedSearch });
		navigate(getPath("bulkActionAbstract", [client.key, event.key]), {});
	}, [client.key, debouncedSearch, event.key, navigate, resetStepStore, resetDataSore, setInitialData]);

	useEffect(() => {
		if (isAbstractModuleActivated.isFetched && !isAbstractModuleActivated.data) {
			setErrorType?.("403");
		}
	}, [isAbstractModuleActivated.isFetched, isAbstractModuleActivated.data, setErrorType]);

	return (
		<Stack pt={5}>
			<ItemsLists title={{ children: <Typography variant="h4">{t(`${component}.title`)}</Typography> }}>
				<>
					<AbstractFilterBar onSearch={onSearchChange} />
					<Stack direction="row">
						<Button disabled={entitiesCount === 0 || !canUseBulkAction} onClick={handleBulkActionClick}>
							{t(`${component}.button.bulkAction`)}
						</Button>
					</Stack>
					<AbstractDataGrid search={debouncedSearch} onRowCountChange={onRowCountChange} />
				</>
			</ItemsLists>
		</Stack>
	);
};
