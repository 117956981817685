import type { AbstractRead, PresentationRead, SessionRead } from "@api";
import { DndContext, type DragEndEvent } from "@dnd-kit/core";
import { Stack, type SxProps, useTheme } from "@mui/material";
import type { Theme } from "@mui/system";
import { deepmerge } from "@mui/utils";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SessionHeader } from "../../Molecules/SessionHeader";
import { useAbstractsAssociation } from "./AbstractsAssociation.hook";
import type { AbstractsAssociationResults } from "./AbstractsAssociation.type";
import { AbstractsAssociationTranslation } from "./common";
import { AbstractAssociationContainer } from "./components";
import { AbstractAssociationDisplayAllSwitch } from "./components";

const AnimatePresenceMemo = memo(AnimatePresence);

export type AbstractsAssociationProps = {
	session: SessionRead;
	presentations: PresentationRead[];
	abstracts: AbstractRead[];
	selectedAbstractIds: string[];
	onAssociationsChange?: (associations: AbstractsAssociationResults) => void;
	containerSx?: SxProps<Theme>;
	onSessionKeyClick?: () => void;
	onPresentationKeyClick?: (presentation: PresentationRead) => void;
	onAbstractKeyClick?: (abstract: AbstractRead) => void;
};

export const AbstractsAssociation = (props: AbstractsAssociationProps) => {
	const { session, containerSx, presentations, abstracts, onSessionKeyClick, onPresentationKeyClick, onAbstractKeyClick } = props;

	const theme = useTheme();
	const { t } = useTranslation();

	const [selectedId, setSelectedId] = useState<string | null>(null);
	const [displayAll, setDisplayAll] = useState<boolean>(true);

	const { items, move, overridePresentationName } = useAbstractsAssociation({ ...props, displayAll });

	const itemsArray = useMemo(() => items.values().toArray(), [items]);

	const handleSwap = useCallback(
		(itemId: string) => {
			setSelectedId((current) => {
				if (!current) {
					return itemId;
				}
				move(current, itemId);
				return null;
			});
		},
		[move],
	);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;
			if (over && active.id !== over.id && active.data.current && over.data.current) {
				move(active.data.current.id as string, over.data.current.id as string);
			}
		},
		[move],
	);

	const handlePresentationKeyClick = useCallback(
		(presentationId: string) => {
			const presentation = presentations.find((p) => p.id === presentationId);
			if (presentation) {
				onPresentationKeyClick?.(presentation);
			}
		},
		[presentations, onPresentationKeyClick],
	);

	const handleAbstractKeyClick = useCallback(
		(abstractId: string) => {
			const abstract = abstracts.find((p) => p.id === abstractId);
			if (abstract) {
				onAbstractKeyClick?.(abstract);
			}
		},
		[abstracts, onAbstractKeyClick],
	);

	return (
		<Stack
			direction={"column"}
			spacing={1}
			padding={2}
			sx={{
				backgroundColor: theme.palette.background.tone,
				borderRadius: 2,
			}}
		>
			<SessionHeader
				session={session}
				sx={{ pb: 1 }}
				onSessionKeyClick={onSessionKeyClick}
				slots={{
					topRight: (
						<AbstractAssociationDisplayAllSwitch
							toolTipText={t(`${AbstractsAssociationTranslation}.displayAll.tooltip`)}
							checked={displayAll}
							onChange={(e) => setDisplayAll(e.target.checked)}
							color="primary"
							typography={{
								variant: "body1",
								children: t(`${AbstractsAssociationTranslation}.displayAll`),
								sx: { whiteSpace: "nowrap", flexShrink: 0 },
							}}
						/>
					),
				}}
			/>
			<Stack sx={deepmerge({}, containerSx)} spacing={1}>
				<DndContext onDragEnd={handleDragEnd}>
					<LayoutGroup>
						<AnimatePresenceMemo propagate>
							{itemsArray.map((item) => (
								<AbstractAssociationContainer
									key={item.id}
									item={item}
									selected={selectedId === item.id}
									onSelectClick={handleSwap}
									onPresentationKeyClick={handlePresentationKeyClick}
									onAbstractKeyClick={handleAbstractKeyClick}
									onOverridePresentationNameClick={overridePresentationName}
								/>
							))}
						</AnimatePresenceMemo>
					</LayoutGroup>
				</DndContext>
			</Stack>
		</Stack>
	);
};
