import { Tooltip, Typography } from "@mykey4/core";
import type { TextVariant } from "@mykey4/core/Theme/Theme.types";
import { memo, useState } from "react";

export const AbstractTypographyCell = memo((props: { text?: string; tooltipText?: string; tooltip?: boolean; variant?: TextVariant }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	let hoverTimer: ReturnType<typeof setTimeout>;

	const handleMouseEnter = () => {
		if (!props.tooltip) {
			return;
		}
		hoverTimer = setTimeout(() => {
			setTooltipOpen(true);
		}, 500);
	};

	const handleMouseLeave = () => {
		clearTimeout(hoverTimer);
		setTooltipOpen(false);
	};

	return (
		<Tooltip title={props.tooltipText || props.text || ""} open={tooltipOpen} disableFocusListener disableHoverListener disableTouchListener>
			<Typography onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} variant={props.variant}>
				{props.text}
			</Typography>
		</Tooltip>
	);
});
