import type { ISettingsSchedulerEditType } from "@application/components/settings/scheduler/scheduler/SettingsSchedulerEdit";
import type { INumberingSettings } from "@domain/interfaces/settings.interface";
import type { INumberingModel, IPutNumbering, ITag, TPutSettingsScheduler } from "@domain/model/settings.model";
import { Services } from "@key4-front-library/core";
import type { DtoClashGetSettings } from "@key4-front-library/core/Dto";
import { DateTime } from "luxon";

const getSettingsClashes = async (clientId: string, eventId: string): Promise<DtoClashGetSettings> => {
	return await Services.Events.Programme.ClashesService.getSettings(clientId, eventId);
};

const getNumberingSettings = async (clientId: string, eventId: string): Promise<INumberingSettings> => {
	const numberingData: INumberingModel = await Services.Events.Programme.SettingsService.getNumbering(clientId, eventId);
	return {
		primaryTagLabel: numberingData.primaryTag ?? "",
		rows: numberingData.tags
			? numberingData.tags.map((tag: ITag) => {
					const primaryTag = {
						chip: tag
							? {
									backgroundColor: tag.backgroundColor,
									borderColor: tag.borderColor,
									fontColor: tag.fontColor,
									label: tag.label,
								}
							: undefined,
					};
					return {
						id: tag.id,
						primaryTag,
						sessionPrefix: tag.sessionPrefix ?? "",
						presentationPrefix: tag.presentationPrefix ?? "",
					};
				})
			: [],
	};
};

const editNumberingTag = async (clientId: string, eventId: string, tagId: string, body: IPutNumbering): Promise<boolean> => {
	return await Services.Events.Programme.SettingsService.putNumbering(clientId, eventId, tagId, body);
};

const updateSettingScheduler = async (clientId: string, eventId: string, form: ISettingsSchedulerEditType): Promise<boolean> => {
	const body: TPutSettingsScheduler = {
		programmeStartDate: form.startDate
			? DateTime.fromFormat(`${form.startDate} ${form.startTime?.toFormat("HH:mm")}`, "yyyy-MM-dd HH:mm", { zone: "UTC" })
					.toUTC()
					.toString()
			: null,
		programmeEndDate: form.endDate
			? DateTime.fromFormat(`${form.endDate} ${form.endTime?.toFormat("HH:mm")}`, "yyyy-MM-dd HH:mm", { zone: "UTC" })
					.toUTC()
					.toString()
			: null,
		schedulerStartHour: form.startTime ? form.startTime.toFormat("HH:mm") : null,
		schedulerEndHour: form.endTime ? form.endTime.toFormat("HH:mm") : null,
	};

	return await Services.Events.Programme.SettingsService.putScheduler(clientId, eventId, body);
};

const SettingsController = {
	getSettingsClashes,
	getNumberingSettings,
	editNumberingTag,
	updateSettingScheduler,
};

export default SettingsController;
