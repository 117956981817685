import type { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const mapKeyTitle = (t: TFunction<"translation", undefined>, key?: string, title?: string) => {
	const isKeyDefined = key && key.trim() !== "";
	const isTitleDefined = title && title.trim() !== "";
	const displayKey = isKeyDefined ? key : t("keyTitle.codeNotDefine");
	const displayTitle = isTitleDefined ? title : t("keyTitle.titleNotDefine");
	return {
		key: displayKey,
		title: displayTitle,
	};
};

export interface UseKeyTitleArgs {
	key?: string;
	title?: string;
}

export const useKeyTitle = <T extends UseKeyTitleArgs>(args: T) => {
	const { t } = useTranslation();
	const { key, title } = args;
	return useMemo(() => mapKeyTitle(t, key, title), [key, title, t]);
};

export const useKeyTitles = <T extends UseKeyTitleArgs & { id: string }>(items: T[]) => {
	const { t } = useTranslation();
	return useMemo(() => {
		const result: Record<string, { key: string; title: string }> = {};
		for (const item of items) {
			result[item.id] = mapKeyTitle(t, item.key, item.title);
		}
		return result;
	}, [items, t]);
};
