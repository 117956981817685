import { type ClashRead, getClashes } from "@api";
import { API_VERSION_QUERY_PARAM, type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useClashes = <TData = Paginated<ClashRead>>(
	clientId: string,
	eventId: string,
	args: UseQueryArgs<Paginated<ClashRead>, TData> = {},
): UseQueryResult<TData> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.clashes, ...queryKeyPart, "Get", "List", clientId, eventId],
		queryFn: ({ signal }) =>
			getClashes({ clientId, eventId, queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]), signal }),
		...others,
	});
};
